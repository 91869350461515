import React from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { Button, Row } from 'antd';
import useGlobal from '@trinity-incyte/store';
import { showFiltersState, closeSidebarsSelector, QlikUser as QlikUserAtom } from '@trinity-incyte/recoil';
import { FilterFilled } from '@ant-design/icons';

declare var window: any;

/* eslint-disable-next-line */
export interface FilterButtonsProps {}

export function FilterButtons(props: FilterButtonsProps | any) {
	const [, globalActions] = useGlobal();
	const { config } = props;
	const [showFilters, setShowFilters] = useRecoilState(showFiltersState);
	const closeSidebars = useSetRecoilState(closeSidebarsSelector);
  const QlikUser = useRecoilValue(QlikUserAtom);
	const onClickClear = () => {
		window.analytics?.track('Button Clicked', { text: 'Clear Filters', context: 'Main Nav Clear Filters Button Clicked' });
		globalActions.Qlik.clearAppSelections( config, true );
		closeSidebars(null);
	};
	const content = (
		<>
			<div style={{display:'flex', flexWrap: 'nowrap'}}>
			{( !props.hideClear ) && (QlikUser.showSidebarFilters) && (
				<div
					style={{ flexFlow:'1', minWidth:'60px', fontWeight:700, fontSize:'0.9em', textAlign:'center', height:'100%' }}
					className="btn-zoomer green with-icon"
					onClick={onClickClear}
				>
					Clear
				</div>
			)}
			{( !props.hideFilters ) && (QlikUser.showSidebarFilters) && (
				<div 
					style={{ minWidth:'60px', fontWeight:700, fontSize:'0.9em', textAlign:'center', height:'100%' }}
					className="btn-zoomer orange with-icon"
					onClick={() => {
						window.analytics?.track('Button Clicked', { text: 'Show Filters', context: 'Main Nav Show Filters Button Clicked' });
						setShowFilters(!showFilters);
					}}
				>
					<FilterFilled style={{fontSize: '0.9em', margin:'0px 2px 0px 0px', padding:'0'}} />
					Filters
				</div>
			)}
			</div>
		</>
	);

	return ( content );
};

export default FilterButtons;
