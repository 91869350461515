import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Sash } from '@trinity-incyte/ui';
import { toggleSubPageSelector } from '@trinity-incyte/recoil';
import { LinkOutlined, TableOutlined, StopOutlined } from '@ant-design/icons';

/* eslint-disable-next-line */
export interface KPIBoxProps {}

export function KPIBox(props: KPIBoxProps | any) {
    const toggleSubPage = useSetRecoilState(toggleSubPageSelector);
	const history = useHistory();

	const headerClass = ['kpibox-header'];
	if ( props.canClick && props.header ) {
		headerClass.push('link-attached');
	}

	// TODO: refactor this to make KPIBox easier to work with
	const onClick = () => {
        const { mashupId, hStyles, tableProps, dynamic } = props;
		if ( mashupId ) {
            toggleSubPage({
                mashupId,
                dynamic,
                tableProps: tableProps || {
                    colOverrides: hStyles
                        ? hStyles.map((item, index) => ({
                              columns: [index],
                              hStyles: Object.assign({}, item), // Tables will try to modified this info so need to create a deep copy of widths
                          }))
                        : [],
                },
            });
		} else if ( props.to ) {
			history.push( props.to );
		}
	};

	const content = (
		<div id={props.id} onClick={props.canClick ? onClick : undefined} color={props.color} className={`kpiBoxInner ${props.customClass} ${props.canClick ? 'box-clickable' : ''}`} style={{ ...props.style, background: 'transparent', textAlign: "left" }}>
			{(props.canClick) && (
				<Sash
					color={props.sashColor || "#4477aa"}
					corner="left"
					margin="-4px"
					size= {props.size || "sm"}
					text={props.mashupId ? <TableOutlined />: <LinkOutlined />}
				/>
			)}
			{( props.header ) && (
				<span className={headerClass.join(' ')}>{props.header}</span>
			)}
			{props.children}
			{props.addOn}
		</div>
	);

	return (
		content
	);
};


export default KPIBox;
