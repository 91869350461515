import React, { useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Result, Button as AntButton, Space } from 'antd';
import { CloseSquareOutlined } from '@ant-design/icons';
import { ConfigContext } from '@trinity-incyte/context';
import { closeModalSelector, AppState as AppStateAtom } from '@trinity-incyte/recoil';
import './maintenance-page.scss';

/* eslint-disable-next-line */
export interface MaintenancePageProps {}

export function MaintenancePage(props: MaintenancePageProps) {
	const Config = useContext(ConfigContext);
	const AppState = useRecoilValue(AppStateAtom);

	return (
		<div>
			<Result
				title="Under Maintenance"
				subTitle={
					(AppState.content)
					? (<>
							{AppState.content}
						</>)
					: (<>
							<p>The application is currently under maintenance.</p>
							<p>Please wait at least a couple minutes, then refresh the page to try again.</p>
						</>)
				}
				extra={
					<Space size="middle">
						<AntButton
							style={{backgroundColor:"green", borderColor:"green", color:"white"}}
							onClick={() => {
								window.location.href = `${Config.App.authFailRedirectUrl}&redirectUrl=${localStorage.getItem('currentPage')}`;
							}}
							onKeyUp={() => {
								window.location.href = `${Config.App.authFailRedirectUrl}&redirectUrl=${localStorage.getItem('currentPage')}`;
							}}
						>
							Refresh
						</AntButton>
					</Space>
				}
			>
			</Result>
		</div>
	);
};

export default MaintenancePage;
