import React from 'react';
import { Typography } from 'antd';
import { Segment } from "@trinity-incyte/ui";
import styled from 'styled-components';
import SideNavGroup from '../../Groups/side-nav-group/side-nav-group';
import './qlik-connection-error.module.scss';

const Container = styled(Segment)`
	box-shadow: none; 
	border: none; 
	border-radius: 0; 
	text-align: center;
`;

/* eslint-disable-next-line */
export interface QlikConnectionErrorProps {
    errorText?: any;
}

export function QlikConnectionError(props: QlikConnectionErrorProps) {
	let { errorText } = props;
	if ( !errorText ) errorText = 'Application encountered an error. Please refresh the page.';

	return (
		<>
			<SideNavGroup simplified />
			<Container color="red">
				<Typography.Title level={2}>{errorText}</Typography.Title>
			</Container>
		</>
	);
};

export default QlikConnectionError;
