import React, { useEffect, useState } from 'react';
import { Button, Image, Row, Col } from 'antd';
import QSMashupObject from '../Qlik/QSMashupObject';
import QSSelectionsListener from '../Qlik/QSSelectionsListener';
import Config from '../../Config';
import KPIBox from '../Boxes/KPIBox';
import Utils from '../../Utils';
import { UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import useGlobal from '../../Store';
import styled from 'styled-components';

// import mapLegendImage from '../../Assets/Images/BMTCenterMapLegend.png';
import mapLegendImage from '../../Assets/Images/MapLegend.png';

// import TierButtonsGroup from './TierButtonsGroup';  PAB - Removed Tier Buttons

let tableHeight; // Which vh to use for container (non-nav) height;
let mapSize;
let kpiHeight; // Which vh to use for container (non-nav) height;

const ShowMapButton = styled(Button)`
	margin-left: 4px;
	font-family: Lato; 
	font-size: 0.8em;
	background-color: #e0e1e2 !important;
	border-color: #e0e1e2 !important; 
`;

const KPIRow = styled(Row)`
	width: 100%;
	margin-left: 0px !important;
	margin-right: 0px !important;
`;

const setSizes = () => {
	const screen = Utils.screen();
	if ( screen.class === 'TABLET' && screen.orientation === 'LANDSCAPE' ) {
		tableHeight = 60;
		kpiHeight = 7;
	} else if ( screen.orientation === 'PORTRAIT' ) {
		tableHeight = 66;
		kpiHeight = 6;
	} else {
		tableHeight = 60;
		kpiHeight = 7;
	}


	if ( screen.orientation === 'PORTRAIT' ) {
		mapSize = {
			h: Math.floor( tableHeight ) * 0.4,
			w: 30,
		};
	} else {
		mapSize = {
			h: Math.floor( tableHeight ) * 0.58,
			w: 34,
		};
	}
};

const OverviewGroup = () => {
	const [globalState] = useGlobal();
	const [showMap, setShowMap] = useState( false );
	const [buttonHasBeenClicked, setButtonHasBeenClicked] = useState( false );
	const { appId } = Config.Qlik.app1.ids;

	setSizes();
	useEffect(() => {
		setSizes();
	}, [globalState.UI.screenOrientation]);

	const mapTransplantCenter = (
		<QSMashupObject
			addon={(
				<div>
					<div style={{ position: 'absolute', zIndex: 1, height: '100%', width: '100%', opacity: 0, bottom: '0px', marginLeft: '-1rem' }} />
					<div style={{ position: 'absolute', zIndex: 1, bottom: '22px', left: '22px', maxWidth: `${Math.floor( mapSize.w * 0.3 )}vw`, opacity: '0.7' }}>
						<Image src={mapLegendImage} />
					</div>
				</div>
			)}
			showMaximize
			contentStyle={{ height: `${Math.floor( mapSize.h )}vh` }}
			appId={appId}
			mashupId={Config.Qlik.app1.ids.masterItems['Transplant Center Map']}
			elementId="qs2"
			loaderSize="massive"
			loaderText="Center Map"
			onToggleMaximize={( el, isMaximized ) => {
				if ( !isMaximized ) {
					setShowMap( false );
					setTimeout(() => {
						setShowMap( true );
					}, 500 );
				}
			}}
		/>
	);

	const tableStaff = ( globalState.UI.showDetails ) ? [] : (
		<QSMashupObject
			showExports
			contentStyle={{ height: `${tableHeight}vh` }}
			appId={appId}
			mashupId={Config.Qlik.app1.ids.masterItems['BMT Staff Search Table']}
			elementId="qs3"
			loaderSize="massive"
			loaderText="BMT Staff Search Table"
		/>
	);

	const tableTransplantCenter = ( globalState.UI.showDetails ) ? [] : (
		<QSMashupObject
			addon={(
				<div style={{ position: 'absolute', zIndex: 4, top: '5px', right: '48px'}}>
					<ShowMapButton
						size="small"
						className={`show-map-button ping ${( buttonHasBeenClicked ) ? '' : 'ping-active'}`}
						onClick={() => {
							setShowMap( !showMap );
							if ( !buttonHasBeenClicked ) {
								setButtonHasBeenClicked( true );
							}
						}}
					>
						{( showMap ) ? <UpCircleOutlined style={{ color: '#2185d0' }} /> : <DownCircleOutlined style={{ color: '#2185d0' }} /> }

						{( showMap ) ? 'Hide Map' : 'Show Map'}
					</ShowMapButton>
						{( showMap )
							&& (
								<div style={{ marginTop: '2px', position: 'fixed', width: `${mapSize.w}vw`, zIndex: 4 }}>
									{mapTransplantCenter}
								</div>
						)}
				</div>
			)}
			showExports
			key="qs1"
			contentStyle={{ height: `${tableHeight}vh`, overflow: 'hidden' }}
			appId={appId}
			mashupId={Config.Qlik.app1.ids.masterItems['Transplant Center Table']}
			exportId={Config.Qlik.app1.ids.masterItems['Transplant Center Excel Table']}
			elementId="qs1"
			loaderSize="massive"
			loaderText="Transplant Center Table"
		/>
	);

	const content = (
		<>
			<QSSelectionsListener appId={appId} />
			<div>
				<KPIRow gutter={[24, 0]}>
					<Col span={8}>
						<KPIBox minimalHeader customClass="kpibox kpi-blue" header="Centers">
							<div>
								<Row>
									<Col span={24}>
										<QSMashupObject
											basic
											contentStyle={{ height: `${kpiHeight * 1.3}vh`, padding: '4px', marginBottom: '0px' }}
											styleKpi="font-size: 4rem;"
											appId={appId}
											mashupId={Config.Qlik.app1.ids.masterItems['Total Allo Centers KPI']}
											elementId="main_KPI1"
											loaderSize="mini"
											isText
										/>
									</Col>
								</Row>
								<Row>
									<Col span={12}>
										<QSMashupObject
											basic
											contentStyle={{ height: `${kpiHeight * 1.1}vh`, padding: '4px', marginBottom: '0px' }}
											styleKpi="font-size: 3.4rem;"
											appId={appId}
											mashupId={Config.Qlik.app1.ids.masterItems['Total Allo Centers Tier 1 KPI']}
											elementId="main_KPI2"
											loaderSize="mini"
											isText
										/>
									</Col>
									<Col span={12}>
										<QSMashupObject
											basic
											contentStyle={{ height: `${kpiHeight * 1.1}vh`, padding: '4px', marginBottom: '0px' }}
											styleKpi="font-size: 3.4rem;"
											appId={appId}
											mashupId={Config.Qlik.app1.ids.masterItems['Total Allo Centers Tier 2 KPI']}
											elementId="main_KPI3"
											loaderSize="mini"
											isText
										/>
									</Col>
								</Row>
							</div>
						</KPIBox>
					</Col>
					<Col span={8}>
						<KPIBox minimalHeader customClass="kpibox kpi-blue" header="SD Purchases">
							<div>
								<Row>
									<Col span={24}>
										<QSMashupObject
											basic
											contentStyle={{ height: `${kpiHeight * 1.3}vh`, padding: '4px', marginBottom: '0px' }}
											styleKpi="font-size: 4rem;"
											appId={appId}
											mashupId={Config.Qlik.app1.ids.masterItems['SD Purchases KPI']}
											elementId="main_KPI4"
											loaderSize="mini"
											isText
										/>
									</Col>
								</Row>
								<Row>
									<Col span={12}>
										<QSMashupObject
											basic
											contentStyle={{ height: `${kpiHeight * 1.1}vh`, padding: '4px', marginBottom: '0px' }}
											styleKpi="font-size: 3.4rem;"
											appId={appId}
											mashupId={Config.Qlik.app1.ids.masterItems['SD Purchases Tier 1 KPI']}
											elementId="main_KPI5"
											loaderSize="mini"
											isText
										/>
									</Col>
									<Col span={12}>
										<QSMashupObject
											basic
											contentStyle={{ height: `${kpiHeight * 1.1}vh`, padding: '4px', marginBottom: '0px' }}
											styleKpi="font-size: 3.4rem;"
											appId={appId}
											mashupId={Config.Qlik.app1.ids.masterItems['SD Purchases Tier 2 KPI']}
											elementId="main_KPI6"
											loaderSize="mini"
											isText
										/>
									</Col>
								</Row>
							</div>
						</KPIBox>
					</Col>
					<Col span={8}>
						<KPIBox minimalHeader customClass="kpibox kpi-blue" header="Transplants">
							<div>
								<Row>
									<Col span={24}>
										<QSMashupObject
											basic
											contentStyle={{ height: `${kpiHeight * 1.3}vh`, padding: '4px', marginBottom: '0px' }}
											styleKpi="font-size: 4rem;"
											appId={appId}
											mashupId={Config.Qlik.app1.ids.masterItems['Total Allo Transplants KPI']}
											elementId="main_KPI7"
											loaderSize="mini"
											isText
										/>
									</Col>
								</Row>
								<Row>
									<Col span={12}>
										<QSMashupObject
											basic
											contentStyle={{ height: `${kpiHeight * 1.1}vh`, padding: '4px', marginBottom: '0px' }}
											styleKpi="font-size: 3.4rem;"
											appId={appId}
											mashupId={Config.Qlik.app1.ids.masterItems['Total Allo Transplants Tier 1 KPI']}
											elementId="main_KPI8"
											loaderSize="mini"
											isText
										/>
									</Col>
									<Col span={12}>
										<QSMashupObject
											basic
											contentStyle={{ height: `${kpiHeight * 1.1}vh`, padding: '4px', marginBottom: '0px' }}
											styleKpi="font-size: 3.4rem;"
											appId={appId}
											mashupId={Config.Qlik.app1.ids.masterItems['Total Allo Transplants Tier 2 KPI']}
											elementId="main_KPI9"
											loaderSize="mini"
											isText
										/>
									</Col>
								</Row>
							</div>
						</KPIBox>
					</Col>
				</KPIRow>
				<Row style={{paddingTop: '1rem'}} gutter={[24, 0]}>
					<Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 13}} xl={{span: 13}}>
						{tableTransplantCenter}
					</Col>
					<Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} lg={{span: 11}} xl={{span: 11}}>
						{tableStaff}
					</Col>
				</Row>
			</div>
		</>
);
return ( content );
};

export default OverviewGroup;
