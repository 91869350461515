// This component is the Left Slider that contains the Prof Details card and the 
// Barriers and DLBCL sliders in Hemonc HCP360

import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Button } from 'antd';
import styled from 'styled-components';
import { closeLeftSliderSelector, leftSliderState } from '@trinity-incyte/recoil';

const Styles = styled.div`
    background: whitesmoke;
    position: absolute;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 100% !important;
    bottom: 0;
    left: 0;
    overflow-y: hidden;
    padding: 1rem;
    transition: all 0.3s linear;
    height: auto;
    width: 70rem;
    z-index: 10;
    display: flex;
    align-items: flex-start;
    align-items: flex-start;
    flex-flow: row-reverse wrap;
    > {
      button.button,
      div[role='link'] {
        flex: 1;
      }
    }
`;

const CloseButton = styled(Button)`
  width: 100%;
  background-color: black;
  border-color: black;
  color: white;
`;


/* eslint-disable-next-line */
export interface LeftSliderProps {}

export function LeftSlider(props: LeftSliderProps) {
  const leftSlider = useRecoilValue(leftSliderState);
  const closeLeftSlider = useSetRecoilState(closeLeftSliderSelector);

useEffect(() => {
  return () => closeLeftSlider(null);
}, []);

  return (
    <Styles
      className="filter-sidebar"
      style={{
        transform: `translateX(${leftSlider.isOpen ? 0 : 'calc(-100%)'})`,
      }}
    >
      <CloseButton
        className="square-corners"
        onClick={closeLeftSlider}
      >
        Close
      </CloseButton>
      {leftSlider.content}
    </Styles>
  );
};

export default LeftSlider;
