import React, { useEffect, useState } from 'react';
import { Typography, Statistic, Row, Col, RowProps } from 'antd';
import { UseWindowSize } from '@trinity-incyte/hooks';

import './text-kpi.module.scss';

/* eslint-disable-next-line */
export interface TextKPIProps {
  title: string;
  value: string;
  reverseOrder?: boolean;
  titleStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
  titleLevel?: 1 | 2 | 3 | 4 | 5;
  valueLevel?: 1 | 2 | 3 | 4 | 5;
  titleJustify?: RowProps['justify'];
  valueJustify?: RowProps['justify'];
  titleAlign?: RowProps['align'];
  valueAlign?: RowProps['align'];
  horizontalJustify?: RowProps['justify'];
  horizontalAlign?: RowProps['align'];
  orientation?: 'horizontal' | 'vertical';
}

export function TextKPI({
  title,
  value,
  reverseOrder = false,
  titleStyle = {},
  valueStyle = {},
  titleLevel = 5,
  valueLevel = 3,
  titleJustify = 'center',
  valueJustify = 'center',
  titleAlign = 'middle',
  valueAlign = 'bottom',
  horizontalJustify = 'space-between',
  horizontalAlign = 'middle',
  orientation = 'horizontal'
}: TextKPIProps) {
	const windowSize = UseWindowSize();
  const [finalTitleLevel, set_finalTitleLevel] = useState(titleLevel);
  const [finalValueLevel, set_finalValueLevel] = useState(valueLevel)
  const finalTitleStyle = {
    margin: '0 4px',
    ...titleStyle
  };
  const finalValueStyle = {
    margin: '0 4px',
    ...valueStyle
  };
  
  /**
   * TODO: This sort of thing would be great as a utility hook
   * And can use the breakpoints we already have defined in the media queries
   * You may be able to use CSS variables even to drive this, rather than listening on window resize?
   */
	useEffect(() => {
		if (windowSize.height < 700) {
			set_finalValueLevel(3);
      set_finalTitleLevel(5);
		} else if (windowSize.height < 1081) {
			set_finalValueLevel(2);
      set_finalTitleLevel(4);
		} else {
			set_finalValueLevel(1);
      set_finalTitleLevel(3);
		}
	}, [windowSize]);

  const displayElements = [
    <Typography.Title level={finalTitleLevel} style={finalTitleStyle}>
      {title}
    </Typography.Title>,
    <Typography.Title level={finalValueLevel} style={finalValueStyle}>
      {value}
    </Typography.Title>
  ];

  if (reverseOrder) displayElements.reverse();

  let content;
  if (orientation === 'horizontal') {
    content = (
      <Row justify={horizontalJustify} align={horizontalAlign}>
        <Col flex="none">{displayElements[0]}</Col>
        <Col flex="auto">{displayElements[1]}</Col>
      </Row>
    );
  } else {
    content = (
      <>
        <Row justify={titleJustify} align={titleAlign}>
          {displayElements[0]}
        </Row>
        <Row justify={valueJustify} align={valueAlign}>
          {displayElements[1]}
        </Row>
      </>
    );
  }

  return content;
}

export default TextKPI;
