import { useEffect } from 'react';

/* eslint-disable-next-line */
export interface UsePageTitleProps {
    defaultTitle: string;
    title?: string;
}

export function usePageTitle({ defaultTitle, title }: UsePageTitleProps) {
    useEffect(() => {
        document.title = title ? `${defaultTitle} - ${title}` : defaultTitle;
        return () => {
            document.title = defaultTitle;
        };
    }, [title]);
    return undefined;
}

export default usePageTitle;
