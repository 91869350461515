import { ConfigContext, HCP360State } from '@trinity-incyte/context';
import { 
	QSAppMetadataFamily,
	toggleLeftSliderSelector
} from '@trinity-incyte/recoil';
import React, { useContext } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { Button } from 'antd';
import './prof-cell.module.scss';
import { modalState, closeModalSelector, toggleSubPageSelector, closeSubPageSelector } from '@trinity-incyte/recoil';

/* eslint-disable-next-line */
export interface ProfCellProps {
  value?: any
	className?: string;
	fontSize?: any;
}

export function ProfCell( props ) {
	const toggleSubPage = useSetRecoilState(toggleSubPageSelector);
	const closeSubPage = useSetRecoilState(closeSubPageSelector);
    const modal = useRecoilValue(modalState);
	const closeModal = useSetRecoilState(closeModalSelector);
	const toggleLeftSlider = useSetRecoilState(toggleLeftSliderSelector);
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.Prof360;
	const { appId } = config.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;
	const isDerm = Config.App.name === "MosaicDerm";

	const ProfKPIComp = Config.App.profComponent;

	let profId;
	if ( props.value && props.value !== '-' && props.value !== '' ) {
		if ( Number.isNaN( parseInt( props.value, 10 ))) {
			// Custom selection needed
			profId = props.value.split('(')[1];
			if ( profId ) {
				profId = profId.split(')')[0];
			} else {
				profId = false;
			}
		} else {
			// It's a regular PROF ID
			profId = parseInt( props.value, 10 );
		}
	}

	let content;
	if ( profId ) {
		content = (
			<Button
				className="button-cell green-cell"
				data-action="self"
				data-profid={props.value}
				size="small"
				style={{
					position: 'relative',
					float: 'right',
					fontWeight: 'bold',
					padding: '6px',
					margin: '2px',
					borderColor: '#21BA45',
					color: '#21BA45',
					backgroundColor: 'transparent',
					borderRadius: '3px',
					fontSize: props.fontSize || '.8em',
					lineHeight: '0rem',
					textAlign: 'center'

				}}
				onClick={() => {
					if (isDerm) {
						toggleLeftSlider({
							content: (<ProfKPIComp profId={profId} horizontal ids={ids} fields={fields} />),
							key: profId
						});
					} else {
						closeSubPage();

						toggleLeftSlider({
							content: (<HCP360State profId={profId}><ProfKPIComp profId={profId} horizontal ids={ids} fields={fields} /></HCP360State>),
							key: profId
					});
					}
				}}
			>Prof Details</Button>
		);
	} else {
		content = ( <></> );
	}

	return content;
};

export default ProfCell;
