// TODO: Write a component description that helps with autocomplete
import React from 'react';
import {
  Row, Col
} from 'antd';

import './center.module.scss';

/* eslint-disable-next-line */
export interface CenterProps {
  children: any;
  centering?: 'horizontal' | 'vertical' | 'both';
  offset?: number[];
  // TODO: also figure out how to pin things to corners
}

export function Center({
  children,
  centering = 'both',
  offset = [0, 0],
}: CenterProps) {
  // TODO: figure out how to do all centering options
  return (
    <Row align="middle" style={{ height: '100%', width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'center', flex: '1 1 auto'}}>
        <Col>
          {children}
        </Col>
      </div>
    </Row>
  );
}

export default Center;
