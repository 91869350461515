import React from 'react';
import {
  Row, Col, Spin, SpinProps
} from 'antd';
import {
  Center
} from '../center/center';

import './loader.module.scss';

/* eslint-disable-next-line */
export interface LoaderProps {
  size?: SpinProps['size'];
  text?: string | null;
  color?: 'red' | 'blue' | 'green';
  fontSize?: any;
}

export function Loader({
  size = 'default',
  text = null,
  color = 'blue',
  fontSize
}: LoaderProps) {
  const spinStyle = {
    flex: '1 1 auto',
    filter: null,
  };

  if (color === 'red') {
    spinStyle.filter = 'hue-rotate(135deg)';
  } else if (color === 'green') {
    spinStyle.filter = 'hue-rotate(270deg)';
  }

  return (
    <Center>
      <Row>
        <Spin style={spinStyle} size={size}  />
      </Row>
      {(text) && (
        <Row>
          <span style={{ display: 'inline-block', verticalAlign: 'middle', fontSize: fontSize }}>
            {text}
          </span>
        </Row>
      )}
    </Center>
  );
}

export default Loader;
