import React from 'react';
import { Row, Col } from 'antd';
import QSMashupObject from '../../Qlik/QSMashupObject';
import Config from '../../../Config';
import KPIBox from '../../Boxes/KPIBox';
import styled from 'styled-components';

const TeamInfoContainer = styled.div`
	height: 66vh; 
	width: 100%; 
	padding-top: 1rem;
`;

const { appId } = Config.Qlik.app1.ids;

const kpiHeight = 11;

const IncyteTeamInfo = () => (
	<TeamInfoContainer id="IncyteTeamInfoContainer">
		<div>
			<Row>
				<Col span={12}>
					<QSMashupObject
						appId={Config.Qlik.app1.ids.appId}
						mashupId={Config.Qlik.app1.ids.other['Sales Team Table']}
						elementId="tab3_qs1"
						contentStyle={{ height: '60vh', border: 'white' }}
						loaderSize="large"
						loaderText="Sales Team"
					/>
				</Col>
				<Col span={1}></Col>
				<Col span={9}>
					<KPIBox customClass="kpibox kpi-blue" header="BMT Staff Interactions (R12Mo)">
						<div style={{ marginTop: '20px' }}>
							<Row justify='center'>
								<Col>
									<QSMashupObject
										basic
										contentStyle={{ height: `${kpiHeight * 1}vh`, padding: '4px', marginBottom: '0px' }}
										appId={appId}
										mashupId={Config.Qlik.app1.ids.masterItems['Sales Team OAS Calls KPI']}
										elementId="tab3_qs4"
										loaderSize="mini"
										isText
									/>
								</Col>
							</Row>
							<Row justify='center'>
								<Col>
									<QSMashupObject
										basic
										contentStyle={{ height: `${kpiHeight * 1}vh`, padding: '4px', marginBottom: '0px' }}
										appId={appId}
										mashupId={Config.Qlik.app1.ids.masterItems['Sales Team OTS Calls KPI']}
										elementId="tab3_qs3"
										loaderSize="mini"
										isText
									/>
								</Col>
							</Row>
							<Row justify='center'>
								<Col>
									<QSMashupObject
										basic
										contentStyle={{ height: `${kpiHeight * 1}vh`, padding: '4px', marginBottom: '0px' }}
										appId={appId}
										mashupId={Config.Qlik.app1.ids.masterItems['Sales Team MSL Engagements KPI']}
										elementId="tab3_qs2"
										loaderSize="mini"
										isText
									/>
								</Col>
							</Row>
							<Row justify='center'>
								<Col>
									<QSMashupObject
										basic
										contentStyle={{ height: `${kpiHeight * 1}vh`, padding: '4px', marginBottom: '0px' }}
										appId={appId}
										mashupId={Config.Qlik.app1.ids.masterItems['Sales Team OCNE Engagements KPI']}
										elementId="tab3_qs5"
										loaderSize="mini"
										isText
									/>
								</Col>
							</Row>
						</div>
					</KPIBox>

				</Col>
				<Col></Col>
			</Row>
		</div>
	</TeamInfoContainer>
);

export default IncyteTeamInfo;
