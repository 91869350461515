/* eslint-disable camelcase */
import React, { createRef, useEffect, useRef } from 'react';
import {
	Menu,
} from 'antd';

const contextRef = createRef();
let isMouseDown = false;
let isTouchStarted = false;
const handleActiveElement = ( event, onChange ) => {
	const active = document.elementFromPoint( event.pageX, event.pageY ); // Can't use event.target because of touch events; this should be reliably cross-platform
	if ( active && active.classList.contains( 'item' ) && !active.classList.contains( 'active' )) {
		let activeIndex;
		if ( active.previousElementSibling === null ) {
			activeIndex = 0;
		} else {
			const indexZeroElement = active.parentElement.firstElementChild;
			let currentIndex = 1;
			const compare = ( element ) => {
				if ( indexZeroElement === element ) {
					return currentIndex;
				} else {
					currentIndex += 1;
					return compare( element.previousElementSibling );
				}
			};
			activeIndex = compare( active.previousElementSibling );
		}

		if ( onChange ) {
			onChange( activeIndex );
		}
	}
};

const on_mousedown = ( event ) => {
	isMouseDown = true;
};
const on_mouseup = ( event ) => {
	isMouseDown = false;
};
const on_touchstart = ( event ) => {
	isTouchStarted = true;
};
const on_touchend = ( event ) => {
	isTouchStarted = false;
};
const on_touchcancel = ( event ) => {
	isTouchStarted = false;
};

const DraggableMenu = ( props ) => {
	const node = useRef();

	const on_mousemove = ( event ) => {
		if ( isMouseDown || isTouchStarted ) {
			event.preventDefault();
			handleActiveElement( event, props.onChange );
		}
	};
	const on_touchmove = ( event ) => {
		if ( isTouchStarted ) {
			event.preventDefault();
			handleActiveElement( event, props.onChange );
		}
	};

	useEffect(() => {
		if ( contextRef ) {
			const el = contextRef.current;
			el.addEventListener( 'mousedown', on_mousedown );
			el.addEventListener( 'mousemove', on_mousemove );
			document.addEventListener( 'mouseup', on_mouseup );
			el.addEventListener( 'touchstart', on_touchstart );
			el.addEventListener( 'touchmove', on_touchmove );
			document.addEventListener( 'touchend', on_touchend );
			document.addEventListener( 'touchcancel', on_touchcancel );

			return () => {
				el.removeEventListener( 'mousedown', on_mousedown );
				el.removeEventListener( 'mousemove', on_mousemove );
				document.removeEventListener( 'mouseup', on_mouseup );
				el.removeEventListener( 'touchstart', on_touchstart );
				el.removeEventListener( 'touchmove', on_touchmove );
				document.removeEventListener( 'touchend', on_touchend );
				document.removeEventListener( 'touchcancel', on_touchcancel );
			};
		}
	}, []); // eslint-disable-line

	const content = (
		<div ref={contextRef}>
			<Menu
				ref={node}
				className={props.className || ['draggable-menu', 'square-corners', 'no-margin']}
				style={props.style || null}
				mode="horizontal"
				theme="dark"
				defaultSelectedKeys={['0']}
				disabledOverflow={true}
			>
				{props.children}
			</Menu>
		</div>
	);

	return ( content );
};

export default DraggableMenu;
