import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { QlikLastModified as QlikLastModifiedAtom } from '@trinity-incyte/recoil';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

const fieldsToSkipSync = ['TimeSelectionDesc'];

const fieldsToSync = [
    'DSF.TM11.TERR_NAME_WITH_REP',
    'DSF.TM11.DIST_NAME_WITH_MGR',
];

/* eslint-disable-next-line */
export interface useSelectionsProps {
    config: { ids: { appId: string }};
    hashSync?: boolean;
}

export interface useSelectionsReturnProps {
    appSelections: any[],
    simpleSelections: any,
    clear: any,
    isActive: any,
    make: any,
}

export function useSelections({
    config,
    hashSync
}: useSelectionsProps) : useSelectionsReturnProps {
    const [dimensions, set_dimensions] = useState<any>({ qDimensionList: [] });
    const [hasSyncedSelections, set_hasSyncedSelections] = useState<boolean>(
        false
    );
    const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
    const unmounted = useRef(false);
    const history = useHistory();
    const { appId } = config.ids;
    const app = Mosaic.Qlik.app[appId];

    const getExpressionFromName = (text) => {
        return dimensions.qDimensionList.reduce((curr, val) => {
            if (val.qData.title === text) curr = val.qData.info[0].qName;
            return curr;
        }, '');
    };

    const getExpressionFromLabel = (text) => {
        return dimensions.qDimensionList.reduce((curr, val) => {
            if (val.qData.labelExpression === text)
                curr = val.qData.info[0].qName;
            return curr;
        }, '');
    };

    const getLabelFromName = (text) => {
        return dimensions.qDimensionList.reduce((curr, val) => {
            if (val.qData.title === text) curr = val.qData.labelExpression;
            return curr;
        }, '');
    };

    const getNameFromLabel = (text) => {
        if (!dimensions.qDimensionList) return null;
        return dimensions.qDimensionList.reduce((curr, val) => {
            if (val.qData.labelExpression === text) curr = val.qData.title;
            return curr;
        }, '');
    };

    useEffect(() => {
        app.getList('DimensionList', (list) => {
            if (unmounted.current == false) set_dimensions(list);
        });
        return () => {
            unmounted.current = true;
        }
    }, []);

    useEffect(() => {
        if (hashSync) {
            if (!app.selections) return;

            const hash = decodeURIComponent(history.location.hash).substr(
                1,
                history.location.hash.length
            );
            const hashObj = hash !== '' ? JSON.parse(hash) : { s: {} };

            const hashSelections = hashObj.s;
            const currentSelections = app.selections.reduce((acc, curr) => {
                if (fieldsToSync.indexOf(curr.fieldName) !== -1) {
                    acc[curr.fieldName] = curr.selectedValues.map((sel) => {
                        return sel.qName;
                    });
                }
                return acc;
            }, {});

            if (
                JSON.stringify(hashSelections) !==
                JSON.stringify(currentSelections)
            ) {
                if (hasSyncedSelections) {
                    hashObj.s = currentSelections;
                    const newUrl = `${
                        history.location.pathname
                    }#${JSON.stringify(hashObj)}`;
                    history.replace(newUrl);
                }
            } else {
                if (!hasSyncedSelections) set_hasSyncedSelections(true);
            }
        }
    }, [QlikLastModified]);

    const clear = useCallback(
        (fieldName) => {
            app.field(fieldName).clear();
        },
        [app]
    );

    const isActive = useCallback(
        (definition) => {
            if (!app) return false;

            const parseValue = (value) => {
                let retVal;
                if (Number.isNaN(parseInt(value, 10))) {
                    retVal= value;
                } else {
                    retVal = parseInt(value,10);
                }
                return retVal;
            }
            const { selections } = app.selectionState();
            const fields = Object.keys(definition);
            const isFieldsSelected = new Array(fields.length).fill(1);
            for (let ii = 0; ii < fields.length; ii += 1) {
                const field = fields[ii];
                const fieldName = (!dimensions.qDimensionList.qItems)
                ? field
                : dimensions.qDimensionList.qItems.reduce(
                    (curr, val) => {
                        if (
                            parseInt(val.qMeta.masterScriptId, 10) ===
                                parseInt(field, 10) ||
                            val.qData.title === field ||
                            val.qData.info[0].qName === field
                        )
                            curr = val.qData.info[0].qName;
                        return curr;
                    },
                    ''
                );

                for (let ij = 0; ij < selections.length; ij += 1) {
                    if (
                        fieldName &&
                        fieldName.toUpperCase() ===
                            selections[ij].fieldName.toUpperCase()
                    ) {
                        const toSelect = Array.isArray(definition[field])
                        ? definition[field].map((val) => parseValue(val))
                        : [parseValue(definition[field])];
                        const isSelected = selections[ij].selectedValues;
                        const isFieldValuesSelected = new Array(
                            toSelect.length
                        ).fill(1);
                        if (toSelect.length === isSelected.length) {
                            for (let ik = 0; ik < toSelect.length; ik += 1) {
                                const valueToCheck = parseValue(isSelected[ik].qName);
                                if (toSelect.indexOf(valueToCheck) !== -1) {
                                    isFieldValuesSelected[ik] = 0;
                                }
                            }
                            if (isFieldValuesSelected.reduce((a, b) => a + b, 0) === 0) {
                                isFieldsSelected[ii] = 0;
                            }
                        }
                    }
                }
                if (isFieldsSelected[ii] !== 0) break; // break early if not matched in any cycle
            }
            const retVal = isFieldsSelected.reduce((a, b) => a + b, 0) === 0;
            return retVal;
        },
        [dimensions]
    );

    const make = useCallback(
        (definition) => {
            const definitionKeys = Object.keys(definition);
            for (let ii = 0; ii < definitionKeys.length; ii += 1) {
                const fieldId = definitionKeys[ii];
                const selection = definition[fieldId];
                const fieldName = isNaN(parseInt(fieldId, 10))
                    ? fieldId
                    : dimensions.qDimensionList.qItems.reduce((curr, val) => {
                          if (
                              parseInt(val.qMeta.masterScriptId, 10) ===
                              parseInt(fieldId, 10)
                          )
                              curr = val.qData.info[0].qName;
                          return curr;
                      }, '');

                if (selection === '*') {
                    app.field(fieldName).selectAll();
                } else {
                    // Selection is case-sensitive
                    const selectionArray = Array.isArray(definition[fieldId])
                        ? definition[fieldId]
                        : new Array(definition[fieldId]);
                    const sel = selectionArray.map((val) => {
                        if (Number.isNaN(parseInt(val, 10))) {
                            return val;
                        } else {
                            return parseInt(val, 10);
                        }
                    });
                    app.field(fieldName)
                        .clear()
                        .then(() => {
                            app.field(fieldName).selectValues(sel);
                        });
                }
            }
        },
        [dimensions]
    );

    useEffect(() => {
        set_selectionsData(() => {
            return {
                appSelections: app.selections,
                simpleSelections: app.selections?.reduce((acc, curr) => {
                      acc[curr.fieldName] = curr.selectedValues;
                      return acc;
                  }, {}) ?? []
            }
        });
    }, [app.selections?.length]);

    const [selectionsData, set_selectionsData] = useState<any>({
        appSelections: app.selections,
        simpleSelections: !Array.isArray(app.selections)
            ? []
            : app.selections.reduce((acc, curr) => {
                  acc[curr.fieldName] = curr.selectedValues;
                  return acc;
              }, {}),
        clear,
        isActive,
        make,
    });

    return selectionsData;
}

export default useSelections;
