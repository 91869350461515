// This component is the Bottom Slider that opens up when you click Alerts on Mosaic Derm

import React, { useEffect } from 'react';
import { Button } from 'antd';
import {closeBottomSliderSelector, bottomSliderState} from '@trinity-incyte/recoil';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

const CloseButton = styled(Button)`
  width: 100%;
  background-color: black;
  border-color: black;
  color: white;
`;

/* eslint-disable-next-line */
export interface BottomSliderProps {}

export function BottomSlider(props: BottomSliderProps | any) {
  const bottomSlider = useRecoilValue(bottomSliderState);
  const closeBottomSlider = useSetRecoilState(closeBottomSliderSelector)

useEffect(() => {
  return () => closeBottomSlider(null);
}, []);

  return (
    <div
      className="filter-sidebar"
      style={{
      background: 'whitesmoke',
      position: 'absolute',
      right: '0',
      bottom: '0',
      overflowY: 'hidden',
      padding: '1rem',
      transition: 'all 0.3s linear',
      maxHeight: '42rem',
      transform: `translateY(${ bottomSlider.isOpen ? 0 : 'calc(100%)'})`,
      width: '30rem',
      zIndex: 1000,
      }}
    >
      <CloseButton className="square-corners" onClick={closeBottomSlider}>
        Close
      </CloseButton>
      {bottomSlider.content}
    </div>
  );
};

export default BottomSlider;
