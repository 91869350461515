import React from 'react';
import './list-card.module.scss';
import {Card, CardProps} from 'antd';
/* eslint-disable-next-line */
export interface ListCardProps {
  bodyStyle?: CardProps['bodyStyle'];
  children?: CardProps['children'];
  bordered?: CardProps['bordered'];
  style?: CardProps['style'];
  className?: CardProps['className'];
  color?: any;
}

export function ListCard({
  children,
  className,
  color = 'gray'
}: ListCardProps) {
  const cardStyle = {
    borderTop:'1px solid rgba(34,36,38,.1)',
    borderBottom: 'rgba(var(--CoolGreyRGB), 0.4)',
    borderRadius:"5px", 
    boxShadow: null,
    background: 'rgba(var(--CoolGreyRGB), 0.4)',
    paddingTop:'1px',
    paddingRight:"2px", 
    width: '100%'
  };
  if (color === 'blue') {
    cardStyle.boxShadow = '0 1px 0 0 #005cab';
  } else if (color === 'red') {
    cardStyle.boxShadow = '0 1px 0 0 #db2828';
  } else if (color === 'gray') {
    cardStyle.boxShadow = null;
  }
  const bodyStyle = {
    padding:'1px'
  }
  return (
    <Card bordered style={cardStyle} bodyStyle={bodyStyle}>
      {children}
    </Card>
  );
}

export default ListCard;
