import Config from './Config';

const Utils = {
    isTransplantCenterSelected: ( selections = false, strict = false ) => {
        let value = false;
        if ( selections && selections.length !== 0 ) {
            let val;
            for( let ii = 0; ii < selections.length; ii += 1 ) {
                val = selections[ii];
                if ( val.fieldName === Config.Qlik.app1.ids.fields.transplant_centers ) {
                    if ( strict === true && val.selectedValues.length === 1 ) {
                        value = true;
                    } else if ( strict === false ) {
                        value = true;
                    }
                }
            }
        }
        return value;
    },
    screen: () => {
        const height = window.innerHeight;
        const width = window.innerWidth;
        const userAgent = window.navigator.userAgent;
        const orientation = ( window.matchMedia('(orientation:portrait)').matches ) ? 'PORTRAIT' : 'LANDSCAPE';

        let returnVal = {
            class: 'DESKTOP',
            device: 'UNKNOWN',
            extra: 'UNKNOWN',
            orientation: orientation,
            height: height,
            width: width,
            userAgent: userAgent,
        };

        if ( !height || !width ) {
            returnVal.class = 'SERVER';
        }

        // Set device class
        if ( width <= 640 ) {
            returnVal.class = 'MOBILE';
        } else if ( width > 640 && width <= 1007 ) {
            returnVal.class = 'TABLET';
        } else if ( width > 1007 ) {
            returnVal.class = 'DESKTOP';
        } else {

        }

        // Exact match on Incyte devices
        if ( height === 610 && width === 1280 && userAgent.indexOf( 'Windows NT 10.' ) !== -1 ) {
            returnVal.device = 'INCYTE';
        } else if ( orientation === 'PORTRAIT' ) {
            returnVal.class = 'TABLET';
            returnVal.device = 'INCYTE';
        } else if ( orientation === 'LANDSCAPE' ) {
            returnVal.class = 'TABLET';
            returnVal.device = 'INCYTE';
        } 

        return returnVal;
    },
};

export default Utils;