import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react';
import { Empty } from 'antd';
import Chart from 'react-apexcharts';
import { useRecoilValue } from 'recoil';
import { QlikLastModified as QlikLastModifiedAtom } from '@trinity-incyte/recoil';
import QSMashupObject from '../../Qlik/qsmashup-object';

/* eslint-disable-next-line */
export interface TargetTypeBarChartProps {
  appId: string;
  mashupId: string;
  qViz?: any;
  title?: any;
  type?: any;
  colors?: string[];
}

export function TargetTypeBarChart({
  appId,
  mashupId,
  title = 'Calls by Target Type',
  type = 'bar',
  colors = ['#4BD5DA', '#DC7B50', '#99CC93'],
}: TargetTypeBarChartProps) {
  const [qViz, set_qViz] = useState(null);
  const [dataPage, set_dataPage] = useState({array: [], modified: Date.now()});
  const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
  const chartConfig = {
    series: [],
    type,
    height: '100%',
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          horizontal: true,
          offsetX: 1,
          dataLabels: {
            position: 'center',
          },
        },
      },
      dataLabels: {        
        // this formatter logic displays the call count above the percentage (two lines)
        formatter: (val: string | number | number[], opts?: any): string | number => {
          const callPercentage = Math.round(Number(val as number)) + '%';
          const callCount = opts.w.globals.labels[opts.seriesIndex].toLocaleString();
          return [callCount, callPercentage] as (unknown) as (string | number);
        },
        offsetY: -8,
        offsetX: 0,
        style: {
          colors: ['#3c3b3b'],
          fontWeight: 'normal'
        },
        enabled: true,
      },
      title: {
        text: title,
        align: 'left',
        floating: true,
        offsetX: 16,
        offsetY: 6,
        style: {
          color: '#82868b',
          fontFamily:  "'QlikView Sans',Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
        },
      },
      legend: {
        position: 'bottom',
        fontSize:  '1.3rem',
        fontWeight: 'normal',
        fontFamily:  "'QlikView Sans',Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
        offsetY: -4,
        showForSingleSeries: true,
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        }
      },
      colors,
      stroke: {
        width: 0,
        colors: ['#fff']
      },
      xaxis: {
        categories: [],
        labels: {
          show: false,
        }
      },
      yaxis: {
        labels: {
          show: false,
        }
      }
    } as ApexOptions,
  };
  
  if (dataPage.array.length > 0) {
    const matrix = dataPage.array[0].qMatrix;
    for (let ii = 0; ii < matrix.length; ii += 1) {
      const item = matrix[ii];
      const label = item[0].qText.toLocaleString();
      const value = item[1].qNum;

      chartConfig.series.push({name: label, data: [value]})
      chartConfig.options.xaxis.categories.push(value);
    }
  }
  
  useEffect(() => {
    if (!qViz) return;
    qViz?.model?.getHyperCubeData(
      '/qHyperCubeDef',
      [{qTop: 0, qLeft: 0, qHeight: 50, qWidth: 2}]
    ).then((page) => {
      set_dataPage({array: page, modified: Date.now()});
    })
  }, [qViz, QlikLastModified]);

  return (
    <>
      <QSMashupObject
        appId={appId}
        mashupId={mashupId}
        noShow
        onQViz={(qViz) => {set_qViz(qViz)}}
      />
      {(dataPage.array.length > 0)
        ? (<Chart key={`chart_${dataPage.modified}`} {...chartConfig} />)
        : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No Data"
          />
        )
      }
    </>
  )
}

export default TargetTypeBarChart;
