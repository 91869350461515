import { StopOutlined } from "@ant-design/icons";
import React from "react";

export function PDRPMessage (props) {
    return (
        <div id={props.id} color={props.color} className={`kpiBoxInner ${props.customClass} ${props.canClick ? 'box-clickable' : ''}`} style={{ ...props.style, background: 'transparent', textAlign: "left", height: '100%', display: 'flex', alignItems:'center' }}>
            <div style={{width:'100%', textAlign:'center', margin:'8px'}}>
                <StopOutlined style={{ fontSize: '5rem', color:'#DB2828' }}/>
                <div className='kpiLabel'>
                    PDRP
                </div>
            </div>
        </div>
    );
};

export default PDRPMessage;