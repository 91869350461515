/**
 * Just add this once to the page (after Qlik Styles have been added)
 */
import React from 'react';

// This chunk imports the font files that were breaking on import from QS server
import '../../App.css';
import '../../Lato.css'
import './QSStyleOverrides.css';

const QSStyleOverrides = () => (
	<span data-qsdsa="Style Overrides" style={{ display: 'none' }} />
);

export default QSStyleOverrides;
