import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, ButtonProps } from 'antd';

/* eslint-disable-next-line */
export interface EditHCPDetailsCellProps {
  value?: any;
  column?: any;
  size?: ButtonProps['size'];
  fontSize?: any;
  style?: any;
  config: any;
}

export function EditHCPDetailsCell(props: EditHCPDetailsCellProps) {

	let profId;
	if ( props.value && props.value !== '-' && props.value !== '' ) {
		if ( Number.isNaN( parseInt( props.value, 10 ))) {
			// Custom selection needed
			profId = props.value.split('(')[1];
			if ( profId ) {
				profId = profId.split(')')[0];
			} else {
				profId = false;
			}
		} else {
			// It's a regular PROF ID
			profId = parseInt( props.value, 10 );
		}
	}

	let content;
	if ( profId ) {
		content = (
			<Button
				className='blue-cell'
        data-action="self"
				size={props.size || "small"}
        style={{
          maxHeight: '21px',
          fontSize: props.fontSize || '.8em',
          ...props.style,
          textDecoration: 'underline',
        }}
				href={`${props.config}${profId}`}
      >
        Edit HCP Details
      </Button>
		);
	} else {
		content = ( <></> );
	}

	return content;
};

export default EditHCPDetailsCell;
