import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Statistic, Select } from 'antd';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

/* eslint-disable-next-line */
export interface UseQSFieldProps {
    config: any;
    fieldName: string;
    onField?: Function;
}

export function useQSField({
    config,
    fieldName,
    onField = (field) => {},
}: UseQSFieldProps) {
    const [fieldObject, set_fieldObject] = useState<any>({});
    const [rows, set_rows] = useState({ array: [] });

    const { appId } = config.ids;

    useEffect(() => {
        const app = Mosaic.Qlik.app[appId];
        if (!app) return;
        const field = app.field(fieldName);
        field.addOnDataListener(() => {
            set_rows({ array: field.rows });
        });
        set_fieldObject(field);
    }, [fieldName]);

    useEffect(() => {
        if (!fieldObject.getData) return;
        fieldObject.getData();
        onField(fieldObject);
    }, [fieldObject]);

    useEffect(() => {
        if (!fieldObject.getData) return;
        if (rows.array.length < fieldObject.rowCount) {
            fieldObject.getMoreData();
        }
    }, [rows]);

    return rows.array;
}

export default useQSField;
