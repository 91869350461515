import React, { createRef } from 'react';
import {
	Center,
	Loader as UILoader
} from '@trinity-incyte/ui';

import NavGroup from '../Components/Groups/NavGroup';
import OverviewGroup from '../Components/Groups/OverviewGroup';
import Config from '../Config';
import useGlobal from '../Store';
import QSAuthentication from '../Components/Qlik/QSAuthentication';
import ModalGroup from '../Components/Groups/ModalGroup';
import DisclaimerGroup from '../Components/Groups/DisclaimerGroup';
import FilterSidebar from '../Components/Sidebars/FilterSidebar';
import DetailsSidebar from '../Components/Sidebars/DetailsSidebar';
import { environment } from '../../environments/environment';
import { Redirect, Route, Switch } from 'react-router-dom';

const contextRef = createRef();
const Main = () => {
	const [globalState, globalActions] = useGlobal();

	const { appId } = Config.Qlik.app1.ids;
	let content;

	// This will replace the page when the app cannot connect to QS
	if ( globalState.Qlik.cannotAcquireRequire === true ) {
		content = (
			<>
				<NavGroup simplified />
				<QSAuthentication />
				<div style={{ marginTop: '0px', overflow: 'hidden', height: 'calc(100% - 10vh)', fontFamily: '"QlikView Sans", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif' }}>
					<Center>
						<UILoader size="large" color="red" />
						<p>Cannot establish connection to Qlik Sense.</p>
						<p>
							In {Math.floor( Config.App.authFailRedirectWait / 100 ) / 10} seconds, you will be redirected to re-authenticate through Okta.
						</p>
					</Center>
				</div>
			</>
		);
		setTimeout(() => {
			window.location.href = Config.App.authFailRedirectUrl;
		}, Config.App.authFailRedirectWait );
	// App is able to connect to QS
	} else if ( !globalState.Qlik.qlik ) {
		content = (
			<>
				<NavGroup simplified />
				<QSAuthentication />
				<div style={{ marginTop: '0px', overflow: 'hidden', height: 'calc(100% - 10vh)', fontFamily: '"QlikView Sans", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif' }}>
					<UILoader size="large" text="Connecting to Qlik Sense..." />
				</div>
			</>
		);
	} else if ( globalState.Qlik.qlik && !globalState.Qlik.app[appId]) {
		globalActions.Qlik.registerApp( appId );
		content = (
			<>
				<NavGroup simplified />
				<QSAuthentication />
				<div style={{ marginTop: '0px', overflow: 'hidden', height: 'calc(100% - 10vh)', fontFamily: '"QlikView Sans", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif' }}>
					<UILoader size="large" text="Connected. Opening Qlik Application..." />
				</div>
			</>
		);
	} else if ( globalState.Qlik.qlik && globalState.Qlik.app[appId]) {
		content = (
			<>
				<NavGroup />
				{environment.production && <DisclaimerGroup />}
				<div ref={contextRef}>
					<div style={{
						width: '100%',
						display: 'block',
						maxWidth: '100%!important'
					}}>
						<FilterSidebar />
						<DetailsSidebar />
						<div
							className="app-container"
							style={{
								width: '100%',
								display: 'block',
								maxWidth: '100%!important'
							}}
						>
							<OverviewGroup />
						</div>
						<ModalGroup />
					</div>
				</div>
			</>
		);
	} else {
		content = (
			<>
				<NavGroup simplified />
				<div style={{ marginTop: '0px', overflow: 'hidden', height: 'calc(100% - 10vh)', fontFamily: '"QlikView Sans", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif' }}>
					<UILoader size="large" color="red" text="Application encountered an error. Please refresh the page." />
				</div>
			</>
		);
	}

	return (
		<Switch>
			<Route path="/">
				{content}
			</Route>
			<Route>
				<Redirect to="/" />
			</Route>
		</Switch>
	);
};

export default Main;
