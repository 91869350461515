import { CloseSquareOutlined } from '@ant-design/icons';
import { ConfigContext } from '@trinity-incyte/context';
import { closeModalSelector } from '@trinity-incyte/recoil';
import { Result, Button as AntButton, Space } from 'antd';
import React, { useContext } from 'react';
import { useSetRecoilState } from 'recoil';
import './qlik-has-disconnected.module.scss';

/* eslint-disable-next-line */
export interface QlikHasDisconnectedProps {}

export function QlikHasDisconnected(props: QlikHasDisconnectedProps) {
    const closeModal = useSetRecoilState(closeModalSelector);
    const Config = useContext(ConfigContext);

	return (
		<div>
			<Result
				title="Session Ended"
				subTitle={
					<div>
						<p>Your session has been ended due to inactivity. </p>
						<p>If you would like to reconnect, please refresh or click the Refresh button below.</p>
					</div>
				}
				extra={
					<Space size="middle">
						<AntButton
							style={{backgroundColor:"green", borderColor:"green", color:"white"}}
							onClick={() => {
								window.location.href = `${Config.App.authFailRedirectUrl}&redirectUrl=${localStorage.getItem('currentPage')}`;
							}}
							onKeyUp={() => {
								window.location.href = `${Config.App.authFailRedirectUrl}&redirectUrl=${localStorage.getItem('currentPage')}`;
							}}
						>
							Refresh
						</AntButton>
						<AntButton 
							style={{backgroundColor:"red", borderColor:"red", color:"white"}} 
							icon={<CloseSquareOutlined/>} 
							onClick={closeModal}
						>
							Close
						</AntButton>
					</Space>
				}
			>
				{/* <Modal.Header as="h3">Session Ended</Modal.Header>
				<p>Your session has been ended due to inactivity. </p>
				<p>If you would like to reconnect, please refresh or click the Refresh button below.</p>
				<br />
					<Button
						color="green"
						onClick={() => {
							window.location.href = `${Config.App.authFailRedirectUrl}&redirectUrl=${localStorage.getItem('currentPage')}`;
						}}
						onKeyUp={() => {
							window.location.href = `${Config.App.authFailRedirectUrl}&redirectUrl=${localStorage.getItem('currentPage')}`;
						}}
						content="Refresh"
					/>
					<Button negative onClick={closeModal}>
						<Icon name="window close outline" />
						Close
					</Button>
			<div style={{ height: '60vh' }} /> */}
			</Result>
		</div>
	);
};

export default QlikHasDisconnected;
