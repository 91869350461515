module.exports = {
  "appId": "",
  "exportId": "2c729a32-2d6f-4f3d-a2dd-610adf207831",
  "isTable": true,
  "layout": "JAKAFI",
  "mashupId": "2c729a32-2d6f-4f3d-a2dd-610adf207831",
  "showExports": true,
  "sticky": true,
  "qViz": {},
  "tableProps": [{}],
  "tableStyles": [{}],
  "rawData": [
    [
      {
        "qElemNumber": 37373,
        "qNum": "NaN",
        "qState": "0",
        "qText": "ABBAS, JONATHAN (10989759)",
      }
    ]
  ],
  "data": [
    {
      "City": "NASVILLE",
      "Cnt": "1",
      "DigitalPush": "LOW",
      "JakafiLTDSpeakerAttendeeCount": "5",
      "JakafiLastDirectCallDate": "11/15/2019",
      "JakafiLastRTESentDate": "9/13/2019",
      "JakafiLastRTEViewedDate": "-",
      "JakafiLastRxDate": "7/1/2019",
      "JakafiLastSPNewPatientStartDate": "7/1/2019",
      "JakafiLastSpeakerAttendeeDate": "8/27/2020",
      "PeerInteraction": "MED",
      "ProfName(ID)": "ABBAS, JONATHAN (10989759)",
      "State": "TN",
      "Territory(TM11)": "11108201, Nashville (Cook, Pamela)",
      "qElemNumbers": [37373, 234, 35, 1258, 33, 408, 1, 5, 382, 2, 66],
      "qStates": ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"]
    }
  ],
  "columns": [
    {
      "Header": "Prof Name (ID)",
      "accessor": "ProfName(ID)",
      "dataParent": {},
      "fallbackTitle": "Prof Name (ID)",
      "fieldExpression": "DP.PROF_BLINDED_FULL_NAME_WITH_ID",
      "filter": "fuzzyText",
      "hide": false,
      "isDimension": true,
      "isMeasure": false,
      "width": 312,
    }
  ]
}