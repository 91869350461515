import { useEffect, useContext } from 'react';

export function useWalkme(active: boolean, source: string) {
    if(!active) return;
    useEffect(() => {
        const walkme: HTMLScriptElement = document.createElement('script');

        walkme.type = 'text/javascript';
        walkme.async = true;
        walkme.src = source;

        const s: any = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(walkme, s);

        (window as any)._walkmeConfig = { smartLoad: true };

        return () => {
            document.body.removeChild(walkme);
            delete (window as any)._walkmeConfig;
        }
    }, []);
};

export default useWalkme;
