import React, { useContext } from 'react';
import { ConfigContext } from '@trinity-incyte/context';
import QSMashupObject from '../../Qlik/qsmashup-object';
import { Row } from 'antd';

import { useRecoilValue } from 'recoil';
import { activeTeam as activeTeamAtom, QSAppMetadataFamily } from '@trinity-incyte/recoil';
import { teamHasBrand } from '@trinity-incyte/utils';

/* eslint-disable-next-line */
export interface BarriersProps {
  profId: any;
}

export function Barriers(props: BarriersProps) {
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.Prof360;
	const { appId } = config.ids;
    const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids } = metadata;
    const activeTeam = useRecoilValue(activeTeamAtom);
    const hcp360barriersreports = Config.App.reports.hcp360barrierspage.filter(report => teamHasBrand(Config.App.teamToBrand, activeTeam, report.brand));
    return (
        <div style={{ height: '100%' }}>
            {hcp360barriersreports.map(report => 
                <Row style={{ height: `${100/hcp360barriersreports.length}%` }} key={report.friendlyName}>
                    <QSMashupObject
                        appId={appId}
                        elementId="barriersTable"
                        mashupId={ids.get(report.friendlyName)?.id}
                        isTable
                        dynamic
                        showExports
                        tableProps={{
                            colOverrides:[
                                {
                                    columns: [0,1,3],
                                    hStyles: { width: '4rem' },
                                }
                            ]
                        }}
                        {...props}
                    />
                </Row>
            )}
        </div>
    );
}

export default Barriers;