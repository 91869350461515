/**
 * Put this component on the page to handle QS Authentication
 */
import { useEffect, useContext } from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import {
    activeBrand as activeBrandAtom,
    activeTeam as activeTeamAtom,
    AppState as AppStateAtom,
    defaultSelection as defaultSelectionAtom,
    QlikAppMetadata as QlikAppMetadataSelector,
    QlikLastModified as QlikLastModifiedAtom,
    QlikUser as QlikUserAtom,
    UserTerritory as UserTerritoryAtom,
    dataDateRetail as dataDateRetailAtom,
} from '@trinity-incyte/recoil';
import { ConfigContext } from '@trinity-incyte/context';

declare const window: any;
declare const Mosaic: MosaicGlobal;

import './qsapp.module.scss';
import Utils from '@trinity-incyte/utils';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

/* eslint-disable-next-line */
export interface QSAppProps {
    appId: string;
		onAppLoad?: any;
}

export function QSApp({
	appId,
	onAppLoad = () => {},
}: QSAppProps) {
	const Config = useContext(ConfigContext);
  const configFieldSales = Config.Qlik.FieldSales;
	const set_activeBrand = useSetRecoilState(activeBrandAtom);
	const [activeTeam, set_activeTeam] = useRecoilState(activeTeamAtom);
	const set_QlikLastModified = useSetRecoilState(QlikLastModifiedAtom);

	const listener = () => {
		// Keep this listener as light as possible, QS fires this a lot in response to data changes
		const { selections } = Mosaic.Qlik.app[appId].selectionState( );
		Mosaic.Qlik.app[appId].lastModified = Date.now();
		Mosaic.Qlik.app[appId].selections = selections;

		let count = 0;
		const ds = configFieldSales.defaultSelections;
		let dsi = 0;
		for (let ii = 0; ii < selections.length; ii += 1) {
			if (selections[ii].fieldName && selections[ii].qSelected) {
				for (dsi = 0; dsi < ds.length; dsi += 1) {
					if (selections[ii].fieldName === ds[dsi].fieldName && selections[ii].qSelected === ds[dsi].qSelected) {
						break;
					}
				}
				if (dsi === ds.length) count += 1;
			}

			if (selections[ii].fieldName === configFieldSales.ids.fields['Brand'] && selections[ii].qSelected) {
				set_activeBrand(selections[ii].qSelected.toUpperCase());
			}
		}

		if (Mosaic.Qlik.app[appId].selectionsCount !== count) {
			Mosaic.Qlik.app[appId].selectionsCount = count;
		}

		set_QlikLastModified(Mosaic.Qlik.app[appId].lastModified);
	};

	useEffect(() => {
		window.WalkMeData = {
			...(window.WalkMeData || {}),
			TeamName: activeTeam,
		};
	}, [activeTeam]);

	useEffect(() => {
		const app = Mosaic.QlikAPI.openApp(appId, Config.Qlik.config);
		Mosaic.Qlik.app[appId] = app;
		const selState = Mosaic.Qlik.app[appId].selectionState( );
		selState.addOnDataListener( listener );
		onAppLoad(app);

		return () => {
			selState.removeOnDataListener(listener);
		}
	}, [appId]);

	return null;
};

 export default QSApp;
