import { Descriptions, Typography } from 'antd';
import {  } from 'antd';
import React from 'react';
import { IncyteTeam } from '@trinity-incyte/recoil';
import './bio-overview.module.scss';

const { Text } = Typography;
/* eslint-disable-next-line */
export interface BioOverviewProps {
    profData: any;
    profId?: string;
    activeTeam?: IncyteTeam;
}

export const BioOverview = ({
    profData,
    profId,
    activeTeam,
}: BioOverviewProps) => {
    let content = (
        <Descriptions bordered column={2}>
            <Descriptions.Item label="Name">
                <Text strong>
                    {!!profData &&
                        `${
                            profData?.bio?.Name ||
                            profData?.bio['Prof Full Name']
                        }, ${profData?.bio?.Degree}`}
                </Text>
            </Descriptions.Item>
            <Descriptions.Item label="Specialty">
                <Text strong>
                    {!!profData &&
                        (profData?.bio?.Specialty || profData?.bio?.Speciality)}
                </Text>
            </Descriptions.Item>
            <Descriptions.Item label="Primary Address">
                <Text strong>
                    {!!profData &&
                        (activeTeam
                            ? `${profData?.bio?.Address[activeTeam]} ${profData?.bio?.City[activeTeam]}, ${profData?.bio?.State[activeTeam]} ${profData?.bio?.Zip[activeTeam]}`
                            : `${profData?.bio?.Address1} ${profData?.bio?.Address2} ${profData?.bio?.City}, ${profData?.bio?.State} ${profData?.bio?.Zip}`)}
                </Text>
            </Descriptions.Item>
            {/* <Descriptions.Item label="Birth Year">
                <Text strong>
                    {profData?.bio &&
                        (profData.bio['Birth Year'] ||
                            profData.bio['Birthdate'])}
                </Text>
            </Descriptions.Item> */}
            <Descriptions.Item label="Phone">
                <Text strong>{profData?.bio?.Phone}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Email">
                <Text strong>{profData?.bio?.Email}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="NPI">
                <Text strong>
                    {profData?.bio &&
                        (profData.bio['NPI'] || profData.bio['NPI Number'])}
                </Text>
            </Descriptions.Item>
            <Descriptions.Item label="Rental Email">
                <Text strong>
                    {profData?.bio && profData.bio['Rental Email']}
                </Text>
            </Descriptions.Item>
            <Descriptions.Item label="Prof ID">
                <Text strong>{profId}</Text>
            </Descriptions.Item>
            <Descriptions.Item label="Twitter Handle">
                {profData?.bio &&
                    (profData.bio['Twitter Handle'] !== '-' ? (
                        <a
                            href={`https://twitter.com/${profData.bio['Twitter Handle']}`}
                            title={`@${profData.bio['Twitter Handle']}`}
                        >
                            <Text strong>{profData.bio['Twitter Handle']}</Text>
                        </a>
                    ) : (
                        '-'
                    ))}
            </Descriptions.Item>
        </Descriptions>
    );
    return (
        <div
            id="bioOverview"
            style={{ minWidth: '400px', width:"100%", padding: '5px'  }}
        >
            {content}
        </div>
    );
};

export default BioOverview;
