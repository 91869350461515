import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
declare const Mosaic: MosaicGlobal;

export const requireNotFound = (store) => {
    const Qlik = { ...store.state.Qlik };
    Qlik.cannotAcquireRequire = true;
    store.setState({ Qlik });
};

export const registerQlik = (store, qlik) => {
    const Qlik = { ...store.state.Qlik };
    Qlik.qlik = qlik;
    Qlik.isActiveSession = true;
    store.setState({ Qlik });
};

const loadingApp = [];
let profImgUrl;
const sessionObjects: any = [];
sessionObjects.getByName = (name) => {
    for (let ii = 0; ii < sessionObjects.length; ii += 1) {
        if (sessionObjects[ii].name && sessionObjects[ii].name === name) {
            return sessionObjects[ii];
        }
    }
    return null;
};

/* export const registerApp = (store, config) => {
    const { appId, fields } = config.ids;
    const Qlik = { ...store.state.Qlik };

    if (loadingApp.indexOf(appId) === -1) { // App is not already loading
        loadingApp.push(appId);
        const app = Qlik.qlik.openApp(appId, Config.Qlik.config);
        Mosaic.Qlik.app[appId] = app;

        const glob = Qlik.qlik.getGlobal(Config.Qlik.config);
        glob.getAuthenticatedUser((authUser) => {
            const au = authUser.qReturn;

            (window as any).WalkMeData = (window as any).WalkMeData || {};
            (window as any).WalkMeData.UserID = au.split('=')[2];

            // Adds the Name to the WalkMe data
            const nameObj = app.createGenericObject({ Name: { qStringExpression: `=${fields['User First Name']} & ' ' & ${fields['User Last Name']}` } }, (reply) => {
                (window as any).WalkMeData.Name = reply.Name;
                nameObj.close();
            });
        });

        // Get user data
        app.createGenericObject({
            empType: { qStringExpression: `=[${fields['User Emp Type']}]` },
            accessType: { qStringExpression: `=[${fields['User Access Type']}]` },
        }, (reply) => {
            const Qlik = { ...store.state.Qlik };
            Qlik.userEmpType = reply.empType;
            Qlik.userAccessType = reply.accessType;

            if (!(window as any).WalkMeData.Role) {
                (window as any).WalkMeData.Role = reply.empType;
            }

            store.setState({ Qlik });
        });

        app.variable.getContent(config.variables['Sales Performance Data Date'], (reply) => {
            if (reply.qContent && reply.qContent.qString) {
                const Qlik = { ...store.state.Qlik };
                Qlik.dataDateRetail = reply.qContent.qString;
                store.setState({ Qlik });
            }
        });

        app.getList('BookmarkList', (reply) => {
            Qlik.app[appId] = {
                isLoaded: true,
                selections: [],
                bookmarks: reply.qBookmarkList,
            };
            loadingApp.splice(loadingApp.indexOf(appId), 1);
            store.setState({ Qlik });
        });

        // Make default selections
        if (config.defaultSelections) {
            for (let selInd = 0; selInd < config.defaultSelections.length; selInd += 1) {
                const selection = config.defaultSelections[selInd];
                app.field(selection.fieldName).selectValues([selection.qSelected]);
            }
        }
    }
}; */

export const updateAppSelections = (store, config, selections) => {
    const { appId, fields } = config.ids;
    const Qlik = { ...store.state.Qlik };
    Qlik.lastModified = Date.now();
    Qlik.app[appId].selections = selections;

    // Use this *sparingly* to keep things in sync only if this is the simplest and safest way
    for (let ii = 0; ii < selections.length; ii += 1) {
        if (selections[ii].fieldName === fields['Brand']) {
            if (selections[ii].qSelected) {
                Qlik.activeBrand = selections[ii].qSelected.toUpperCase();
            }
        }
    }

    store.setState({Qlik});
};

export const updateSelectionsCount = (store, config, selections) => {
    const { appId } = config.ids;
    const Qlik = { ...store.state.Qlik };
    let count = 0;
    const ds = config.defaultSelections;
    let dsi = 0;
    for (let si = 0; si < selections.length; si++) {
        if (selections[si].fieldName && selections[si].qSelected) {
            for (dsi = 0; dsi < ds.length; dsi++) {
                if (selections[si].fieldName === ds[dsi].fieldName && selections[si].qSelected === ds[dsi].qSelected) {
                    break;
                }
            }
            if (dsi === ds.length) count++;
        }
    }
    if (store.state.Qlik.app[appId].selectionsCount !== count) {
        Qlik.app[appId].selectionsCount = count;
        store.setState({ Qlik });
    }
};

export const clearSelectionsWithoutLocks = (store, config, fields) => {
    const { appId } = config.ids;
    const app = Mosaic.Qlik.app[appId];

    const selectionsFilter = config.locks.map(
        (fieldName) => fields.get(fieldName)?.id
    );

    app.selections?.forEach((selection) => {
        if (!selectionsFilter.includes(selection.fieldName)) {
            app.field(selection.fieldName).clear();
        }
    });    
};

export const clearAppSelections = (store, config, defaultSelections = false) => {
    const { appId } = config.ids;
    const Qlik = { ...store.state.Qlik };
    const app = Mosaic.Qlik.app[appId];
    app.clearAll().then(() => {
        if (defaultSelections) {
            if (config.defaultSelections) {
                for (let selInd = 0; selInd < config.defaultSelections.length; selInd += 1) {
                    const selection = config.defaultSelections[selInd];
                    app.field(selection.fieldName).selectValues([selection.qSelected]);
                }
            }
        } else {
            Qlik.app[appId].selections = [];
            store.setState({ Qlik });
        }
    });
};

export const clearFieldSelections = (store, appId, fieldName) => {
    Mosaic.Qlik.app[appId].field(fieldName).clear();
};

export const clearOtherFieldSelections = (store, appId, fieldName) => {
    Mosaic.Qlik.app[appId].field(fieldName).clearOther(true);
};

export const bookmarkApply = (store, appId, bookmarkId) => {
    Mosaic.Qlik.app[appId].bookmark.apply(bookmarkId);
};

export const bookmarkCreate = (store, appId, title, description) => {
    Mosaic.Qlik.app[appId].bookmark.create(title, description);
};

export const bookmarkDelete = (store, appId, bookmarkId) => {
    Mosaic.Qlik.app[appId].bookmark.remove(bookmarkId);
};

export const onError = (store, event) => {
    const Qlik = { ...store.state.Qlik };
    console.error('onError', event);

    if (event.refreshButton) {
        Qlik.isActiveSession = false;
    }

    store.setState({ Qlik });
};

export const onWarning = (store, event) => {
    const Qlik = { ...store.state.Qlik };
    console.warn('onWarning', event);
};

export const onClosed = (store, event) => {
    const Qlik = { ...store.state.Qlik };
    console.log('onClosed', event);
    store.setState({ Qlik });
};
