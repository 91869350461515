import React from 'react';
import { Image } from 'antd';
import {imgJakafi, imgPemazyre, imgMonjuvi, imgRegionalPlan, imgZynyz} from '@trinity-incyte/assets'

import './brand-logo-img.module.scss';

const brandToImageMap = new Map<string, string>([
	['JAKAFI', imgJakafi],
	['PEMAZYRE', imgPemazyre],
	['MONJUVI', imgMonjuvi],
	['ZYNYZ', imgZynyz],
])

/* eslint-disable-next-line */
export interface BrandLogoImgProps {
    brand?: any;
    height?: any;
		width?: any;
    padded?: boolean;
}

export function BrandLogoImg(props: BrandLogoImgProps) {
	const { brand } = props;

	let content;
	
	if (brand === 'DERM') {
        content = <></>;
    } else {
		content = (
			<Image preview={false} height={ props.height } width={props.width} src={brandToImageMap.get(brand) ?? brandToImageMap.get('JAKAFI')} />
		);
	}

	return (
		content
	);
};

export default BrandLogoImg;
