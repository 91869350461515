import { MosaicGlobal, QlikVisualization, QlikVisualizationInstance, QlikVisualizationInstanceFacade } from '@trinity-incyte/api-interfaces'
import { QSAppMetadataFamily } from '@trinity-incyte/recoil'
import { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'

declare var Mosaic: MosaicGlobal

export const useMashup = (appId, mashupName, mashupId=null) => {

	const app = Mosaic.Qlik.app[appId]
    const metadata = useRecoilValue(QSAppMetadataFamily(appId))
    const { IDsTable: ids } = metadata
	mashupId = mashupId??ids.get(mashupName)?.id;
	const [qVis, setQVis] = useState<QlikVisualizationInstance>()

	const onDataHandler = (visualization: QlikVisualizationInstance) => {
		setQVis(new QlikVisualizationInstanceFacade(visualization._instance))
	}

	const initializeTable = async () => {
		const visualization = await app?.visualization.get(mashupId)
		const wrappedOnDataHandler = () => {onDataHandler(visualization)}
		visualization.table.addOnDataListener(wrappedOnDataHandler)
		visualization.table.getMoreData()
		setQVis(visualization)
	}

	const onInvalidateHandler = (visualization: QlikVisualizationInstance) => {
		visualization.close()
		initializeTable()
	}

	useEffect(() => {
		initializeTable()
		return () => {
			qVis?.close?.()
		}
	}, [])

	useEffect(() => {
		const wrappedOnInvalidatedHandler = () => {onInvalidateHandler(qVis)}

		qVis?.model?.addInvalidatedListener(wrappedOnInvalidatedHandler)

		return () => {
			qVis?.model?.removeInvalidatedListener(wrappedOnInvalidatedHandler)
			qVis?.close?.()
		}
	}, [qVis?.model?._instance])
    
    return qVis
}

export default useMashup