import { environment } from '../../../../../../apps/mosaic/src/environments/environment';
import { ConfigContext, HCP360Context } from '@trinity-incyte/context';

import React, { useContext, useEffect, useState, ReactNode } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button, Menu, Dropdown } from 'antd';
import InnerPageNavGroup from '../inner-page-nav-group/inner-page-nav-group';
import styled from 'styled-components';
import { CaretDownOutlined } from '@ant-design/icons';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare var window: any;
declare const Mosaic: MosaicGlobal;
const MAX_RECENT_ITEMS = 10


type RecentlyViewedItems = {
    name: string
    id: string
}

/* eslint-disable-next-line */

const NavButton = styled(Button)`
	background-color: #005CAB;
	border-color: #005CAB;
	color: white;
	height: 100%;
	font-size: 1.2rem;
	font-weight: 700;
`;
export interface NavGroupMosaicProps {
	urlSuffix?: string;
	showClear?: boolean;
	incyteActivities?: any[];
	publicActivities?: any[];
	size?: any;
	children?: ReactNode;
	hideConnections?: boolean
}

export function NavGroupMosaic(props: NavGroupMosaicProps) {
	const Config = useContext(ConfigContext);
	const hcp360Context = useContext(HCP360Context);
	const config = Config.Qlik.Prof360;
	const { appId, items } = config.ids;
	const app = Mosaic.Qlik.app[appId];
	const [IncyteActivity, set_IncyteActivity] = useState([]);
	const [PublicActivity, set_PublicActivity] = useState([]);
	const [Overview, set_Overview] = useState([]);
    const [recentlyViewedItems, setRecentlyViewedItems] = useState<Array<RecentlyViewedItems>>(JSON.parse(localStorage.getItem('HCP360RecentlyViewedItems')) || []);

	const history = useHistory();
	let { urlSuffix, incyteActivities, publicActivities, hideConnections = false } = props;
	if ( !urlSuffix ) urlSuffix = '/';
	let profIdForHistory = urlSuffix.replace('/', '');
	
    useEffect(() => {

		if (!hcp360Context?.profData?.profId) return;

		const profName = hcp360Context.profData?.bio?.Name;
		const profId = hcp360Context.profData.profId;

		// form the new entry object
		let newEntry:RecentlyViewedItems = { name: profName, id: profId };

		// initialize the new array
		let newRecentlyViewedItems = [...recentlyViewedItems];

		let recentItemsFoundIndex = newRecentlyViewedItems.find((recentItem) => recentItem.id == profId);

		// if it's already in there, remove it
		let itemInListIndex = newRecentlyViewedItems.indexOf(recentItemsFoundIndex);

		if (itemInListIndex!=-1) {
			newRecentlyViewedItems.splice(itemInListIndex, 1);
			setRecentlyViewedItems(newRecentlyViewedItems);
		}

		// now add it at the beginning
		newRecentlyViewedItems = [newEntry, ...newRecentlyViewedItems];

		// get rid of the one at the end
		if (newRecentlyViewedItems.length > MAX_RECENT_ITEMS) {
			newRecentlyViewedItems.pop();
		}

		localStorage.setItem('HCP360RecentlyViewedItems', JSON.stringify(newRecentlyViewedItems));
		setRecentlyViewedItems(newRecentlyViewedItems);

		
    }, [hcp360Context.profData]);

    let dropDownItemsRecent = recentlyViewedItems.map((ri) => {
        return { text: `${ri.name} (${ri.id})` , to: `/HCP360/Overview/${ri.id}`, type: 'ITEM' }
    });

	if (dropDownItemsRecent.length==0) {
        dropDownItemsRecent = [{ text: 'No recent items', to: '', type: 'ITEM' }]
	}

	let dropDownItems = [
		{ text: 'Search', to: '/HCP360/'},
		{ text: null, to: '', onClick: null, type: 'DIVIDER' },
		{ text: 'Recent HCPs:', to: '', onClick: null, type: 'HEADER' },
		...dropDownItemsRecent
	];

	useEffect(() => {
		set_Overview([
			{ text: 'Prof Overview', to: '/HCP360/Overview' + urlSuffix }
		]);

		set_IncyteActivity(incyteActivities || [
			{ text: 'Multi-Channel Engagement', to: '/HCP360/IncyteActivity/MCE' + urlSuffix },
			{ text: 'Speaker Programs', to: '/HCP360/IncyteActivity/SpeakerPrograms' + urlSuffix },
			{ text: 'Interactions', to: '/HCP360/IncyteActivity/Interactions' + urlSuffix },
			{ text: 'Clinical Trials', to: '/HCP360/IncyteActivity/ClinicalTrials' + urlSuffix },
			{ text: 'Ad Boards', to: '/HCP360/IncyteActivity/AdBoards' + urlSuffix },
			{ text: 'Alerts', to: '/HCP360/IncyteActivity/Alerts' + urlSuffix },
			{ text: 'Barriers', to: '/HCP360/IncyteActivity/Barriers' + urlSuffix },
		]);

		set_PublicActivity(publicActivities || [
			{ text: 'Publications', to: '/HCP360/PublicActivity/Publications' + urlSuffix },
			{ text: 'Clinical Trials', to: '/HCP360/PublicActivity/ClinicalTrials' + urlSuffix },
			{ text: 'Presentations', to: '/HCP360/PublicActivity/Presentations' + urlSuffix },
			{ text: 'Grants', to: '/HCP360/PublicActivity/Grants' + urlSuffix },
			{ text: 'CMS Spend', to: '/HCP360/PublicActivity/CMS_Spend' + urlSuffix },
			{ text: 'Disclosures', to: '/HCP360/PublicActivity/Disclosures' + urlSuffix },
		]);
	}, [urlSuffix]);

	const incyteActivityMenu = (
		<Menu className="inner-glow">
			{IncyteActivity.map(( val ) => {
				let component;
				if ( val.type ) {
					if ( val.type.toUpperCase() === 'HEADER' ) {
						component = (
							<Menu.ItemGroup
								key={`item_${val.text}`}
								title={val.text}
							/>
						);
					} else if ( val.type.toUpperCase() === 'ITEM' ) {
						component = (
							<Menu.Item
								key={`item_${val.text}`}
							>
								<NavLink to={val.to}>
									{val.text}
								</NavLink>
							</Menu.Item>
						);
					} else if ( val.type.toUpperCase() === 'SUBMENU' ) {
						component = (
							<Menu.SubMenu title={val.text}>
								{val.items.map(( item ) => (
									<Menu.Item
										key={`item_${item.text}`}
									>
										<NavLink to={item.to}>
											{item.text}
										</NavLink>
									</Menu.Item>
								))}
							</Menu.SubMenu>
						);
					}
				} else {
					component = (
						<Menu.Item
							key={`item_${val.text}`}
							color="teal"
						>
							<NavLink to={val.to}>
								{val.text}
							</NavLink>
						</Menu.Item>
					);
				}

				return ( component );
			})}
		</Menu>
	);

	const publicActivityMenu = (
		<Menu className="inner-glow">
			{PublicActivity.map(( val ) => {
				let component;
				if ( val.type ) {
					if ( val.type.toUpperCase() === 'HEADER' ) {
						component = (
							<Menu.ItemGroup
								key={`item_${val.text}`}
								title={val.text}
							/>
						);
					} else if ( val.type.toUpperCase() === 'ITEM' ) {
						component = (
							<Menu.Item
								key={`item_${val.text}`}
							>
								<NavLink to={val.to}>
									{val.text}
								</NavLink>
							</Menu.Item>
						);
					} else if ( val.type.toUpperCase() === 'SUBMENU' ) {
						component = (
							<Menu.SubMenu title={val.text}>
								{val.items.map(( item ) => (
									<Menu.Item
										key={`item_${item.text}`}
									>
										<NavLink to={item.to}>
											{item.text}
										</NavLink>
									</Menu.Item>
								))}
							</Menu.SubMenu>
						);
					}
				} else {
					component = (
						<Menu.Item
							key={`item_${val.text}`}
							color="teal"
						>
							<NavLink to={val.to}>
								{val.text}
							</NavLink>
						</Menu.Item>
					);
				}

				return ( component );
			})}
		</Menu>
	)

	return (
		<InnerPageNavGroup
			{...props}
			dropdownText={(<span className="submenu-header">HCP 360°</span>)}
			dropdownItems={dropDownItems}
			config={config}
		>
			{( urlSuffix !== '/' ) && (
				<Menu.Item 
					key="hcp360-topNav" 
					className="no-padding static-topNav-item"
				>
					<NavButton
						size={props.size || 'large'} color="blue"
						className="square-corners"
						style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
						onClick={() => { 
							history.push('/HCP360/Overview' + urlSuffix);
							window.analytics?.track('Link Clicked', { text: 'Overview', context: 'HCP360 Overview Button Clicked'})
						}}
					>
						Overview
					</NavButton>
					<NavButton
						size={props.size || 'large'} color="blue"
						className="square-corners"
						style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
						onClick={() => { 
							history.push('/HCP360/Overview/Bio' + urlSuffix);
							window.analytics?.track('Link Clicked', { text: 'Bio', context: 'HCP360 Bio Button Clicked'})
						}}
					>
						Bio
					</NavButton>
					{!!IncyteActivity.length && 
						<Dropdown trigger={['click']} overlay={incyteActivityMenu}>
							<NavButton
								size={props.size || 'large'}
								className="square-corners"
								style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
							>
								Incyte Activity
								<CaretDownOutlined />
							</NavButton>
						</Dropdown>
					}
					{!!PublicActivity.length && 
						<Dropdown trigger={['click']} overlay={publicActivityMenu}>
							<NavButton
								size={props.size || 'large'}
								className="square-corners"
								style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
							>
								Public Activity
								<CaretDownOutlined />
							</NavButton>
						</Dropdown>
					}
					{!hideConnections && <NavButton
						size={props.size || 'large'}
						className="square-corners"
						style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
						onClick={() => { 
							history.push('/HCP360/Connections' + urlSuffix);
							window.analytics?.track('Link Clicked', { text: 'Connections', context: 'HCP360 Connections Button Clicked'})
						}}
					>
						Connections
					</NavButton>}
					{environment.hasPreCallPlanning &&
					<NavButton
						size={props.size || 'large'}
						className="square-corners"
						style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
						onClick={() => { 
							history.push('/HCP360/PreCallPlanning' + urlSuffix);
							window.analytics?.track('Link Clicked', { text: 'Pre Call Planning', context: 'HCP360 Pre Call Planning Button Clicked'})
						}}
					>
						Pre Call
					</NavButton>
					}
					{/* {<NavButton
						size={props.size || 'large'}
						className="square-corners"
						style={{ paddingLeft: '2rem', paddingRight: '2rem' }}
						onClick={() => { 
							history.push('/HCP360/Affiliations' + urlSuffix);
							window.analytics?.track('Link Clicked', { text: 'Affiliations', context: 'HCP360 Affiliations Button Clicked'})
						}}
					>
						Affiliations
					</NavButton>} */}
				</Menu.Item>
			)}
			{props.children}
		</InnerPageNavGroup>
	);
};

export default NavGroupMosaic;
