import React from 'react';
import { Segment, Loader, Center } from "@trinity-incyte/ui";
import QSAuthentication from '../qsauthentication/qsauthentication';
import styled from 'styled-components';

import './qlik-is-connecting.module.scss';

const Container = styled(Segment)`
	margin-top: 0px; 
	color: white; 
	overflow: hidden; 
	height: 100%; 
`;

/* eslint-disable-next-line */
export interface QlikIsConnectingProps {}

export function QlikIsConnecting(props: QlikIsConnectingProps) {
  return (
	<>
		<QSAuthentication />
		<Container inverted>
			<Center>
				<Loader
					size="large"
					fontSize="1.71rem"
					text="Connecting to Qlik Sense..."
				/>
			</Center>
			<div style={{ height: '100vh' }} />
		</Container>
	</>
);
}

export default QlikIsConnecting;
