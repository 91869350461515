import React from 'react';

import './qsstyle-overrides.scss';

/* eslint-disable-next-line */
export interface QSStyleOverridesProps {}

export function QSStyleOverrides(props: QSStyleOverridesProps) {
    return <span data-qsdsa="Style Overrides" style={{ display: 'none' }} />;
}

export default QSStyleOverrides;
