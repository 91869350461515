import { FilterOutlined } from '@ant-design/icons';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { ConfigContext } from '@trinity-incyte/context';
import { useQSListbox } from '@trinity-incyte/hooks';
import { Button, Empty, Input, List, Typography } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import styled from 'styled-components';

declare const window: any;
declare var Mosaic: MosaicGlobal;

const cleanFieldName = (fieldName) => {
  let retVal = fieldName.replace('=', '').replace('[', '').replace(']', '');
  return retVal;
}

const StyledQSFieldListbox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

/* eslint-disable-next-line */
export interface QSFieldListboxProps {
  config?: any;
  appId?: string;
  definition?: string[];
  title?: string;
  placeholder?: string;
  set_value?: Function;
  sort?: (a: any, b: any) => number;
  multi?: boolean;
  defaultValue?: string;
  handleClose?: () => void;
  selectionAppIds?: string[];
  options?: any;
}

export function QSFieldListbox({
  config,
  definition,
  title,
  defaultValue,
  placeholder = 'Filter listbox...',
  set_value = (_: any) => undefined,
  sort = ({ qText: opt1 }, { qText: opt2 }) =>
    opt1 > opt2 ? 1 : opt1 === opt2 ? 0 : -1, // Default to alphanumeric sort
  multi = false,
  handleClose,
  options,
  selectionAppIds = null,
}: QSFieldListboxProps) {
    const Config = useContext(ConfigContext);
    const [searchText, set_searchText] = useState<any>('');
    const { rows: listbox, totalRow, getMore } = useQSListbox({
      config,
      definition,
      title,
      single: !multi,
      options,
      searchText,
    });
    const [singleSelectionValue, set_singleSelectionValue] = useState(undefined);
    const [multiSelectionValue, set_multiSelectionValue] = useState([]);
    const singleSelection = useCallback(
      ({ value: newValue }) => {
        const toSelect = listbox.find(({ qElemNumber }) => qElemNumber === newValue);
        /**
         * This if branch is necessary to handle circumstances where
         * the listbox is invoked for a calculated dimension, which
         * likely will not exist on another app. In that case,
         * just select natively. Otherwise if specified to select
         * multiple apps, then expect each app to have the necessary field.
         */
        if (selectionAppIds && selectionAppIds.length > 1) {
          const fieldName = cleanFieldName(definition.toString());
          selectionAppIds.forEach((appId) => {
            const field = Mosaic.Qlik.app[appId].field(fieldName);
            field.selectValues([toSelect.qText]);
          });
        } else {
          toSelect?.select();
        }
      },
      [listbox]
    );
    const multiSelection = useCallback(
      (newValue: { value: number; label: string }[]) => {
        const currentSelectedElems = listbox.reduce((acc, curr) => {
          if (curr.qState === Config.Qlik.qStateValues.SELECTED)
            acc.push({ label: curr.qText, value: curr.qElemNumber });
          return acc;
        }, []);

        if (currentSelectedElems.length > newValue.length) {
          // Deselect
          const changedCellElem = currentSelectedElems.filter((curr) =>
            !newValue.find((item) => item.value === curr.value)
          )[0];

          if (!changedCellElem) {
            listbox[0].clear();
            return;
          }

          listbox
            .reduce((acc, curr) => {
              if (curr.qElemNumber === changedCellElem.value) acc = curr;
              return acc;
            }, null)
            ?.unselect();
        } else if (currentSelectedElems.length < newValue.length) {
          // Select
          const changedCellElem = newValue.filter((item) =>
            !currentSelectedElems.find((currItem) => currItem.value === item.value)
          )[0];

          if (!changedCellElem) {
            listbox[0].clear();
            return;
          }

          listbox
            .reduce((acc, curr) => {
              if (curr.qElemNumber === changedCellElem.value) acc = curr;
              return acc;
            }, null)
            ?.select();
        }
      },
      [listbox]
    );
    const clearSelection = useCallback(() => {
      const toDeselect = listbox.reduce((acc, curr) => {
        const { qState } = curr;
        if (qState === Config.Qlik.qStateValues.SELECTED) acc = curr;
        return acc;
      }, null);
      
      if (selectionAppIds && selectionAppIds.length > 1) {
        const fieldName = cleanFieldName(definition.toString());
        selectionAppIds.forEach((appId) => {
          const field = Mosaic.Qlik.app[appId].field(fieldName);
          field.selectValues([toDeselect.qText], true); // This ensures it toggles off only the selected one
        });
      } else {
        toDeselect?.clear();
      }

      set_searchText('');
    }, [listbox]);
    const clickHandler = useCallback(
      ({ qElemNumber, qText }) => {
        multi
          ? multiSelectionValue.find((item) => item.value === qElemNumber)
          ? multiSelection(multiSelectionValue.filter((item) => item.value !== qElemNumber))
          : multiSelection([
              {
                value: qElemNumber,
                label: qText,
              },
              ...multiSelectionValue,
            ])
          : singleSelectionValue?.value === qElemNumber
          ? clearSelection()
          : singleSelection({
            value: qElemNumber,
            label: qText,
          });
      },
      [multiSelectionValue, singleSelectionValue]
    );
    const clearButton = (
      <Button
        block
        style={{
          backgroundColor: '#1abc9c',
          borderColor: '#1abc9c',
        }}
        type="primary"
        onClick={clearSelection}
      >
        Clear
      </Button>
    );

    useEffect(() => {
      set_singleSelectionValue((_) =>
        listbox.reduce(
          (acc, { qState, qText: label, qElemNumber: value }) => {
            if (qState === Config.Qlik.qStateValues.SELECTED) {
              acc = { label, value };
              set_value(label);
            }
            return acc;
          },
          undefined
        )
      );
      set_multiSelectionValue((_) =>
        listbox.reduce((acc, curr) => {
          if (curr.qState === Config.Qlik.qStateValues.SELECTED)
            acc.push({ label: curr.qText, value: curr.qElemNumber });
          return acc;
        }, [])
      );

      if (
        defaultValue &&
        !multi &&
        !listbox.find(({ qState }) => qState === Config.Qlik.qStateValues.SELECTED)
      ) {
        listbox.find(({ qText }) => qText === defaultValue)?.select();
      }
    }, [listbox]);

    return (
      <StyledQSFieldListbox>
        {handleClose ? (
          <Button.Group style={{ width: '100%' }}>
            <Button
              block
              style={{
                  backgroundColor: 'black',
                  borderColor: 'black',
              }}
              type="primary"
              onClick={handleClose}
            >
              Close
            </Button>
            {clearButton}
          </Button.Group>
        ) : (
          <>
            <Typography.Title level={3} style={{ paddingTop: 10 }}>
              {title}
            </Typography.Title>
            {clearButton}
          </>
        )}
        <Input
          bordered={false}
          onChange={({ target: { value } }) => set_searchText(value)}
          size="large"
          suffix={<FilterOutlined />}
          placeholder={placeholder}
          value={searchText}
          allowClear
        />
        <Virtuoso
          style={{
            width: '100%',
            height: '100%',
          }}
          endReached={(index) => {
            if (getMore && index < totalRow) {
              getMore(index);
            }
          }}
          data={
            listbox
              .filter(({ qState }) => qState !== Config.Qlik.qStateValues.EXCLUDED)
              .filter(({ qText }) =>
                !searchText ||
                qText
                  .toLowerCase()
                  .replace('\\\\', '\\')
                  .indexOf(searchText.toLowerCase()) !== -1
              )
            /* .sort(sort) */ //Using the sort logic provided in the qlik sense listbox instead
          }
          components={{
            List: React.forwardRef((props, listRef) => (
              <List size="small" style={{ backgroundColor: 'white' }}>
                <div {...props} ref={listRef} />
              </List>
            )),
            EmptyPlaceholder: () => (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={'No Results'}
                />
              </div>
            ),
          }}
          itemContent={(index, { qText, qElemNumber, qState }) => {
            return (
              <List.Item
                key={qText}
                style={{
                  width: '100%',
                  cursor: 'pointer',
                  border: '1px solid #d9d9d9',
                  borderRadius: 2,
                  color:
                    qState === Config.Qlik.qStateValues.SELECTED
                      ? 'white'
                      : 'inherit',
                  backgroundColor:
                    qState === Config.Qlik.qStateValues.SELECTED
                      ? 'mediumseagreen'
                      : 'none',
                }}
                onClick={(e) =>
                  clickHandler({ qElemNumber, qText })
                }
              >
                {qText}
              </List.Item>
            );
          }}
        />
      </StyledQSFieldListbox>
    );
}

export default QSFieldListbox;