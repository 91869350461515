import React from 'react';

import './selected-cell.module.scss';

/* eslint-disable-next-line */
export interface SelectedCellProps {
	cell: any,
	row: any,
	column: any,
	editable: any,
	cbProps: any,
	rowInd: any,
	cellInd: any,
	Addon: any,
	ProfAddon: any,
	tableStyles: any,
}

export function SelectedCell({
	cell: { value: initialValue },
	row: { index },
	column: { id },
	editable,
	cbProps,
	rowInd,
	cellInd,
	Addon,
	ProfAddon,
	cell,
	tableStyles,
}: SelectedCellProps) {
	// We need to keep and update the state of the cell normally
	const [value, setValue] = React.useState( initialValue );
	// If the initialValue is changed externall, sync it up with our state
	React.useEffect(() => {
		setValue( initialValue );
	}, [initialValue]);

	if ( !editable ) {
		return `${initialValue}`;
	}
	// return <div onClick={onCellClick} onBlur={onBlur}> {value}</div>

	// if you are adding divs, put these data attributes to the deepest nested div ele
	return (
		<div
			className="editable-cell"
			style={{ ...tableStyles.bodyCellStyles, ...tableStyles.specificColBodyCellStyle[cellInd] }}
		>
			<div
				style={{
					...cbProps,
					backgroundColor: 'mediumseagreen',
					boxShadow: '-1px 0px 2px 2px mediumseagreen',
					color: 'white',
				}}
				data-row-id={rowInd}
				data-col-id={cellInd}
				className="customCell"
			>
				{value}
				{cbProps.icon ? <span>{cbProps.icon}</span> : <></>}
			</div>
			<>
				{ProfAddon && (
					<ProfAddon column={cell.column.Header} value={cell.value} rowInd={rowInd} />
										)}
				{Addon && (
					<Addon column={cell.column.Header} value={cell.value} />
										)}
			</>
		</div>
	);

	// return <button onClick={onCellClick}>{value}</button>
	// return <input value={value} onChange={onChange} onBlur={onBlur} />
};

export default SelectedCell;
