import React from 'react';

import './with-color.module.scss';

/* eslint-disable-next-line */
export interface WithColorProps {
  color: string;
  component: any;
  borderSize?: string;
  borderColor?: string;
  textColor?: string;
}

export function WithColor({
  color,
  component,
  borderSize,
  borderColor,
  textColor
}: WithColorProps) {
  if ( component?.type?.__ANT_BUTTON === true) {
    component.props.style.backgroundColor = color;
    component.props.style.borderWidth = borderSize;
    component.props.style.borderColor = borderColor;
    component.props.style.color = textColor;
  }
  return (
    component
  );
}

export default WithColor;
