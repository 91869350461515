import { ApexOptions } from 'apexcharts';
import React, { useEffect, useState } from 'react';
import StackedBar from './stacked-bar/stacked-bar';

function setupData(originalData, config) {
    const accumulator2 = (acc, item) => {
        const setup = item.measures.map((atom) => ({
            value: atom.qNum,
            label: atom.qMeasureInfo.qFallbackTitle + ' ' + atom.qText,
        }));
        setup.map(({ label, value }) => {
            acc.series.push({ name: label, data: [value] });
        });
        return acc;
    };
    originalData.reduce(accumulator2, config);
    return config;
}

/* eslint-disable-next-line */
export interface SingleStackedBarChartProps {
    qViz?: any;
    title?: any;
    type?: any;
    data?: any[];
    colors?: string[];
}

export function SingleStackedBarChart({
    qViz,
    title,
    data,
    type = 'bar',
    colors = ['#4477aa', '#ddcc77'],
}: SingleStackedBarChartProps) {
    const config = {
        height: 140,
        series: [],
        type,
        options: {
            chart: {
                // offsetY: -40,
                stacked: true,
                stackType: '100%',
                toolbar: {
                    show: false,
                },
            },
            colors,
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'top',
                    },
                },
            },
            title: {
                text: title,
                margin: 0,
                offsetX: 17,
                offsetY: 40,
                style: {
                    fontSize: '1em',
                    fontWeight: 'bold',
                    color: '#1b1c1d',
                },
            },
            xaxis: {
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                crosshairs: {
                    show: false,
                },
            },
            yaxis: {
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                crosshairs: {
                    show: false,
                },
            },
            tooltip: {
                x: {
                    show: false,
                },
                y: {
                    title: {
                        formatter: (seriesName) => seriesName,
                    },
                    formatter: function (val: any) {
                        return '' && val.toFixed(4) * 100 + '%';
                    },
                },
            },
            fill: {
                opacity: 1,
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'left',
                offsetX: 5,
                fontSize: '12px',
                labels: {
                    useSeriesColors: true,
                },
            },
            grid: {
                show: false,
            },
            responsive: [
                {
                    breakpoint: undefined,
                    options: {},
                },
            ],
        } as ApexOptions,
    };

    const [chartConfig, setChartConfig] = useState(config);

    const setChart = () => {
        if (data?.filter((item) => item).length) {
            setChartConfig({ ...config, series: data.filter((item) => item) });
        } else {
            setChartConfig(setupData(qViz?.table?.rows || [], config));
        }
    };

    useEffect(() => {
        if (data) return;
        qViz?.table?.addOnDataListener(setChart);
        qViz?.table?.getMoreData();
        return () => {
            qViz.table?.removeOnDataListener();
        };
    }, [qViz?.table]);

    useEffect(setChart, [data]);

    return <StackedBar chartConfig={chartConfig} />;
}

export default SingleStackedBarChart;
