import React from 'react';
import { Modal } from 'antd';

import useGlobal from '../../Store';

const ModalGroup = () => {
  const [globalState, globalActions] = useGlobal();

  const content = (
    <Modal
      size={globalState.UI.modal.size}
      visible={globalState.UI.modal.isOpen}
      closeIcon={globalState.UI.modal.closeIcon}
      onCancel={globalActions.UI.closeModal}
      maskClosable={true}
      keyboard={true}
      width={'50%'}
      footer={null}
      style={{ fontFamily: '"QlikView Sans", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif' }}
    >
      {globalState.UI.modal.content}
    </Modal>
  );

  return (
    content
  );
};

export default ModalGroup;
