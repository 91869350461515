import { screenOrientationState } from '@trinity-incyte/recoil';
import { Empty } from 'antd';
import { ApexOptions } from 'apexcharts';
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import { useRecoilValue } from 'recoil';
import './group-by-year-bar-chart.scss';

// function groupByYear(originalData, config) {
//     const accumulator = (acc, item) => {
//         const [
//             { qText: company },
//             { qText: year },
//             { qNum: payment },
//         ] = item.cells;
//         const {
//             series,
//             options: {
//                 xaxis: { categories },
//             },
//         } = acc;
//         let itemIndex = categories.indexOf(year);
//         if (itemIndex === -1) {
//             categories.push(year);
//         }
//         itemIndex = categories.indexOf(year);
//         const category = series.find((item) => item.name === company);
//         if (category) {
//             category.data[itemIndex] = payment;
//         } else {
//             let data = [];
//             data[itemIndex] = payment;
//             series.push({
//                 name: company,
//                 data,
//             });
//         }
//         acc.series = series.map((item) => {
//             item.data.length = categories.length;
//             return item;
//         });
//         return acc;
//     };
//     originalData.reduce(accumulator, config);
//     return config;
// }

function groupByCompany(originalData, config) {
    const accumulator = (acc, item) => {
        const [
            { qText: company },
            { qNum: year },
            { qNum: payment },
        ] = item.cells;
        const {
            series,
            options: {
                xaxis: { categories },
            },
        } = acc;
        let itemIndex = categories
            .map((item) => item.toLowerCase())
            .indexOf(company.toLowerCase());
        if (itemIndex === -1) {
            categories.push(company);
        }
        itemIndex = categories
            .map((item) => item.toLowerCase())
            .indexOf(company.toLowerCase());
        const category = series.find((item) => item.name === year.toString());
        if (category) {
            category.data[itemIndex] = Math.ceil(payment) || 0;
        } else {
            let data = [];
            data[itemIndex] = Math.ceil(payment) || 0;
            series.push({
                name: year.toString(),
                data,
            });
        }
        acc.series = series
            .sort((a, b) => Number(a.name) - Number(b.name))
            .map((item) => {
                item.data.length = categories.length;
                return item;
            });
        return acc;
    };
    originalData.reduce(accumulator, config);
    return config;
}

function formatCurrency(useCurrency = false) {
    if (useCurrency) {
        return (val) =>
            new Intl.NumberFormat('us-US', {
                style: 'currency',
                currency: 'USD',
            }).format(val);
    }
    return (val) => new Intl.NumberFormat().format(val);
}

/* eslint-disable-next-line */
export interface GroupByYearBarChartProps {
    qViz: any;
}

export function GroupByYearBarChart(props: GroupByYearBarChartProps) {
    const { qViz } = props;
    const config = {
        height: 430,
        series: [],
        options: {
            chart: {
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'top',
                    },
                },
            },
            dataLabels: {
                enabled: true,
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#000'],
                },
                formatter: formatCurrency(false),
            },
            stroke: {
                show: true,
                width: 0,
                colors: ['#fff'],
            },
            xaxis: {
                categories: [],
            },
            tooltip: {
                y: {
                    formatter: formatCurrency(true),
                },
            },
            responsive: [
                {
                    breakpoint: undefined,
                    options: {},
                },
            ],
        } as ApexOptions,
    };

    const [qVizTable, setQVizTable] = useState<any>();
    const [chartConfig, setChartConfig] = useState(config);
    const screenOrientation = useRecoilValue(screenOrientationState);
    const containerRef = useRef<HTMLDivElement>();

    useEffect(() => {
        setQVizTable(qViz.table);
        return () => {
            qViz.close();
        };
    }, []);

    const setChart = () => {
        config.height = containerRef.current.clientHeight;
        setChartConfig(groupByCompany(qVizTable?.rows || [], config));
    };

    useEffect(() => {
        qVizTable?.addOnDataListener(setChart);
        qVizTable?.getMoreData();
        return () => {
            qVizTable?.removeOnDataListener();
        };
    }, [qVizTable]);

    useEffect(setChart, [screenOrientation]);

    return (
        <>
            <div
                style={{
                    fontSize: '1.15385em',
                    fontWeight: 'bold',
                    color: 'rgb(128, 128, 128)',
                    textAlign: 'left',
                    position: 'relative',
                    top: '7px',
                }}
            >
                {qViz.model.layout.title}
            </div>
            <div ref={containerRef} className="group-by-year-chart">
                {chartConfig.options.xaxis.categories.length ? (
                    chartConfig.options.xaxis.categories.map((item, index) => (
                        <span className="chart-container" key={item}>
                            <Chart
                                type="bar"
                                height={chartConfig.height}
                                options={{
                                    ...chartConfig.options,
                                    xaxis: { categories: [item] },
                                    chart: {
                                        ...chartConfig.options.chart,
                                    },
                                }}
                                series={chartConfig.series.map(
                                    ({ name, data }) => ({
                                        name,
                                        data: [data[index] || 0],
                                    })
                                )}
                            />
                        </span>
                    ))
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No Data"
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default GroupByYearBarChart;
