import { useMashup } from "@trinity-incyte/hooks";
import React, { CSSProperties } from "react";

interface QSFieldProperties {
    appId: string,
    mashupName: string,
    fieldStyle?: CSSProperties,
    titleStyle?: CSSProperties,
    valueStyle?: CSSProperties
}

const QSField = (props: QSFieldProperties) => {
    const mashupData = useMashup(props.appId, props.mashupName);
    const title = mashupData?.table?.rows[0]?.cells[0]?.qMeasureInfo?.qFallbackTitle ?? "-";
    const value = mashupData?.table?.rows[0]?.cells[0]?.qText ?? "-";

    return (
        <div style={props.fieldStyle}>
            <div style={props.titleStyle}>{`${title}:`}</div>
            <div style={props.valueStyle}>{value}</div>
        </div>
    )
    
}

export default QSField;