export const degree_options = [
    {
        key: 'degree-md',
        text: 'MD',
        value: 'MD',
    },
    {
        key: 'degree-do',
        text: 'DO',
        value: 'DO',
    },
    {
        key: 'degree-np',
        text: 'NP',
        value: 'NP',
    },
    {
        key: 'degree-pa',
        text: 'PA',
        value: 'PA',
    },
    {
        key: 'degree-phar',
        text: 'PHAR',
        value: 'PHAR',
    },
    {
        key: 'degree-phard',
        text: 'PHARD',
        value: 'PHARD',
    },
    {
        key: 'degree-rn',
        text: 'RN',
        value: 'RN',
    },
    {
        key: 'degree-os',
        text: 'Office Staff',
        value: 'OS',
    },
    {
        key: 'degree-phd',
        text: 'PHD',
        value: 'PHD'
    },
    {
        key: 'degree-psy',
        text: 'PSY',
        value: 'PSY',
    },
    {
        key: 'degree-sw',
        text: 'SW',
        value: 'SW',
    },
];
