import { Empty } from 'antd';
import { ApexOptions } from 'apexcharts';
import React, { useState, useCallback, useEffect, useContext } from 'react';
import Chart from 'react-apexcharts';
import QSMashupObject from '../Qlik/qsmashup-object';
import './apex-chart.scss';
import { ConfigContext } from '@trinity-incyte/context';

type CUSTOM_CHART_TYPE = 'horizontal-bar' | 'stacked-bar';

function getLabel(label) {
    return label
        ?.split(' ')
        .map((item, _, list) => (isNaN(item[0]) && list.length > 1 ? item[0] : item))
        .join('');
}

/* eslint-disable-next-line */
export interface ApexChartProps {
	appId: string;
	mashupId: string;
	type: CUSTOM_CHART_TYPE;
	colors: string[];
	labelColors?: string[];
  formatter: (val: any) => any;
  suffix?: any;
	defaultLabels?: string[];
	title?: string;
	titlePosition?: 'right'|'left'|'center';
	height?: number;
	width?: number;
	isSubset?: boolean;
}

export function ApexChart({
	appId,
	mashupId,
	type,
	colors,
	labelColors = ['#1D1D1D', '#1D1D1D'],
  formatter,
  suffix,
	defaultLabels,
	title,
	titlePosition = 'center',
	height,
	width,
	isSubset = false,
}: ApexChartProps) {
	const [labels, set_labels] = useState({ array: []});
	const [kpi, set_kpi] = useState({ array: []});
	const Config = useContext(ConfigContext);
	const apexFontFamily = Config.App.defaultStyles.fontFamily;

	let GaugeConfig;
	let series;
	if (type === 'horizontal-bar') {
		series = (isSubset)
		? [{
			name: labels.array[0],
			data: [kpi.array[0]]
		}, {
			name: labels.array[1],
			data: [kpi.array[1]]
		}]
		: [{
			data: kpi.array
		}];

		GaugeConfig = {
			height: '100%',
			type: 'bar',
			options: {
				colors,
				chart: {
					type: 'bar',
					toolbar: {
						show: false,
					},
					sparkline: {
						enabled: true,
					}
				},
				tooltip: {
					enabled: false,
				},
				plotOptions: {
					bar: {
						horizontal: true,
						// borderRadius: 4,
						distributed: (isSubset) ? false : true,
						dataLabels: {
							position: 'bottom',
						},
					},
				},
				grid: {
					show: false,
					padding: {
						top: 0,
						right: 12,
						bottom: -16,
						left: 0,
					}
				},
				title: {
					text: title,
					offsetY: 18,
					align: titlePosition,
					// floating: true,
					// margin: 22, // 18 works for smallest breakpoint, 22 works for everything else
					style: {
						fontSize:  '1.2rem',
						fontFamily:  apexFontFamily,
					},
				},
				responsive: [{
					breakpoint: 1536,
					options: {
						title: {
							offsetY: 18
						},
						legend: {
							offsetY: -18,
						},
					}
				}],
        legend: {
          show: (isSubset) ? true : false,
					fontFamily: apexFontFamily,
					// fontSize: '2rem',
					fontWeight: 'bold',
          formatter: (seriesName, {seriesIndex, w: {config, globals}}) => {
            const dataValue = Number(globals.series[seriesIndex]);
            const legendValue = (isNaN(dataValue))
						? 0
						: Intl.NumberFormat().format(dataValue)

            return `${seriesName}: ${legendValue}`;
          },
					itemMargin: {
						vertical: -1,
					},
					position: 'left',
					horizontalAlign: 'left',
					// offsetY: -22,
					offsetX: -35,
        },
				dataLabels: {
					enabled: (isSubset) ? false : true,
					style: {
						fontSize: '1.1rem',
						colors: ['#FFFFFF99']
					},
					formatter: function(val, {seriesIndex, dataPointIndex}) {
						const ind = (isSubset) ? seriesIndex : dataPointIndex;
            const num = Number(val);
						return (isNaN(num))
							? `${labels.array[ind]}: 0`
							: `${labels.array[ind]}: ${Intl.NumberFormat().format(num)}${suffix ? suffix : ''}`
					},
					background: {
						enabled: true,
						foreColor: labelColors[0],
						borderWidth: 0,
					},
					textAnchor: 'start',
					// offsetX: 10,
					offsetY: 4,
				},
				xaxis: {
					categories: [...labels.array],
					labels: {
						show: false,
					},
				}
			} as ApexOptions
		};
	} else if (type === 'stacked-bar') {
		series = [{
			data: [kpi.array[0]]
		}, {
			data: [kpi.array[1]]
		}];
		GaugeConfig = {
			height: '100%',
			type: 'bar',
			options: {
				chart: {
					type: 'bar',
					stacked: true,
					toolbar: {
						show: false,
					}
				},
				legend: {
					show: true,
				},
				tooltip: {
					enabled: false,
				},
				plotOptions: {
					bar: {
						horizontal: true,
						dataLabels: {
							position: 'bottom',
						},
					},
				},
				grid: {
					padding: {
						top: 4,
						right: 24,
						bottom: 4,
						left: 0,
					}
				},
				title: {
					text: title,
					offsetY: 10,
					style: {
						fontSize:  '1.2rem',
						fontFamily: apexFontFamily,
					},
				},
				dataLabels: {
					enabled: true,
					style: {
						fontSize: '1rem',
						colors: [labelColors[0]]
					},
					formatter: function(val, {seriesIndex, dataPointIndex, w}) {
						const num = Number(val);
						return (isNaN(num))
							? `${labels.array[seriesIndex]}: 0`
							: `${labels.array[seriesIndex]}: ${Intl.NumberFormat().format(num)}`
					},
					textAnchor: 'start',
					offsetX: 10,
					offsetY: 5,
					dropShadow: (labelColors[1])
						? {
							enabled: true,
							left: 0,
							top: 0,
							opacity: 0.95,
							color: labelColors[1],
							blur: 2,
						}
						: {
							enabled: false,
						},
				},
				xaxis: {
					categories: [''],
					labels: {
						show: false,
					},
				}
			} as ApexOptions
		};
	}

	// Get the max to use for the limits when formatting to percentages
	const config = {
		...GaugeConfig,
		series,
		options: {
			...GaugeConfig.options,
			// colors,
		} as ApexOptions,
	};

	return (
		<>
			<QSMashupObject
				appId={appId}
				mashupId={mashupId}
				noShow
				customFormatter={({ title1, title2, num1, num2 }) => {
                    set_labels({ array: [getLabel(title1), getLabel(title2)]});
					set_kpi({ array: [formatter(num1), formatter(num2)] });
				}}
			/>
			{(kpi.array.length === 2) ? (
				<Chart {...config} type="bar" />
			) : (
				<Empty
					image={Empty.PRESENTED_IMAGE_SIMPLE}
					description="No Data"
				/>
			)}
		</>
	);
}

export default ApexChart;
