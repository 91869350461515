import React from 'react';
import QSMashupObject from '../../Qlik/QSMashupObject';
import Config from '../../../Config';

const TransplantDetails = () => (
	<div id="TransplantDetailsContainer" style={{ height: '66vh', width:'100%' }}>
		<QSMashupObject
			showExports
			appId={Config.Qlik.app1.ids.appId}
			mashupId={Config.Qlik.app1.ids.masterItems['Transplant Details Table']}
			elementId="tab4_qs1"
			contentStyle={{ height: '60vh' }}
			loaderSize="large"
			loaderText="Transplant Details Table"
		/>
	</div>
);

export default TransplantDetails;
