/**
 * Is imported by qscustom-table
 * Handles rendering and on-DOM events
 */
 import { Empty, Spin } from 'antd';
 import React, { useCallback, useMemo, useRef, useContext, useState, useEffect } from 'react';
 import {
   useResizeColumns,
   useTable,
 } from 'react-table';
 import EditableCell from '../editable-cell/editable-cell';
 import SelectedCell from '../selected-cell/selected-cell';
 import TotalCell from '../total-cell/total-cell';
 import ReactResizeObserver from 'rc-resize-observer';
 // import styled from 'styled-components';
 import './basic-table.module.scss';
 import { ConfigContext } from '@trinity-incyte/context';
 import HCP360Cell from '../../../Addons/hcp360-cell/hcp360-cell';
 import ProfCell from '../../../Addons/prof-cell/prof-cell';

 
 const defaultHiddenColumns = ['Cnt', 'Count']; // Any columns matching any of these names (case-sensitive) will be hidden
 
export interface BasicTableColumnRenderer {
  headerRenderer?: (cellInfo, columns, index) => any,
  cellRenderer?: (cellInfo, columns, data) => any
}

 /* eslint-disable-next-line */
 export interface BasicTableProps {
   alternateCellColors: boolean,
   columns: any;
   data: any;
   rawData?: any;
   tableProps?: any;
   qViz: any;
   tableStyles: any;
   getMore?: (index: number) => void;
   dynamic?: boolean;
   triggerSort?: (cId: string) => void;
   initialState?: any;
   id?: string;
   customClass?: string;
   objectKey?: string;
   columnRenderers?: Map<string, BasicTableColumnRenderer>;
 }
 
 export function BasicTable({
   alternateCellColors = true,
   customClass = 'qscustom-table',
   objectKey = '',
   columns,
   data,
   tableProps = { colProps: null },
   qViz,
   tableStyles,
   // sticky,
   // noResize = false,
   dynamic = false,
   initialState = {},
   id,
   columnRenderers = new Map<string, BasicTableColumnRenderer>()
 }: BasicTableProps) {
   const defaultColumn = useMemo(
     () => ({
       // Let's set up our default Filter UI
       // Filter: DefaultColumnFilter,
       // And also our default editable cell
       Cell: EditableCell,
       SelectedCell,
       TotalCell,
       minWidth: 60,
       // width: 150,
       maxWidth: 1200,
     }),
     []
   );
   // Use the state and functions returned from useTable to build your UI
 
   const {
     setHiddenColumns,
     state: { hiddenColumns },
     setSortBy,
   } = useTable(
     {
       columns,
       data: data,
       defaultColumn,
       initialState: {
         ...initialState,
         hiddenColumns: defaultHiddenColumns
       },
     },
     useResizeColumns
   ) as any;
 
   const newHiddenColumns = [...defaultHiddenColumns];
   for (let ii = 0; ii < columns.length; ii += 1) {
     const col = columns[ii];
     if (col.hide) {
       newHiddenColumns.push(col.accessor);
     }
   }
 
   if (newHiddenColumns.length !== hiddenColumns.length) {
     setHiddenColumns(newHiddenColumns); // This will cause a re-render
   }
 
   const [sortedColumn, set_sortedColumn] = useState<{ id: string, desc?: boolean }>();
   const strLoadingMessage = "Loading Data";
   const [loadingMessage, set_loadingMessage] = useState(strLoadingMessage);
   const Config = useContext(ConfigContext);
   const dataLoaded = useEffect(() => {
    if (!qViz?.table) {
      set_loadingMessage(strLoadingMessage);
    } else if (qViz?.table.rowCount>0) {
      set_loadingMessage(strLoadingMessage);
    } else {
      set_loadingMessage("No Data");
    }
  }, [qViz.table.rowCount]);
 

   useEffect(() => {
     if (sortedColumn?.id) {
       setSortBy([sortedColumn]);
     }
   }, [data]);

    // check to see if this is a count column, which don't dipsplay in Qlik
    const checkForCount = (itemToCheck) => (itemToCheck === 'Count' || itemToCheck === 'Cnt');
    const profHeaders = Config.App.profHeaders; // check for profheader to show HCP360 button
    const checkforHcp = (itemToCheck) => (profHeaders.includes(itemToCheck));
    
    const NativeHeaders = columns.map((objHeader, columnIndex) => {
      if (checkForCount(objHeader.Header)) return;
      if (columnRenderers.has(objHeader.accessor) && columnRenderers.get(objHeader.accessor).headerRenderer != null) {
        return columnRenderers.get(objHeader.accessor).headerRenderer(objHeader, columns, columnIndex);
      } else {
        return (
          <th key={`header_cell_${objHeader.qFallbackTitle}_${columnIndex}`}>
            {objHeader.Header}
          </th>
        )}
      }
    );

      const NativeRows = data.map((objRow, rowItem) => 
          <tr key={`dataRow_${rowItem}`}>
            {
              columns.map((colName, colIndex) => {
                if (checkForCount(colName.accessor)) return;
                if (columnRenderers.has(colName.accessor) && columnRenderers.get(colName.accessor).cellRenderer != null) {
                  return columnRenderers.get(colName.accessor).cellRenderer(objRow[colName.accessor], columns, objRow);
                } else {
                  if (checkforHcp(colName.accessor)) {
                    var regExp = /\(([^)]+)\)/;
                    var matches = regExp.exec(objRow[colName.accessor]);
                    if (matches && matches.length>=2) {
                      return (
                        <td key={`dataCell_${colName.accessor}`}>
                          <div style={{width:'100%', display:'flex'}}>
                            <div style={{flex:'1', paddingTop:'4px'}}>
                              {objRow[colName.accessor]} 
                            </div>
                            <div style={{flex:'0 0 38px', textAlign:'right'}}>
                              <ProfCell value={matches[1]} />
                            </div>
                            <div style={{flex:'0 0 36px', textAlign:'right'}}>
                              <HCP360Cell value={matches[1]} />
                            </div>
                          </div>
                        </td>
                      )
                    }    
                  } else {
                    return (
                      <td key={`dataCell_${colName.accessor}`} style={{}}>{objRow[colName.accessor]}</td>
                    )  
                  }

                }
              })
            }
          </tr>
      )

      const NativeRowGenerator = () => {
        if (data?.length==0) {
          return (
            <tr>
              <td colSpan={columns?.length || 1}>No data available</td>
            </tr>
          )
        } else {
          return null;
        }
      }

   return (
    <table key={objectKey} className={customClass}>
    <thead>
      <tr>
      {NativeHeaders}
      </tr>
    </thead>
    <tbody>
      <NativeRowGenerator />
      {NativeRows}
    </tbody>
  </table>
  );
 }
 
 export default BasicTable;
