import { useEffect, useState, useContext } from 'react';
import { useRecoilValue } from 'recoil';
import {
  QSAppMetadataFamily,
  QlikLastModified as QlikLastModifiedAtom
} from '@trinity-incyte/recoil';
import { ConfigContext } from '@trinity-incyte/context';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

export function use360ProfSelection(profId: string) {
    const [isProfSelected, set_isProfSelected] = useState<boolean>(false);
    const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
    const Config = useContext(ConfigContext);
    const config = Config.Qlik.Prof360;
    const { appId } = config.ids;
    const app = Mosaic.Qlik.app[appId];

    const metadata = useRecoilValue(QSAppMetadataFamily(appId));
    const { FieldsTable: fields } = metadata;

    useEffect(() => {
        app.unlockAll(); // Unlocks all locks on the current app;
        return () => {
            // Clear everything except for items in the lock list before moving to a new page
            const selectionsFilter = config.locks.map(
                (fieldName) => fields.get(fieldName)?.id
            );
            app.selections?.forEach((selection) => {
                if (selectionsFilter.indexOf(selection.fieldName) === -1) {
                    app.field(selection.fieldName).clear();
                }
            });
        };
    }, []);

    useEffect(() => {
      let selectionValue;
      let selectionField;
      if ( Number.isNaN( parseInt( profId, 10 ))) {
        const decodedSelection = atob( profId );
        [selectionField, selectionValue] = decodedSelection.split( '' );
      } else {
        selectionField = fields.get(Config.Qlik.Prof360.ids.fields['PROF ID SELECTION'])?.id;
        selectionValue = parseInt( profId, 10 );
      }

      const profIsSelected = app?.selections?.some(selection => selection.fieldName === selectionField && selection.selectedValues.length === 1 && selection.selectedValues[0].qName == profId);

      if (!profIsSelected) {
        const field = app.field( selectionField );
        field.clear().then(() => {
          field.selectValues([selectionValue], false, true );
        })
        set_isProfSelected(false);
      } else {
        set_isProfSelected(true);
      }
    }, [QlikLastModified]);

    return isProfSelected;
  };

export default use360ProfSelection;
