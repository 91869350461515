/**
 * Put this component on the page to handle QS Authentication
 */
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import React, { useEffect } from 'react';
import Config from '../../Config';

import useGlobal from '../../Store';
import QSStyleOverrides from './QSStyleOverrides';

declare var Mosaic: MosaicGlobal;

const QSAuthentication = () => {
	const [globalState, globalActions] = useGlobal();
	let content;

	const { config } = Config.Qlik;
	const resourceLink = `${( config.isSecure ? 'https://' : 'http://' ) + config.host + ( config.port ? ':' + config.port : '' ) + ( config.prefix === '/' ? '' : config.prefix ) }/resources`; // eslint-disable-line
	const qlikSetup = ( require ) => {
		require.config({ baseUrl: resourceLink });

		require(['js/qlik'], ( qlik ) => { // eslint-disable-line
			 qlik.setOnError(( error ) => {
				console.error( error.message ); // eslint-disable-line
			});

			globalActions.Qlik.registerQlik( qlik );
		});
	};

	useEffect(() => {
		if ( Config.Qlik.useJwtAuth ) {
			const xhr = new XMLHttpRequest();
			xhr.onreadystatechange = () => {
				if ( xhr.readyState === XMLHttpRequest.DONE ) {
					console.log( 'XHR done' ); // eslint-disable-line
					return;
				}
			};
			xhr.onload = () => {
				console.log( this ); // eslint-disable-line
			};
			xhr.open( 'GET', `https://${Config.Qlik.config.host}${Config.Qlik.config.prefix}/content/Default/BMTCenterMapLegend.PNG`, true );
			xhr.setRequestHeader( 'Authorization', `Bearer ${Config.Qlik.TESTTOKEN}` );
			xhr.withCredentials = true;
			xhr.send( null );

			setTimeout(() => {
				const elem = document.createElement( 'script' );
				elem.src = `${resourceLink}/assets/external/requirejs/require.js`;
				elem.type = 'text/javascript';
				document.body.appendChild( elem );
			}, 500 );
		} else {
			const elem = document.createElement( 'script' );
			elem.src = `${resourceLink}/assets/external/requirejs/require.js`;
			elem.type = 'text/javascript';
			document.body.appendChild( elem );
		}

		if ( Config.Qlik.doLoadQlikStyles ) {
			const styleElem = document.createElement( 'link' );
			styleElem.setAttribute( 'href', `${resourceLink}/autogenerated/qlik-styles.css` );
			styleElem.setAttribute( 'rel', 'stylesheet' );
			styleElem.setAttribute( 'type', 'text/css' );
			document.head.appendChild( styleElem );
		}

		// This will attempt to register qlik, and will retry 3 times, offset
		let require;
		setTimeout(() => {
			require = window.require;
			if ( typeof require !== 'function' ) {
				setTimeout(() => {
					require = window.require;
					if ( typeof require !== 'function' ) {
						setTimeout(() => {
							require = window.require;
							if ( typeof require !== 'function' ) {
								globalActions.Qlik.requireNotFound();
							} else {
								qlikSetup( require );
							}
						}, 3400 ); // Third & last try
					} else {
						qlikSetup( require );
					}
				}, 800 ); // Second try
			} else {
				qlikSetup( require );
			}
		}, 800 ); // First try
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if ( globalState.Qlik.qlik ) {
		console.log( 'loading styles' ); // eslint-disable-line
		content = (
			<span data-qsdsa="Authentication" style={{ display: 'none' }}>
				<QSStyleOverrides />
			</span>
		);
} else {
		console.log( 'not loading overrides' ); // eslint-disable-line
		content = <span data-qsdsa="Authentication" style={{ display: 'none' }} />;
	}

	return ( content );
};

 export default QSAuthentication;
