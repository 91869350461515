import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import Chart from 'react-apexcharts';
import styled from 'styled-components';
import './stacked-bar.scss';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

/* eslint-disable-next-line */
export interface StackedBarProps {
    chartConfig;
}

export function StackedBar({ chartConfig }: StackedBarProps) {
    return (
        <Container className="split-chart">
            {chartConfig.series.length ? (
                <Chart {...chartConfig} />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <LoadingOutlined
                        style={{
                            fontSize: 70,
                            display: 'block',
                        }}
                        spin
                    />
                </div>
            )}
        </Container>
    );
}

export default StackedBar;
