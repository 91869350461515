import incyteConnectLogo from '../assets/Images/incyte-connect-logo-sm.png';
import incyteConnectLogoColor from '../assets/Images/incyte-connect-logo-color.png';
import imgAdvisor from '../assets/Images/Ad03_w.png';
import imgDigitalPull from '../assets/Images/digital_pull.png';
import imgDigitalPush from '../assets/Images/digital_push.png';
import imgEEFlag from '../assets/Images/EE08_w.png';
import imgFaceToFace from '../assets/Images/face_to_face.png';
import imgExternalInvestigator from '../assets/Images/Inv01_w.png';
import imgJakafiExperience from '../assets/Images/link01_w.png';
import imgJakafi from '../assets/Images/Logo-Jakafi.png';
import imgMonjuvi from '../assets/Images/Logo-Monjuvi.jpg';
import imgPemazyre from '../assets/Images/Logo-Pemazyre.png';
import imgZynyz from '../assets/Images/Logo-Zynyz.png';
import imgInvestigatorFlag from '../assets/Images/micro01_w.png';
import MosaicLogoImg from '../assets/Images/MosaicLogo.png';
import MosaicDermLogoImg from '../assets/Images/MosaicDermLogo.png';
import imgPeerInteraction from '../assets/Images/peer_interaction.png';
import imgSpeaker from '../assets/Images/SP01_w.png';
import imgRegionalPlan from '../assets/Images/US_map_w.png';
import imgAlert from '../assets/Images/alert-icon.png';
import imgDoctor from '../assets/Images/doctoricon.png';
import imgDoctorSearch from '../assets/Images/doctorIconDark.png';
import imgOrganization from '../assets/Images/organizationicon.png';
import imgUsers from '../assets/Images/users.png';
import scheduledMail from '../assets/Images/scheduled-mail.png';
import stopIcon from '../assets/Images/StopIcon.png';
import GrayCheck from '../assets/Images/GrayCheck.png';
import GreenCheck from '../assets/Images/GreenCheck.png';

export {
    incyteConnectLogo,
    incyteConnectLogoColor,
    imgAdvisor,
    imgAlert,
    imgEEFlag,
    imgExternalInvestigator,
    imgJakafiExperience,
    imgInvestigatorFlag,
    imgSpeaker,
    imgRegionalPlan,
    imgJakafi,
    imgPemazyre,
    imgZynyz,
    imgMonjuvi,
    imgFaceToFace,
    imgDigitalPull,
    imgDigitalPush,
    imgPeerInteraction,
    MosaicLogoImg,
    MosaicDermLogoImg,
    imgDoctor,
    imgDoctorSearch,
    imgOrganization,
    imgUsers,
    scheduledMail,
    stopIcon,
    GrayCheck,
    GreenCheck,
};

