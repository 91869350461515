import React, { createRef, useEffect, useState } from 'react';
import { 
	Modal, 
	Row, 
	Col, 
	Image as AntImage, 
	Dropdown, 
	Menu as AntMenu, 
	Card, 
	Divider,
	Button,
	Menu as AntdMenu
} from 'antd';
import { Segment } from 'libs/ui/src/lib/Other/segment/segment';
import useGlobal from '../../Store';
import Config from '../../Config';
import KPIBox from '../Boxes/KPIBox';
import QSMashupObject from '../Qlik/QSMashupObject';
import { 
	ReloadOutlined, 
	ArrowLeftOutlined, 
	CloseOutlined,
	UpCircleOutlined,
	DownCircleOutlined 
} from '@ant-design/icons';
import imgGoogleMap from '../../Assets/Images/google_map.png';
import imgWebIcon from '../../Assets/Images/www icon small.png';
import imgSalesforce from '../../Assets/Images/SalesForce.png';
import imgUnknownDoc from '../../Assets/Images/UnknownDoc.jpg';
import Utils from '../../Utils';
import DraggableMenu from '../Menu/DraggableMenu';
import DraggablePanes from '../Menu/DraggablePanes';
import TransplantDetails from './Panes/TransplantDetails';
import ClinicalTrials from './Panes/ClinicalTrials';
import IncyteTeamInfo from './Panes/IncyteTeamInfo';
import EditStaffModalForm from '../Forms/edit-staff-modal-form/edit-staff-modal-form';
import TransferStaffModalForm from '../Forms/transfer-staff-modal-form/transfer-staff-modal-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare var Mosaic: MosaicGlobal;

const QuintileInfoButton = styled(Button)`
	color: white;
	background-color: #005CAD;
	border-color: #005CAD;
	font-size: .78571429rem;
	font-weight: 700;
	font-family: "QlikView Sans", Lato;
`;

const FilterByRoleButton = styled(Button)`
	color: black !important;
	background-color: #e0e1e2 !important;
	font-size: .78571429rem;
	font-weight: 700;
	font-family: "QlikView Sans", 'Lato';
`;

const RoleButton = styled(Button)`
	font-size: .78571429rem;
	font-weight: 700;
	font-family: "QlikView Sans", 'Lato';
	background-color: #E0E1E2;
	color: #595959;
`;

const ActionMenuButton = styled(Button)`
	width: 100%;
	color: white;
	background-color: #00b5ad;
	border-color: #00b5ad;
	font-family: "QlikView Sans", 'Lato';
	font-size: 1rem;
	font-weight: 700;
`;

const ActionButton = styled(Button)`
	color: white;
	background-color: #767676;
	border-color: #767676;
	font-size: 1rem;
	font-family: "QlikView Sans", 'Lato';
	font-weight: 700;
`;

const ButtonSegment = styled(Segment)`
	cursor: pointer; 
	background: rgba(240, 230, 255, 0.2) !important;
	font-family: "QlikView Sans", 'Lato';
	padding: 1rem !important;
`;

const contextRef = createRef<HTMLDivElement>();

let kpiHeight; // Which vh to use for container (non-nav) height;
let filterHeight;

const setSizes = () => {
	const screen = Utils.screen();
	if ( screen.class === 'TABLET' && screen.orientation === 'LANDSCAPE' ) {
		kpiHeight = 8;
		filterHeight = 20;
	} else if ( screen.orientation === 'PORTRAIT' ) {
		kpiHeight = 14;
		filterHeight = 20;
	} else {
		kpiHeight = 7;
		filterHeight = 18;
	}
};

const DetailsSidebar = ( ) => {
	const [globalState, globalActions] = useGlobal();
	const [paneIndex, setPaneIndex] = useState( 0 );
	const [showQuintileInfo, setShowQuintileInfo] = useState( false );
	const history = useHistory();

	setSizes();
	useEffect(() => {
		setSizes();
	}, [globalState.UI.screenOrientation]);

	const screen = Utils.screen();
	const { appId } = Config.Qlik.app1.ids;
	const sidebarWidth = 100;
	const sidebarTranslate = ( globalState.UI.showDetails ) ? 0 : screen.width * -1;

	// eslint-disable-next-line max-len
	const selections = ( globalState.Qlik.app[appId] && globalState.Qlik.app[appId].selections ) ? globalState.Qlik.app[appId].selections : false;

	let swipeEl;
	const switchPane = ( ind ) => {
		if ( swipeEl ) swipeEl.slide( ind );
		setPaneIndex( ind );
	};

	useEffect(() => {
		if ( !globalState.Qlik.app[appId] || !globalState.Qlik.app[appId].selections ) return;
		if ( Utils.isTransplantCenterSelected( globalState.Qlik.app[appId].selections )) {
			globalActions.UI.toggleDetails();
		}
	}, [selections]); // eslint-disable-line

	useEffect(() => {
		if ( globalState.UI.showDetails === false ) {
			globalActions.Qlik.clearAppSelections( appId );
			setPaneIndex( 0 );
		} else {
			const searchPopup = document.querySelector( '.lui-popover-container' );
			if ( searchPopup && searchPopup.parentElement && searchPopup.parentElement.parentElement ) {
				searchPopup.parentElement.parentElement.remove();
			}
		}
	}, [globalState.UI.showDetails]); // eslint-disable-line

	useEffect(() => {
		setTimeout(() => {
			if ( globalState.UI.showDetails ) {
				// console.log('details is now open');
			} else {
				// console.log('details is now closed');
			}
			globalActions.UI.toggleDetailsIsOpen();
		}, globalState.UI.detailsTransitionTiming );
	}, [globalState.UI.showDetails, globalState.UI.detailsTransitionTiming]); // eslint-disable-line

	const buttonSelection = ( event ) => {
		let button;
		if (event.target.nodeName === 'BUTTON') {
			button = event.target;
		} else if (event.target.nodeName === 'SPAN') {
			button = event.target.parentElement;
		} else {
			button = event.target.firstElementChild;
		}

		const selectionData = JSON.parse( button.dataset.selection );
		const selectionDataKeys = Object.keys( selectionData );

		Mosaic.Qlik.app[appId].field( 'BMTS.DEGREE' ).clear();
		Mosaic.Qlik.app[appId].field( 'BMTS.STAFF_ROLE' ).clear();

		for ( let ii = 0; ii < selectionDataKeys.length; ii += 1 ) {
			const field = selectionDataKeys[ii];
			const selection = selectionData[field];
			if ( selection !== '*' ) {
				// Selection is case-sensitive
				const selectionArray = Array.isArray( selectionData[field]) ? selectionData[field] : new Array( selectionData[field]);
				Mosaic.Qlik.app[appId].field( field ).selectValues( selectionArray, true, true );
			}
		}
	};

	const panes = [
	{
		menuItem: 'Center Details',
		pane: {
			key: '0',
			content: (
				<div>
					<Row gutter={[24, 10]} style={{ height: '100%' }}>
						<Col span={4} style={{ paddingTop: '10px' }}>
							<KPIBox customClass="kpibox kpi-blue kpibox-clickable" header="BMT Staff">
								<div>
									<Row>
										<Col span={12}>
											<QSMashupObject
												basic
												contentStyle={{ height: `${kpiHeight * 1.2}vh`, padding: '4px', marginBottom: '0px' }}
												appId={appId}
												mashupId={Config.Qlik.app1.ids.masterItems['Target MF/PV KPI']}
												elementId="details_KPI1"
												loaderSize="mini"
												isText
												textOnClick={() => {
													console.log( 'clicked on 1' );
													switchPane( 2 );
												}}
											/>
										</Col>
										<Col span={12}>
											<QSMashupObject
												basic
												contentStyle={{ height: `${kpiHeight * 1.2}vh`, padding: '4px', marginBottom: '0px' }}
												appId={appId}
												mashupId={Config.Qlik.app1.ids.masterItems['Target GVHD EES']}
												elementId="details_KPI2"
												loaderSize="mini"
												isText
												textOnClick={() => {
													console.log( 'clicked on 2' );
													switchPane( 2 );
												}}
											/>
										</Col>
									</Row>
									<Row>
										<Col span={12}>
											<QSMashupObject
												basic
												contentStyle={{ height: `${kpiHeight * 1.2}vh`, padding: '4px', marginBottom: '0px' }}
												appId={appId}
												mashupId={Config.Qlik.app1.ids.masterItems['Target National KPI']}
												elementId="details_KPI3"
												loaderSize="mini"
												isText
												textOnClick={() => {
													console.log( 'clicked on 3' );
													switchPane( 2 );
												}}
											/>
										</Col>
										<Col span={12}>
											<QSMashupObject
												basic
												contentStyle={{ height: `${kpiHeight * 1.2}vh`, padding: '4px', marginBottom: '0px' }}
												appId={appId}
												mashupId={Config.Qlik.app1.ids.masterItems['Target Regional -  KPI']}
												elementId="details_KPI4"
												loaderSize="mini"
												isText
												textOnClick={() => {
													console.log( 'clicked on 4' );
													switchPane( 2 );
												}}
											/>
										</Col>
									</Row>
								</div>
							</KPIBox>
							<KPIBox customClass="kpibox kpi-green" header={( globalState.UI.hasOrgPurchased ) ? 'SD Purchased - Yes' : 'SD Purchased - No'}>
								<div>
									<Row>
										<Col span={24}>
											<QSMashupObject
												basic
												contentStyle={{ height: `${kpiHeight * 1.3}vh`, padding: '4px', marginBottom: '0px' }}
												styleKpi="font-size: 4rem;"
												appId={appId}
												mashupId={Config.Qlik.app1.ids.masterItems['SD Purchases - First Date KPI']}
												elementId="details_KPI8"
												loaderSize="mini"
												isText
											/>
										</Col>
									</Row>
									<Row>
										<Col span={24}>
											<QSMashupObject
												basic
												contentStyle={{ height: `${kpiHeight * 1.3}vh`, padding: '4px', marginBottom: '0px' }}
												styleKpi="font-size: 3.4rem;"
												appId={appId}
												mashupId={Config.Qlik.app1.ids.masterItems['SD Purchases - Last Date KPI']}
												elementId="details_KPI9"
												loaderSize="mini"
												isText
											/>
										</Col>
									</Row>
								</div>
							</KPIBox>
						</Col>
						<Col span={8}>
							<Segment basic style={{ padding: '0px', height: `${filterHeight * 3.0}vh` }}>
								<QSMashupObject
									contentStyle={{ height: '50%' }}
									appId={appId}
									mashupId={Config.Qlik.app1.ids.masterItems['Transplants By Year Stacked Bar']}
									elementId="details_qs3a"
									loaderSize="small"
								/>
								<QSMashupObject
									contentStyle={{ height: '50%' }}
									appId={appId}
									mashupId={Config.Qlik.app1.ids.masterItems['Allo Transplants By Year Stacked Bar']}
									elementId="details_qs3b"
									loaderSize="small"
								/>
							</Segment>
						</Col>
						<Col span={4} style={{ paddingTop: '10px' }}>
							<KPIBox customClass="kpibox kpi-orange" header="Incyte Cares Usage">
								<div>
									<Row>
										<Col span={24}>
											<QSMashupObject
												basic
												contentStyle={{ height: `${kpiHeight * 1.3}vh`, padding: '4px', marginBottom: '0px' }}
												appId={appId}
												mashupId={Config.Qlik.app1.ids.masterItems['iCares MF PV KPI']}
												elementId="details_KPI11"
												loaderSize="mini"
												isText
											/>
										</Col>
									</Row>
									<Row>
										<Col span={24}>
											<QSMashupObject
												basic
												contentStyle={{ height: `${kpiHeight * 1.3}vh`, padding: '4px', marginBottom: '0px' }}
												appId={appId}
												mashupId={Config.Qlik.app1.ids.masterItems['iCares GVHD KPI']}
												elementId="details_KPI12"
												loaderSize="mini"
												isText
											/>
										</Col>
									</Row>
								</div>
							</KPIBox>
							<KPIBox customClass="kpibox kpi-purple kpibox-clickable" header="Clinical Trials">
								<div // eslint-disable-line
									style={{ height: '100%', width: '100%', cursor: 'pointer', position: 'absolute', zIndex: 2, top: 0 }}
									onClick={() => {
										switchPane( 5 );
									}}
									onKeyPress={() => {
										switchPane( 5 );
									}}
								/>
								<div>
									<Row>
										<Col span={24}>
											<QSMashupObject
												basic
												contentStyle={{ height: `${kpiHeight * 1.3}vh`, padding: '4px', marginBottom: '0px' }}
												appId={appId}
												mashupId={Config.Qlik.app1.ids.other.QKPICTTotal}
												elementId="details_KPI13"
												loaderSize="mini"
												isText
											/>
										</Col>
									</Row>
									<Row>
										<Col span={12}>
											<QSMashupObject
												basic
												contentStyle={{ height: `${kpiHeight * 1.3}vh`, padding: '2px', marginBottom: '0px' }}
												appId={appId}
												mashupId={Config.Qlik.app1.ids.other.QKPICTRecruiting}
												elementId="details_KPI14"
												loaderSize="mini"
												isText
											/>
										</Col>
										<Col span={12}>
											<QSMashupObject
												basic
												contentStyle={{ height: `${kpiHeight * 1.3}vh`, padding: '2px', marginBottom: '0px' }}
												appId={appId}
												mashupId={Config.Qlik.app1.ids.other.QKPICTActive}
												elementId="details_KPI15"
												loaderSize="mini"
												isText
											/>
										</Col>
									</Row>
								</div>
							</KPIBox>
						</Col>
						<Col span={8} style={{ height:'100%' }}>
							<Segment basic style={{ padding: '0px', height: `${filterHeight * 2.1}vh` }}>
								<QSMashupObject
									showExports
									contentStyle={{ height: '100%' }}
									appId={appId}
									mashupId={Config.Qlik.app1.ids.masterItems['BMT Key Staff Table']}
									elementId="details_qs2"
									loaderSize="large"
								/>
							</Segment>
						</Col>
					</Row>
				</div>
			),
		},
	}, {
		menuItem: 'Transplant Stats',
		pane: {
			key: '1',
			content: (
				<div id="tab1_container" style={{ height: '66vh', width: '100%' }}>
					<div>
						<Row>
							<Col span={24}>
								<QSMashupObject
									basic
									contentStyle={{ height: '5vh', paddingTop: '0px', paddingBottom: '0px' }}
									appId={Config.Qlik.app1.ids.appId}
									mashupId={Config.Qlik.app1.ids.masterItems['Center Details Text Box']}
									elementId="tab1_qs0"
									loaderSize="tiny"
									isText
								/>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<QSMashupObject
									appId={Config.Qlik.app1.ids.appId}
									mashupId={Config.Qlik.app1.ids.masterItems['Year Allogenic Transplants Pie Chart']}
									elementId="tab1_qs1"
									contentStyle={{ height: '50vh' }}
									loaderSize="large"
								/>
							</Col>
							<Col span={12}>
								<QSMashupObject
									appId={Config.Qlik.app1.ids.appId}
									mashupId={Config.Qlik.app1.ids.masterItems['Year Allogenic Transplants Table']}
									elementId="tab1_qs2"
									contentStyle={{ height: '50vh' }}
									loaderSize="large"
								/>
							</Col>
						</Row>
					</div>
				</div>
			),
		},
	}, {
		menuItem: 'Staff Info',
		pane: {
			key: '2',
			content: (
				<div id="tab2_container" style={{ height: '66vh', width: '100%' }}>
					<QSMashupObject
						addon={(
							<div
								style={{ position: 'absolute', zIndex: 2, top: '7px', right: '25%' }}
							>
								<QuintileInfoButton
									size="small"
									className="square-corners"
									icon={( showQuintileInfo ) ? <UpCircleOutlined style={{ paddingRight: '5px' }} /> : <DownCircleOutlined style={{ paddingRight: '5px' }} />}

									onClick={() => {
										setShowQuintileInfo( !showQuintileInfo );
									}}
								>
									Quintile Info
								</QuintileInfoButton>
								{( showQuintileInfo )
								&& (
									<div style={{ marginTop: '2px', position: 'fixed', textAlign: 'left' }}>
										<QSMashupObject
											appId={Config.Qlik.app1.ids.appId}
											mashupId={Config.Qlik.app1.ids.masterItems['GVHD Quintiles Patient Count Text']}
											elementId="tab6_qs1"
											contentStyle={{ height: `${Math.floor( filterHeight * 1.5 )}vh`, width: '20vw' }}
											loaderSize="large"
											loaderText="GVHD Quintiles Patient Count Text"
										/>
									</div>
								)}
									<FilterByRoleButton
										size="small" 
										disabled 
										className="square-corners"
									>
										Filter by Role
									</FilterByRoleButton>
									<RoleButton
										size="small"
										className="square-corners"
										data-appid={Config.Qlik.app1.ids.appId}
										data-selection='{"BMTS.STAFF_ROLE": ["PROGRAM DIRECTOR","MEDICAL DIRECTOR"]}'
										onClick={buttonSelection}
									>
										Directors
									</RoleButton>
									<RoleButton
										size="small"
										className="square-corners"
										data-appid={Config.Qlik.app1.ids.appId}
										data-selection='{"BMTS.STAFF_ROLE": ["TRANSPLANT PHYSICIAN", "ATTENDING PHYSICIAN", "PHYSICIAN"]}'
										onClick={buttonSelection}
									>
										Physicians
									</RoleButton>
									{/* <RoleButton
										size="small"
										className="square-corners"
										data-appid={Config.Qlik.app1.ids.appId}
										data-selection='{"BMTS.STAFF_ROLE": ["CLINICAL PHARMACIST"]}'
										onClick={buttonSelection}
									>
									Pharmacists
									</RoleButton> */}
									<RoleButton
										size="small"
										className="square-corners"
										data-appid={Config.Qlik.app1.ids.appId}
										data-selection='{"BMTS.DEGREE": ["NP", "PA", "RN", "APRN"]}'
										onClick={buttonSelection}
									>
										APP
									</RoleButton>
									<RoleButton
										size="small"
										className="square-corners"
										data-appid={Config.Qlik.app1.ids.appId}
										data-selection='{"BMTS.STAFF_ROLE": "TRANSPLANT COORDINATOR"}'
										onClick={buttonSelection}
									>
										Transplant Coordinators
									</RoleButton>
									<RoleButton
										size="small"
										className="square-corners"
										data-appid={Config.Qlik.app1.ids.appId}
										data-selection='{"BMTS.STAFF_ROLE": ["TRANSPLANT SOCIAL WORKER" , "BMT PSYCHOLOGISTS"]}'
										onClick={buttonSelection}
									>
										Patient Support
									</RoleButton>
									<RoleButton
										size="small"
										className="square-corners"
										data-appid={Config.Qlik.app1.ids.appId}
										data-selection='{"BMTS.STAFF_ROLE": ""}'
										onClick={buttonSelection}
									>
										All
									</RoleButton>
							</div>
						)}
						appId={Config.Qlik.app1.ids.appId}
						mashupId={Config.Qlik.app1.ids.masterItems['Transplant Center Staff Table']}
						elementId="tab2_qs1"
						showExports
						addStaff
						contentStyle={{ height: '60vh' }}
						loaderSize="large"
						loaderText="Transplant Center Staff Table"
					/>
				</div>
			),
		},
	}, {
		menuItem: 'Incyte Team Info',
		pane: {
			key: '3',
			content: <IncyteTeamInfo />,
		},
	}, {
		menuItem: 'Transplant Details',
		pane: {
			key: '4',
			content: <TransplantDetails />,
		},
	}, {
		menuItem: 'Clinical Trials',
		pane: {
			key: '5',
			content: <ClinicalTrials />,
		},
	},
];


	const content = (
		<div
			className="details-sidebar"
			style={{ position: 'fixed', overflow: 'hidden', transition: `all ${globalState.UI.detailsTransitionTiming / 1000}s ${globalState.UI.detailsTransitionEasing}`, transform: `translateX(${sidebarTranslate}px)`, width: `${sidebarWidth}vw`, zIndex: 10 }}
		>
			<div ref={contextRef}>
				<Segment style={{ margin: 0 }}>
					<div style={{ width: '100%' }}>
						<Segment style={{ paddingTop: '0px', paddingBottom: '0px', marginTop: 0 }}>
							<Row style={{ width: '100%' }}>
								<Col span={12}>
									<QSMashupObject
										basic
										contentStyle={{ fontSize: '11px !important', fontFamily: "'QlikViewSans', 'Lato'" }}
										contentClass="no-vertical-padding"
										appId={appId}
										mashupId={Config.Qlik.app1.ids.masterItems['Transplant Center Text Box']}
										elementId="details_qs1"
										loaderSize="small"
										isText
									/>
								</Col>
								<Col span={5}>
									<Segment basic>
										<Row style={{ width: '100%' }}>
											<Col span={( screen.orientation === 'PORTRAIT' ) ? 8 : 5}>
												<a className='tiny-mini' style={{ display: 'block' }} href={globalState.UI.hrefGoogleMap}>
													<AntImage preview={false} src={imgGoogleMap} />
												</a>
											</Col>
											<Col span={( screen.orientation === 'PORTRAIT' ) ? 8 : 5}>
												<a className='tiny-mini' style={{ display: 'block' }} href={globalState.UI.hrefWebIcon}>
													<AntImage preview={false} src={imgWebIcon} />
												</a>
											</Col>
											<Col span={( screen.orientation === 'PORTRAIT' ) ? 8 : 5}>
												<a className='tiny-mini' style={{ display: 'block' }} href={globalState.UI.hrefSalesforce}>
													<AntImage preview={false} src={imgSalesforce} />
												</a>
											</Col>
										</Row>
									</Segment>
								</Col>
								<Col span={3}>
									<div onClick={() => {
										globalActions.Qlik.clearOtherFieldSelections( appId, Config.Qlik.app1.ids.fields.org_id );
									}}
									>
										<ButtonSegment 
											basic 
											size="small" 
											color="#21ba45" 
										>
											<ReloadOutlined style={{ color: "#21ba45", paddingRight: "5px" }} />
											<strong>Reset</strong>
										</ButtonSegment>
									</div>
								</Col>
								<Col span={1}/>
								<Col span={3}>
									<div onClick={() => {
										globalActions.UI.toggleDetails();
									}}
									>
										<ButtonSegment basic size="small" color="red">
											<ArrowLeftOutlined style={{ color: 'red', paddingRight: '5px' }} />
											<strong>Close</strong>
										</ButtonSegment>
									</div>
								</Col>
							</Row>
						</Segment>
					</div>
					<Modal
						visible={globalState.UI.showStaffCard}
						mask={globalState.UI.showStaffCard}
						maskClosable = {!globalState.UI.showStaffCard}
						width={`${Math.floor( filterHeight * 1.4 )}vw`}
						bodyStyle={{ padding: 0 }}
						footer={null}
						closeIcon={
							<CloseOutlined
							style={{ display: 'flex', flexDirection: 'row-reverse' }}
								onClick={() => {
									globalActions.Qlik.clearOtherFieldSelections( appId, Config.Qlik.app1.ids.fields.org_id );
									globalActions.UI.closeStaffCard();
								}}
							/>
						}
					>	
						<div style={{ zIndex: 3 }}>
							{ globalState.UI.showStaffCard && (
								<Card id="staffCard" bodyStyle={{ padding: 0 }}>
									<Row>
										<Col span={6}>
										<Dropdown
											overlay={
												<AntMenu mode="inline" style={{width: '150px'}}>
													<ActionMenuButton id="buttonMosaicComm" disabled={!globalState.UI.mosaicHCPUrl} href={globalState.UI.mosaicHCPUrl} target="_blank" rel="noopener noreferrer">Open In Mosaic</ActionMenuButton>
													<ActionMenuButton id="buttonMDMComm" disabled={!globalState.UI.hrefMosaicComm} href={globalState.UI.hrefMosaicComm} target="_blank" rel="noopener noreferrer">Go To MDM</ActionMenuButton>
													<ActionMenuButton id="buttonTransferComm" disabled={globalState.UI.disableEdit} onClick={() => globalActions.UI.openModal({content: <TransferStaffModalForm />, closeIcon: true, closeOnOutsideClick: false})}>Change Affiliation</ActionMenuButton>
													<ActionMenuButton id="buttonEditComm" disabled={globalState.UI.disableEdit} onClick={() => globalActions.UI.openModal({content: <EditStaffModalForm />, closeIcon: true, closeOnOutsideClick: false})}>Edit</ActionMenuButton>
												</AntMenu>
											}
										>
											<ActionButton title={!globalState.UI.mosaicHCPUrl && !globalState.UI.hrefMosaicComm && globalState.UI.disableEdit ? 'Prof ID Not Found': undefined}>
												Action
											</ActionButton>
										</Dropdown>
										</Col>
										<Col span={12}>
											<AntImage src={globalState.UI.imgProfURL} fallback={imgUnknownDoc} preview={false} style={{ width: `${Math.floor( filterHeight * 0.7 )}vw`, margin: '0 auto', minHeight: 100, minWidth: 100 }} />
										</Col>
									</Row>
									<Divider type="horizontal" style={{ width: '100%', margin: 0 }}/>
										<div>
											<Row style={{ width: '100%', paddingBottom: '0px' }}>
												<Col span={24}>
													<QSMashupObject
														basic
														contentStyle={{ height: `${Math.floor( filterHeight * 0.8 )}vh`, padding: '0px', marginBottom: '0px' }}
														appId={appId}
														mashupId={Config.Qlik.app1.ids.masterItems['Staff Details Text Box']}
														elementId="card_qs1"
														loaderSize="mini"
													/>
												</Col>
											</Row>
											<Row style={{ paddingTop: '0px' }}>
												<Col span={7}>
													<QSMashupObject
														basic
														contentStyle={{ height: `${Math.floor( filterHeight * 0.8 )}vh`, padding: '0px', marginBottom: '0px' }}
														appId={appId}
														mashupId={Config.Qlik.app1.ids.masterItems['Staff Details MF/PV Target Text Box']}
														elementId="card_qs2"
														loaderSize="mini"
													/>
												</Col>
												<Col span={1}>
													<Divider type="vertical" style={{ height: '100%' }} />
												</Col>
												<Col span={8}>
													<QSMashupObject
														basic
														contentStyle={{ height: `${Math.floor( filterHeight * 0.8 )}vh`, padding: '0px', marginBottom: '0px' }}
														appId={appId}
														mashupId={Config.Qlik.app1.ids.masterItems['Staff Details GVHD EE Text Box']}
														elementId="card_qs3"
														loaderSize="mini"
													/>
												</Col>
												<Col span={1}>
													<Divider type="vertical" style={{ height: '100%' }} />
												</Col>
												<Col span={7}>
													<QSMashupObject
														basic
														contentStyle={{ height: `${Math.floor( filterHeight * 0.8 )}vh`, padding: '0px', marginBottom: '0px' }}
														appId={appId}
														mashupId={Config.Qlik.app1.ids.masterItems['Staff Details Focus Areas Text Box']}
														elementId="card_qs4"
														loaderSize="mini"
													/>
												</Col>
											</Row>
										</div>
								</Card>
							)}
						</div>
					</Modal>
					<DraggableMenu
						className="square-corners"
						selectedKeys={[paneIndex.toString()]}

						onClick={( activeIndex ) => {
							switchPane( activeIndex );
							// Clear filters from previous panes when switching.
							globalActions.Qlik.clearOtherFieldSelections( appId, Config.Qlik.app1.ids.fields.org_id );
						}}
					>
						{
						panes.map(( val ) => (
							<AntdMenu.Item
								title={val.menuItem}
								key={val.pane.key}
								onClick={() => {
									switchPane( val.pane.key );
									// Clear filters from previous panes when switching.
									globalActions.Qlik.clearOtherFieldSelections( appId, Config.Qlik.app1.ids.fields.org_id );
								}}
							>
								{val.menuItem}
							</AntdMenu.Item>
						))
					}
					</DraggableMenu>
					<DraggablePanes
						panes={panes}
						containerHeight="66vh"
						activeItem={paneIndex}
					/>
				</Segment>
			</div>
		</div>
);
return ( content );
};

export default DetailsSidebar;
