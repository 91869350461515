import React from 'react';
import { Button, ButtonProps } from 'antd';
import './list-card-label.module.scss';

/* eslint-disable-next-line */
export interface ListCardLabelProps {
  style?: React.CSSProperties;
  component: any;
  size?: ButtonProps['size'];
  type?: ButtonProps['type'];
  children?: ButtonProps['children'];
  className?: ButtonProps['className'];
}

export function ListCardLabel({
  component,
  children,
  className
}: ListCardLabelProps) {
  const labelStyle = {
    color: 'white',
    boxShadow: '0px 0 2px 0px black',
    fontWeight: 'bold',
    fontSize: '0.9rem',
  }
  if ( component?.type?.__ANT_BUTTON === true) {
    component.props.style.color = labelStyle.color;
    component.props.style.boxShadow =labelStyle.boxShadow;
    component.props.style.fontWeight = labelStyle.fontWeight;
    component.props.style.fontSize = labelStyle.fontSize;
  };
  return (
    component
  );
}

export default ListCardLabel;
