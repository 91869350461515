// This is the popup that opens when you click View As Table on the HCP360 Interactions page

import React, { useState, useContext } from 'react';
import { Button, Modal } from 'antd';
import { closeSubPageSelector, subPageState } from '@trinity-incyte/recoil';
import Utils from '@trinity-incyte/utils';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { ConfigContext } from '@trinity-incyte/context';
import QSMashupObject from '../Qlik/qsmashup-object';
import styled from 'styled-components';

/* eslint-disable-next-line */

const CloseButton = styled(Button)`
	position: absolute;
	margin-right: 0;
	margin-top: 0;
	top: 0px;
	right: 0px;
	color: white;
	background-color: black;
	border-color: black;
`;

export interface SubPageProps {}

export function SubPage(props: SubPageProps) {
	const Config = useContext(ConfigContext);
  const subPage = useRecoilValue(subPageState);
  const closeSubPage = useSetRecoilState(closeSubPageSelector);
  const config = Config.Qlik.Prof360,
		{ appId } = config.ids;
  const subPageData = (
		<QSMashupObject
			key={subPage.mashupId}
			appId={appId}
			mashupId={subPage.mashupId}
			tableProps={subPage.tableProps}
			dynamic={subPage.dynamic}
			contentStyle={{ marginTop: '0px !important', height: 'calc(100% - 2rem)' }}
			isTable
			showExports
			subPage
		/>
	);
    return (
		<Modal
			closable={false}
			centered={true}
			footer={null}
			visible={subPage.isOpen}
			destroyOnClose={true}
			onCancel={()=>closeSubPage()}
			width='80vw'
			zIndex={1030}
		>
			<div
				style={{
					width: '100%',
					height: '80vh',
					fontSize: '1.42857143rem'
				}}
			>
				<CloseButton
					onClick={()=>closeSubPage()}
				>
					Close
				</CloseButton>
				{subPageData}
			</div>
		</Modal>
	);
};

export default SubPage;
