import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil';
import { activeTeam as activeTeamAtom } from '@trinity-incyte/recoil';
import { editQueryParameter, useQuery } from '@trinity-incyte/utils';
import { useHistory, useLocation } from 'react-router-dom';

export type TabDef = {
	comp: (props: any) => JSX.Element
	name: string
}

type TabGroupParams = {
	tabs?: Array<TabDef>,
	tabsByTeam?: any,
	onTabChange?: () => void
}

export const TabGroup = (props: TabGroupParams) => {
	const query = useQuery();
	const initialLoad = useRef(true);
	const activeTeam = useRecoilValue(activeTeamAtom);
	const tabsToRender: Array<TabDef> = props.tabs ?? props.tabsByTeam[activeTeam];
	const defaultTabIndex = tabsToRender?.findIndex((tab) => tab.name === query.get('tab'));
	const [ activeTabIndex, setActiveTabIndex ] = useState(Math.max(defaultTabIndex, 0));
	
	const history = useHistory();
	const location = useLocation();

	useEffect(()=> { 
		if (initialLoad.current) {
			initialLoad.current = false;
		}
		else if (props.tabsByTeam) {
			editQueryParameter('tab', tabsToRender[0].name, history, location)
			setActiveTabIndex(0)
		}
	}, [activeTeam]);

	if (!tabsToRender) return (<></>);
	if (activeTabIndex + 1 > tabsToRender.length) {
		setActiveTabIndex(0);
		return (<></>);
	}
	if (tabsToRender.length === 0) return (<></>);
	if (tabsToRender.length === 1) {
		const TabComp = tabsToRender[0].comp;
		return (<TabComp {...props}/>);
	}

	const ActiveTabComp = tabsToRender[activeTabIndex].comp;
	return (
		<div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
			<div className='overviewTabGroup'>
				{tabsToRender.map((tabToRender, tabIndex) => (
					<div 
						className={`overviewTabHeader ${tabIndex === activeTabIndex ? 'active' : ''}`}
						onClick={() => { 
							editQueryParameter('tab', tabsToRender[tabIndex].name, history, location)
							setActiveTabIndex(tabIndex) 
							props.onTabChange?.()
						}}
						key={tabToRender.name}
					>
							{tabToRender.name}
					</div>
				))}
			</div>
			<ActiveTabComp {...props} />
		</div>
	)
};