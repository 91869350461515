import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useBackend, useSelections } from '@trinity-incyte/hooks';
import {
	HCP360Dimensions as HCP360DimensionsAtom,
	ListBuilderState as ListBuilderStateAtom,
	QlikLastModified as QlikLastModifiedAtom,
	QlikUser as QlikUserAtom,
} from '@trinity-incyte/recoil';
import useGlobal from '@trinity-incyte/store';
import { 
	Button, 
	Col, 
	Divider, 
	Form, 
	Image,
	Input, 
	message, 
	Popconfirm, 
	Row 
} from 'antd';
import { 
	CheckOutlined, 
	DeleteOutlined, 
	SearchOutlined, 
} from '@ant-design/icons';
import { Loader } from "@trinity-incyte/ui";
import  stopIcon from 'libs/assets/src/assets/Images/StopIcon.png';
import { GreenCheck, GrayCheck } from '@trinity-incyte/assets';
import styled from 'styled-components';
import QSMashupObject from '../Qlik/qsmashup-object';
import { MosaicGlobal, QlikAppInstance, QlikVisualizationInstance } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;
declare var window: any;

const BACKEND_CLASS_NAME = 'MosaicComm_LB_CustomList';

const StyledRow = styled(Row)`
	height: 38%;
	width: 100%;
	margin: 0 !important;
`;

const StyledTableRow = styled(Row)`
	height: 62%;
	width: 100%;
	margin: 0 !important;
`;

const StyledCol = styled(Col)`
	height: 100%;
	overflow: hidden auto;
`;

const StyledListHeader = styled.div`
	text-align: center;
	position: relative;
	top: 2px;
	font-weight: 700;
`;

/* eslint-disable-next-line */
export interface MosaicListBuilderProps {
	config: any;
	myGeo: any;
	activeTeam?: any;
	userIdField: string;
	appName?: string;
	reset: Date;
	segmentList: any[];
	mappings: any[];
	filterButtonItems: any[];
	filtersReducer?: (curr, val) => any;
	segmentButtonReducer: (curr, val) => any;
	initialItemsArrayReducer: (curr, val) => any;
	allowlistedItemsReducer: (curr, val) => any;
	dimensionListReducer: (one, two) => any;
	dimensionListFilter: (_) => any;
	tableOptions?: any;
}

export function MosaicListBuilder({
	config,
	myGeo,
	activeTeam,
	userIdField,
	appName,
	reset,
	segmentList,
	mappings,
	filterButtonItems,
	filtersReducer = (curr, val) => {
		curr.push(val);
		return curr;
	},
	segmentButtonReducer,
	initialItemsArrayReducer,
	allowlistedItemsReducer,
	dimensionListReducer,
	dimensionListFilter,
	tableOptions = {},
}: MosaicListBuilderProps) {
	const { appId } = config.ids;
	const app = Mosaic.Qlik.app[appId];
	const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
	const QlikUser = useRecoilValue(QlikUserAtom);
	const [ListBuilderState, set_ListBuilderState] = useRecoilState(
		ListBuilderStateAtom
	);
	const [, globalActions] = useGlobal();
	const Parse = useBackend();
	const { simpleSelections } = useSelections({ config });
	const [tableObject, set_tableObject] = useState<QlikVisualizationInstance>(null);
	const [dimensions, set_dimensions] = useRecoilState(HCP360DimensionsAtom);
	const [filterButtons, set_filterButtons] = useState({ array: [] });
	const [selectedItems, set_selectedItems] = useState({
		curr: JSON.stringify([]),
		prev: null,
	});
	const [backendBookmarks, set_backendBookmarks] = useState({ array: [] });
	const [backendState, set_backendState] = useState(null);
	const [searchTextFields, set_searchTextFields] = useState('');
	const [searchTextFilters, set_searchTextFilters] = useState('');
	const [searchTextLists, set_searchTextLists] = useState('');

	const dimensionList = useMemo(() => {
		if (!mappings.length) return false;

		let initialItemsArray = mappings.reduce(initialItemsArrayReducer, []);

		let allowlistedItems = mappings.reduce(allowlistedItemsReducer, {});

		set_selectedItems({
			curr: JSON.stringify(initialItemsArray),
			prev: selectedItems.curr,
		});

		// Sort field order
		const allowlistedIDs = Object.keys(allowlistedItems);
		return dimensionListReducer(allowlistedIDs, dimensions);
	}, [dimensions, reset, mappings, activeTeam]);

	const listSelection = useCallback(
		(target, definition, clearedSelect) => {
			if (target === window.document.body) return;
			if (target && Object.keys(target.dataset).length > 0) {
				const selectionData =
					definition.selection[target.dataset.selection];
				if (!selectionData) return;
				if (target.dataset.selection === 'clear') {
					selectionData.forEach((fieldId) => {
						const fieldName = dimensions.qDimensionList.qItems.reduce(
							(curr, val) => {
								if (
									parseInt(
										val.qMeta.masterScriptId ||
											val.qInfo.qId,
										10
									) === parseInt(fieldId, 10)
								)
									curr = val.qData.info[0].qName;
								return curr;
							},
							''
						);
						app.field(fieldName).clear();
					});

					set_selectedItems({
						curr: selectedItems.prev,
						prev: selectedItems.curr,
					});
				} else if (target.dataset.active === 'true') {
					const fieldKeys = Object.keys(selectionData);
					fieldKeys.forEach((fieldId) => {
						const fieldName = dimensions.qDimensionList.qItems.reduce(
							(curr, val) => {
								if (
									parseInt(
										val.qMeta.masterScriptId ||
											val.qInfo.qId,
										10
									) === parseInt(fieldId, 10)
								)
									curr = val.qData.info[0].qName;
								return curr;
							},
							''
						);
						app.field(fieldName).clear();
					});

					set_selectedItems({
						curr: selectedItems.prev,
						prev: selectedItems.curr,
					});
				} else {
					const requiredFieldsData = definition.requiredFields;
					const selectionDataKeys = Object.keys(selectionData);
					selectionDataKeys.forEach((fieldId) => {
						const selection = selectionData[fieldId];
						const fieldName = dimensions.qDimensionList.qItems.reduce(
							(curr, val) => {
								if (
									parseInt(
										val.qMeta.masterScriptId ||
											val.qInfo.qId,
										10
									) === parseInt(fieldId, 10)
								)
									curr = val.qData.info[0].qName;
								return curr;
							},
							''
						);

						if (selection === '*') {
							app.field(fieldName).selectAll();
						} else {
							// Selection is case-sensitive
							const selectionArray = Array.isArray(
								selectionData[fieldId]
							)
								? selectionData[fieldId]
								: new Array(selectionData[fieldId]);
							app.field(fieldName)
								.clear()
								.then(() => {
									app.field(fieldName).selectValues(
										selectionArray.map((val) => {
											if (
												Number.isNaN(parseInt(val, 10))
											) {
												if (fieldName === userIdField) {
													return val.replace(
														'\\\\',
														'\\'
													); // Changes 'DS\\blah' to 'DS\blah' to make it selectable
												} else {
													return val;
												}
											} else {
												return parseInt(val, 10);
											}
										})
									);
								});
						}
					});

					let sel = [];
					if (clearedSelect) {
						sel = requiredFieldsData;
						globalActions.Qlik.clearAppSelections(config, true);
					} else {
						sel = JSON.parse(selectedItems.curr);
						requiredFieldsData.forEach((fieldId) => {
							// NOTE: only looking at dimensions here
							const isValid = dimensionList.reduce(
								(curr, val) => {
									if (
										parseInt(
											val.qMeta.masterScriptId ||
												val.qInfo.qId,
											10
										) === fieldId
									)
										curr = true;
									return curr;
								},
								false
							);
							if (isValid && sel.indexOf(fieldId) === -1) {
								sel.push(fieldId);
							}
						});
					}

					set_selectedItems({
						curr: JSON.stringify(sel),
						prev: selectedItems.curr,
					});
				}
			} else {
				return listSelection(
					target.parentElement,
					definition,
					clearedSelect
				);
			}
		},
		[selectedItems, appId]
	);

	const createTableDef = (items) => {
		const tempArray = [];

		items.forEach((item) => {
			if (item === 10376) {
				// Special case for adding hidden Count column
				tempArray.push({
					qDef: {
						qDef: "Sum({<FC.RECORD_TYPE={'PROF_TARGET'}>} [DP.PROF_CNT])",
						qType: 'measure',
						qLabel: 'Count',
						title: 'Count',
						masterScriptId: item,
					},
				});
			} else {
				dimensionList.forEach((dimensionItem) => {
					if (
						item ===
						parseInt(
							dimensionItem.qMeta.masterScriptId?.toString() ||
								dimensionItem.qInfo.qId,
							10
						)
					)
						tempArray.push({
							qLibraryId: dimensionItem.qInfo.qId,
							qType: dimensionItem.qInfo.qType,
							title: dimensionItem.qMeta.title,
							masterScriptId:
								dimensionItem.qMeta.masterScriptId ||
								dimensionItem.qInfo.qId,
						});
				});
			}
		});
		return tempArray;
	};

	const isSelectionActive = useCallback(
		(app: QlikAppInstance, definition) => {
			if (!app) return false;
			const { selections } = app.selectionState();
			const fields = Object.keys(definition);
			const isFieldsSelected = new Array(fields.length).fill(1);
			fields.some((field, ii) => {
				const fieldName =
					dimensions.qDimensionList.qItems.reduce((curr, val) => {
						if (
							parseInt(
								val.qMeta.masterScriptId || val.qInfo.qId,
								10
							) === parseInt(field, 10) ||
							val.qData.title === field ||
							val.qData.info[0].qName === field
						)
							curr = val.qData.info[0].qName;
						return curr;
					}, '') || field;
				selections.forEach((selection) => {
					if (
						fieldName &&
						fieldName.toUpperCase() ===
							selection.fieldName.toUpperCase()
					) {
						const toSelect = Array.isArray(definition[field])
							? definition[field]
							: [definition[field]];
						const isSelected = selection.selectedValues;
						const isFieldValuesSelected = new Array(
							toSelect.length
						).fill(1);
						if (toSelect.length === isSelected.length) {
							isSelected.forEach(({ qName }, ik) => {
								let valueToCheck;
								if (Number.isNaN(parseInt(qName, 10))) {
									valueToCheck = qName;
								} else {
									valueToCheck = parseInt(qName, 10);
								}
								if (toSelect.indexOf(valueToCheck) !== -1) {
									isFieldValuesSelected[ik] = 0;
								}
							});
							if (
								isFieldValuesSelected.reduce(
									(a, b) => a + b
								) === 0
							) {
								isFieldsSelected[ii] = 0;
							}
						}
					}
				});
				if (isFieldsSelected[ii] !== 0) return true; // break early if not matched in any cycle
			});
			const retVal = isFieldsSelected.reduce((a, b) => a + b, 0) === 0;
			return retVal;
		},
		[dimensions]
	);

	// Checks both selection and requiredFields
	const isSegmentActive = useCallback(
		(segment) => {
			let retVal = false;
			if (
				isSelectionActive(
					Mosaic.Qlik.app[appId],
					segment.selection.default
				)
			) {
				const { requiredFields } = segment;
				const isFieldsSelected = new Array(requiredFields.length).fill(
					1
				);
				requiredFields.some((fieldId, ii) => {
					if (JSON.parse(selectedItems.curr).indexOf(fieldId) >= 0) {
						isFieldsSelected[ii] = 0;
					} else {
						return true;
					}
				});
				retVal = isFieldsSelected.reduce((a, b) => a + b, 0) === 0;
			}

			return retVal;
		},
		[selectedItems]
	);

	const getExpressionFromName = useCallback(
		(text) => {
			return dimensionList.reduce((curr, val) => {
				if (val.qData.title === text) curr = val.qData.info[0].qName;
				return curr;
			}, '');
		},
		[dimensionList]
	);
	const getExpressionFromLabel = useCallback(
		(text) => {
			return dimensionList.reduce((curr, val) => {
				if (val.qData.labelExpression === text)
					curr = val.qData.info[0].qName;
				return curr;
			}, '');
		},
		[dimensionList]
	);
	const getLabelFromName = useCallback(
		(text) => {
			return dimensionList.reduce((curr, val) => {
				if (val.qData.title === text) curr = val.qData.labelExpression;
				return curr;
			}, '');
		},
		[dimensionList]
	);
	const getNameFromLabel = useCallback(
		(text) => {
			if (!dimensionList) return null;
			return dimensionList.reduce((curr, val) => {
				if (val.qData.labelExpression === text) curr = val.qData.title;
				return curr;
			}, '');
		},
		[dimensionList]
	);

	const getIdFromExpression = useCallback(
		(text) => {
			if (!dimensionList) return null;
			const val = dimensionList.find((data) => data?.qData?.info?.[0]?.qName === text);
			return parseInt(val?.qMeta.masterScriptId || val?.qInfo.qId);
		},
		[dimensionList]
	);

	const CustomListInput = useCallback(() => {
		const [form] = Form.useForm();

		const isNameValid = (name: string) => {
			let retVal;
			const nameExists = backendBookmarks.array.reduce((acc, curr) => {
				if (
					curr.attributes.TITLE.toUpperCase() === name.toUpperCase()
				) {
					acc = true;
				}
				return acc;
			}, false);
			if (name === '') {
				retVal = false;
			}
			if (nameExists) {
				retVal = false;
			} else if (name !== '') {
				retVal = true;
			}
			return retVal;
		};

		const customListSave = (values: any) => {
			const { title } = values;
			if (!isNameValid(title)) return;

			set_backendState('SAVING');
			const LBClass = Parse.Object.extend(BACKEND_CLASS_NAME);
			const lb = new LBClass();
			lb.set('USER', QlikUser.authenticatedUser);
			lb.set('APP', appName);
			lb.set('DEFINITION', ListBuilderState);
			lb.set('TITLE', title);

			lb.save().then(
				(result) => {
					set_backendState(null);
					set_backendBookmarks({
						array: [...backendBookmarks.array, lb],
					});
					form.resetFields();
					window.analytics?.track('Button Clicked', {
						text: 'Save Custom List',
						context: 'List Builder Created Custom List',
						app: appName,
					});
				},
				(error) => {
					console.error('Error while creating ParseObject: ', error);
				}
			);
		};

		const onReset = () => form.resetFields();

		const layout = {
			labelCol: { span: 4 },
			wrapperCol: { span: 20 },
		};

		const nameProps = {
			name: 'title',
			rules: [
				() => ({
					validator(_, value) {
						const nameExists = backendBookmarks.array.reduce((acc, curr) => {
							if (
								curr.attributes.TITLE.toUpperCase() === value.toUpperCase()
							) {
								acc = true;
							}
							return acc;
						}, false);

						if (!value) {
							return Promise.reject(new Error('A name is required to save this list.'));
						}
						if (nameExists) {
							return Promise.reject(new Error('A list with this name already exists.'));
						}
						return Promise.resolve();
					}
				})
			],
		};

		return (
			<Form
				{...layout}
				autoComplete="off"
				form={form}
				id="customListForm"
				name="custom-list-form"
				onReset={onReset}
				onFinish={customListSave}
				style={{
					background: 'var(--WhiteFogAltHEX)',
					padding: '0.5rem',
					boxShadow: '0 0 4px var(--WhiteFogAltHEX)',
				}}
			>
					<div style={{display:'flex', width:'100%'}}>
						<div style={{width:'calc(100% - 70px)'}}>
						<Form.Item  {...nameProps}>
							<Input
								id="custom_list_name"
								style={{width:'calc(100%)'}}
								placeholder="Custom List Name"
							/>
						</Form.Item>
						</div>
						<div style={{marginLeft:'4px', width:'70px'}}>
						<Button type="primary" htmlType="submit">
							Save
						</Button>
						</div>
					</div>
			</Form>
		);
	}, [backendBookmarks, QlikUser, ListBuilderState]);

	const customLists = [
		backendBookmarks.array.map((bookmark) => {
			const definition = JSON.parse(bookmark.attributes.DEFINITION);
			const val = {
				text: bookmark.attributes.TITLE,
				requiredFields: definition.selectedItems,
				selection: definition.selection,
			};
			const isActive = isSegmentActive(val);
			function confirm(e) {
				console.log(e);
				bookmark.destroy().then(
					(row) => {
						message.success(`Bookmark deleted.`);
						set_backendBookmarks({
							array: backendBookmarks.array.filter((curr) => {
								return curr.id !== bookmark.id;
							}),
						});
					},
					(error) => {
						console.error(error);
					}
				);
			}

			function cancel(e) {
				console.log(e);
			}
			return (
				<Row
					style={{ padding: "0.3rem 0" }}
					align="middle"
					key={`segment_${val.text}`}
					data-selection="default"
					data-active={isActive}
					data-fields={JSON.stringify(val.requiredFields)}
					onClick={(event) => {
						listSelection(event.target, val, true);
					}}
				>
					<Col span={2}>
						<Button 
							shape="circle" 
							size="small"
							className="listbuilder-button"
						>
							<Image 
								preview={false} 
								width="17px" 
								height="17px" 
								src={isActive ? GreenCheck : GrayCheck}
							/>
						</Button>
					</Col>
					<Col span={20} style={{verticalAlign: "bottom"}}>
						{val.text}
					</Col>
					<Col span={2}>
						<Popconfirm
							title="Are you sure you want to delete this bookmark?"
							onConfirm={confirm}
							onCancel={cancel}
							okText="Yes"
							cancelText="No"
						>
							<DeleteOutlined style={{ fontSize: "1.5rem", color: "red" }}/>
						</Popconfirm>
					</Col>
					<Divider type="horizontal" className="no-margin"/>
				</Row>
			);
		}),
		<div key={'CustomListSection'}>
			<CustomListInput />
		</div>,
	];

	useEffect(() => {
		const fields = JSON.parse(selectedItems.curr);

		const selection = {};
		const selKeys = Object.keys(simpleSelections);
		for (let ii = 0; ii < selKeys.length; ii += 1) {
			const id = getIdFromExpression(selKeys[ii]);
			const values = simpleSelections[selKeys[ii]].map((val) => {
				return val.qName;
			});
			if (id) {
				selection[id] = values;
			} else {
				selection[selKeys[ii]] = values;
			}
		}
		set_ListBuilderState(
			JSON.stringify({
				selectedItems: fields,
				selection: { default: selection },
				activeTeam,
				myGeo,
			})
		);
	}, [simpleSelections, selectedItems, activeTeam]);

	useEffect(() => {
		if (!QlikUser.authenticatedUser) return;
		const LBClass = Parse.Object.extend(BACKEND_CLASS_NAME);
		const query = new Parse.Query(LBClass);
		query.equalTo('USER', QlikUser.authenticatedUser);
		query.equalTo('APP', appName);
		query.find().then(
			(results) => {
				set_backendBookmarks({
					array: results,
				});
			},
			(error) => {
				console.error('Error while fetching ParseObjects', error);
			}
		);
	}, [QlikUser]);

	useEffect(() => {
		let filters = filterButtonItems.reduce(filtersReducer, []);

		set_filterButtons({ array: filters });
	}, [filterButtonItems, activeTeam]);

	useEffect(() => {
		if (!app) return;

		if (dimensions.qDimensionList.qItems.length == 0) {
			app.getList('DimensionList', (list) => {
				if (dimensionList !== false) return;
				set_dimensions(list);
			});
		}
	}, [app]);

	const searchFilter = (text) => {
		return ({qData: { labelExpression } }) => (labelExpression.toUpperCase().indexOf(text.toUpperCase()) !== -1);
	};

	const fieldList = useMemo(() => {
		if (dimensionList) {
			const listD = dimensionList
				.filter(dimensionListFilter)
				.filter(searchFilter(searchTextFields))
				.map((val) => (
					<Row
						style={{ padding: "0.3rem 0" }}
						align="middle"
						key={val.qInfo.qId}
						data-qtitle={val.qMeta.title}
						data-qid={val.qInfo.qId}
						data-qfieldid={
							val.qMeta.masterScriptId || val.qInfo.qId
						}
						data-qtype="dimension"
						onClick={(event) => {
							const qFieldId = parseInt(
								event.currentTarget.dataset.qfieldid,
								10
							);
							const sel = JSON.parse(selectedItems.curr);
							if (sel.indexOf(qFieldId) >= 0) {
								sel.splice(sel.indexOf(qFieldId), 1);
							} else {
								sel.push(qFieldId);
							}

							set_selectedItems({
								curr: JSON.stringify(sel),
								prev: selectedItems.curr,
							});
						}}
					>
						<Col span={2}>
							<Button
								shape="circle"
								size="small"
								className="listbuilder-button"
							>
								<Image 
									preview={false} 
									width="17px" 
									height="17px" 
									src={JSON.parse(selectedItems.curr).indexOf(
										parseInt(
											val.qMeta.masterScriptId ||
												val.qInfo.qId,
											10
										)
									) >= 0
										? GreenCheck : GrayCheck}
								/>
							</Button>
						</Col>
						<Col span={22} style={{ verticalAlign: "bottom" }}>
							{val.qData.labelExpression}
						</Col>
						<Divider type="horizontal" className="no-margin" />
					</Row>
				));
			return listD;
		}
		return [];
	}, [dimensionList, selectedItems, searchTextFields, QlikLastModified]);

	const segmentButtons = useMemo(
		() => segmentList.reduce(segmentButtonReducer, []), 
		[dimensionList, segmentList, activeTeam]
	);

	useEffect(() => {
		if (
			!app ||
			!app.visualization ||
			!dimensionList ||
			!dimensionList.length /* || !isFieldListLoaded */
		)
			return;

			set_tableObject(null);
	
		app.visualization
			.create('table', createTableDef(JSON.parse(selectedItems.curr)), {
				scrolling: { keepFirstColumnInView: true },
				title: 'Filtered List',
			})
			.then((table) => {
				set_tableObject(table);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [app, dimensionList, selectedItems]);

	return (
		<>
			<StyledRow id="listBuilderMenus" gutter={[16, 16]}>
				<StyledCol span={8}>
					<div className="predefinedList">
						<Row justify="center">
							<StyledListHeader>
								Predefined Lists
							</StyledListHeader>
						</Row>
						{segmentButtons.length === 0 ? (
							<Row justify="center">
								<Col style={{ verticalAlign: "bottom" }}>
									<Loader text="Loading" />
								</Col>
							</Row>
						) : (
							segmentButtons.map((val) => {
								const isActive = isSegmentActive(val);
								return (
									<Row
										style={{ padding: "0.3rem 0" }}
										align="middle"
										key={`segment_${val.text}`}
										data-selection="default"
										data-active={isActive}
										onClick={(event) => {
											listSelection(
												event.target,
												val,
												true
											);
										}}
									>
										<Col span={2}>
											<Button 
												shape="circle" 
												size="small"
												className="listbuilder-button"
											>
												<Image 
													preview={false} 
													width="17px" 
													height="17px" 
													src={isActive ? GreenCheck : GrayCheck}
												/>
											</Button>
										</Col>
										<Col span={22}>
											{val.text}
										</Col>
										<Divider type="horizontal" className="no-margin"/>
									</Row>
								);
							})
						)}
					</div>
					{customLists ? (
						<div className="customList">
							<Row justify="center">
								<StyledListHeader>
									Custom Lists
								</StyledListHeader>
							</Row>
							{customLists}
						</div>
					) : null}
				</StyledCol>
				<StyledCol span={8}>
					<div className="fields">
						<Row justify='center'>
							<StyledListHeader>Fields</StyledListHeader>
							<Input
								placeholder={"Search Fields"}
								bordered={false}
								prefix={<SearchOutlined />}
								style={{
									borderBottom: '1px solid var(--brand)'
								}}
								allowClear
								onChange={(event) => {
									set_searchTextFields(event.target.value);
								}}
							/>
						</Row>
						{!fieldList || !fieldList.length ? (
							<Row justify="center">
								<div>
									<Loader text="Loading" />
								</div>
							</Row>
						) : (
							fieldList
						)}
					</div>
				</StyledCol>
				<StyledCol span={8}>
					<div className="filters">
						<Row justify='center'>
							<StyledListHeader>Filters</StyledListHeader>
						</Row>
						{filterButtons.array.length === 0 ? (
							<Row justify="center">
								<div style={{ verticalAlign: "bottom"}} >
									<Loader text="Loading" />
								</div>
							</Row>
						) : (
							filterButtons.array.map((val) => {
								return (
									<Row
										align="middle"
										style={{ padding: "0.3rem 0" }}
										key={`filters_${val.text}`}
										onClick={(event) => {
											listSelection(
												event.target,
												val,
												false
											);
										}}
									>
										<Col flex="20px">
											<Button
												data-selection="y"
												data-active={
													isSelectionActive(
														Mosaic.Qlik.app[appId],
														val.selection['y']
													)
														? 'true'
														: 'false'
												}
												style={{ 
													color: `${
														isSelectionActive(
															Mosaic.Qlik.app[appId],
															val.selection['y']
														)
															? '#21ba45'
															: '#595959'
													}`,
													borderColor: `${
														isSelectionActive(
															Mosaic.Qlik.app[appId],
															val.selection['y']
														)
															? '#21ba45'
															: '#595959'
													}`,
												}}
												className="listbuilder-yn"
												size="small"
												shape="circle"
											>
												Y
											</Button>
										</Col>
										<Col flex="20px">
											{val.selection['n'] && (
												<Button
													data-selection="n"
													data-active={
														isSelectionActive(
															Mosaic.Qlik.app[appId],
															val.selection['n']
														)
															? 'true'
															: 'false'
													}
													style={{
														color: `${
															isSelectionActive(
																Mosaic.Qlik.app[appId],
																val.selection['n']
															)
																? 'red'
																: "#595959"
														}`,
														borderColor: `${
															isSelectionActive(
																Mosaic.Qlik.app[appId],
																val.selection['n']
															)
																? 'red'
																: "#595959"
														}`,
													}}
													className="listbuilder-yn"
													size="small"
													shape="circle"
												>
													N
												</Button>
											)}
										</Col>
										<Col flex="auto">
											{val.text}
										</Col>
										<Col flex="20px">
											<Button 
												data-selection="clear"
												className="listbuilder-button"
												size="small"
											>
												<Image src={stopIcon} preview={false} width="16px" height="16px"/>
											</Button>
										</Col>
										<Divider type="horizontal" className="no-margin"/>
									</Row>
								);
							})
						)}
					</div>
				</StyledCol>
			</StyledRow>
			<StyledTableRow>
				<Col flex={1}>
					{!!tableObject ? (
					<QSMashupObject
						dynamic
						id="listBuilderTable"
						showExports
						key={`table_${tableObject.id}`}
						mashupId={tableObject.id}
						appId={tableObject.qapp.id}
						isTable
						{...tableOptions}
					/>
					) : (
						<div className="dimmer">
							<Loader text="Loading Data" />
						</div>
					)}
				</Col>
			</StyledTableRow>
		</>
	);
}

export default MosaicListBuilder;
