/**
 * Colorized KPI box
 */

import React from 'react';

const KPIBox = ( props ) => {
	const content = (
		<div color={props.color} className={props.customClass} style={props.boxStyle}>
			<h4 className={( props.minimalHeader ) ? 'zeroHeight' : ''} style={{ textAlign: 'left', fontWeight: 'bold' }}>{props.header}</h4>

			{props.children}
			{props.addOn}
		</div>
	);

	return (
		content
	);
};

export default KPIBox;
