import { selector, selectorFamily } from 'recoil';
import {
    bottomSliderState,
    leftSliderState,
    modalState,
    mosaicSearchResultsState,
    QlikAppsMetadata as QlikAppsMetadataAtom,
    showDetailsState,
    showFiltersState,
    sideNavIsExpandedState,
    subPageState
} from './atoms';

declare var window: any;

export const sideNavSelector = selector({
    key: 'sideNavSelector',
    get: ({ get }) => get(sideNavIsExpandedState),
    set: ({ set, get }) => {
        set(sideNavIsExpandedState, !get(sideNavIsExpandedState));
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 300);
    },
});

export const closeSidebarsSelector = selector({
    key: 'closeSidebarsSelector',
    get: undefined,
    set: ({ set }) => {
        set(showDetailsState, false);
        set(showFiltersState, false);
    },
});

export const toggleSubPageSelector = selector({
    key: 'toggleSubPageSelector',
    get: undefined,
    set: ({ set, get }, { mashupId, tableProps, dynamic }) => {
        const subPage = get(subPageState),
        { isOpen } = subPage;
        if (isOpen) {
            set(subPageState, { ...subPage, isOpen: false });
        } else {
            set(subPageState, {
                ...subPage,
                isOpen: true,
                mashupId,
                tableProps,
                dynamic
            });
        }
    },
});

export const toggleLeftSliderSelector = selector({
    key: 'toggleLeftSliderSelector',
    get: undefined,
    set: ({ set, get }, { content, key }) => {
        const state = get(leftSliderState);
        if (state.isOpen && state.key === key) {
            set(leftSliderState, {
                ...state,
                isOpen: false,
                content: false,
                key: false,
            });
        } else {
            set(leftSliderState, {
                ...state,
                isOpen: true,
                content,
                key,
            });
        }
    },
});

export const closeLeftSliderSelector = selector({
    key: 'closeLeftSliderSelector',
    get: undefined,
    set: ({ set, get }) => {
        set(leftSliderState, {
            ...get(leftSliderState),
            isOpen: false,
            content: false,
        });
    },
});

export const openLeftSliderSelector = selector({
    key: 'openLeftSliderSelector',
    get: undefined,
    set: ({ set, get }, content) => {
        set(leftSliderState, {
            ...get(leftSliderState),
            isOpen: true,
            content,
        });
    },
});

export const openBottomSliderSelector = selector({
    key: 'openBottomSliderSelector',
    get: undefined,
    set: ({ set, get }, content) => {
        set(bottomSliderState, {
            ...get(bottomSliderState),
            isOpen: true,
            content,
        });
    },
});

export const closeBottomSliderSelector = selector({
    key: 'closeBottomSliderSelector',
    get: undefined,
    set: ({ set, get }) => {
        set(bottomSliderState, {
            ...get(bottomSliderState),
            isOpen: false,
            content: false,
        });
    },
});

export const closeSubPageSelector = selector<void>({
    key: 'closeSubPageSelector',
    get: undefined,
    set: ({ set, get }) => {
        set(subPageState, { ...get(subPageState), isOpen: false });
    },
});

export const openModalSelector = selector({
    key: 'openModalSelector',
    get: undefined,
    set: ({ set }, { content, closable, width="50%", maskClosable = true }) => {
        set(modalState, {
            visible: true,
            mask: true,
            maskClosable,
            closable,
            keyboard: true,
            footer:null,
            content,
            width: "50%"
        });
    },
});

export const closeModalSelector = selector({
    key: 'closeModalSelector',
    get: undefined,
    set: ({ get, set }) => {
        set(modalState, { ...get(modalState), visible: false });
        window.analytics?.track('Button Clicked', { text: 'Close Icon', key: 'closeModalSelector', context: 'Close Modal Icon Clicked' })
    },
});

export const openDisclaimerSelector = selector({
    key: 'openDisclaimerSelector',
    get: undefined,
    set: ({ set }, { content, width="100%" }) => {
        set(modalState, {
            visible: true,
            mask: true,
            maskClosable: false,
            closable: false,
            keyboard: false,
            footer:null,
            content,
            width: "80%"
        });
    },
});

export const closeDisclaimerSelector = selector({
    key: 'closeDisclaimerSelector',
    get: undefined,
    set: ({ get, set }) => {
        set(modalState, { ...get(modalState), visible: false });
        window.analytics?.track('Button Clicked', { text: 'Checkmark Icon', key: 'closeDisclaimerSelector', context: 'Close Disclaimer Icon Clicked' })
    },
});

export const saveSearchResultsSelector = selector({
    key: 'saveSearchResults',
    get: ({ get }) => get(mosaicSearchResultsState),
    set: ({ get, set }, { searchText, results }) => {
        if (!searchText && !results) {
            get(mosaicSearchResultsState).clear();
        } else {
            get(mosaicSearchResultsState).set(searchText, results);
            set(mosaicSearchResultsState, get(mosaicSearchResultsState));
        }
    },
});

export const QlikAppMetadata = selectorFamily({
    key: 'QlikAppMetadata',
    get: (appId: string) => ({ get }) => {
        const metadata = get(QlikAppsMetadataAtom);
        return metadata[appId];
    },
    set: (appId: string) => ({ set }, newValue) =>
        set(QlikAppsMetadataAtom, (prevState) => ({
            ...prevState,
            [appId]: newValue,
        })),
});

export const QlikLatestUpdate = selector({
    key: 'QlikLatestUpdate',
    get: ({ get }) => {
        const metadata = get(QlikAppsMetadataAtom);
        const ids = Object.keys(metadata);

        let latestUpdate = 0;
        for (let ii = 0; ii < ids.length; ii += 1) {
            if (
                metadata[ids[ii]].lastModified &&
                metadata[ids[ii]].lastModified > latestUpdate
            ) {
                latestUpdate = metadata[ids[ii]].lastModified;
            }
        }
        return latestUpdate;
    },
});
