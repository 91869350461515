import React from 'react';
import { Modal } from 'antd';
import Disclaimer from '../Disclaimer';
import useGlobal from '../../Store';

const DisclaimerGroup = () => {
	const [globalState, globalActions] = useGlobal();

	return (
		<Modal
			maskClosable={false}
			onOk={globalActions.UI.closeModal}
			footer={null}
			width={'70%'}
			centered
			visible={!globalState.UI.hasAccepted}
			open={!globalState.UI.hasAccepted}
			bodyStyle={{ padding: '0px' }}

		>
			<Disclaimer/>
		</Modal>
	);
};

export default DisclaimerGroup;
