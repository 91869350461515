import React from 'react';
import styled, { CSSProperties } from 'styled-components';

/* eslint-disable-next-line */
export interface SegmentProps {
  children?: any;
  id?: string;
  className?: any;
  active?: any;
  style?: CSSProperties;
  basic?: any;
  inverted?: any;
  color?: string;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'big' | 'huge' | 'massive';
}

const StyledSegment = styled.div<SegmentProps>`
  position: relative;
  background: ${props => props.inverted ? 'black' : '#fff'};
  color: ${props => props.inverted ? 'white' : 'black'};
  margin: 1rem 0;
  padding: 1em 1em;
  border-top: ${props => props.color};
  border: 1px solid rgba(34,36,38,.15);
  font-size: ${props => props.size || '1rem'};
`;

const BasicSegment = styled.div<SegmentProps>`
  position: relative;
  margin: 0;
  padding: 4px;
  color: ${props => props.inverted ? 'white' : 'black'};
  background: ${props => props.inverted ? 'black' : 'transparent'};
  border-top: ${props => props.color};
  border: none;
  font-size: ${props => props.size || '1rem'};
`;

export function Segment({
  children, 
  id,
  className,
  style,
  basic,
  active,
  inverted,
  color,
  size
}: SegmentProps) { 

  let textFontSize;
  if (size === 'mini') {
    textFontSize = ".78571429rem";
  } else if (size === 'tiny') {
    textFontSize = ".85714286rem";
  } else if (size === 'small') {
    textFontSize = ".92857143rem";
  } else if (size === 'large') {
    textFontSize = "1.14285714rem";
  } else if (size === 'big') {
    textFontSize = "1.28571429rem";
  } else if (size === 'huge') {
    textFontSize = "1.42857143rem"
  } else if (size === 'massive') {
    textFontSize = "1.71428571rem";
  };

  let topBorder;
  if (color) {
    topBorder = `2px solid ${color} !important`;
  } else if (basic) {
    topBorder = 'none';
  } else {
    topBorder = '1px solid rgba(34,36,38,.15)';
  }

  if (basic) {
    return (
      <BasicSegment
        active={active} 
        className={className} 
        id={id}
        style={style} 
        size={textFontSize} 
        color={topBorder}
        inverted={inverted}
      >
        {children}
      </BasicSegment>
    )
  } else {
    return (
      <StyledSegment 
        active={active} 
        className={className}
        id={id}
        style={style} 
        size={textFontSize} 
        color={topBorder}
        inverted={inverted}
      >
        {children}
      </StyledSegment>
    );
  }
}

export default Segment;
