import Parse from 'parse';

/* eslint-disable-next-line */
export interface useBackendProps {}

export function useBackend() {
  Parse.serverURL = 'https://mosaic-backend.northcentralus.cloudapp.azure.com/parse'; // This is your Server URL
  Parse.initialize(
    'MosaicBackend', // This is your Application ID
    'E7AHyrWl6jOTqEitQT3uOpuQ' // This is your Javascript key
  );
  return Parse;
}

export default useBackend;
