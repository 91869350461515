import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
	QSAppMetadataFamily,
	defaultSelection as defaultSelectionAtom,
} from '@trinity-incyte/recoil';
import { ConfigContext } from '@trinity-incyte/context';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/* eslint-disable-next-line */
export interface useNon360ClearProfSelectionProps {
	selection?: any;
}

export function useNon360ClearProfSelection(
	{ selection }: useNon360ClearProfSelectionProps
) {
	const defaultSelection = useRecoilValue(defaultSelectionAtom);
	const Config = useContext(ConfigContext);
	const query = useQuery();
	const config = Config.Qlik.FieldSales;
	const { appId } = config.ids;
	const app = Mosaic.Qlik.app[appId];
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { FieldsTable: fields } = metadata;

	useEffect(() => {
		if (!app) return;
		
		app.unlockAll(); // Unlocks all locks on the current app;
		app.field(fields.get('Mosaic_Field Sales_PROF ID')?.id).clear().then(() => {
			if (selection) {
				for ( let ii = 0; ii < selection.length; ii += 1 ) {
					const { fieldName, qSelected } = selection[ii];
					const field = app.field(fieldName);
					if ( qSelected === '*' ) {
						field.selectAll();
					} else {
						// Selection is case-sensitive
						let selectionArray;
						if (Array.isArray(qSelected)) {
							selectionArray = qSelected;
						} else {
							selectionArray = new Array(qSelected);
						}
						field.selectValues( selectionArray );
					}
				}
			} else {
				const selKeys = Object.keys(defaultSelection);
				for ( let ii = 0; ii < selKeys.length; ii += 1 ) {
					const { fieldName, qSelected } = defaultSelection[selKeys[ii]];
					const field = app.field(fieldName);
					if ( qSelected === '*' ) {
						field.selectAll();
					} else {
						// Selection is case-sensitive
						let selectionArray;
						if (Array.isArray(qSelected)) {
							selectionArray = qSelected;
						} else {
							selectionArray = new Array(qSelected);
						}

						field.selectValues( selectionArray );
					}
				}
			}
		});
		return () => {
			const selectionsFilter = config.locks.map(
				(fieldName) => fields.get(fieldName)?.id
			);
			app.selections?.forEach((selection) => {
				if (!selectionsFilter.includes(selection.fieldName)) {
					app.field(selection.fieldName).clear();
				}
			});
		};
	}, []);
};

export default useNon360ClearProfSelection;
