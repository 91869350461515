import { ConfigContext } from '@trinity-incyte/context';
import React, { memo, useContext } from 'react';
import './prof-img.module.scss';

/* eslint-disable-next-line */
export interface ProfImgProps {}

export function ProfImg(props: ProfImgProps | any) {
    const Config = useContext(ConfigContext);
    let content;
        content = (
            <div style={{width:'100%', textAlign:'center', margin:'auto'}}>
            <img id={'profImg'} src={props.profImgUrl} loading="lazy" style={{maxHeight:'185px', maxWidth:'285px', border:'none'}} 
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src=Config.App.profImagesDefault;
              }}
             />
            </div>
        );
    return (
        content
    );
};

export default memo(ProfImg);
