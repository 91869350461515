import React from 'react';
import {
	NavLink,
	useLocation
} from 'react-router-dom';
import { Menu as AntdMenu, Divider, Dropdown } from 'antd';
import { useRecoilValue } from 'recoil';
import {
	QlikUser as qlikUserAtom,
	activeTeam as activeTeamAtom,
} from '@trinity-incyte/recoil';
import FilterButtons from '../filter-buttons/filter-buttons';
import BrandLogoImg from '../brand-logo-img/brand-logo-img';
import { CaretDownFilled } from '@ant-design/icons';
import './inner-page-nav-group.scss';
import Utils from '@trinity-incyte/utils';

declare var window: any;

export interface InnerPageNavGroupProps {}

export function InnerPageNavGroup(props: InnerPageNavGroupProps | any) {
	const { config } = props;
	const location = useLocation();
	const userData = useRecoilValue(qlikUserAtom);
	const dataDateJakPem = userData.dataDate.jakPem;
	const dataDateDerm = Utils.moment(new Date(userData.dataDate.derm)).format('MM/DD/YYYY');
	const dataDateMon = userData.dataDate.mon;
	const activeTeam = useRecoilValue(activeTeamAtom);

	const lastDateLabel = props.dataDateLabel != null 
		? props.dataDateLabel
		: props.showLastRX === true
			? 'Last Rx'
			: props.showLastRX

	const lastRxDate = props.dataDate != null
		? props.dataDate
		: dataDateJakPem == null
			? dataDateDerm
			: (activeTeam === 'HO3')
				? dataDateMon
				: dataDateJakPem

	let content;
	if ( props.simplified ) {
		content = (
			<AntdMenu mode="horizontal" theme="dark" id="topNav" className='noPrint'>
				<AntdMenu.Item>
					<h3>
						Page Name
					</h3>
				</AntdMenu.Item>
			</AntdMenu>
		);
	} else {
		content = (
			<AntdMenu 
				selectedKeys={[]}
				forceSubMenuRender={true}
				triggerSubMenuAction={'click'}
				mode="horizontal" 
				theme="dark" 
				className="square-corners no-margin noPrint" 
				style={{ justifyContent: `${location.pathname === "/ListBuilder" ? 'flex-end' : ''}`}}
				id="topNav" 
			>
				{( props.dropdownText && props.dropdownItems ) && (
					<AntdMenu.SubMenu
						theme="light"
						key="inner-pag-nav-submenu"
						icon={<CaretDownFilled style={{ color: "white" }}/>}
						className="styled-submenu square-corners no-active"
						title={props.dropdownText || 'Pages'}
					>
							{(props.dropdownItems) && props.dropdownItems.map(( val, i ) => {
								let component;
								if ( val.type ) {
									if ( val.type.toUpperCase() === 'HEADER' ) {
										component = (
											<div className='dropdown-header'
												key={val.key || val.text}
											>
												{val.text}
											</div>
										);
									} else if ( val.type.toUpperCase() === 'ITEM' ) {
										component = (
											<AntdMenu.Item
												key={val.key || val.text}
												style={{ minWidth: "200px", fontSize: "1em" }}
											>
												<NavLink to={val.to} onClick={val.onClick}>
													{val.text}
												</NavLink>
											</AntdMenu.Item>
										);
									} else if ( val.type.toUpperCase() === 'DIVIDER' ) {
										component = (
											<Divider
												className="no-margin"
												key={val.key || val.type}
											/>
										);
									} else if ( val.type.toUpperCase() === 'SUBMENU' ) {
										let menu = (
											<AntdMenu>
												{val.items.map(( item ) => (
													<AntdMenu.Item
														color="teal"
														key={item.text}
													>
														<NavLink to={item.to}>
															{item.text}
														</NavLink>
													</AntdMenu.Item>
												))}
											</AntdMenu>
										)
										component = (
											<Dropdown
												overlay={menu}
												key={(val.key || val.text)+i}
											>
												{val.text}
											</Dropdown>
										);
									}
								} else {
									component = (
										<AntdMenu.Item
											color="teal"
											key={val.key || val.text}
											style={{ minWidth: "200px", fontSize: "1em" }}
										>
											<NavLink to={val.to} onClick={val.onClick}>
												{val.text}
											</NavLink>
										</AntdMenu.Item>
									);
								}
								return ( component );
							})}
					</AntdMenu.SubMenu>
				)}
				{props.children}
				{(props.showLastRX) && (
					<AntdMenu.Item 
						key="last-rx-inner-nav-item"
						className="center-vertically static-topNav-item"
					>
						<span style={{ fontSize: '1.2rem', color: '#FFFFFFE6' }}>{` ${lastDateLabel}: ${lastRxDate}`}</span>
				</AntdMenu.Item>
				)}
				{(props.attached) && (
					<AntdMenu.Item 
						key="attached-inner-nav-item"
						className="center-vertically static-topNav-item" 
						style={{ paddingRight: 0, display: 'flex', justifyContent: 'flex-end' }}
					>
						{props.attached}
					</AntdMenu.Item>
				)}
				{( props.showClear || props.showFilters ) && (
					<AntdMenu.Item 
						key="filter-inner-nav-item"
						className="center-vertically static-topNav-item"
						style={{ padding:'0px', marginLeft: 'auto', marginRight:'0px' }}
					>
						{( props.showClear && props.showFilters ) && (
							<FilterButtons config={config} />
						)}
						{( !props.showClear && props.showFilters ) && (
							<FilterButtons config={config} hideClear />
						)}
						{( props.showClear && !props.showFilters ) && (
							<FilterButtons config={config} hideFilters />
						)}
					</AntdMenu.Item>
				)}
			</AntdMenu>
		);
	}

	return ( content );
};

export default InnerPageNavGroup;
