import React from 'react';
import { Modal } from 'antd';

import { modalState, closeModalSelector } from '@trinity-incyte/recoil';
import { useRecoilValue, useSetRecoilState } from 'recoil';


/* eslint-disable-next-line */
export interface ModalGroupProps {}

export function ModalGroup(props: ModalGroupProps) {
    const modal = useRecoilValue(modalState);
	const closeModal = useSetRecoilState(closeModalSelector);
    const content = (
	<Modal
		width={modal.width}
		visible={modal.visible}
		centered
		mask
		//alows for closing when you click outside the modal
		maskClosable={true}
		closable={modal.closable}
		footer={null}
		//allows for closing on escape
		keyboard={true} 
		onCancel={closeModal}
	>
		{modal.content}
	</Modal>
    );

    return (
        content
    );
};

export default ModalGroup;
