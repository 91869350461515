import React from 'react';
import { Progress } from 'antd';
import { gold, green, volcano } from '@ant-design/colors';
import './progress-bar.module.scss';

/* eslint-disable-next-line */
export interface ProgressBarProps {}

export const ProgressBarColor = (val) => {
    if (!val || val === '-') return '';
    let color;
    if (Number.isNaN(Number(val))) {
        if (val === 'LOW') {
            color = volcano[4];
        } else if (val === 'HIGH') {
            color = green[3];
        } else {
            color = gold[4];
        }
    } else {
        color = ``;
    }
    return color;
}

export const ProgressBar = (val) => () => {
    if (!val || val === '-') return <> </>;
    let color = ProgressBarColor(val);
    let percent;
    if (Number.isNaN(Number(val))) {
        if (val === 'LOW') {
            percent = 33;
        } else if (val === 'HIGH') {
            percent = 100;
        } else {
            percent = 66;
        }
    } else {
        percent = parseInt(val, 10) * 10;
    }

    return (
        <Progress
            strokeColor={color}
            trailColor="#AAAAAA00" // Invisible
            showInfo={false}
            percent={percent}
        />
    );
};

export default ProgressBar;
