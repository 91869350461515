import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { generateGetMoreData } from '@trinity-incyte/utils';
import { useEffect, useState } from 'react';
import { RecoilState, useSetRecoilState } from 'recoil';

declare const Mosaic: MosaicGlobal;

/* eslint-disable-next-line */
export interface QSMashupLoaderProps {
    mashupId: string;
    appId: any;
    mashupAtom: RecoilState<Map<string, { id: string; description: string }>>;
    lookup: { senseName: string; senseId: string; senseDescription: string };
}

// TODO: make this more usable outside its current limited context; i.e. dimensionlist
export function QSMashupLoader({
    mashupId,
    appId,
    mashupAtom,
    lookup: { senseName, senseId, senseDescription },
}: QSMashupLoaderProps) {
    const set_mashupMap = useSetRecoilState(mashupAtom);
    const [tableObject, set_tableObject] = useState<any>();
    const app = Mosaic.Qlik.app[appId];
    const onQVizData = (qViz) => {
        if (!qViz) return;
        const { rows, rowCount } = qViz.table,
            { length } = rows;
        if (length !== rowCount) {
            qViz.table.getMoreData();
        } else {
            const newMap = new Map();
            rows.forEach((item) => {
                const { qText: key } =
                    item.cells.find(
                        ({ qDimensionInfo: { qFallbackTitle } }) =>
                            qFallbackTitle === senseName
                    ) || {};
                const { qText: id } =
                    item.cells.find(
                        ({ qDimensionInfo: { qFallbackTitle } }) =>
                            qFallbackTitle === senseId
                    ) || {};
                const { qText: description } =
                    item.cells.find(
                        ({ qDimensionInfo: { qFallbackTitle } }) =>
                            qFallbackTitle === senseDescription
                    ) || {};
                newMap.set(key, {
                    id,
                    description,
                });
            });
            set_mashupMap(newMap);
        }
    };

    useEffect(() => {
        if (tableObject) {
            tableObject.table.addOnDataListener(() => onQVizData(tableObject));
            tableObject.table._instance.getMoreData = generateGetMoreData();
            tableObject.table.getMoreData();
        }
        return () => {
            if (tableObject && tableObject.close) {
                tableObject.close();
            }
        };
    }, [tableObject]);

    // This updates the table when the selections change
    // and once on page load
    useEffect(() => {
        app?.visualization.get(mashupId).then((qViz) => {
            set_tableObject(qViz);
        });
    }, []);
}

export default QSMashupLoader;
