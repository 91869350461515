import React, { useEffect } from 'react';
import { QSFieldListbox, Segment } from '@trinity-incyte/ui';

import './search-popup.module.scss';

/* eslint-disable-next-line */
export interface SearchPopupCustomProps {
	id: string;
	invokeElementId?: string;
	appId: string;
	fieldExpression: string;
	coordinates: any;
	handleClose?: any;
	selectionAppIds?: string[];
}

export function SearchPopupCustom({
	id,
	invokeElementId = null,
	appId,
	fieldExpression,
	coordinates,
	handleClose = () => {},
	selectionAppIds = null
}: SearchPopupCustomProps | any) {
	const options:any = {
		'showTitles': true,
		'title': id,
	};

	useEffect(() => {
		const shouldClosePopup = (el) => {
			let retVal = true;

			if (!el) {
				retVal = true;
			} else if (el.id === id) {
				retVal = false;
			} else if (invokeElementId && el.id === invokeElementId) {
				retVal = false;
			} else {
				retVal = shouldClosePopup(el.parentElement);
			}

			return retVal;
		};

		const popupListenerMouse = (ev:MouseEvent) => {
			if (shouldClosePopup(ev.target) === true) {
				handleClose();
			}
		};

		const popupListenerTouch = (ev:TouchEvent) => {
			if (shouldClosePopup(ev.target) === true) {
				handleClose();
			}
		};
		
		window.addEventListener('mouseup', popupListenerMouse);
		window.addEventListener('touchend', popupListenerTouch);

		return () => {
			window.removeEventListener('mouseup', popupListenerMouse);
			window.removeEventListener('touchend', popupListenerTouch);
		}
	}, []);

	if (id?.toUpperCase()?.indexOf('DATE') !== -1) {
		options.qListObjectDef = {
			qDef: {
				"autoSort": false,
				// "qFieldDefs": [fieldExpression],
				"qSortCriterias": [{
					"qSortByState": 1,
					"qSortByAscii": 1,
					"qSortByNumeric": -1,
					"qSortByLoadOrder": 1,
					"qSortByFrequency": 0,
					"qSortByGreyness": 1
				}],
			}
		};
	}

	const popupWidth = 30;
	const popupHeight = 58;
	const getLeft = () => {
		const popupWidthPX = ( popupWidth * window.innerWidth ) / 100;
		let left;
		// we don't want the popup to be shown at extreme right even if it fits. So adding 30px buffer
		if ( window.innerWidth - coordinates.left > popupWidthPX + 30 ) {
			left = coordinates.left + coordinates.width;
		} else {
			left = coordinates.left - popupWidthPX;
		}
		return left;
	};

	const getTop = () => {
		const popupHeightPX = ( popupHeight * window.innerHeight ) / 100;
		let top;
		// we don't want the popup to be shown at extreme bottom even if it fits. So adding 30px buffer
		if ( window.innerHeight - coordinates.top > popupHeightPX + 30 ) {
			top = coordinates.top ;
		} else {
			top = window.innerHeight - popupHeightPX - 30;
		}
		return top;
	};

	return (
		<Segment
			id={id}
			style={{
				position: 'fixed',
				left: `${getLeft()}px`,
				top: `${getTop()}px`,
				height: `${popupHeight}vh`,
				width: `${popupWidth}vw`,
				zIndex: 2000,
				padding: '0.25rem',
			}}
		>
			<QSFieldListbox
				{...{
					config: { ids: { appId: appId } },
					definition: [fieldExpression],
					title: id,
					placeholder: 'Filter ...',
					handleClose: handleClose,
					selectionAppIds: selectionAppIds || [appId],
					options
				}}
			/>
		</Segment>
	);
}

export default SearchPopupCustom;
