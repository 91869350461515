import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { QlikLastModified as QlikLastModifiedAtom } from '@trinity-incyte/recoil';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

/* eslint-disable-next-line */
export interface BadgeProps {
    appId?: string;
}

export function Badge(props: BadgeProps) {
	const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
	const [count, set_count] = useState(0);
	const { appId } = props;

	useEffect(() => {
		if ( Mosaic.Qlik.app[appId] ) {
			set_count(Mosaic.Qlik.app[appId].selectionsCount);
		} else {
			set_count(0);
		}
	}, [QlikLastModified]);

	if ( count > 0 ) {
		return (
			<span className="notification-icon">{count}</span>
		);
	} else {
		return (<></>);
	}
};

export default Badge;
