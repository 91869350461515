import React, { useState } from 'react';
import { Row, Col, Typography, Statistic, Select, Button } from 'antd';
import { ClearOutlined, FilterOutlined } from '@ant-design/icons';

/* eslint-disable-next-line */
export interface UseFilterProps {
	fromTable;
	defaultFilterValues?;
	set_selectedCompany;
	set_selectedTeam;
	set_selectedType;
	hideCount?;
}

export function useFilter({
	fromTable,
	defaultFilterValues,
	set_selectedCompany: filterCompany,
	set_selectedTeam: filterTeam,
	set_selectedType: filterType,
	hideCount = false,
}: UseFilterProps) {
	const sortAllToTop = (a, b) => {
		if (a < b || a === 'All') {
			return -1;
		} else if (a > b || b === 'All') {
			return 1;
		} else {
			return 0;
		}
	};
	const companiesKeys = Object.keys(fromTable.companies)
		.filter((val) => val !== '-')
		.sort();
	const typesKeys = Object.keys(fromTable.types)
		.filter((val) => val !== '-')
		.sort();
	const teamsKeys = Object.keys(fromTable.teams)
		.filter((val) => val !== '-')
		.sort();
	const [company, set_company] = useState('All');
	const [team, set_team] = useState('All');
	const [type, set_type] = useState(defaultFilterValues?.interaction ?? 'All');

	return (
		<Row
			wrap={false}
			gutter={[8, 8]}
			justify="start"
			align="stretch"
			style={{
				paddingBottom: 4,
				marginRight: 0,
				display:
					(companiesKeys.length <= 1 &&
					teamsKeys.length <= 1 &&
					typesKeys.length <= 1 &&
					hideCount)
					? 'none'
					: undefined,
			}}
		>
			{(companiesKeys.length > 2 ||
				teamsKeys.length > 2 ||
				typesKeys.length > 2) && (
				<Col flex="none" style={{ padding: 0 }}>
					<Button
						type="primary"
						disabled={
							company === 'All' &&
							team === 'All' &&
							type === 'All'
						}
						onClick={() => {
							filterCompany(false);
							filterTeam(false);
							filterType(false);
							set_company('All');
							set_team('All');
							set_type('All');
						}}
						title="Reset Filter"
						icon={<ClearOutlined />}
					>
						Reset Filter
						{/* <ClearOutlined /> */}
					</Button>
				</Col>
			)}
			{companiesKeys.length > 2 && (
				<Col flex={3} span={6}>
					<Row align="bottom" style={{ height: '100%' }}>
						<Col style={{ textAlign: 'center' }} span={24}>
							<Typography.Title
								level={5}
								underline
								style={{ padding: '0 1rem' }}
							>
								Company
							</Typography.Title>
						</Col>
						<Col span={24}>
							<Select
								size="large"
								value={company}
								onChange={(value: any) => {
									filterCompany(value === 'All' ? false : value);
									set_company(value);
								}}
								suffixIcon={<FilterOutlined />}
								style={{ width: '100%' }}
								options={companiesKeys.sort(sortAllToTop).map((value) => {
									const count = (value === 'All')
										? 0
										: fromTable.totalCount?.companies[value];
									return {
										label: `${value}${count ? ` (${count})` : ''}`,
										value,
									};
								})}
							/>
						</Col>
					</Row>
				</Col>
			)}
			{teamsKeys.length > 2 && (
				<Col flex={3} span={6}>
					<Row align="bottom" style={{ height: '100%' }}>
						<Col style={{ textAlign: 'center' }} span={24}>
							<Typography.Title
								level={5}
								underline
								style={{ padding: '0 1rem' }}
							>
								Team
							</Typography.Title>
						</Col>
						<Col span={24}>
							<Select
								size="large"
								value={team}
								onChange={(value: any) => {
									filterTeam(value === 'All' ? false : value);
									set_team(value);
								}}
								suffixIcon={<FilterOutlined />}
								style={{ width: '100%' }}
								options={teamsKeys.sort(sortAllToTop).map((value) => {
									const count = (value === 'All')
										? 0
										: fromTable.totalCount?.teams[value];
									return {
										label: `${value}${count ? ` (${count})` : ''}`,
										value,
									};
								})}
							/>
						</Col>
					</Row>
				</Col>
			)}
			{typesKeys.length > 2 && (
				<Col flex={3} span={6}>
					<Row align="bottom" style={{ height: '100%' }}>
						<Col style={{ textAlign: 'center' }} span={24}>
							<Typography.Title
								level={5}
								underline
								style={{ padding: '0 1rem' }}
							>
								Interaction Type
							</Typography.Title>
						</Col>
							<Col span={24}>
								<Select
									size="large"
									value={type}
									onChange={(value: any) => {
										filterType(value === 'All' ? false : value);
										set_type(value);
									}}
									suffixIcon={<FilterOutlined />}
									style={{ width: '100%' }}
									options={typesKeys.sort(sortAllToTop).map((value) => {
										const count = (value === 'All')
											? 0
											: fromTable.totalCount?.types[value];
										return {
											label: `${value}${count ? ` (${count})` : ''}`,
											value,
										};
									})}
								/>
							</Col>
					</Row>
				</Col>
			)}
			{!hideCount && (
				<Col flex={'auto'}>
					<Row justify="space-around" align="bottom">
						<Typography.Title
							level={5}
							underline
							style={{
								padding: '0 1rem',
								display: 'inline-block',
								textAlign: 'center',
							}}
						>
							Total Count
						</Typography.Title>
					</Row>
					<Row justify="space-around">
						<Statistic
							style={{ display: 'inline-block' }}
							valueStyle={{
								color: '#005cab',
								fontSize: '1.5rem',
							}}
							value={fromTable.totalCount?.All}
						/>
					</Row>
				</Col>
			)}
			{hideCount && hideCount !== true && (
				<Col flex={'auto'}>{hideCount}</Col>
			)}
		</Row>
	);
}

export default useFilter;
