import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ConfigContext } from '@trinity-incyte/context';
import { Button } from 'antd';
import { NavLink } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import QSFieldSelection from '../../../../../../libs/ui/src/lib/Qlik/qsfield-selection/qsfield-selection';
import {
  closeModalSelector,
  QSAppMetadataFamily,
	activeTeam as activeTeamAtom,
  isMasqueradeActive as isMasqueradeActiveAtom,
  currentMasqueradeSelection as currentMasqueradeSelectionAtom,
  masqueradeUserId as masqueradeUserIdAtom,
  myGeoState
} from '@trinity-incyte/recoil';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const window: any;
declare const Mosaic: MosaicGlobal;

export const TrainerSelect = (props) => {
  const Config = useContext(ConfigContext);
  const { appId } = Config.Qlik.FieldSales.ids;
  const app = Mosaic.Qlik.app[appId];
  const metadata = useRecoilValue(QSAppMetadataFamily(appId));
  const { IDsTable: ids, FieldsTable: fields } = metadata;
  const activeTeam = useRecoilValue(activeTeamAtom);
  const config = Config.Qlik.FieldSales;
  const [masqueradeSelectionValue, set_masqueradeSelectionValue] = useState('');
	const [currentMasqueradeSelectionAtm, set_currentMasqueradeSelectionAtm] = useRecoilState(currentMasqueradeSelectionAtom);

  const [initialRenderComplete, set_initialRenderComplete] = useState(false);
  const set_isMasqueradeActive = useSetRecoilState(isMasqueradeActiveAtom);
  const set_masqueradeUserIdAtom = useSetRecoilState(masqueradeUserIdAtom);
	const set_geographyFlag = useSetRecoilState(myGeoState);

  // this reconciles the difference between the friendly names in derm and mosaic
  let masqueradeFieldIdFriendlyName = "";
  let repNameFieldIdFriendlyName = "";
  let selectorHeadline = "";

  if (props.selectorUse=='trainers') {
    // trainier conditions
    masqueradeFieldIdFriendlyName = Config.App.fieldFriendlyNames.trainerSelector.masqueradeFieldId;
    repNameFieldIdFriendlyName = Config.App.fieldFriendlyNames.trainerSelector.repNameFieldId;
    selectorHeadline = "View as User";
  } else {
    // masquerade conditions // default
    selectorHeadline = "Masquerade as User";
    masqueradeFieldIdFriendlyName = Config.App.fieldFriendlyNames.masqueradeSelector.masqueradeFieldId;
    repNameFieldIdFriendlyName = Config.App.fieldFriendlyNames.masqueradeSelector.repNameFieldId;
  }

  const MASQUERADE_FIELD_ID = fields.get(masqueradeFieldIdFriendlyName)?.id;
  const REP_NAME_FIELD_ID = fields.get(repNameFieldIdFriendlyName)?.id;

  const closeModal = useSetRecoilState(closeModalSelector);

  // reset trainer rep to blank
  const clearMasqueradeSelections = () => {
    app.field(MASQUERADE_FIELD_ID).clear();
    app.field(REP_NAME_FIELD_ID).clear();
    set_isMasqueradeActive(false);
    set_currentMasqueradeSelectionAtm("");
    set_masqueradeUserIdAtom(false);
  }

  useEffect(() => {
    // clear selections if team changes 
    if (!initialRenderComplete) {
      return;
    } else {
      clearMasqueradeSelections();
    }
  }, [activeTeam]);

  useEffect(() => {
    set_initialRenderComplete(true);
  }, [app.selections]);


  useEffect(() => {
    let userToUse = "";
    
    if (masqueradeSelectionValue) {
      app.field(MASQUERADE_FIELD_ID).clear().then(() => {
        app.field(MASQUERADE_FIELD_ID).onceOnDataListener(() => {
          if (userToUse=="") {
            let validUserId = app.field(MASQUERADE_FIELD_ID).rows.find(row => row.qState !== 'X').qText;
            userToUse = validUserId;
          }
          Mosaic.Qlik.app[appId].field(MASQUERADE_FIELD_ID).selectValues([userToUse], false, true );
          set_isMasqueradeActive(true);
          set_currentMasqueradeSelectionAtm(masqueradeSelectionValue);
          set_masqueradeUserIdAtom(userToUse);

        });
        app.field(MASQUERADE_FIELD_ID).getData({rows:10000});
      })
    }

    // if filter has changed, check for rep selection
    let hasMasqueradeSelection = app.selections.find(objSelection => objSelection.fieldName.includes([MASQUERADE_FIELD_ID]) || objSelection.fieldName.includes(REP_NAME_FIELD_ID));
    if (hasMasqueradeSelection) {
      // masquerade is active
      set_isMasqueradeActive(true);
      set_geographyFlag(true);
    } else {
      set_isMasqueradeActive(false);
      set_geographyFlag(false);
    }

  }, [app.selections, masqueradeSelectionValue]);

  const repFilterField = useMemo(()=>{
    return {
      config,
      definition: [REP_NAME_FIELD_ID],
      title: 'Sales Rep',
      placeholder: 'Select a User...',
      defaultValue: masqueradeSelectionValue,
      set_value: set_masqueradeSelectionValue,
    }
  }, []);

  return (
    <>
    <div className="wholeScreenReponsiveContainer">
    <h2>{selectorHeadline}</h2>
    <div style={{display: 'flex', margin: 'auto', width: '400px' }}>
      {(currentMasqueradeSelectionAtm !== "") && (
      <div 
      onClick={clearMasqueradeSelections}
      className="filledUnselectableField">
        <span style={{fontWeight:'bold'}}>Selected:</span> <span>{currentMasqueradeSelectionAtm}</span>
      </div>
      )}
      {(currentMasqueradeSelectionAtm == "") && (
      <div style={{ display: "inline-block", width: '360px' }}>
        <QSFieldSelection 
        allowDisabledOptions={false}
        multi={false}
        {...repFilterField} />
      </div>)}
      <div>
          <Button
          size="small"
          style={{fontSize: '1em', margin:'7px 0px 0px 7px', padding: '0px 16px'}}
          className="btn-zoomer green"
          onClick={clearMasqueradeSelections}
        >
          Clear
        </Button>
      </div>
      </div>
      {(props?.showOkButton) && (
        <div style={{width:'100px', textAlign:'center', margin:'auto'}}>
        <Button
          onClick={closeModal}
          size="large"
          className="ant-btn ant-btn-default ant-btn-sm button-cell green-cell button-cell-home"
          style={{ maxWidth: '130px', borderRadius: '10px', margin:'18px 0px 0px 0px'}}
        >
          OK
        </Button>
        </div>
        )}
      {(currentMasqueradeSelectionAtm !== "" && !props?.hideGoButton) && (
        <div style={{width:'100px', textAlign:'center', margin:'auto'}}>
        <NavLink to="/">
        <Button
          size="large"
          className="ant-btn ant-btn-default ant-btn-sm button-cell green-cell button-cell-home"
          style={{ maxWidth: '130px', borderRadius: '10px', margin:'18px 0px 0px 0px'}}
        >
          Go
        </Button>
        </NavLink>
        </div>
        )}
  </div>
  </>
  )
}

export default TrainerSelect;