import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { ConfigContext } from '@trinity-incyte/context';
import { bioState, QlikProfData } from '@trinity-incyte/recoil';
import { useContext, useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

declare const Mosaic: MosaicGlobal;
/* eslint-disable-next-line */
export interface UseProfGenericObjectProps {
  definition: any;
  parseProfData?: (reply) => any;
}

export function useProfGenericObject({
  definition: customDefinition,
  parseProfData = (reply) => reply,
}: UseProfGenericObjectProps) {
  const Config = useContext(ConfigContext);
  const { appId } = Config.Qlik.Prof360.ids;
  const app = Mosaic.Qlik.app[appId];
  const setBio = useSetRecoilState(bioState);
  const set_globalProfData = useSetRecoilState<any>(QlikProfData);
  const [isRetried, set_isRetried] = useState<boolean>(false);
  const [profDataObject, set_profDataObject] = useState(null);
  const [data, set_data] = useState();
  const getProfDataObject = () => {
    if (!app) return;
    /**
     * The timestamp ensures the definition is unique each time
     * This avoids the issue where QS returns an error and refuses to retry the object
     */
    set_profDataObject(app.createGenericObject({
      ...customDefinition,
      timestamp: Date.now(), 
    }));
  };
  useEffect(() => {
    if(!customDefinition) return;
    getProfDataObject();
    return () => {
      if (profDataObject && profDataObject.close) profDataObject.close();
      set_globalProfData(null);
      set_data(null);
      setBio(null);
    };
  }, [customDefinition]);

  useEffect(() => {
    if (profDataObject) {
      profDataObject
        .then(({ layout }) => {
          const formattedData = parseProfData(layout);
          set_globalProfData(formattedData);
          set_data(formattedData);
          setBio(layout.bio);
        })
        .catch((err) => {
          console.error(err);
          if (isRetried === false) {
            set_isRetried(true);
            setTimeout(() => {
              getProfDataObject();
            }, 100);
          }
        });
    }
    return () => {
      if (profDataObject) {
        profDataObject.then((qViz) => qViz.close());
      }
    };
  }, [profDataObject]);
  return data;
}

export default useProfGenericObject;
