import { environment } from '../environments/environment';

const Config = {
    Mosaic: { // Onload, this object will get put global as window.Mosaic
        Qlik: { app: {} },
    },
    App: {
        title: 'BMT Mosaic',
        name: 'BMT Mosaic',
        authFailRedirectUrl: environment.authFailRedirectUrl, // This is the URL that the user will get redirected to when auth fails, and this should exactly match the URL in Okta
        authFailRedirectWait: 5000, // In milliseconds
        profImagesServer: environment.profImagesServer, // The dynamic image name gets directly appended to this url
        profImagesDefault: environment.profImagesServer + 'default_profimage.png',
        crmPrefix: 'https://na43.salesforce.com/apex/QlickviewGVHDPage?BMTOrgId=', // The Prof ID gets directly appende to this
        unknownWebsiteUrl: 'https://inside.incyte.com/',
        unknownCRMUrl: 'https://inside.incyte.com/',
        unknownGoogleMapUrl: 'https://inside.incyte.com/',
        siteStatusUrl: 'https://incyte-mosaic.azurewebsites.net/api/siteStatus?code=_jSFcysBNTBCBG4skNGWk6bFkEkFDEEY9-LQkq3TudnSAzFuDV3QvA==',
        runWalkMeSnippet: true, // false this to skip adding walkme
        mosaicHcpUrl: environment.mosaicHcpUrl,
        mdmUrlRedirect: environment.mdmUrlRedirect,
        azureTableUrl: environment.azureTableUrl,
        analyticsEnabled: false,
        profHeaders: ['PROF ID', 'PROF FULL NAME (ID)', 'PROF NAME (ID)', 'HCP', 'BLINDED PROF FULL NAME (ID)'],
        defaultStyles: {
            fontFamily:"'Roboto', sans-serif"
        },
        // *One* of these walkme snippets will fire onload
        WalkMeSnippet_TEST: function () { var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/43ef3cd54be340adb2d5b6748a7f869e/test/walkme_43ef3cd54be340adb2d5b6748a7f869e_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = { smartLoad: true }; },
        WalkMeSnippet_PROD: function () { var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/43ef3cd54be340adb2d5b6748a7f869e/walkme_43ef3cd54be340adb2d5b6748a7f869e_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = { smartLoad: true }; },
    },
    Qlik: {
        useJwtAuth: false,
        loadMashupObjects: true,
        doLoadQlikStyles: true, // Should load Qlik styles?
        config: { // This is the Qlik config object
            host: environment.host,
            prefix: '/', // Dev & Prod
            port: 443,
            isSecure: true,
        },
        qStateValues: {
            LOCKED: 'L',
            SELECTED: 'S',
            OPTIONAL: 'O',
            DESELECTED: 'D',
            ALTERNATIVE: 'A',
            EXCLUDED: 'X',
            EXCLUDED_SELECTED: 'XS',
            EXCLUDED_LOCKED: 'XL',
        },
        app1: {
            ids: {
                appId: environment.appId,
                fields: {
                    transplant_centers: 'BMTC.TRANSPLANT_CENTER',
                    org_id: 'BMTC.ORG_ID',
                    'Transplant Center': 'BMTC.TRANSPLANT_CENTER',
                    // 'Key Staff': 'BMTS.PROF_STAFF_NAME',
                    'ORG ID': 'BMTC.ORG_ID',
                    'PROF ID': 'BMTS.PROF_STAFF_NAME',
                    'PROF IMAGE URL': 'DP.PROF_IMG_URL',
                    'Google Maps URL': 'BMTC.gLoc',
                    'Website URL': 'BMTC.WEBSITE',
                    'Salesforce ID': 'CDS.SFA_CUSTOMER_ID',
                    Role: 'BMTS.STAFF_ROLE',
                    'Staff Name': 'BMTS.PROF_STAFF_NAME',
                    'Staff Prof ID': 'BMTS.PROF_ID',
                    'Emp Type': 'DEMP.EMP_TYPE',
                    'Transplant Center State': 'BMTC.TRANSPLANT_CENTER_STATE',
                    'NODE ID': 'BTMS.NODE_ID',

                },
                variables: {
                    vCenterWebsite: '=BMTC.WEBSITE',
                    vCRMLink: "='https://na43.salesforce.com/apex/QlickviewGVHDPage?BMTOrgId=' & [%BMT_ORG_ID]",
                    vGoogleMapLoc: "='http://maps.google.com/?q=loc:' & [BMTC.DO.ORG_ADDRESS1] & ', ' & [BMTC.DO.ORG_CITY] & ', ' & [BMTC.DO.ORG_STATE] & ', ' & [BTMC.DO.ORG_ZIP5]",
                    vProfImageFile: "=if(count(distinct DP.PROF_IMG_URL)=1,subfield(DP.PROF_IMG_URL,'ProfImages\\',2),'UnknownDoc.jpg')",
                },
                masterItems: {
                    'Year Allogenic Transplants Pie Chart': 'pMjTDPZ',
                    'Year Allogenic Transplants Table': 'bajHQJ',
                    'BMT Key Staff Table': 'JLJVpsG',
                    'BMT Staff Interactions KPI': '0660fef3-b9e8-49f0-a217-fbcfcdc341cf',
                    'BMT Staff Search Table': 'hPFmQM',
                    'Center Details Text Box': 'RjnKWP',
                    'Center Filter Pane': '70407b8e-b243-4fa6-b7f5-fb271c17aa21',
                    'Clinical Trials Active KPI': 'a851b7ce-ed19-4d9a-a180-7f10d6b9f3d8',
                    'Clinical Trials Recruiting KPI': 'bdea3732-43a5-45b7-a80b-e038cca9aa8f',
                    'Clinical Trials Table': 'mwEcemF',
                    'Clinical Trials Total GVHD KPI': 'fc5a61e9-0fae-4862-b724-3d801933d747',
                    'GVHD Quintiles Patient Count Text': '993e2ce6-119a-4e71-a372-f9553bb8be7c',
                    'iCares GVHD KPI': 'd93c0ea8-28e0-4018-9657-f0995b06558c',
                    'iCares MF PV KPI': '6859e89c-d28a-4929-a2ef-ab8388c5579b',
                    'SD Purchases - First Date KPI': 'CcwTy',
                    'SD Purchases - Last Date KPI': 'tNMkRN',
                    'SD Purchases - Purchased Flag KPI': '020e7c48-a5a8-46bd-8fb5-095ed5c671d7',
                    // 'Staff Details Demographics Text Box': 'VjEmXTw',
                    'Staff Details Focus Areas Text Box': 'ea3fcb95-5de1-46f6-b66d-6b6187b1b2a9',
                    'Staff Details GVHD EE Text Box': '26632ef2-d00d-425b-8eb7-2fbda819b051',
                    'Staff Details Image': '96e97bf2-4dd1-4d8e-8c6f-cda03a261948',
                    'Staff Details MF/PV Target Text Box': '63d67a21-ef55-4467-8890-3aef6e8ed0b2',
                    'Staff Details Text Box': 'VjEmXTw',
                    'Target MF/PV KPI': 'bf801bf1-c843-43c2-ac54-ed5b4bf67b6e',
                    'Target GVHD EES': '83ad312e-1a93-40a6-b346-3b8ee21a7b67',
                    'Target National KPI': '245806cd-648b-43d3-a95d-b93c8bdc986e',
                    'Target Regional -  KPI': 'a640332e-f9c6-4b14-b474-3d15cd80c0f7',
                    'Transplant Center Map': 'aa49fa76-5d2c-40b6-b97c-69ff34e3df19',
                    'Transplant Center Staff Table': 'SxbgQS',
                    'Transplant Center Table': 'xUgAN',
                    'Transplant Center Excel Table': 'VJmHH',
                    'Transplant Center Text Box': 'sDgyWuR',
                    'ORG ID Text Box': 'UtmJSgB',
                    'ORG ID DW Text Box': 'psjYr',
                    'Transplant Details Table': 'c2de5876-8274-40f1-8743-e6bd96e2e6e7',
                    'Sales Team OTS Calls KPI': 'pJMQa',
                    'Sales Team OAS Calls KPI': 'rsQTQ',
                    'Sales Team MSL Engagements KPI': 'BRDpDVG',
                    'Sales Team OCNE Engagements KPI': 'DjYvSg',
                    'Sales Team KPIs (R12Mo)': '0660fef3-b9e8-49f0-a217-fbcfcdc341cf',
                    'Transplants By Year Stacked Bar': 'agEtWv',
                    'Allo Transplants By Year Stacked Bar': 'ETyXVrW',
                    //  KPIs for main page - added 1-23-2020 pab
                    'Total Allo Centers KPI': 'dnMqj',
                    'Total Allo Centers Tier 1 KPI': 'MxZpe',
                    'Total Allo Centers Tier 2 KPI': 'fcjKN',
                    'Total Allo Transplants KPI': 'TkHVCZ',
                    'Total Allo Transplants Tier 1 KPI': 'ksFZK',
                    'Total Allo Transplants Tier 2 KPI': 'Jppgk',
                    'SD Purchases KPI': 'uPKtq',
                    'SD Purchases Tier 1 KPI': 'MgAEP',
                    'SD Purchases Tier 2 KPI': 'awDD',
                    'Edit Staff Table Info': 'hksBrJs',
                },
                other: {
                    'Sales Team Table': 'WJJpw',
                    'Staff Details Mosaic Button': '67aa66cd-f391-4a59-be3c-c31a331969c2',
                    QKPICTTotal: 'tpFkpd',
                    QKPICTRecruiting: 'eBHYB',
                    QKPICTActive: 'jGknGa',
                    filter_staff: 'yZpaDf',
                    filter_state: 'jYBXq',
                    filter_center: 'adcee0f7-bd44-4ea1-80d2-21afa8fe7062',
                    filter_center_tier: '26c9ca42-97af-4013-b6ae-28a29b9b8fb1',
                    'Filter ORG ID': 'jsPhQ',
                    'Filter PROF ID': 'ppxdmy',
                },
            },
        },
    },
};

export default Config;
