import React, { useContext } from 'react';
import {Button, Typography} from 'antd';
import { closeDisclaimerSelector, hasAcceptedState } from '@trinity-incyte/recoil';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ConfigContext } from '@trinity-incyte/context';
import { CheckOutlined } from '@ant-design/icons';
import { Center } from '@trinity-incyte/ui';

/* eslint-disable-next-line */
export interface DisclaimerProps {
    closeDisclaimerGroup
}

export function Disclaimer(props: DisclaimerProps) {
    const [hasAccepted, setHasAccepted] = useRecoilState(hasAcceptedState);
    const Config = useContext(ConfigContext);
    const closeDisclaimer = useSetRecoilState(closeDisclaimerSelector);

    return (
        <>
            <div
                style={{
                    padding:'1rem',
                    marginTop: '0px',
                    overflow: 'hidden',
                    height: '80vh',
                    fontSize: '1.4rem',
                    textAlign: 'center'
                }}
            >
                <Center centering='both'>
                    <Typography.Title style={{color:'#595959'}} level={2}>Legal Disclaimer</Typography.Title>
                    <p style={{fontWeight:'bold'}}>
                        {`${Config.App.name} and the content you are about to access is for your use only and is not to be discussed, printed, exported, downloaded or shown to anyone outside of the company. The information contained within ${Config.App.name} is to be used consistent with the ${Config.App.name} Training, applicable Incyte Policies, and the Code of Conduct.`}
                    </p>
                    <br />
                    <Button
                        style={{fontWeight: 'bold', backgroundColor: '#21BA45', borderColor: '#21BA45', borderRadius: '.28571429rem', color: 'white' }}
                        onClick={() => {
                            props.closeDisclaimerGroup();
                            closeDisclaimer(arguments);
                        }}
                    >
                        <CheckOutlined style={{color:'white', fontWeight:'bold'}}/>
                        Accept
                    </Button>
                </Center>
            </div>
        </>
    );
};

export default Disclaimer;
