/**
 * Put this component on the page to handle QS Authentication
 * TODO: refactor this into a hook
 */
import { MosaicGlobal, QlikAPIFacade } from '@trinity-incyte/api-interfaces';
import { ConfigContext } from '@trinity-incyte/context';
import { AppState as AppStateAtom } from '@trinity-incyte/recoil';
import React, { useContext, useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { QSStyleOverrides } from '../qsstyle-overrides/qsstyle-overrides';
import './qsauthentication.module.scss';
declare const Mosaic: MosaicGlobal;

/* eslint-disable-next-line */
export interface QSAuthenticationProps {}

export function QSAuthentication(props: QSAuthenticationProps) {
	const set_AppState = useSetRecoilState(AppStateAtom);
    let content;
    const Config = useContext(ConfigContext);

  const { config } = Config.Qlik;
	const resourceLink = `${( config?.isSecure ? 'https://' : 'http://' ) + config?.host + ( config?.port ? ':' + config?.port : '' ) + ( config?.prefix === '/' ? '' : config?.prefix ) }/resources`; // eslint-disable-line

	const qlikSetup = ( require ) => {
		require.config({ baseUrl: resourceLink });

		require(['js/qlik'], ( qlik ) => { // eslint-disable-line

			if ( qlik ) {
				const onError = (event) => {
					console.error('onError', event);

					if (event.refreshButton) {
						set_AppState({
							state: 'ALL GOOD',
							error: 'EXPIRED SESSION',
						});
					}
				};

				const onWarning = (event) => {
					console.warn('onWarning', event);
				};

				const onClosed = (event) => {
					console.log('onClosed', event);
					set_AppState({
						state: 'ERROR',
						error: 'NO QLIK',
					});
				};

				qlik.on( 'error', ( event ) => {
					onError( event );
				});
				qlik.on( 'warning', ( event ) => {
					onWarning( event );
				});
				qlik.on( 'closed', ( event ) => {
					onClosed( event );
				});
			}

			Mosaic.QlikAPI = new QlikAPIFacade(qlik);
			set_AppState({
				state: 'LOADING APP',
				error: false,
			});
		});
	};

	useEffect(() => {
		if ( Config.Qlik.useJwtAuth ) {
			const xhr = new XMLHttpRequest();
			xhr.onreadystatechange = () => {
				if ( xhr.readyState === XMLHttpRequest.DONE ) {
					console.log( 'XHR done' ); // eslint-disable-line
					return;
				}
			};
			xhr.onload = () => {
				console.log( this ); // eslint-disable-line
			};
			xhr.open( 'GET', `https://${config?.host}${config?.prefix}/content/Default/BMTCenterMapLegend.PNG`, true );
			xhr.setRequestHeader( 'Authorization', `Bearer ${Config.Qlik['TESTTOKEN']}` );
			xhr.withCredentials = true;
			xhr.send( null );

			setTimeout(() => {
				const elem = document.createElement( 'script' );
				elem.src = `${resourceLink}/assets/external/requirejs/require.js`;
				elem.type = 'text/javascript';
				document.body.appendChild( elem );
			}, 500 );
		} else {
			const elem = document.createElement( 'script' );
			elem.src = `${resourceLink}/assets/external/requirejs/require.js`;
			elem.type = 'text/javascript';
			document.body.appendChild( elem );
		}

		if ( Config.Qlik.doLoadQlikStyles ) {
			const styleElem = document.createElement( 'link' );
			styleElem.setAttribute( 'href', `${resourceLink}/autogenerated/qlik-styles.css` );
			styleElem.setAttribute( 'rel', 'stylesheet' );
			styleElem.setAttribute( 'type', 'text/css' );
			document.head.appendChild( styleElem );
		}

		// This will attempt to register qlik, and will retry 3 times, offset
		let require;
		let recheckCounter = 0;
		let intervalId = setInterval(() => {
				require = window.require;
				if (typeof require !== 'function') {
						if (recheckCounter++ > 6) {
								//Tries 7 times within 800 * 7 = 5600 milliseconds
								clearInterval(intervalId);
								set_AppState({
										state: 'ERROR',
										error: 'NO REQUIRE',
								});
						}
				} else {
						clearInterval(intervalId);
						qlikSetup(require);
				}
		}, 800);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if ( Mosaic.QlikAPI ) {
		content = (
			<span data-qsdsa="Authentication" style={{ display: 'none' }}>
				<QSStyleOverrides />
			</span>
		);
} else {
		content = <span data-qsdsa="Authentication" style={{ display: 'none' }} />;
	}

	return ( content );
};

 export default QSAuthentication;
