import React from 'react';
import { Segment, Loader, Center } from '@trinity-incyte/ui';

import './qlik-is-opening-app.module.scss';

/* eslint-disable-next-line */
export interface QlikIsOpeningAppProps {
	children?: any;
}

export function QlikIsOpeningApp(props: QlikIsOpeningAppProps) {
	return (
		<>
			{/* <SideNavGroup simplified />
		<QSAuthentication /> */}
			<Segment style={{ marginTop: '0px', overflow: 'hidden', height: '100%', fontFamily: '"QlikView Sans", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif' }} inverted>
				<Center>
					<Loader
						size="large"
						fontSize="1.71rem"
						text="Connected. Opening Qlik Application..."
					/>
				</Center>
				<div style={{ height: '100vh' }} />
				{props.children}
			</Segment>
		</>
	);
}

export default QlikIsOpeningApp;
