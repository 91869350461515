export const role_options = [
    {
        key: 'empty-role',
        text: '',
        value: ''
    },
    {
        key: 'role-transplant-pa',
        text: 'TRANSPLANT PA',
        value: 'TRANSPLANT PA',
    },
    {
        key: 'role-transplant-np',
        text: 'TRANSPLANT NP',
        value: 'TRANSPLANT NP',
    },
    {
        key: 'role-transplant-sw',
        text: 'TRANSPLANT SOCIAL WORKER',
        value: 'TRANSPLANT SOCIAL WORKER',
    },
    {
        key: 'role-transplant-nurse',
        text: 'TRANSPLANT NURSE',
        value: 'TRANSPLANT NURSE',
    },
    {
        key: 'role-bmt_other',
        text: 'BMT OTHER',
        value: 'BMT OTHER'
    },
    {
        key: 'role-medical-director',
        text: 'MEDICAL DIRECTOR',
        value: 'MEDICAL DIRECTOR',
    },
    {
        key: 'role-program-director',
        text: 'PROGRAM DIRECTOR',
        value: 'PROGRAM DIRECTOR',
    },
    {
        key: 'role-attending-physician',
        text: 'ATTENDING PHYSICIAN',
        value: 'ATTENDING PHYSICIAN',
    },
    {
        key: 'role-physician',
        text: 'PHYSICIAN',
        value: 'PHYSICIAN',
    },
    {
        key: 'role-transplant-co',
        text: 'TRANSPLANT COORDINATOR',
        value: 'TRANSPLANT COORDINATOR',
    },
    {
        key: 'role-transplant-ph',
        text: 'TRANSPLANT PHYSICIAN',
        value: 'TRANSPLANT PHYSICIAN',
    },
    {
        key: 'role-bmt-psy',
        text: 'BMT PSYCHOLOGIST',
        value: 'BMT PSYCHOLOGIST'
    },
    {
        key: 'role-pharma-director',
        text: 'PHARMACY DIRECTOR',
        value: 'PHARMACY DIRECTOR',
    },
    {
        key: 'role-clinical-pharma',
        text: 'CLINICAL PHARMACIST',
        value: 'CLINICAL PHARMACIST',
    },
];
