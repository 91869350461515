import { ConfigContext } from '@trinity-incyte/context';
import React, { useContext } from 'react';
import { Segment, Center } from '@trinity-incyte/ui';
import QSAuthentication from '../qsauthentication/qsauthentication';
import './qlik-cannot-connect.module.scss';
import styled from 'styled-components';

const Container = styled(Segment)`
	margin-top: 0px; 
	text-align: center; 
	overflow: hidden; 
	height: 100%; 
	font-size: 1.4rem; 
	font-family: "QlikView Sans", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
`;

/* eslint-disable-next-line */
export interface QlikCannotConnectProps {}

export function QlikCannotConnect(props: QlikCannotConnectProps) {
    const Config = useContext(ConfigContext);
	setTimeout(() => {
		window.location.href = `${Config.App.authFailRedirectUrl}&redirectUrl=${localStorage.getItem('currentPage') || '/'}`;
	}, Config.App.authFailRedirectWait );

	return (
		<>
			<QSAuthentication />
			<Container inverted>
				<Center>
					<p>Cannot establish connection to Qlik Sense.</p>
					<p>
						In 
						{Math.floor( Config.App.authFailRedirectWait / 100 ) / 10}
						seconds, you will be redirected to re-authenticate through Okta.
					</p>
				</Center>
				<div style={{ height: '100vh' }} />
			</Container>
		</>
	);
};

export default QlikCannotConnect;
