import React, { createRef, useEffect } from 'react';
import { Row, Col, Layout } from 'antd';
import { Segment } from 'libs/ui/src/lib/Other/segment/segment';
import { ArrowRightOutlined } from '@ant-design/icons';
import useGlobal from '../../Store';
import Config from '../../Config';
import Utils from '../../Utils';
import QSMashupObject from '../Qlik/QSMashupObject';
// import BookmarksDropdown from '../BookmarksDropdown';

const contextRef = createRef();

let filterHeight;
let filterHeightShort;

const setSizes = () => {
	const screen = Utils.screen();
	if ( screen.class === 'TABLET' && screen.orientation === 'LANDSCAPE' ) {
		filterHeight = 30;
		filterHeightShort = Math.floor( filterHeight * 0.6 );
	} else if ( screen.orientation === 'PORTRAIT' ) {
		filterHeight = 24;
		filterHeightShort = Math.floor( filterHeight * 0.5 );
	} else {
		filterHeight = 30;
		filterHeightShort = Math.floor( filterHeight * 0.8 );
	}
};

const FilterSidebar = ( ) => {
	const [globalState, globalActions] = useGlobal();

	setSizes();
	useEffect(() => { // An example of a React Hook. This function will run only when the 2nd argument changes.
		setSizes();
	}, [globalState.UI.screenOrientation]);

	// useEffect(() => {
	// 	setTimeout(() => { // This will be prematurely set open/closed if show is switched before this fires
	// 		globalActions.UI.toggleFiltersIsOpen();
	// 	}, globalState.UI.filtersTransitionTiming );
	// }, [globalState.UI.showFilters, globalState.UI.filtersTransitionTiming]);

	const screen = Utils.screen();
	const { appId } = Config.Qlik.app1.ids;
	const sidebarWidth = 28;
	const sidebarTranslate = ( globalState.UI.showFilters ) ? 0 : screen.width * ( sidebarWidth * 0.01 );
	const stickyOffset = document.getElementById( 'navbar' ).offsetHeight + 0;
	let filterFields;

	if ( !globalState.UI.showDetails ) {
		filterFields = (
			<>
				<QSMashupObject
					// color='blue'
					contentStyle={{ height: `${filterHeight}vh` }}
					appId={appId}
					mashupId={Config.Qlik.app1.ids.other.filter_center}
					elementId="filter1"
				/>
				<QSMashupObject
					// color='blue'
					contentStyle={{ height: `${filterHeightShort}vh` }}
					appId={appId}
					mashupId={Config.Qlik.app1.ids.other.filter_center_tier}
					elementId="filter2"
				/>
				<QSMashupObject
					// color='blue'
					contentStyle={{ height: `${filterHeight}vh` }}
					appId={appId}
					mashupId={Config.Qlik.app1.ids.other.filter_state}
					elementId="filter3"
				/>
				<QSMashupObject
					// color='blue'
					contentStyle={{ height: `${filterHeight}vh` }}
					appId={appId}
					mashupId={Config.Qlik.app1.ids.other.filter_staff}
					elementId="filter5"
				/>
				<QSMashupObject
					// color='blue'
					contentStyle={{ height: `${filterHeight}vh` }}
					appId={appId}
					mashupId={Config.Qlik.app1.ids.other['Filter ORG ID']}
					elementId="filter0"
				/>
				<QSMashupObject
					// color='blue'
					contentStyle={{ height: `${filterHeight}vh` }}
					appId={appId}
					mashupId={Config.Qlik.app1.ids.other['Filter PROF ID']}
					elementId="filter4"
				/>
			</>
		);
	} else {
		filterFields = (
			<>
				<QSMashupObject
					// color='blue'
					contentStyle={{ height: `${filterHeight}vh` }}
					appId={appId}
					mashupId={Config.Qlik.app1.ids.other.filter_staff}
					elementId="filter6"
				/>
				<QSMashupObject
					// color='blue'
					contentStyle={{ height: `${filterHeight}vh` }}
					appId={appId}
					mashupId={Config.Qlik.app1.ids.other['Filter PROF ID']}
					elementId="filter7"
				/>
			</>
		);
	}

	// This ensures fields get closed *after* the sidebar is hidden
	if ( globalState.UI.showFilters === false && globalState.UI.filtersIsOpen === false ) {
		filterFields = [];
	}

	const content = (
		<Layout
			className="filter-sidebar"
			style={{ background: 'none', position: 'fixed', right: 0, transition: `all ${globalState.UI.filtersTransitionTiming / 1000}s ${globalState.UI.filtersTransitionEasing}`, transform: `translateX(${sidebarTranslate}px)`, width: `${sidebarWidth}vw`, zIndex: 200 }}
		>
			<div ref={contextRef}>
				<Segment>
						<Layout style={{ width: '100%', background: 'none' }}>

							<Segment style={{ paddingTop: '0px', paddingBottom: '0px' }}>
								<Row>
									<Col span={9}>
										<div style={{ width:  '100%' }}>

											<h3 style={{ color: '#215AD0', textAlign: 'left' }}>Filters</h3>
										</div>
									</Col>
									<Col span={6}>
									</Col>
									<Col span={9}>
										<div onClick={globalActions.UI.toggleFilters}>
											<Segment basic size="small" color="red" style={{ cursor: 'pointer', background: 'rgba(240, 230, 255, 0.2)', fontFamily: '"QlikView Sans", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif' }}>
												<ArrowRightOutlined style={{ color: 'red', paddingRight: '5px' }} />
												<strong>Close</strong>
											</Segment>
										</div>
									</Col>
								</Row>
							</Segment>
						</Layout>
					<br />
					{/* <textarea id="safariLog" rows="40" cols="44" /> */}
					{/* <BookmarksDropdown /> */}
					{filterFields}
				</Segment>
			</div>
		</Layout>
	);

	return ( content );
};

export default FilterSidebar;
