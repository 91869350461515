import { ConfigContext, LibraryContext } from '@trinity-incyte/context';
import React, { Suspense, useEffect } from 'react';
import Config from './Config';
import Main from './Pages/Main';
import '@trinity-incyte/assets/antd-overrides.less';

import useGlobal from './Store';
import { BrowserRouter as Router } from 'react-router-dom';
import { Spin } from 'antd';

declare const window: any;

window.Mosaic = { QlikAPI: null, Qlik: { app: [], temp: [] } };

const HOSTNAMES = {
    'incyte-app.bellcyn.com': 1,
    'incyte-apps-bmt-mosaic.bellcyn.com': 2
}

function App() {
	const globalActions = useGlobal()[1];

	useEffect(() => {
		if ( Config.App.runWalkMeSnippet ) {
			if ( window.location.hostname in HOSTNAMES ) {
				Config.App.WalkMeSnippet_PROD();
			} else {
				Config.App.WalkMeSnippet_TEST();
			}
		}
	}, []);

	useEffect(() => {
		window.addEventListener( 'resize', () => {
			globalActions.UI.changeScreenOrientation({ isPortrait: window.matchMedia( '(orientation: portrait)' ).matches });
		});
	}, [globalActions.UI]);

	return (
        <ConfigContext.Provider value={Config}>
            <LibraryContext.Provider value={undefined}>
                <Router>
                    <Suspense
                        fallback={(
                            <>
                                <Spin size="large" />
                                <div style={{ height: '100%' }} />
                            </>
                        )}
                    >
                        <div className="App">
                            {/* <img src={screenshot} alt="screenshot of GVHD Mosaic" /> */}
                            <Main />
                        </div>
                    </Suspense>
                </Router>
            </LibraryContext.Provider>
        </ConfigContext.Provider>
	);
}

export default App;
