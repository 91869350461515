import React from 'react';

/* eslint-disable-next-line */
export interface MenuProps {}

export function Menu(props: MenuProps) {
  return (
    <div>
      <h1>Welcome to menu!</h1>
    </div>
  );
}

export default Menu;
