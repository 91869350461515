import React, { useContext } from 'react';
import { ConfigContext } from '@trinity-incyte/context';
import QSMashupObject from '../../Qlik/qsmashup-object';
import { Row } from 'antd';

import { useRecoilValue } from 'recoil';
import { activeTeam as activeTeamAtom, QSAppMetadataFamily } from '@trinity-incyte/recoil';

/* eslint-disable-next-line */
export interface AffiliationsProps {
  profId: any;
}

export function Affiliations(props: AffiliationsProps) {
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.Prof360;
	const { appId } = config.ids;
    const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids } = metadata;
    const hcp360affiliationsreports = Config.App.reports.hcp360affiliationspage;
    return (
        <div style={{ height: '100%' }}>
            <div className="qsKPIValue">Affiliations</div>
            {hcp360affiliationsreports.map(report => 
                <Row style={{ height: '50%' }} key={report.friendlyName}>
                    <QSMashupObject
                        appId={appId}
                        elementId="affiliationsTable"
                        mashupId={ids.get(report.friendlyName)?.id}
                        isTable
                        showExports
                        {...props}
                    />
                </Row>
            )}
        </div>
    );
}

export default Affiliations;