import React from 'react';
import { Button, ButtonProps } from 'antd';
import { NavLink } from 'react-router-dom';
import { modalState, closeModalSelector, toggleSubPageSelector, closeSubPageSelector } from '@trinity-incyte/recoil';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';


declare var window: any;

/* eslint-disable-next-line */
export interface HCP360CellProps {
    value?: any;
    column?: any;
		size?: ButtonProps['size'];
		fontSize?: any;
		style?: any;
		extraClass?: any;
}

export function HCP360Cell(props: HCP360CellProps) {

	let profId;
	let extraClass = props.extraClass || "";
	const toggleSubPage = useSetRecoilState(toggleSubPageSelector);
	const closeSubPage = useSetRecoilState(closeSubPageSelector);

	if ( props.value && props.value !== '-' && props.value !== '' ) {
		if ( Number.isNaN( parseInt( props.value, 10 ))) {
			// Custom selection needed
			profId = props.value.split('(')[1];
			if ( profId ) {
				profId = profId.split(')')[0];
			} else {
				profId = false;
			}
		} else {
			// It's a regular PROF ID
			profId = parseInt( props.value, 10 );
		}
	}

	let styleClasses = 'button-cell green-cell ' + extraClass;
	let content;
	if ( profId ) {
			content = (
				<NavLink to={`/HCP360/Overview/${profId}`}>
					<Button
					className={styleClasses}
					href={`/HCP360/Overview/${profId}`}
					data-action="self"
					 size={props.size || "small"}
					 style={{
						cursor: 'pointer',
					 	fontSize: props.fontSize || '.8em',
					 	...props.style,
					 }}
					onClick={(event) => {
						closeSubPage();
						
						window.analytics?.track('Link Clicked', { text: '360°', context: 'HCP360 Button Clicked to Open HCP360', profId: profId });
					}}
					>
						360°
					</Button>
				</NavLink>
			);
	} else {
		content = ( <></> );
	}

	return content;
};

export default HCP360Cell;
