import React from 'react';

import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

/* eslint-disable-next-line */
export interface LoadingIndicatorProps {
    size?: number;
}

const StyledLoadingIndicator = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

const StyledLoadingIndicatorIcon = styled(LoadingOutlined)`
    display: block;
`;

export function LoadingIndicator({ size = 70 }: LoadingIndicatorProps) {
    return (
        <StyledLoadingIndicator>
            <StyledLoadingIndicatorIcon spin style={{ fontSize: size }} />
        </StyledLoadingIndicator>
    );
}

export default LoadingIndicator;
