import React from "react"
import pptxgen from "pptxgenjs"
import { Result, Button as AntButton, Space } from "antd"
import { CloseSquareOutlined, DownloadOutlined } from "@ant-design/icons"
import Flavor from 'apps/mosaic/src/assets/Powerpoint/Incyte_Flavor.png'
import logo from 'apps/mosaic/src/assets/Powerpoint/Incyte_Logo.png'
import whiteRectangle from 'apps/mosaic/src/assets/Powerpoint/white_rectangle.png'
import { Loader as LoaderUI } from 'libs/ui/src/lib/Other/loader/loader'
import { QlikAppInstance, QlikVisualizationInstance } from "@trinity-incyte/api-interfaces"

interface ExportDataOptions {
    format?: string
    state?: string
}

export enum ExportType {
    exportAsXLS,
    exportAsPDF,
    exportAsImg,
    exportAsPPT
}

interface ExportHelper {
    exportVisualization: (exportAs: ExportType) => void
}

export const ExportHelper = (Config: any, app: QlikAppInstance, objectId: string | string[], openModal, closeModal, exportFileName?: string):ExportHelper => {
    if (!app || !objectId) {
        return
    }

    let exportStarted: Date

    const showExportStartedModal = () => {
        // Show the loader modal
        openModal({
            content:
                <div>
                    <Result
                        title="Exporting Data"
                        subTitle="Data is being exported. You may close this dialog; the exported data will be downloaded automatically."
                        extra={
                            <div>
                                <div style={{ height: "10%", marginBottom: "1em" }}>
                                    <LoaderUI size="small" />
                                </div>
                                <br />
                                <AntButton onClick={closeModal} icon={<CloseSquareOutlined />} className='close-button'>
                                    Close
                                </AntButton>
                            </div>
                        }
                    >
                    </Result>
                </div>,
            size: 'small',
            maskClosable: false
        })
    }

    const showErrorModal = () => {
        // Show the loader modal
        openModal({
            content:
                <div>
                    <Result
                        status="error"
                        title="Something Went Wrong"
                        subTitle="There was an error during export. Please try again."
                        extra={
                            <div>
                                <AntButton onClick={closeModal} icon={<CloseSquareOutlined />} className='close-button'>
                                    Close
                                </AntButton>
                            </div>
                        }
                    >
                    </Result>
                </div>,
            size: 'small',
            maskClosable: false
        })
    }

    const exportAsImg = () => {
        //This export mode doesn't support Multi-Export
        if (Array.isArray(objectId)) return false
        app.visualization.get(objectId).then((qViz) => {
            let imgSettings = { format: 'png', height: '1200', width: '1600' }
            qViz.exportImg(imgSettings).then((downloadURL) => {
                window.open(downloadURL, "_blank")
                closeModal()
            })
        })
    }

    const createMasterSlide = (pptx: pptxgen) => {
        pptx.defineSlideMaster({
            title: "ParentSlide",
            objects: [
                { image: { x: 8.1, y: 0.15, h: "50%", w: "17.5%", path: Flavor } },
                { image: { x: 0.5, y: "90%", h: "6%", w: "5%", path: logo } }
            ],
        })

    }

    const createSlideFromViz = (pptx: pptxgen, qViz: QlikVisualizationInstance, data: string) => {
        let slide = pptx.addSlide({ masterName: "ParentSlide" })
        // Image from remote URL
        slide.addText(qViz?.model?.layout?.title ?? qViz?.model?.layout?.qMeta?.title, {
            x: '4.5%',
            y: 0.2,
            color: "005CAB",
            fontFace: "Tahoma (Headings)",
            fontSize: 20,
            bold: true,
            align: 'left'
        })
        if (qViz?.model?.enigmaModel?.layout?.visualization === "piechart") {
            slide.addImage({ data, x: '12.5%', y: '7%', h: '90%', w: '67.5%' })
            slide.addImage({ path: whiteRectangle, x: '12.5%', y: '7%', h: '3%', w: '30%' })
        } else {
            slide.addImage({ data, x: '6%', y: '8%', h: '80%', w: '75%' })
            slide.addImage({ path: whiteRectangle, x: '6%', y: '8%', h: '3%', w: '30%' })
        }
    }

    const exportAsPPT = () => {
        if (Array.isArray(objectId)) {
            exportMultiplePPT()
        } else {
            exportAsSinglePPT()
        }
    }

    const exportMultiplePPT = () => {
        //This function is only called when objectId is an array of ids
        const exportPromises = (objectId as string[]).map((id) => {
            return app.visualization.get(id as string).then((qViz) => {
                const isPieChart = qViz?.model?.enigmaModel?.layout?.visualization === "piechart"
                const imgSettings = isPieChart ? { format: 'png', height: '768', width: '1024' } : { format: 'png', height: '750', width: '1250' }

                return new Promise<{ data: string, qViz: QlikVisualizationInstance }>((resolveData, rejectData) => {
                    new Promise<string>((resolveExport, rejectExport) => {
                        let tryCount = 0
                        const tryExport = (exportTask) => {
                            if (tryCount < 15) {
                                qViz.exportImg(imgSettings).then((downloadURL) => {
                                    clearInterval(exportTask)
                                    resolveExport(downloadURL)
                                })
                            } else {
                                clearInterval(exportTask)
                                showErrorModal()
                                rejectExport(`qViz.exportImg failed for ${id}`)
                            }
                            tryCount += 1
                        }
                        //We need to try multiple times because the Qlik API likes to drop export requests with no warning
                        const exportTask = setInterval(() => tryExport(exportTask), 20000)
                        tryExport(exportTask) //Try once immediately
                    })
                        .then(downloadURL => fetch(downloadURL, { credentials: 'include' }))
                        .then(response => response.blob())
                        .then(blob => {
                            const reader = new FileReader()
                            reader.onload = function () {
                                resolveData({ data: this.result.toString(), qViz })
                            }

                            reader.readAsDataURL(blob)
                        })
                })
            })
        })

        Promise.all(exportPromises).then((exportResults) => {
            let pptx = new pptxgen()
            createMasterSlide(pptx)

            exportResults.forEach(({ data, qViz }) => {
                createSlideFromViz(pptx, qViz, data)
            })

            pptx.writeFile({ fileName: `${exportFileName ?? "Reports"} - ${exportStarted.toISOString().substring(0, 10)}.pptx` })
            closeModal()
        })
    }

    const exportAsSinglePPT = () => {
        //This function is only called when objectId is a single id
        app.visualization.get(objectId as string).then((qViz) => {
            const isPieChart = qViz?.model?.enigmaModel?.layout?.visualization === "piechart"
            const imgSettings = isPieChart ? { format: 'png', height: '768', width: '1024' } : { format: 'png', height: '750', width: '1250' }

            qViz.exportImg(imgSettings).then((downloadURL) => {
                fetch(downloadURL, { credentials: 'include' })
                    .then(response => response.blob())
                    .then(function (blob) {
                        const reader = new FileReader()
                        reader.onload = function () {
                            let pptx = new pptxgen()
                            createMasterSlide(pptx)
                            createSlideFromViz(pptx, qViz, this.result.toString())

                            pptx.writeFile({ fileName: `${qViz?.model?.layout?.title ?? qViz?.model?.layout?.qMeta?.title} - ${exportStarted.toISOString().substring(0, 10)}.pptx` })
                            closeModal()
                        }

                        reader.readAsDataURL(blob)
                    })
            })
        })
    }

    const exportAsPDF = () => {
        //This export mode doesn't support Multi-Export
        if (Array.isArray(objectId)) return false
        app.visualization.get(objectId).then((qViz) => {
            let pdfSettings = { orientation: 'landscape' }
            qViz.exportPdf(pdfSettings).then((downloadURL) => {
                window.open(downloadURL, "_blank")
                closeModal()
            })
        })
    }

    const exportAsXLS = () => {
        //This export mode doesn't support Multi-Export
        if (Array.isArray(objectId)) return false
        app.visualization.get(objectId).then((qViz) => {
            qViz.model.exportData({} as ExportDataOptions).then((downloadMeta) => {
                window.open(`https://${Config.Qlik.config.host}${(Config.Qlik.config.prefix === '/' ? '' : Config.Qlik.config.prefix)}${downloadMeta.qUrl}`, "_blank")
                closeModal()
            })
        })
    }

    const exportVisualization = (exportAs: ExportType) => {
        exportStarted = new Date
        showExportStartedModal()

        switch (exportAs) {
            case ExportType.exportAsImg:
                exportAsImg()
                break
            case ExportType.exportAsPPT:
                exportAsPPT()
                break
            case ExportType.exportAsPDF:
                exportAsPDF()
                break
            case ExportType.exportAsXLS:
                exportAsXLS()
                break
            default:
                exportAsXLS()
                break
        }

    }

    return {
        exportVisualization
    }
}
