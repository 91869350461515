/* eslint-disable max-len */
/**
 * QS Mashup Object, meant for direct use on the page
 */

import React, { useState, useEffect } from 'react';
import { Segment } from 'libs/ui/src/lib/Other/segment/segment';
import { Col, Row, Result, Button, Space } from 'antd';
import { Loader } from '@trinity-incyte/ui';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { Textfit } from '@kyo_ago/react-textfit';
import { CloseSquareOutlined, DownloadOutlined, FileExcelFilled, FullscreenOutlined } from '@ant-design/icons';
// import domtoimage from 'dom-to-image-more';
import useGlobal from '../../Store';
import Config from '../../Config';
import AddStaffModalForm from '../Forms/add-staff-modal-form/add-staff-modal-form';
import styled from "styled-components";
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

const ReactMarkdown = require('react-markdown/react-markdown.min.js');
declare var Mosaic: MosaicGlobal;

const StatisticLabel = styled(Col)`
	font-size:1em; 
	text-align: center;
	font-weight: 700;
	text-transform: uppercase;
	color:black;
`;

const StatisticValue = styled(Col)`
	font-weight: 400;
	font-size: 1.5rem;
	text-transform: uppercase;
	line-height: 1.5rem;
	text-align: center;
`;

const UpperRightButtonContainer = styled.div`
	position: relative;
	float: right;
	top: -7px;
	margin-bottom: -28px;
	margin-right: 1px;
	z-index: 2;
`;

const AddStaffButton = styled(Button)`
	background-color: #b7cb18; 
	border-color: #b7cb18;
	color: white;
	font-size: .78571429rem;
	font-weight: 700;
	margin-right: 5px;
`;

const DownloadButton = styled(Button)`
	color: white;
	background-color: #2c5dad;
	border-color: #2c5dad;
`;

const CloseButton = styled(Button)`
	background-color: red; 
	border-color: red; 
	color: white;
`;

const toggleMaximized = ( el, callback ) => {
	let isMaximized = false;
	if ( el ) isMaximized = el.classList.toggle( 'maximized' );
	if ( callback ) callback( el, isMaximized );
};

const exportToExcel = ( app, objectId, callback? ) => {
	if ( !app ) {
		return;
	}

	app.getObject( objectId ).then(( model ) => {
		document.body.style.cursor = 'wait';

		model.exportData().then(( reply ) => {
			document.body.style.cursor = 'auto';
			callback( reply );
		});
	});
};

const renderManualText = ( props, qViz ) => {
	const { layout } = qViz.model;
	const cube = layout.qHyperCube;
	let textElem = '';
	let content;
	let contentChildren;
	if ( layout.markdown || layout.visualization.toUpperCase() === 'TEXT-IMAGE' ) { // This is a text object
		textElem = layout.markdown
				.split( '%[' ).join( '' )
				.replace( '****', '** **' )
				.replace( /\(center\)/gi, '' )
				.replace( /\(inherit\)/gi, '' )
				.replace( /\[/g, '' )
				.replace( /\]/g, '' )
				.replace( '(4)', '' )
				.replace( '(2)', '' );

		for ( let ii = 0; ii < cube.qMeasureInfo.length; ii += 1 ) {
			textElem = textElem.replace( `=(${cube.qMeasureInfo[ii].cId})`, cube.qGrandTotalRow[ii].qText );
		}

		textElem = textElem.split( '^' ).slice( 1 ).join( '<br />' ); // Replace caret with newline except for first
		contentChildren = (
			<Textfit mode="multi">
				<Segment basic style={{ padding: '2px 0px 4px' }}>
					<ReactMarkdown children={textElem} rehypePlugins={[rehypeRaw, rehypeSanitize]}/>
				</Segment>
			</Textfit>
		);
	} else if ( layout.visualization.toUpperCase() === 'KPI' ) { // This is a KPI object
		textElem = `**${cube.qMeasureInfo[0].qFallbackTitle}** <br> ${cube.qGrandTotalRow[0].qText}`;

		if ( cube.qMeasureInfo.length === 1 ) {
			contentChildren = (
				<div>
					<StatisticValue style={{ color: '#2185D0' }}>
						{cube.qGrandTotalRow[0].qText}
					</StatisticValue>
					<StatisticLabel>
						{cube.qMeasureInfo[0].qFallbackTitle}
					</StatisticLabel>
				</div>
			);
		} else if ( cube.qMeasureInfo.length === 2 ) {
			contentChildren = (
				<Row justify="center">
					<Col span={6}>
						<StatisticValue style={{ color: '#2185D0' }}>
							{cube.qGrandTotalRow[0].qText}
						</StatisticValue>
						<StatisticLabel>
							{cube.qMeasureInfo[0].qFallbackTitle}
						</StatisticLabel>
					</Col>
					<Col span={6}>
						<StatisticValue style={{ color: '#00B5AD' }}>
							{cube.qGrandTotalRow[1].qText}
						</StatisticValue>
						<StatisticLabel>
							{cube.qMeasureInfo[1].qFallbackTitle}
						</StatisticLabel>
					</Col>
				</Row>
			);
		}
	}

	if ( props.textOnClick ) {
		content = (
			// eslint-disable-next-line jsx-a11y/interactive-supports-focus
			<div
				className="manualText"
				style={{ height: 'inherit', textAlign: 'center', overflow: 'hidden', cursor: 'pointer' }}
				onClick={props.textOnClick}
				onKeyPress={props.textOnClick}
				role="link"
			>
				{contentChildren}
			</div>
		);
	} else {
		content = (
			<div className="manualText" style={{ height: 'inherit', textAlign: 'center', overflow: 'hidden' }}>
				{contentChildren}
			</div>
		);
	}

	return content;
	// ReactDOM.render( content, document.getElementById( props.elementId ));
};

const QSMashupObject = ( props ) => {
	const [globalState, globalActions] = useGlobal();
	const [isMounting, setIsMounting] = useState<any>( 0 );
	const [qsObject, setQsObject] = useState<any>( 0 );
	const [textContent, setTextContent] = useState({ component: false });

	if ( qsObject && props.isText ) {
		const app = Mosaic.Qlik.app[props.appId];
		app.getObject( props.mashupId );
	}

	const exportId = props.exportId || props.mashupId;

	useEffect(() => () => {
		try {
			document.getElementById( props.elementId )?.remove();
			const app = Mosaic.Qlik.app[props.appId];
			app.visualization.get( props.mashupId ).then(( viz ) => {
				viz.close();
			});
		} catch ( e ) {
			console.warn( e );
		}
	}, []); // eslint-disable-line

	useEffect(() => {
		if ( globalState.Qlik.qlik ) { // If Qlik has been loaded and connection established
			if ( globalState.Qlik.app[props.appId] && globalState.Qlik.app[props.appId].isLoaded ) {
				// If the specific app has been loaded
				const app = Mosaic.Qlik.app[props.appId];
				if ( !qsObject && !isMounting ) {
					setIsMounting( true );
					if ( Config.Qlik.loadMashupObjects === false || props.noLoad ) return;

					app.visualization.get( props.mashupId ).then(( qViz ) => {
						setQsObject( qViz );

						if ( props.isText ) {
							setTextContent({ component: renderManualText( props, qViz ) });

							if ( qViz.table ) {
								qViz.table.addOnDataListener(() => {
									setTextContent({ component: renderManualText( props, qViz ) });
								});
							}
						} else {
							qViz.show( props.elementId );
						}
					});
				}
			} else if ( !globalState.Qlik.app[props.appId]) {
				globalActions.Qlik.registerApp( props.appId );
			}
		}
	}, [globalState.Qlik.qlik]); // eslint-disable-line

	let selections;
	if ( globalState.Qlik && globalState.Qlik.app[props.appId] && globalState.Qlik.app[props.appId].selectionsLastModified ) {
		selections = globalState.Qlik.app[props.appId].selectionsLastModified;
	} else {
		selections = false;
	}
	useEffect(() => {
		if ( selections && props.isText && qsObject && globalState.Qlik.app[props.appId].visualization ) {
			globalState.Qlik.app[props.appId].visualization.get( props.mashupId ).then(( qViz ) => {
				setTextContent({ component: renderManualText( props, qViz ) });
			});
		}
    }, [selections]); // eslint-disable-line

	const content = (
		<Segment basic={props.basic} color={props.color} style={props.contentStyle} className="qs-object-segment">
			{( qsObject )
		? (
			<UpperRightButtonContainer>
				{props.addStaff && <>
					<AddStaffButton
						size="small" 
						onClick={() => {
							globalActions.UI.openModal({content: <AddStaffModalForm />, closeIcon: true})
					}}>Add Staff</AddStaffButton>
				</>}
				{( props.showExports )
				&& (
					<Button
						style={{ backgroundColor: '#e0e1e2', color: '#686869' }}
						size="small"
						icon={<FileExcelFilled />}
						onKeyDown={() => { exportToExcel( Mosaic.Qlik.app[props.appId], exportId ); }}
						onClick={() => {
							exportToExcel( Mosaic.Qlik.app[props.appId], exportId, ( reply ) => {
								globalActions.UI.openModal({
									content: 
										<div>
											<Result
												status="success"
												title="Download Data"
												subTitle="Data is ready for download."
												extra={[
													<Space size="middle">
														<DownloadButton
															rel="noopener noreferrer"
															onClick={() => { globalActions.UI.closeModal(); }}
															href={`https://${Config.Qlik.config.host}${( Config.Qlik.config.prefix === '/' ? '' : Config.Qlik.config.prefix )}${reply.qUrl}`}
														>
															<DownloadOutlined />
															{' '}
															Download
														</DownloadButton>
														<CloseButton
															onClick={() => { globalActions.UI.closeModal(); }}>
															<CloseSquareOutlined />
															{' '}
															Close
														</CloseButton>
													</Space>
												]}
											>
										</Result>
									</div>,
									size: 'small',
								});
							});
						}}
					/>
				)}
				{( props.showMaximize )
				&& (
					<Button
						style={{ borderRadius: '0', marginLeft: '4px' }}
						icon={<FullscreenOutlined />}
						onClick={() => {
							toggleMaximized( document.querySelector( `#${props.elementId}` ).parentElement, ( el, isMaximized ) => {
								if ( props.onToggleMaximize ) props.onToggleMaximize( el, isMaximized );
								if ( qsObject && isMaximized ) qsObject.resize();
							});
						}}
					/>
				)}
			</UpperRightButtonContainer>
		) : ''}
			<div id={props.elementId} className="qs-object" style={props.loaderStyle || { height: '100%' }}>
				{ textContent.component && (
					textContent.component
				)}
				{ textContent.component === false && (
					<>
						<div>
							<Loader size={props.loaderSize || 'medium'} text={props.loaderText || ''}></Loader>
						</div>
						<div style={{ height: '100%' }} />
					</>
				)}
			</div>
			{( props.addon && qsObject ) ? props.addon : ''}
			{( props.styleKpi )
			&& <style>{`#${props.elementId} .kpi-value { ${props.styleKpi} }`}</style>}
			{( props.styleTitle )
			&& <style>{`#${props.elementId} .measure-title { ${props.styleTitle} }`}</style>}
		</Segment>
	);

	return (
		content
	);
};

export default QSMashupObject;
