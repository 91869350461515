import React, { useEffect, useState, useContext } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ExportOutlined, LinkOutlined } from '@ant-design/icons';
import { Textfit } from '@kyo_ago/react-textfit';
import {
	activeBrand as activeBrandAtom,
	activeTeam as activeTeamAtom,
	closeLeftSliderSelector,
	QlikUser as QlikUserAtom,
	QSAppMetadataFamily,
	toggleLeftSliderSelector,
} from '@trinity-incyte/recoil';
import { 
	Button as ButtonAntd, 
	Col, 
	Image, 
	Row, 
	Space, 
	Table as AntTable, 
	Divider, 
	Typography, 
	Popover
} from 'antd';
import {
	imgAdvisor,
	imgEEFlag,
	imgExternalInvestigator,
	imgJakafiExperience,
	imgInvestigatorFlag,
	imgSpeaker,
	imgRegionalPlan,
} from '@trinity-incyte/assets';
import { StarFilled } from '@ant-design/icons';
import Utils, { convertTeam, PathGenerators } from '@trinity-incyte/utils';
import { ConfigContext, HCP360Context } from '@trinity-incyte/context';
import NavLinkWrapper from '../../nav-link-wrapper/nav-link-wrapper';
import ProfImg from '../../prof-img/prof-img';
import profKPIGroupStyles from './prof-kpigroup.module.scss';
import Loader from '../../Other/loader/loader';
import HCP360Cell from '../../Addons/hcp360-cell/hcp360-cell';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;
declare var window: any;

const { Title } = Typography;

const Markers = (props) => {
		const imgWidth = 42;
		const imgHeight = 42;
		const imgStyle = {
			padding: '3px',
			backgroundColor: '#21ba45',
			borderRadius: '20%',
		};

		const listOfTargets = [
				props.targetSpeaker === 'Y' && {
					title: 'Incyte Speaker',
					src: imgSpeaker,
				},
				props.targetEEFlag === 'Y' && {
					title: 'External Expert',
					src: imgEEFlag,
				},
				props.targetRegionalPlan === 'Y' && {
					title: 'Regional Plan',
					src: imgRegionalPlan,
				},
				props.targetAdvisor === 'Y' && {
					title: 'Advisor',
					src: imgAdvisor,
				},
				props.targetInvestigatorFlag === 'Y' && {
					title: 'Incyte Investigator',
					src: imgInvestigatorFlag,
					style: {
						padding: '2px',
						backgroundColor: '#21ba45',
						borderRadius: '25%',
					},
				},
				props.targetExternalInvestigator === 'Y' && {
					title: 'External Investigator',
					src: imgExternalInvestigator,
				},
				props.targetJakafiExperience === 'Y' && {
					title: 'Jakafi Experience',
					src: imgJakafiExperience,
				},
		].filter((item) => item); //Only available items will show in the list

		return (
			<Row
				align="middle"
				justify="space-around"
				style={{
					paddingTop: '5px',
					paddingBottom: props.connected ? '10px' : 0,
					textAlign: 'center',
					width: '100%',
				}}
				gutter={[10, 10]}
			>
				{listOfTargets.map(({ title, src, style }) => (
					<Col
						className={profKPIGroupStyles.listOfTargetsCol}
						span={12}
						key={title}
					>
						<strong>{title}</strong>
						<Image
							preview={false}
							width={imgWidth}
							height={imgHeight}
							title={title}
							alt={title}
							src={src}
							style={style || imgStyle}
						/>
					</Col>
				))}
			</Row>
		);
};

const Barriers = ({ barriers, profId, linkOnly = false }) => {
	const toggleLeftSlider = useSetRecoilState(toggleLeftSliderSelector);
	let content;

	if (linkOnly) { // Adds the Barriers link in the Derm Alerts and Events Prof Card
		content = (
			<ButtonAntd
				type="link"
				danger
				style={{
					width: '100%',
				}}
				onClick={() => window.analytics?.track('Button Clicked', { text: 'Barriers', context: 'HCP 360 Prof Card Barriers Button Clicked' })}
			>
				<NavLink to={`/HCP360/Overview/${profId}`}>Barriers</NavLink>
			</ButtonAntd>
		)
	} else { 
		// Regular Barriers button in the left Prof Card on HCP 360
		let barriersDataSource = ['MF', 'PV', 'AGVHD', 'DLBCL'].reduce((acc, curr, ind) => {
			if (barriers[`${curr} Text`] && barriers[`${curr} Text`] !== '-') {
				acc.push({
					key: ind + 1,
					type: curr,
					text: barriers[`${curr} Text`],
					date: Utils.moment(barriers[`${curr} Date`]).format('MM/DD/YYYY'),
				});
			}
			return acc;
		}, []);

		// find barriers highlights
		let barriersCount = barriers[`Barrier Count`];

		content = (
			<div style={{width:'100%', overflow:'visible', padding:'4px 6px 0px 6px' }}>
			<ButtonAntd
				danger
				style={{
					minWidth: '100%',
				}}
				onClick={() => {
					window.analytics?.track('Button Clicked', { text: 'Barriers', context: 'HCP 360 Prof Card Barriers Button Clicked' })

					toggleLeftSlider({
						content: (
							<div style={{ width: '100%', zIndex: 11 }}>
								<Divider orientation="center">
									<Typography.Title
										level={5}
										style={{
											fontSize: '1.07142857rem',
											fontWeight: 700,
											color: '#595959',
											margin: 0
										}}
									>
										Barriers
									</Typography.Title>
								</Divider>
								<AntTable
									pagination={false}
									columns={[{
										title: 'Type',
										dataIndex: 'type',
										key: 'type',
									}, {
										title: 'Text',
										dataIndex: 'text',
										key: 'text',
									}, {
										title: 'Date',
										dataIndex: 'date',
										key: 'date',
										defaultSortOrder: 'ascend',
										sorter: (a, b, sortOrder) => {
											if (sortOrder === 'ascend') {
												if (Utils.moment(a.date).isAfter(Utils.moment(b.date))) {
													return -1;
												} else return 1;
											} else if (sortOrder === 'descend') {
												return (Utils.moment(a.date).isBefore(Utils.moment(b.date))) ? 1 : -1;
											}
										},
									}]}
									dataSource={barriersDataSource}
								/>
							</div>
						),
						key: profId
					});
				}}
			>
				{ (barriers['Recent Barriers']==='Y') ? (
					<div style={{ display:'flex', width:'100%' }}>
						<div style={{ textAlign:'left', width: '24px', marginLeft:'-10px' }}>
							<StarFilled style={{ color: 'gold', marginTop: '1px', stroke: 'red', strokeWidth: '40', fontSize: '18px' }} />
						</div>
						<div style={{ textAlign:'center', width: 'calc(100% - 24px)' }}>
							Barriers {`(${barriersCount})`}
						</div>
				</div>
				) : (
					<div style={{ width: '100%', textAlign: 'center' }}>
							Barriers {`(${barriersCount})`}
					</div>
				) }
			</ButtonAntd>
			</div>

		);
	}

	return content;
}

const Products = ({ profId, linkOnly = false }) => {
	const toggleLeftSlider = useSetRecoilState(toggleLeftSliderSelector);
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.Prof360;
	const { appId } = config.ids;
	const location = useLocation();
	const app = Mosaic.Qlik.app[appId];
	const activeTeam = useRecoilValue(activeTeamAtom);
	const QlikUser = useRecoilValue(QlikUserAtom);
	const [productsData, set_productsData] = useState(null);
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids } = metadata;

	let content;

	let dlbclProducts = ids.get('Mosaic_HCP 360 - DLBCL_DLBCL Products')?.id

	// This updates the table when the selections change
	useEffect(() => {
		app?.visualization
			.get(dlbclProducts)
			.then((qViz) => {
				qViz.model.getHyperCubeData(
					"/qHyperCubeDef", 
					[{qTop: 0, qLeft: 0, qHeight: 50, qWidth: 3}]
				)
				.then((dataPage) => {
					const products = {
						headers: qViz.model.layout.qHyperCube.qDimensionInfo.map((val) => (val.qFallbackTitle)),
						data: []
					};

					for (let ii = 0; ii < dataPage[0].qMatrix.length; ii += 1) {
						let row = dataPage[0].qMatrix[ii];
						let rowData = {
							productName: null,
							patientRange: null,
							cellColor: null,
						};

						// Get the 2 displayed data values
						rowData.productName = row[0].qText;
						rowData.patientRange = row[1].qText;

						// Figure out the cell color for patientRange
						if (row[1]?.qAttrExps?.qValues[0].qText === undefined) {
							rowData.cellColor = 'white';
						} else {
							rowData.cellColor = row[1]?.qAttrExps?.qValues[0].qText;
						}

						products.data.push(rowData);
					}

					set_productsData(products);
					qViz.close();
				});
			})
	}, [])

	// Opens DLBCL Slider when you click the DLBCL Product Overview in a horizontal Prof Card (Events, Derm Alerts, Prof Details)
	useEffect(() => {
		const withDLBCL = location.pathname.endsWith('DLBCLProductOverview');
		if (withDLBCL) {
			if (!productsData) {
				content = (
					<div style={{ height: '50%', width: '100%' }} >
						<Loader text="Loading..."/>
					</div>
				);
			} else {
				toggleLeftSlider({
					content: (
						<div>
							{(QlikUser.features?.APP?.is360Only !== 'Y') && (
								<Divider orientation="center">
									<NavLink to={PathGenerators.FieldSalesPathGenerator(activeTeam, "/DLBCLTreatment/")} onClick={() => { app.field('FSD.TeamID').selectValues([convertTeam(activeTeam)]) }}>
										<Typography.Title level={5} style={{ color: '#005cab', textDecoration: 'underline' }}>
											<span>DLBCL Product View</span>
											<LinkOutlined/>
										</Typography.Title>
									</NavLink>
								</Divider>
							)}
							<AntTable 
								bordered 
								tableLayout="fixed" 
								pagination={false} 
								size="small" 
								columns={
									[{
										title: `${productsData.headers[0]}`,
										dataIndex: 'productName',
										key: 'productName',
										render: productName => (
											<>
												<span>
													{productName}
												</span>
											</>
										)
									}, {
										title: `${productsData.headers[1]}`,
										dataIndex: 'patientRange',
										key: 'patientRange',
										render: function(text, record) {
											return {
												props: {
													style: { backgroundColor: record.cellColor }
												},
												children: 
												<div style={{ height: '100%', width: '100%' }}>
													<span>{text}</span>
												</div>
											}
										}
									}]
								} 
								dataSource={productsData.data} 
							/>
						</div>
					),
					key: profId
				});
			}
		}
	}, [productsData]);

	if (!productsData) {
		content = (
			<div style={{ height: '50%', width: '100%' }} >
				<Loader text="Loading..."/>
			</div>
		);
	} else if (linkOnly) { // Adds the DLBCL Product Overview link in the Derm Alerts and Events Prof Card
		content = (
			<ButtonAntd
				type="link"
				style={{
					color: '#005cab',
					width: '100%'
				}}
				onClick={() => window.analytics?.track('Button Clicked', { text: 'DLBCL Product Overview', context: 'Alerts & Events Prof Card - DLBCL Product Overview Link Clicked' })}
			>
				<NavLink to={`/HCP360/Overview/${profId}/DLBCLProductOverview`}>DLBCL Product Overview</NavLink>
			</ButtonAntd>
		)
	} else { // Regular DLBCL button in the left Prof Card on HCP 360
		content = (
			<div style={{padding: '0px 6px', width:'100%'}}>
			<ButtonAntd
				style={{
					color: '#005cab',
					borderColor: '#005cab',
					width: '100%'
				}}
				onClick={() => {
					window.analytics?.track('Button Clicked', { text: 'DLBCL Product Overview', context: 'HCP 360 Prof Card - DLBCL Product Overview Button Clicked' })
					toggleLeftSlider({
						content: (
							<div>
								{(QlikUser.features?.APP?.is360Only !== 'Y') && (
									<Divider orientation="center">
										<NavLink to={PathGenerators.FieldSalesPathGenerator(activeTeam, "/DLBCLTreatment/")} onClick={() => { app.field('FSD.TeamID').selectValues([convertTeam(activeTeam)]) }}>
											<Typography.Title level={5} style={{ color: '#005cab', textDecoration: 'underline' }}>
												<span>DLBCL Product View</span>
												<LinkOutlined/>
											</Typography.Title>
										</NavLink>
									</Divider>
								)}
								<AntTable 
									bordered 
									tableLayout="fixed" 
									pagination={false} 
									size="small" 
									columns={
										[{
											title: `${productsData.headers[0]}`,
											dataIndex: 'productName',
											key: 'productName',
											render: productName => (
												<>
													<span>
														{productName}
													</span>
												</>
											)
										}, {
											title: `${productsData.headers[1]}`,
											dataIndex: 'patientRange',
											key: 'patientRange',
											render: function(text, record) {
												return {
													props: {
														style: { backgroundColor: record.cellColor }
													},
													children: 
													<div style={{ height: '100%', width: '100%' }}>
														<span>{text}</span>
													</div>
												}
											}
										}]
									} 
									dataSource={productsData.data} 
								/>
							</div>
						),
						key: profId
					});
				}}
			>
				DLBCL Product View
			</ButtonAntd>
		  </div>
		);
	}
	return content;
};


const KPIs = ( props ) => {
	let content;
	const { profId } = props;
	
	const profProductColumns = [
		{
			title: <div className={profKPIGroupStyles.styledTableText} style={{ fontWeight: 'bold' }}>Jakafi</div>,
			dataIndex: 'jakafi',
			key: 'jakafi',
			render: jakafi => (
				<div className={profKPIGroupStyles.styledTableText}>{jakafi}</div>
			)
		},
		{
			title: <div className={profKPIGroupStyles.styledTableText} style={{ fontWeight: 'bold' }}>Pemazyre</div>,
			dataIndex: 'pemazyre',
			key: 'pemazyre',
			render: pemazyre => (
				<div className={profKPIGroupStyles.styledTableText}>{pemazyre}</div>
			)
		},
		{
			title: <div className={profKPIGroupStyles.styledTableText} style={{ fontWeight: 'bold' }}>Monjuvi</div>,
			dataIndex: 'monjuvi',
			key: 'monjuvi',
			render: monjuvi => (
				<div className={profKPIGroupStyles.styledTableText}>{monjuvi}</div>
			)
		}
	]

	const profProductData = [{
		jakafi: props.targetJak || 'NT',
		pemazyre: props.targetPem || 'NT',
		monjuvi: props.targetMon || 'NT'
	}];

	const opportunityGroupsColumns = [
		{
			title: <div className={profKPIGroupStyles.styledTableText} style={{ fontWeight: 'bold' }}>Growth</div>,
			dataIndex: 'growth',
			key: 'growth',
			render: growth => (
				<div className={profKPIGroupStyles.styledTableText}>{growth}</div>
			)
		},
		{
			title: <div className={profKPIGroupStyles.styledTableText} style={{ fontWeight: 'bold' }}>Prospecting</div>,
			dataIndex: 'prospecting',
			key: 'prospecting',
			render: prospecting => (
				<div className={profKPIGroupStyles.styledTableText}>{prospecting}</div>
			)
		}
	];

	const opportunityGroupsData = [{
		growth:      props.opportunityGrowth || 'NT',
		prospecting: props.opportunityProspecting || 'NT'
	}];

	if ( props.vertical || props.connected ) {
		content = (
			<div className="no-margin">
				{(props.bio['State Restrictions'] && props.bio['State Restrictions'] !== '-') && (
					<Row className="no-margin" style={{ alignSelf: 'center', justifyContent: 'center' }}>
						<span>
							<div className={profKPIGroupStyles.stateRestrictionValue}>{props.bio['State Restrictions'] || 'N/A'}</div>
							<div className={profKPIGroupStyles.stateRestrictionLabel}>State Restrictions</div>
						</span>
					</Row>
				)}
				{(props.activeTeam !== 'OCNE') && (
					<Row className="no-margin" style={{ alignSelf: 'center', paddingTop: '5px', justifyContent: 'center' }}>
						<span>
							<div className={profKPIGroupStyles.styledProfValueLabel}><span style={{fontWeight:'bold'}}>EDUCATION:</span> {props.education}</div>
							<div className={profKPIGroupStyles.styledProfValueLabel}><span style={{fontWeight:'bold'}}>FELLOWSHIP:</span> {props.fellowship}</div>
						</span>
					</Row>
				)}
				<Row className="no-margin" style={{ alignSelf: 'center', paddingTop: '5px' }}>
					<div className={`${profKPIGroupStyles.salesTargetTableHeader} no-margin`}>Sales Target Type</div>
					<AntTable className={profKPIGroupStyles.salesTargetTable} columns={profProductColumns} dataSource={profProductData} bordered pagination={false} size="small"/>
				</Row>				
				{(props.activeTeam === 'OCNE') && (props.barriers) && (props.barriers['Barrier Count'] > 0) && (
					<Row className="no-margin" style={{ alignSelf: 'center' }}>
						<Barriers barriers={props.barriers} profId={profId} />
					</Row>
				)}
				{(props.activeTeam === 'OCNE') && (
					<Row className="no-margin" style={{ justifyContent: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
						<Markers {...props} />
					</Row>
				)}
			</div>
		)
	} else {
		content = (
			<Row style={{ height: '100%' }}>
				<Col span={1}>
					<Divider type='vertical' style={{ height: '100%' }}/>
				</Col>
				<Col span={11}
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						padding: '12px'
					}}
				> 
					<Space align="center" direction="vertical" size={6}>
						<Row>
							<span>
								<div className={profKPIGroupStyles.styledProfValue}>{props?.bio.Affiliations}</div>
								<div className={profKPIGroupStyles.styledProfValueLabel}>Affiliation</div>
							</span> 
						</Row>
							<Row>
								<span>
									<div className={profKPIGroupStyles.styledProfValue}>{profId}</div>
									<div className={profKPIGroupStyles.styledProfValueLabel}>Prof ID</div>
								</span>
							</Row>
							<Row>
								<span>
									<div className={profKPIGroupStyles.styledProfValue}>{props.location[props.activeTeam] || 'N/A'}</div>
									<div className={profKPIGroupStyles.styledProfValueLabel}>Location</div>
								</span>
							</Row>
						{(props.bio['State Restrictions']) && (
							<Row className="no-margin" 
								style={{
									alignSelf:'center', 
									alignItems:'center'
							}}>
								<span>
									<div className={profKPIGroupStyles.stateRestrictionValue}>{props.bio['State Restrictions'] || 'N/A'}</div>
									<div className={profKPIGroupStyles.stateRestrictionLabel}>State Restrictions</div>
								</span>
							</Row>
						)}
					</Space>
				</Col>
				<Col span={1} style={{flex:0}}>
					<Divider type='vertical' style={{ height: '100%' }}/>
				</Col>
				<Col span={11}>
					<div style={{ alignSelf: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
						<div className={`${profKPIGroupStyles.salesTargetTableHeader} no-margin`}>Sales Target Type</div>
						<AntTable className={profKPIGroupStyles.salesTargetTable} columns={profProductColumns} dataSource={profProductData} bordered pagination={false} size="small"/>
					</div>
					{(props.barriers) && (props.barriers['Barrier Count'] > 0) && (
						<Barriers barriers={props.barriers} profId={profId} linkOnly />
					)}
				{((props.products) && (props.activeTeam === 'HO1' || props.activeTeam === 'HO3')) && (
					<Products profId={profId} linkOnly />
				)}
				</Col>
			</Row>
		)
	}

	return content;
};

/* eslint-disable-next-line */
export interface ProfKPIGroupProps {}

export function ProfKPIGroup(props: ProfKPIGroupProps | any) {
	const { profId, placeholder } = props;
	const { profData } = useContext(HCP360Context);
	const [activeBrand, set_activeBrand]= useRecoilState(activeBrandAtom);
	const QlikUser = useRecoilValue(QlikUserAtom);
	const activeTeam = useRecoilValue(activeTeamAtom);
	const [isProfActionsOpen, set_isProfActionsOpen] = useState(false);
	const closeLeftSlider = useSetRecoilState(closeLeftSliderSelector);
	const Config = useContext(ConfigContext);
	const history = useHistory();
	const config = Config.Qlik.Prof360;
	const { appId, items } = config.ids;



	const toggleProfActions = () => {
		set_isProfActionsOpen( !isProfActionsOpen );
	};

	// function to test the production link - should not be called except to test
	const RteProdLink = () => {
		if ((!profData.OCE_Prof_ID) || (profData.OCE_Prof_ID==='-')) return (<></>);
		let oceProfId = profData.OCE_Prof_ID;
		let oceSiteUrl_prod = `https://incyte-oce.lightning.force.com`;
		let oceLink = `${oceSiteUrl_prod}/apex/RTEPage_INC?accountId=${oceProfId}&isURL=true`;
		let oceSamlUrl_prod = `https://incyte-us.okta.com/app/salesforce/exk2ix2mtsPaoBWC1297/sso/saml`;
		let linkToOce = encodeURIComponent(oceLink);
		let samlLink = oceSamlUrl_prod;
		let samlLinkRelay = `${samlLink}?RelayState=${linkToOce}`;
		return (
			<div style={{marginTop:'4px'}}>
				<ButtonAntd className={profKPIGroupStyles.editHCPDetailsButton} href={samlLinkRelay} target="_blank" onClick={window.analytics?.track('Link Clicked', { text: 'OCE RTE', context: `OCE RTE link clicked from HCP 360`})}>
					<ExportOutlined />
					OCE Prod RTE
				</ButtonAntd>
			</div>
		)
	}

	const RteLink = () => {
		if ((!profData.OCE_Prof_ID) || (profData.OCE_Prof_ID === '-')) return (<></>);
		let oceProfId = profData.OCE_Prof_ID;
		let oceLink = `${Config.App.oceSiteUrl}/apex/RTEPage_INC?accountId=${oceProfId}&isURL=true`;
		let linkToOce = encodeURIComponent(oceLink);
		let samlLink = Config.App.oceSamlUrl;
		let samlLinkRelay = `${samlLink}?RelayState=${linkToOce}`;
		return (
			<div style={{ marginTop: '4px' }}>
				<ButtonAntd className={profKPIGroupStyles.editHCPDetailsButton} href={samlLinkRelay} target="_blank" onClick={window.analytics?.track('Link Clicked', { text: 'OCE Send an RTE', context: `OCE Send an RTE link clicked from HCP 360`})}>
					<ExportOutlined />
					OCE Send an RTE
				</ButtonAntd>
			</div>
		)
	}

	const OceLink = () => {
		if ((!profData.OCE_Prof_ID) || (profData.OCE_Prof_ID === '-')) return (<></>);
		let oceProfId = profData.OCE_Prof_ID;
		let oceLink = `${Config.App.oceSiteUrl}/lightning/r/Account/${oceProfId}/view`;
		let linkToOce = encodeURIComponent(oceLink);
		let samlLink = Config.App.oceSamlUrl;
		let samlLinkRelay = `${samlLink}?RelayState=${linkToOce}`;
		return (
			<div style={{ marginTop: '4px' }}>
				<ButtonAntd className={profKPIGroupStyles.editHCPDetailsButton} href={samlLinkRelay} target="_blank" onClick={window.analytics?.track('Link Clicked', { text: 'OCE View HCP Profile', context: `OCE View HCP Profile link clicked from HCP 360`})}>
					<ExportOutlined />
					OCE View HCP Profile
				</ButtonAntd>
			</div>
		)
	}

	let content;
	if ( placeholder ) {
		content = (
			<div
				key={`profkpigroup_${profId}Connected`}
				className="no-padding"
				style={{
					width: '100%',
					overflow: 'auto',
					wordBreak: 'break-word',
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					justifyContent: 'center',
				}}
			>
				<Row
					align="middle"
					justify="center"
					style={{ paddingTop: '5px' }}
				>
					<Col>
						<ProfImg />
					</Col>
				</Row>
				<Row
					align="middle"
					justify="center"
					style={{ paddingTop: '5px' }}
				>
					<Col>
						<span>
							<div className={profKPIGroupStyles.styledProfValue}>Connected profile</div>	
							<div className={profKPIGroupStyles.styledProfValueLabel}>Filter to specific HCP to compare</div>
						</span>
					</Col>
				</Row>
			</div>
		);
	} else if ( !profId || !profData ) {
		content = (
			<div className="full-height no-padding" style={{ width: '100%', overflowY: 'auto', wordBreak: 'break-word' }}>
				<div style={{ margin: '0px' }}>
					<Row>
						<ProfImg />
					</Row>
				</div>
			</div>
		);
	} else if (props.connected) {
		content = (
			<div key={`profkpigroup_${profId}Connected`} className="no-padding" style={{ width: '100%', overflow: 'hidden auto', wordBreak: 'break-word' }}>
				<Row align="middle" justify="center" style={{ paddingTop: '5px' }}>
					<Col>
						<ProfImg profImgUrl={profData.imgUrl} />
					</Col>
				</Row>
				<Row align="middle" justify="center" style={{ paddingTop: '5px' }}>
					<Col>
						<span>
							<div className={profKPIGroupStyles.styledProfValue}>{profData.kpi0[activeTeam]?.split('\n')[0] || ''}</div>
							<div className={profKPIGroupStyles.styledProfValueLabel}>{profData.kpi0[activeTeam]?.split('\n')[1] || ''}</div>
						</span>
					</Col>
				</Row>
				<Row align="middle" justify="center" style={{ paddingTop: '8px' }}>
					<KPIs {...props} {...profData} appId={appId} items={items} activeTeam={activeTeam} />
				</Row>
			</div>
		);
	} else if ( props.horizontal ) {
		if ( props.basic ) { // Prof card in the Event card and Derm Alerts
			content = (
				<div style={{ padding: '11px' }}>
					<div key={`profkpigroup_${profId}`} className="no-padding" style={{ borderTop: '2px solid #21ba45', backgroundColor: '#fff', width: '100%', overflow: 'hidden', wordBreak: 'break-word' }}>
						<Row>
							<Col span={6}>
								<Row>
									<div style={{ width: '100%', padding: '12px', textAlign: 'center' }}>
										<HCP360Cell value={profId} style={{ width: '50%' }}/>
										<ProfImg profImgUrl={profData.imgUrl} />
									</div>
								</Row>
							</Col>
							<Col span={18}>
								<div>
									<KPIs {...props} {...profData} appId={appId} items={items} activeTeam={activeTeam}/>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			);
		} else {
			content = (<>
				{activeTeam === 'HO1' && (QlikUser.features?.APP?.is360Only !== 'Y') && <NavLinkWrapper to={PathGenerators.FieldSalesPathGenerator(activeTeam, `/${activeBrand}/0/0`)}>
					<Space size="middle" style={{ paddingRight: '1rem' }}>
						<ButtonAntd
							onClick={() => {
								closeLeftSlider(null);
								const {appId, fields} = Config.Qlik.FieldSales.ids;
								const app = Mosaic.Qlik.app[appId];
								if (profData?.bio?.Zip) {
									app.field(
										fields['Primary Zip']
									).selectValues(
										[
											Number(
												profData.bio.Zip[
													activeTeam
												]
											),
										],
										true
									);
								}
							}}
							icon={<LinkOutlined />}
							type="primary"
						>
								Sales Trend for Zip
						</ButtonAntd>
						<ButtonAntd
							onClick={() => {
								// TODO apply Qlik selection on the current city for this hcp
								closeLeftSlider(null);
								const {appId, fields} = Config.Qlik.FieldSales.ids;
								const app = Mosaic.Qlik.app[appId];
								if (
									profData?.bio?.City &&
									profData?.bio?.State
								) {
									app.field(
										fields['Primary City State']
									).selectValues(
										[
											`${profData.bio.City[activeTeam]}, ${profData.bio.State[activeTeam]}`,
										],
										false,
										true
									);
								}
							}}
							icon={<LinkOutlined />}
							type="primary"
						>
							Sales Trend for City
						</ButtonAntd>
					</Space>
				</NavLinkWrapper>}
				<div key={`profkpigroup_${profId}`} className="no-padding vertical-scroll" style={{ backgroundColor: '#fff', marginTop: '10px', borderTop: '2px solid #21ba45', wordBreak: 'break-word', width: '100%', height: '100%' }}>
					<Row style={{ margin: '0px' }}>
						<Col span={6}>
							<Row justify='center' style={{ textAlign: 'center', padding: '10px' }}>
								<span>
									<div className={profKPIGroupStyles.styledProfDetailName}>{profData.kpi0[activeTeam]?.split('\n')[0] || ''}</div>
									<div className={profKPIGroupStyles.styledProfValueLabel}>{profData.kpi0[activeTeam]?.split('\n')[1] || ''}</div>
								</span>
							</Row>
							<Row>
								<div style={{ width: '100%', textAlign: 'center' }}>
									<ButtonAntd
										className={profKPIGroupStyles.profCard360Button}
										size="small"
										onClick={(event) => {
											const link = `/HCP360/Overview/${profId}`;
											history.push( link );
											window.analytics?.track('Link Clicked', { text: '360°', context: 'Prof Card - HCP360 Button in Alert Clicked', profId: profId });
										}}
									>
										360°
									</ButtonAntd>
									<ProfImg profImgUrl={profData.imgUrl} />
								</div>
							</Row>
						</Col>
						<Col span={18}>
							<KPIs {...props} {...profData} appId={appId} items={items} activeTeam={activeTeam}/>
						</Col>
					</Row>
				</div>
			</>);
		}
	} else if ( props.layout === 'card' ) {
		if ( props.reversed ) {
			content = (
				<div key={`profkpigroup_${profId}`} className="no-padding" style={{ borderTop: '2px solid #21ba45', width: '100%', overflowY: 'auto', wordBreak: 'break-word' }}>
					<Row style={{ margin: '0px' }}>
						<Col span={18}>
							<KPIs {...props} {...profData} appId={appId} items={items} activeTeam={activeTeam}/>
						</Col>
						<Col span={6}>
							<Row style={{ height: '100%' }}>
								<div style={{ width: '100%', textAlign: 'center' }}>
									<ButtonAntd
										className={profKPIGroupStyles.profCard360Button}
										size="small"
										onClick={(event) => {
											const link = `/HCP360/Overview/${profId}`;
											history.push( link );
											window.analytics?.track('Link Clicked', { text: '360°', context: 'Prof Card - HCP360 Button in Alert Clicked', profId: profId });
										}}
									>
										360°
									</ButtonAntd>
									<ProfImg profImgUrl={profData.imgUrl} />
								</div>
							</Row>
							<Row style={{ height: '100%' }}>
								<span>
									<div className={profKPIGroupStyles.styledProfDetailName}>{profData.kpi0[activeTeam]?.split('\n')[0] || ''}</div>
									<div className={profKPIGroupStyles.styledProfValueLabel}>{profData.kpi0[activeTeam]?.split('\n')[1] || ''}</div>
								</span>
							</Row>
						</Col>
					</Row>
				</div>
			);
		} else {
			content = (
				<div key={`profkpigroup_${profId}`} className="no-padding" style={{ borderTop: '2px solid #21ba45', width: '100%', overflowY: 'auto', wordBreak: 'break-word' }}>
					<div>
						<h4>refff</h4>
					<div style={{ margin: '0px' }}>
						<Row>
							<div style={{ width: '100%' }}>
								<ButtonAntd
									className={profKPIGroupStyles.profCard360Button}
									size="small"
									onClick={(event) => {
										const link = `/HCP360/Overview/${profId}`;
										history.push( link );
										window.analytics?.track('Link Clicked', { text: '360°', context: 'Prof Card - HCP360 Button in Alert Clicked', profId: profId });
									}}
								>
									360°
								</ButtonAntd>
								<ProfImg profImgUrl={profData.imgUrl} />
							</div>
						</Row>
						<Row className="no-padding" >
							<span>
								<div className={profKPIGroupStyles.styledProfValue}>{profData.kpi0[activeTeam]?.split('\n')[0] || ''}</div>
								<div className={profKPIGroupStyles.styledProfValueLabel}>{'Dummy' || profData.kpi0[activeTeam]?.split('\n')[1] || ''}</div>
							</span>
						</Row>
						<KPIs {...props} {...profData} appId={appId} items={items} activeTeam={activeTeam}/>
					</div>
					</div>
				</div>
			);
		}
	} else { // is vertical
		content = (
			<div key={`profkpigroup_${profId}`} className="no-padding hide-x" style={{ width: '100%', overflowY: 'auto', wordBreak: 'break-word' }}>
				<Title
					style={{
						fontSize: '1rem',
						color: '#595959',
						background: 'none',
						fontWeight: 'bold',
					}}
				>{profId}</Title>
					<Popover
						placement="topRight"
						content={
							<>
							<ButtonAntd className={profKPIGroupStyles.editHCPDetailsButton}
								href={`${Config.App.mdmRedirect}${profId}`}
								target="_blank"
								onClick={window.analytics?.track('Link Clicked', { text: 'MDM EDIT HCP', context: `MDM EDIT HCP link clicked from HCP 360`})}
							>
								<ExportOutlined />
								MDM Edit HCP
							</ButtonAntd>
							<OceLink />
							<RteLink />
							</>
						}
						trigger="click"
					>
						<ButtonAntd
							className={`${profKPIGroupStyles.actionButton} no-margin square-corners`}
							size="small" 
						>
							Actions
						</ButtonAntd>
					</Popover>
				<div style={{ alignSelf: 'center' }}>
					<Row style={{ width: '100%', justifyContent: 'center' }}>
						<div className="no-margin" style={{ width: '100%', padding: '0 0 1rem 0' }}>
							<ProfImg profImgUrl={profData.imgUrl} />
						</div>
					</Row>
					<Row className="no-padding no-margin" style={{ justifyContent: 'center' }}>
						<span>
							<div className={profKPIGroupStyles.styledProfValue}>{profData.kpi0[activeTeam]?.split('\n')[0] || ''}</div>
							<div style={{fontWeight:700, textAlign:'center'}}>{profData.location[activeTeam] || ''}</div>
						</span>
						{profData['HCO Name'] !== '-' && (
							<Textfit mode="multi">
								{profData['HCO Name']}
							</Textfit>
						)}
					</Row>
					<Row className="no-padding no-margin" justify="center">
						<span style={{ margin: '0.5rem' }}>
							<div className={profKPIGroupStyles.styledProfValue}>{profData?.bio.Affiliations || ''}</div>
						</span>
					</Row>
					<Row className="no-padding">
						<KPIs {...props} {...profData} appId={appId} items={items} activeTeam={activeTeam}/>
					</Row>
				</div>
			</div>
		);
	}

	return (
		content
	);
};

export default ProfKPIGroup;
