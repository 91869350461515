import { useEffect } from 'react';

/* eslint-disable-next-line */
export interface useAnalyticsProps {
    Config: any;
}

export function useAnalytics(props: useAnalyticsProps) {
    const {
        Config: {
            App: { enableAnalytics, segmentWriteKey },
        },
    } = props;
    if (!enableAnalytics) return;

    useEffect(() => {
        const sTag: HTMLScriptElement = document.createElement('script');

        sTag.type = 'text/javascript';
        sTag.async = true;
        sTag.innerText = `
    !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${segmentWriteKey}";analytics.SNIPPET_VERSION="4.13.2";

    analytics.load("${segmentWriteKey}");

    analytics.page();

    }}();
    `; // Snippet from segment

        const s: any = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(sTag, s);

        return () => {
            document.body.removeChild(sTag);
        };
    }, []);
}

export default useAnalytics;
