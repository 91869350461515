import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { useState, useEffect } from 'react';

declare const Mosaic: MosaicGlobal;

/* eslint-disable-next-line */
export interface useQSVariableProps {
  config: any;
  variableName: string;
  onField?: Function;
}

export function useQSVariable({
  config,
  variableName,
}: useQSVariableProps): {
  value: any;
  set_value: Function;
} {
  const [value, set_value] = useState({qString: null, qIsNum: null});
  const { appId } = config.ids;
  const app = Mosaic.Qlik.app[appId];

  useEffect(() => {
      if (!app) return;
      
			app.variable.getContent(variableName, function(reply) {
        set_value(reply?.qContent);
			});
  }, [variableName]);

  return {
    value,
    set_value: (newValue: string | number) => {
      if (typeof(newValue) === 'string') {
        app.variable
        .setStringValue(variableName, newValue)
        .then(() => {
          setTimeout(() => {
            app.variable.getContent(variableName, function(reply) {
              set_value(reply?.qContent);
            });
          }, 80);
        });
      } else {
        app.variable
        .setNumValue(variableName, newValue)
        .then(() => {
          setTimeout(() => {
            app.variable.getContent(variableName, function(reply) {
              set_value(reply?.qContent);
            });
          }, 80);
        });
      }
    }
  };
}

export default useQSVariable;
