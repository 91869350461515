/* Note: this only supports one QS app at a time */
import { CloseOutlined } from '@ant-design/icons';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import { QlikLastModified as QlikLastModifiedAtom } from '@trinity-incyte/recoil';
import { Divider, Popover } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import './qsselection-bar.module.scss';

enum HiddenSelectionFields {
	TimeSelectionDesc,
	TRX_NRX_TOGGLE,
	Time_Filter,
	LocationSelectionDesc,
	'DPROD.BRAND_NAME',
	'FSD.TeamID',
	'HCP360.TeamID',
	'LB.TeamID',
	'SA_MASQUERADE_REP_NAME',
	'SA_TRAINER_REP_NAME',
	'Time_Filter_MB'
}


declare const Mosaic: MosaicGlobal;

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	overflow-x: auto;
	max-height: -webkit-fill-available;
	height: 100%;
`;

const SelectionWrapper = styled.span`
	box-sizing: border-box;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	display: flex;
	line-height: 14px;
	padding: 6px 8px;
	font-size: 1rem;
	flex-direction: row;
	max-width: 12vw;
	height: 47px;
	margin: 0px 2px 0px 2px;
	box-shadow: 0px 0px 1px 0px;
	background: white;
	cursor: pointer;
`;

const TextWrapper = styled.span`
	width: 140px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const IconWrapper = styled.span`
	cursor: pointer;
	flex-direction: column;
	margin: 4px 0 2px 6px;
`;

const shouldDisplay = ({qSelected}) => {
	let retVal = false;

	if (qSelected && !qSelected.includes('DS\\')) {
		retVal = true;
	}

	return retVal;
};

const transformFieldName = (fieldName: string): string => {
	const splitSections = fieldName.split('.');
	let name = splitSections[1] || splitSections[0];
	name = name.split(']')[0];
	const newName = name.split('_').map((item) => {
		const tempStr = item.trim().toLowerCase();
		return tempStr.substring(0, 1).toUpperCase() + tempStr.substring(1);
	}).join(' ');
	return newName;
};

const SelectionTag = (props) => {
	const { field, displayName, fieldName, qSelected, selectedValues } = props;
	const handleClose = () => field.clear();

	const popoverContent = (
		<div>
			{shouldDisplay({qSelected}) ? (
				<p>
					{(Array.isArray(selectedValues))
						? selectedValues.map(val => (
							<Fragment key={val.qName}>
								<strong>{`${val.qName}`}</strong>
								<br/>
							</Fragment>))
						: qSelected
					}
				</p>
			) : null}
		</div>
	)
	return (
		<>
			{shouldDisplay({qSelected}) ? (
				<SelectionWrapper className="selection-wrapper">
					<Popover title={!displayName ? fieldName : displayName} trigger="click" content={popoverContent}>
						<TextWrapper>
							{!displayName ? fieldName : displayName}
							<Divider style={{ margin: '2px 0' }} />
							<strong>{qSelected.replace(/\\/g, '')}</strong>
						</TextWrapper>
					</Popover>
					<IconWrapper onClick={handleClose} tabIndex={-1}>
						<CloseOutlined style={{ color: '#1890FF', fontSize: '18px' }} />
					</IconWrapper>
				</SelectionWrapper>

			) : (
					<SelectionWrapper style={{ display: 'none' }} />
				)}
		</>
	)
};

/* eslint-disable-next-line */
export interface QSSelectionBarProps {
	config?: any;
}

export function QSSelectionBar(props: QSSelectionBarProps) {
	const appId = props?.config?.ids?.appId;
	const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
	const [dimensions, set_dimensions] = useState({ qDimensionList: { qItems: [] } });
	const [dimensionList, set_dimensionList] = useState<any>(false);
	const [selections, set_selections] = useState<any>([]);

	useEffect(() => {
		const app = Mosaic.Qlik.app[appId];
		if (!app) return;
		app.getList('DimensionList', (list) => {
			if (dimensionList !== false) return;
			set_dimensions(list);
		});
	}, []);

	useEffect(() => {
		let retVal;
		const app = Mosaic.Qlik.app[appId];
		if (!app) return;
		let tempSelections = app.selections && app.selections.map((val, index) => {
			let {
				field,
				fieldName,
				isNumeric,
				qSelected,
				selectedValues,
				selectedCount,
				totalCount
			} = val;

			if (!isNumeric) {
				if (qSelected === "-1" || qSelected === "1") qSelected = "True";
				if (qSelected === "0") qSelected = "False";
			}

			// Hide the selection tags so they cannot be cleared
			if(fieldName in HiddenSelectionFields) return null;

				const matchingDimensions = dimensions.qDimensionList.qItems.find(
					(curr) =>
						curr.qData.info.length === 1 &&
						curr.qData.info[0]?.qName.toUpperCase() ===
							val.fieldName.toUpperCase()
				);

			set_dimensionList(matchingDimensions);

			if (matchingDimensions) {
				retVal = {
					...val,
					fieldName: matchingDimensions.qData.labelExpression,
					value: matchingDimensions.qData.labelExpression,
					onClose: () => { field.clear() },
					qSelected,
					selectedValues,
					selectedCount,
					totalCount,
					field,
				}
			} else {
				retVal = {
					fieldName: transformFieldName(fieldName),
					value: 'Field ' + `${index + 1}`,
					onClose: () => { field.clear() },
					qSelected,
					field,
				}
			}

			return retVal;
		});

		set_selections(tempSelections);

	}, [dimensions, dimensionList, QlikLastModified]);

	return (
		<Wrapper id="selectionBar">
			<div style={{ margin: '0 5px 0 5px', fontSize: '1.2em', fontWeight: 500 }}>Current Selections:</div>
			{selections && selections.map((val, index) => (
				<SelectionTag key={val?.displayName || val?.fieldName || index} {...val} />
			))}
		</Wrapper>
	)
};

export default QSSelectionBar;
