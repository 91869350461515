import React from 'react';
import { Image, Menu as AntdMenu } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import logo from '../../Assets/Images/Incyte-logo_white_rgb.png';
import home from '../../Assets/Images/home_white.png';
import Config from '../../Config';
import useGlobal from '../../Store';
import styled from 'styled-components';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare var Mosaic: MosaicGlobal;

const StyledMenu = styled(AntdMenu)`
	background-color: #005cad;
	color: white;
	font-size: 1rem;
`;

const ClearMenuItem = styled(AntdMenu.Item)`
	position: absolute !important;
	right: 8rem !important;
	font-weight: 400;
	padding: 1rem !important;
	font-size: 1.2rem;
	text-align: center;
	color: white !important;
`;

const FilterMenuItem = styled(AntdMenu.Item)`
	position: absolute !important;
	right: 0rem !important;
	font-weight: 400;
	padding: 1rem !important;
	font-size: 1.2rem;
	text-align: center;
	color: white !important;
`;

const onClickClear = () => {
	const app = Mosaic.Qlik.app[Config.Qlik.app1.ids.appId];
	if ( app ) {
		app.clearAll();
	}
};

const NavGroup = ( props ) => {
	const [globalState, globalActions] = useGlobal();

	let content;
	if ( props.simplified ) {
		content = (
			<StyledMenu theme="dark" mode="horizontal" id="navbar">
				<AntdMenu.Item style={{ padding: "1rem" }}>
					<Image width="80px" height="auto" preview={false} src={logo} style={{ padding: '4px' }} />
				</AntdMenu.Item>
				<AntdMenu.Item style={{ padding: '1rem', fontSize: '1.3rem', color: 'white' }}><strong>{Config.App.name}</strong></AntdMenu.Item>
			</StyledMenu>
		);
	} else {
		content = (
			<StyledMenu theme="dark" mode="horizontal" id="navbar">
				<AntdMenu.Item style={{ padding: "1rem" }}>
					<Image width="80px" height="auto" preview={false} src={logo} style={{ padding: '4px' }} />
				</AntdMenu.Item>
				<AntdMenu.Item style={{ padding: '1rem', fontSize: '1.3rem', color: 'white' }}>
					<strong>{Config.App.name}</strong>
				</AntdMenu.Item>
				<ClearMenuItem
					key="ClearMenu"
					onClick={() => {
						globalActions.UI.closeSidebars();
						onClickClear();
					}}
				>
					{( globalState.UI.showDetails )
						? <Image width="35px" preview={false} height="35px" src={home} />
						: 'Clear'}
				</ClearMenuItem>
				<FilterMenuItem
					key="FilterMenu"
					onClick={globalActions.UI.toggleFilters}
				>
					<SearchOutlined style={{ fontSize: '20px', paddingRight: '10px' }}/>
					Filters
				</FilterMenuItem>
			</StyledMenu>
		);
	}
	return ( content );
};

export default NavGroup;
