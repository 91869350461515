import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
	QSAppMetadataFamily,
	defaultSelection as defaultSelectionAtom
} from '@trinity-incyte/recoil';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

/* eslint-disable-next-line */
export interface useDefaultSelectionsProps {
  config: any,
	selection?: any;
	useHash?: boolean;
}

export function useDefaultSelections(
	{ config, selection, useHash = false }: useDefaultSelectionsProps
) {
	const defaultSelection = useRecoilValue(defaultSelectionAtom);
  const history = useHistory();
  const { appId } = config.ids;
  const app = Mosaic.Qlik.app[appId];
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { FieldsTable: fields } = metadata;

	useEffect(() => {
		app.unlockAll(); // Unlocks all locks on the current app;
		// uses the friendly name for prof id in the hcp360 because the only place useDefaultSelections
		// is used is in the hcp360 app
		app.field(fields.get('Mosaic_HCP 360_PROF ID')?.id).clear().then(() => {
      if (useHash) {
        const hash = decodeURIComponent(history.location.hash).substr(1, history.location.hash.length);
        const hashObj = (hash !== '') ? JSON.parse(hash) : {};
        
        if (hashObj.s) {
          const selKeys = Object.keys(hashObj.s);

          for ( let ii = 0; ii < selKeys.length; ii += 1 ) {
            const fieldName = selKeys[ii];
            const field = app.field(fieldName);
            const qSelected = hashObj.s[fieldName];
            if ( qSelected === '*' ) {
              field.selectAll();
            } else {
              // Selection is case-sensitive
              let selectionArray;
              if (Array.isArray(qSelected)) {
                selectionArray = qSelected;
              } else {
                selectionArray = new Array(qSelected);
              }
              field.selectValues( selectionArray );
            }
          }
        }
      } else {
				history.replace(history.location.pathname);
			}

			if (selection) {
				for ( let ii = 0; ii < selection.length; ii += 1 ) {
					const { fieldName, qSelected } = selection[ii];
					const field = app.field(fieldName);
					if ( qSelected === '*' ) {
						field.selectAll();
					} else {
						// Selection is case-sensitive
						let selectionArray;
						if (Array.isArray(qSelected)) {
							selectionArray = qSelected;
						} else {
							selectionArray = new Array(qSelected);
						}
						field.selectValues( selectionArray );
					}
				}
			} else {
				const selKeys = Object.keys(defaultSelection);
				for ( let ii = 0; ii < selKeys.length; ii += 1 ) {
					const { fieldName, qSelected } = defaultSelection[selKeys[ii]];
					const field = app.field(fieldName);
					if ( qSelected === '*' ) {
						field.selectAll();
					} else {
						// Selection is case-sensitive
						let selectionArray;
						if (Array.isArray(qSelected)) {
							selectionArray = qSelected;
						} else {
							selectionArray = new Array(qSelected);
						}

						field.selectValues( selectionArray );
					}
				}
			}
		});
		return () => {
			const selectionsFilter = config.locks.map(
				(fieldName) => fields.get(fieldName)?.id
			);
			app.selections?.forEach((selection) => {
				if (selectionsFilter.indexOf(selection.fieldName) === -1) {
					app.field(selection.fieldName).clear();
				}
			});
		};
	}, []);
};

export default useDefaultSelections;
