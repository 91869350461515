import React, { createContext, useState, useContext, useEffect } from "react";
import ConfigContext from "./config";
import { MosaicGlobal } from "@trinity-incyte/api-interfaces";
import { useRecoilValue } from "recoil";
import { activeTeam as activeTeamAtom, QSAppMetadataFamily } from "@trinity-incyte/recoil";
import { convertTeam } from "@trinity-incyte/utils";
declare var Mosaic: MosaicGlobal;

export type MosaicState = {
    filterByTeam: boolean
}

export type MosaicContext = {
    state: MosaicState,
    setFilterByTeam: (newState: boolean) => void
}
export const MosaicContext = createContext({} as MosaicContext);

export const MosaicState = (props) => {
	const Config = useContext(ConfigContext);
    const [ filterByTeam, setFilterByTeam ] = useState<boolean>(true)
	const activeTeam = useRecoilValue(activeTeamAtom);

    useEffect(() => {
		if (activeTeam === null) return;

		const selectedTeam = convertTeam(activeTeam);

		Config.Qlik.TeamFilteredApps.forEach((filteredAppName) => {
			let filteredAppConfigObj = Config.Qlik[filteredAppName];
			const { appId: filteredAppId, fields: filteredAppFields } = filteredAppConfigObj.ids;
			let qlikAppObj = Mosaic.Qlik.app[filteredAppId];
			let filteredApp_TeamId = filteredAppFields['Team ID'];

			const TeamField = qlikAppObj.field(filteredApp_TeamId);
			TeamField.clear().then(() => {
				if (filterByTeam) TeamField.selectValues([selectedTeam])
			});
		
		});
    }, [filterByTeam, activeTeam])

    return (
        <MosaicContext.Provider value={{
            state: {
                filterByTeam
            },
            setFilterByTeam
        }}>
            {props.children}
        </MosaicContext.Provider>
    )
};

export default MosaicContext;