import React from 'react';
import QSMashupObject from '../../Qlik/QSMashupObject';
import Config from '../../../Config';

const ClinicalTrials = () => (
	<div id="ClinicalTrialsContainer" style={{ height: '62vh', width:'100%' }}>
		<QSMashupObject
			showExports
			appId={Config.Qlik.app1.ids.appId}
			mashupId={Config.Qlik.app1.ids.masterItems['Clinical Trials Table']}
			elementId="tab5_qs1"
			contentStyle={{ height: '62vh' }}
			loaderSize="large"
			loaderText="Clinical Trials Table"
		/>
	</div>
);

export default ClinicalTrials;
