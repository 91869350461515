import { useQSListbox } from '@trinity-incyte/hooks';
import { 
  Col, 
  Modal as AntdModal, 
  Row, 
  Typography, 
  Select,
  Divider,
  Button,
  Form,
} from 'antd';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import Config from '../../../Config';
import useGlobal from '../../../Store';
import styled from 'styled-components';
import Loader from 'libs/ui/src/lib/Other/loader/loader';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

const HeaderDivider = styled(Divider)`
  font-family: "QlikView Sans", Lato;
  font-size: 1rem !important;
  font-weight: 700 !important;
  text-transform: uppercase;
`;

declare var Mosaic: MosaicGlobal;
declare var WalkMeData: any;

const TRANSPLANT_CENTER = 'Transplant Center';
const TRANSPLANT_CENTER_STATE = 'Transplant Center State';
const NODEID = 'Node ID';

const TableUrl = Config.App.azureTableUrl;
// const StackUrl = 'https://prod-58.eastus2.logic.azure.com:443/workflows/8279178c72c146ee98321fded1e5b548/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=EeBcJuopHj85Ii_5h3xMyFVkrdMCjM19U3PPRC1vcQ0';

/* eslint-disable-next-line */
export interface TransferStaffModalFormProps {}

export function TransferStaffModalForm(props: TransferStaffModalFormProps) {
  const [, globalActions] = useGlobal();
  const config = Config.Qlik.app1;
  const { appId, masterItems, fields } = config.ids;
  const app = Mosaic.Qlik.app[appId];

  const [open, set_open] = useState(false);
  const [tableObject, set_tableObject] = useState<any>();
  const [loading, set_loading] = useState(true);
  const [firstName, set_firstName] = useState();
  const [lastName, set_lastName] = useState();
  const [profId, set_profId] = useState();
  const [transplantName, set_transplantName] = useState<string>();
  const [newTransplantName, set_newTransplantName] = useState<any>();
  const [newOrgId,set_newOrgId] = useState<any>();
  const [orgId, set_orgId] = useState();
  const [orgState, set_orgState] = useState();
  const [profNodeId, set_profNodeId] = useState();
  // const [newOrgId, set_newOrgId] = useState();
  // const [newOrgState, set_newOrgState] = useState();
  // const [menuList, set_menuList] = useState([]);
  const { rows: listbox } = useQSListbox({
    config,
    definition: [fields[TRANSPLANT_CENTER_STATE]],
    title: TRANSPLANT_CENTER,
    single: true,
  });

  const isFormInvalid = useCallback((): boolean => {
    return !transplantName || !newTransplantName;
  }, [transplantName, newTransplantName]);

  const submitForm = useCallback(async () => {
    set_loading(true);
    if (isFormInvalid()) {
      set_loading(false);
      set_open(true);
      return;
    }


    try {
      await axios({
        url: TableUrl,
        method: 'POST',
        timeout: 0,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          FirstName: firstName,
          LastName: lastName,
          UserID: WalkMeData?.UserID,
          ProfID: profId,
          PrevCenter: orgId,
          NewCenter: newOrgId,
          NodeID: profNodeId,
          ActionType: 'TRANSFER',
        }),
      });
      AntdModal.success({
        title: 'Successful submission',
        content: (
          <>
            <Typography.Text strong>
              {lastName},{firstName}
            </Typography.Text>{' '}
            has a request to be transferred to{' '}
            <Typography.Text strong>
              {newTransplantName}
            </Typography.Text>
            . Please allow 1 business day for the update to be available in BMT Mosaic.
          </>
        ),
      });
      globalActions.UI.closeModal();
      set_loading(false);
    } catch (error) {
      AntdModal.error({
        title: 'Failed Submission',
        content: (
          <>
            <Typography.Text strong>
              {lastName},{firstName}
            </Typography.Text>{' '}
            has not been transferred to the new center. Please try
            again.
          </>
        ),
      });
      globalActions.UI.closeModal();
      set_loading(false);
    }
  }, [firstName, lastName, profId, orgId, transplantName, newTransplantName]);

  useEffect(() => {
    set_loading(!profId || !transplantName || !orgState);
  }, [profId, transplantName]);

  useEffect(() => {
    if (tableObject) {
      tableObject.table.addOnDataListener(() => {
        if (tableObject.table.rowCount === 1) {
          const [
            { qText: profNameId },
            { qText: profId },
            { qText: profDegree },
            { qText: profStaffRoleCombined },
            { qText: profPhone },
            { qText: profEmail },
            { qText: profNodeId},
          ] = tableObject.table.rows[0].cells;
          const name = profNameId.split('(')[0];
          const [lastName, firstName] = name.split(',');
          set_firstName(firstName.trim());
          set_lastName(lastName.trim());
          set_profId(profId);
          set_profNodeId(profNodeId);
        }
      });
      tableObject.table.getMoreData();
    }
    return () => {
        if (tableObject && tableObject.close) {
            tableObject.close();
        }
    };
  }, [tableObject]);

  useEffect(() => {
    app.visualization
      .get(masterItems['Edit Staff Table Info'])
      .then(set_tableObject);
  }, []);

  useEffect(() => {
    app.visualization
      .get(masterItems['Transplant Center Text Box'])
      .then((qViz) => {
        qViz.table.addOnDataListener(() => {
          const [
            { qText: transplantCenter },
            { qText: sourceCity },
            { qText: sourceState },
            { qText: orgZip },
            { qText: unitType },
            { qText: activeAllogenicFlag },
            { qText: factApprovedAllogenic },
            { qText: yearOpenedAllogenic },
            { qText: nciMember },
            { qText: nccnMember },
            { qText: orgId },
            { qText: orgIdDw },
          ] = qViz.table.rows[0].cells;
          set_transplantName(transplantCenter);
          set_orgId(orgId);
          set_orgState(sourceState);
        });
        qViz.table.getMoreData();
      });
  }, []);
  
  const update_newTransplantName = (value) => value.slice(0, value.indexOf(','));
  const update_newOrgId = (value) => value.slice(value.indexOf('(') + 1, value.indexOf(')'));
  
  if (loading) {
    return (
      <div style={{ height: '50%', width: '100%' }} >
        <Loader text="Loading..."/>
      </div>
    )
  } else {
    return (
      <>
        <div>
          <h1>Change Staff Affiliation</h1>
          <Form>
            <HeaderDivider>Affiliation Selection</HeaderDivider>
            <Row gutter={[16, 16]} align="top">
              <Col span={24}>
                <h4>
                  Name: {firstName} {lastName}
                    <br />
                  Center: {transplantName}, {orgState} ({orgId})
                </h4>
              </Col>
              <Col span={16}>
                <Typography.Text strong id="transplant-form-select-label">Transplant Centers:</Typography.Text>
                <Form.Item 
                  rules={[{ required: true }]}
                >
                  <Select
                    aria-labelledby="transplant-form-select-label"
                    showSearch
                    optionFilterProp="value"
                    options={listbox
                      .filter(
                      ({ qText: text }) =>
                        text.indexOf(transplantName) === -1
                      )
                      .map(({ qText: text, qElemNumber }) => ({
                        value: text,
                        text,
                        key: text,
                      }))}
                    placeholder="Select a center..."
                    style={{ width: '100%' }}
                    onChange={(value, _) => {
                      set_newOrgId(update_newOrgId(value)); 
                      set_newTransplantName(update_newTransplantName(value))
                    }}
                  />
                  </Form.Item>
                </Col>
            </Row>
            <Row>
              <Form.Item>
                <Button onClick={submitForm}>Submit</Button>
              </Form.Item>
              <Form.Item>
                <Button onClick={() => globalActions.UI.closeModal()}>
                  Cancel
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default TransferStaffModalForm;
