import './prof-workspace-group.module.scss';

import React from 'react';
import { Drawer, Button, Typography, Row, Col } from 'antd';
import { useRecoilState } from 'recoil';

import {
	workspaceState as workspaceStateAtom,
	workspaceChildState as workspaceChildStateAtom,
} from '@trinity-incyte/recoil';
import { CloseCircleOutlined } from '@ant-design/icons';

declare var window: any;

/* eslint-disable-next-line */
export interface ProfWorkspaceGroupProps {}

export function ProfWorkspaceGroup() {
	const [workspaceState, set_workspaceState] = useRecoilState(workspaceStateAtom);
	const [workspaceChildState, set_workspaceChildState] = useRecoilState(workspaceChildStateAtom);

	const workspaceWidth = Math.round((window.innerWidth / 100) * workspaceState.width);
	const workspaceChildWidth = Math.round((window.innerWidth / 100) * workspaceChildState.width);

	const closeDrawer = () => {
		set_workspaceState({
			...workspaceState,
			isOpen: false,
		});
		setTimeout(() => {
			set_workspaceState({
				...workspaceState,
				isOpen: false,
				content: null,
			});
		}, 500);
		window.analytics?.track('Button Clicked', { text: 'Close Drawer', context: 'Prof Workspace Group - Close Drawer Button Clicked' });
	};

	const content = (
		<Drawer
			title={(
				<Row>
					<Col flex="auto">
						{workspaceState.title}
					</Col>
					<Col flex="none">
						<Button
							type="text"
							icon={<CloseCircleOutlined />}
							onClick={closeDrawer}
						/>
					</Col>
				</Row>
			)}
			width={workspaceWidth}
			closable={false}
			onClose={closeDrawer}
			visible={workspaceState.isOpen}
		>
			{workspaceState.content}
			<Drawer
				title={workspaceChildState.title}
				width={workspaceChildWidth}
				closable={false}
				onClose={() => {
					set_workspaceChildState({
					...workspaceChildState,
					isOpen: false,
					content: null,
				})}}
				visible={workspaceChildState.isOpen}
			>
				{workspaceChildState.content}
			</Drawer>
		</Drawer>
	);

	return (
		content
	);
};

export default ProfWorkspaceGroup;
