import { useEffect, useState, useContext } from 'react';
import { useRecoilValue } from 'recoil';
import {
    QlikLastModified as QlikLastModifiedAtom,
    QlikUser as QlikUserAtom,
    QSAppMetadataFamily,
} from '@trinity-incyte/recoil';
import { ConfigContext }  from '@trinity-incyte/context';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

/* eslint-disable-next-line */
export interface UserQlikUserIdProps {}

export function useQlikUserId(props?: UserQlikUserIdProps | any) {
    const Config = useContext(ConfigContext);
    // Grabs the selected SA_USERID from masquerade in the fieldsales app before pushing it to the 360 app
    // Applicable only to masquerade users as masquerade will not be interactive if not admin.
    const config = Config.Qlik.FieldSales;
    const { appId } = config.ids;
    const metadata = useRecoilValue(QSAppMetadataFamily(appId));
    const { IDsTable: ids, FieldsTable: fields } = metadata;
    const app2 = Mosaic.Qlik.app[appId];
    const userField = fields.get(Config.App.name === 'Mosaic' ? 'Mosaic_HCP 360_Masquerade User Id' : 'Derm Mosaic_HCP_Sa_UserId')?.id;
    const QlikUser = useRecoilValue(QlikUserAtom);
    const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
    const [userId, set_userId] = useState('');

    useEffect(() => {
        if (!QlikUser.authenticatedUser) return;
        const val = app2.selections
            ?.find((item) => item.fieldName === userField)
            ?.qSelected.split(', ')[0];
        set_userId(
            val ||
                (QlikUser.authenticatedUser &&
                    `${
                        QlikUser.authenticatedUser.split('; ')[0].split('=')[1]
                    }\\${
                        QlikUser.authenticatedUser.split('; ')[1].split('=')[1]
                    }`)
        );
    }, [QlikLastModified]);
    return userId?.toUpperCase();
};

export default useQlikUserId;
