import React from 'react';
import useGlobalHook from 'use-global-hook';

import * as Actions from '../Actions';

const initialState = {
    Qlik: {
        cannotAcquireRequire: false,
        qlik: false,
        isSelectedCenter: false,
        isSelectedStaff: false,
        app: {},
    },
    lastModified: Date.now(),
    UI: {
        activeGroup: 'overview',
        showFilters: false,
        showDetails: false,
        showStaffCard: false,
        showTest: false,
        hasAccepted: false, // Set to false to enable Legal Disclaimer modal
        hasOrgPurchased: false,
        detailsTabIndex: 0,
        hrefGoogleMap: '',
        hrefWebIcon: '',
        hrefSalesforce: '',
        hrefMosaicComm: '',
        hrefQVOkta: 'https://incyte-us.okta.com/home/incytecorporationhipaaprod_mosaiccommercial_1/0oahshqm0GcjtjVEX296/alnhsnt7albwczpmC296',
        profImgUrl: '',
        screenOrientation: { isPortrait: window.matchMedia( '(orientation: portrait)' ).matches },
        detailsIsOpen: false,
        detailsTransitionTiming: 800, // transition in milliseconds
        detailsTransitionEasing: 'ease-out',
        filtersIsOpen: false,
        filtersTransitionTiming: 500, // transition in milliseconds
        filtersTransitionEasing: 'ease-out',
        modal: {
            size: 'large',
            isOpen: false,
            closeIcon: false,
            content:
                <>
                    <h1>Delete Your Account</h1>
                    <br/>
                    <p>Are you sure you want to delete your account</p>
                </>,
        },
    },
};

const useGlobal = useGlobalHook( React, initialState, Actions );

export default useGlobal;
