import React, { useState, useRef } from 'react';
import {
	Button as AntButton,
	Space,
	Row,
	Col,
	Tag,
	Divider,
	Badge,
} from 'antd';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { dismissedAlerts as dismissedAlertsAtom } from '@trinity-incyte/recoil';
import { generate as generatePalette } from '@ant-design/colors';
import { HCP360Cell, ListCard, ListCardLabel, RecentEventsTable, TimelineCell, DismissCell, UnDismissCell } from '@trinity-incyte/ui';
import styled from 'styled-components';
import { DownCircleFilled, LeftCircleOutlined, StarFilled } from '@ant-design/icons';

declare var window: any;

const LabelsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
`;

const Alert = ( props ) => {
	const [showItems, set_showItems] = useState(props.showItems);
	const [showActions, set_showActions] = useState(props.showActions);
	const measureRef: any = useRef();
	const {
		labels,
		icon,
		key,
		date,
		prof,
		profId,
		brandName,
		alertType,
		alertSubtype,
		alertTrigger,
		employee,
		supplementalLabel,
		alertKey,
		count,
		style,
		recentAlertFlag
	} = props;

	const brandBlue	= generatePalette('#005cab');
	const productColors = {
			'Monjuvi': '#256678',
			'Jakafi': '#6db73c',
			'Pemazyre': '#ed6551',
	};
	const dismissedAlerts = useRecoilValue(dismissedAlertsAtom);
	const setDismissedAlerts = useSetRecoilState(dismissedAlertsAtom);

	let alertParseDbId = dismissedAlerts.find(x => x.AlertKey === alertKey)?.AlertParseDbId;

	const BrandLabel = ({brandName}) => {
		return (
			<ListCardLabel component={
				<AntButton
					size="small"
					style={{
						backgroundColor: productColors[brandName] || 'green',
						borderColor: productColors[brandName] || 'green'
					}}
				>
					{brandName}
				</AntButton>}>
			</ListCardLabel>
		)
	}

	return (
		// bodyStyle to edit the inner div that gets added with AntD cards
		<ListCard>
			<div style={{ position: "absolute", left:"-5px", top:"-3px"}}>
				<LabelsContainer>
					{(recentAlertFlag === "Y") ? (
					<Badge count={<StarFilled style={{ left: '-10px', color: 'gold', stroke: productColors[brandName], strokeWidth: '40' }}/>} style={{ width: '10px', height: '10px', borderColor: productColors[brandName] }}>
						<BrandLabel brandName={brandName} />
					</Badge>
					) : (
						<BrandLabel brandName={brandName} />
					)}
					{(labels) && (
						<Space style={{ marginTop: '-1px', marginLeft: '-4px' }}>
							{labels.map((val) => {
								return (
									<Tag
										style={val.style}
										icon={val.icon}
										key={val.content}
									>
										{val.content}
									</Tag>
								)
							})}
						</Space>
					)}
				</LabelsContainer>
			</div>
				<div style={{color: '#595959'}} className={alertParseDbId?`no-padding alert-fill`:`no-padding`}>
					<Row style={{width:"100%", paddingLeft:"2%", paddingBottom:'0.25rem'}}>
						<Col span={17} style={{ paddingTop: '2rem', paddingBottom: (employee && employee !== '-') ? null : '0.25rem' }}>
							{/* Main alert text */}
							<Row style={{fontSize: '1.25rem'}}>
								<strong>
									<i>{`${prof || ''}`}</i>
								</strong>
							</Row>
							{/* CSS Grid makes this *SO MUCH EASIER*!!!! */}
							<div style={{ display: 'grid', gridTemplateColumns: `${(supplementalLabel !== '-') ? '5fr 1fr' : '1fr'}`, fontSize: '1.25rem' }}>
								<div style={{ gridColumn: 'auto' }}>
									{alertTrigger}
								</div>
								{(supplementalLabel !== '-') && (
									<div style={{ gridColumn: 'auto' }}>
										<div style={{
											position: 'absolute',
											bottom: '0',
											right: '1px',
											color: '#595959',
											backgroundColor:'rgb(250, 250, 250)',
											fontSize: '1rem',
											fontWeight: 'bolder',
										}}>
											{supplementalLabel}
										</div>
									</div>
								)}
							</div>
						</Col>
						{/* Side buttons */}
						<Col style={{ textAlign:"left", paddingLeft: "3px" }} span={7}>
							<Row>
								<Col flex='5px'>
									<Divider type="vertical" style={{position: "absolute", margin:"0px", height:"100%"}}></Divider>
								</Col>
								<Col flex="auto" style={{padding:'5px 0 0 0'}}>
									<Space direction="vertical" size={4} style={{ width: '100%' }}>
										<Row align="bottom" justify="space-between" gutter={4}>
											<Col flex="auto">
												<HCP360Cell value={profId} extraClass="button-cell-home" size="small" />
											</Col>
											<Col flex="auto" style={{ paddingRight: '4px' }}>
												<TimelineCell extraClass="button-cell-home" value={profId} size="small" />
											</Col>
										</Row>
										<Divider type="horizontal" style={{ margin: '0' }} />
										<Row align="bottom" justify="space-between" gutter={4}>
											<Col flex="auto">
													<AntButton
														className='button-cell green-cell button-cell-home'
														data-action="self"
														size="small"
														style={{ backgroundColor: 'transparent', marginLeft: '0px' }} 
														onClick={(event) => {
															window.analytics?.track('Button Clicked', { text: 'Recent Events', context: 'Toggles Showing or Hiding Recent Events inside Alert' });
															props.setShowItems(!showItems);
															set_showItems(!showItems);
														}}
													>Recent Events{(showItems) ? (<DownCircleFilled style={{ padding: "0px" }} />) : (<LeftCircleOutlined style={{ padding: "0px" }} />)}</AntButton>
											</Col>
											<Col flex="auto" style={{ paddingRight: '4px' }}>
												{(!alertParseDbId)&&
													<DismissCell value={profId} alertKey={alertKey} alertType={alertType} />
												}
												{(alertParseDbId)&&
													<UnDismissCell value={profId} alertParseDbId={alertParseDbId} alertKey={alertKey} alertType={alertType} />
												}
											</Col>
										</Row	>
									</Space>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			<div style={{ display: showActions || showItems ? '' : 'none', width: '100%', padding:'10px', overflowY: 'auto', backgroundColor: '#d0d9e0' }}>
				<div ref={measureRef}>
					{showItems && (<RecentEventsTable profId={profId} />)}
				</div>
			</div>
		</ListCard>
	);
};

export default Alert;