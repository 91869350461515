import React from 'react';

const DraggablePanes = ( props ) => {
	const content = (
		<div id="draggable_pane" style={{ height: props.containerHeight, width: '100%' }}>
			{props.children}
			{props.panes[props.activeItem].pane.content}
		</div>
	);

	return ( content );
};

export default DraggablePanes;
