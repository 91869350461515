import { 
  Col, 
  Modal as AntdModal, 
  Row, 
  Typography,
  Form,
  Button,
  Input,
  Select,
  Divider 
} from 'antd';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import Config from '../../../Config';
import useGlobal from '../../../Store';
import { degree_options } from '../degree_options';
import { role_options } from '../role_options';
import { status_options } from '../status_options';
import styled from 'styled-components';
import Loader from 'libs/ui/src/lib/Other/loader/loader';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

const HeaderDivider = styled(Divider)`
  font-family: "QlikView Sans", Lato;
  font-size: 1rem !important;
  font-weight: 700 !important;
  text-transform: uppercase;
`

declare var Mosaic: MosaicGlobal;
declare var WalkMeData: any;

const TableUrl = Config.App.azureTableUrl;
// const StackUrl = 'https://prod-58.eastus2.logic.azure.com:443/workflows/8279178c72c146ee98321fded1e5b548/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=EeBcJuopHj85Ii_5h3xMyFVkrdMCjM19U3PPRC1vcQ0';

/* eslint-disable-next-line */
export interface EditStaffModalFormProps {}

export function EditStaffModalForm(props: EditStaffModalFormProps) {
  const [, globalActions] = useGlobal();
  const { appId, masterItems } = Config.Qlik.app1.ids;
  const app = Mosaic.Qlik.app[appId];

  const [open, set_open] = useState(false);
  const [tableObject, set_tableObject] = useState<any>();
  const [loading, set_loading] = useState(true);
  const [firstName, set_firstName] = useState();
  const [lastName, set_lastName] = useState();
  const [profId, set_profId] = useState();
  const [degree, set_degree] = useState();
  const [status, set_status] = useState('ACTIVE');
  const [role1, set_role1] = useState<string>();
  const [role2, set_role2] = useState<string>();
  const [role3, set_role3] = useState<string>();
  const [roleStatus1, set_roleStatus1] = useState<string>();
  const [roleStatus2, set_roleStatus2] = useState<string>();
  const [roleStatus3, set_roleStatus3] = useState<string>();
  const [phonenumber, set_phonenumber] = useState();
  const [faxnumber, set_faxnumber] = useState();
  const [email, set_email] = useState();
  const [transplantName, set_transplantName] = useState();
  const [orgId, set_orgId] = useState();

  const isFormInvalid = useCallback(() => {
    return (
      (role1 && !roleStatus1) ||
      (role2 && !roleStatus2) ||
      (role3 && !roleStatus3)
    );
  }, [
    firstName,
    lastName,
    email,
    phonenumber,
    role1,
    role2,
    role3,
    roleStatus1,
    roleStatus2,
    roleStatus3,
    status,
    degree,
    profId,
    faxnumber,
  ]);

  const submitForm = useCallback(async () => {
    set_loading(true);
    if (isFormInvalid()) {
      set_loading(false);
      set_open(true);
      return;
    }
    try {
      await axios({
        url: TableUrl,
        method: 'POST',
        timeout: 0,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          FirstName: firstName,
          LastName: lastName,
          UserID: WalkMeData?.UserID,
          Status: status,
          Degree: degree,
          DEA: '',
          NPI: '',
          Role1: role1,
          Role2: role2,
          Role3: role3,
          RoleStatus1: roleStatus1,
          RoleStatus2: roleStatus2,
          RoleStatus3: roleStatus3,
          Direct_Num: phonenumber,
          Fax_Num: faxnumber,
          Email: email,
          OrgID: orgId,
          ProfID: profId,
          ActionType: 'EDIT',
        }),
    });

      AntdModal.success({
        title: 'Successful submission',
        content: (
          <>
            <Typography.Text strong>
              {lastName},{firstName} {degree}
            </Typography.Text>{' '}
            has been updated as a staff member of{' '}
            <Typography.Text strong>
              {transplantName}
            </Typography.Text>
            . Please allow 1 business day for the update to be available in BMT Mosaic.
          </>
        ),
      });
      globalActions.UI.closeModal();
      set_loading(false);
    } catch (error) {
      AntdModal.error({
        title: 'Failed Submission',
        content: (
          <>
            <Typography.Text strong>
              {lastName},{firstName} {degree}
            </Typography.Text>{' '}
            has not been updated as a staff member. Please try
            again.
          </>
        ),
      });
      globalActions.UI.closeModal();
      set_loading(false);
    }
  }, [
    firstName,
    lastName,
    email,
    phonenumber,
    role1,
    role2,
    role3,
    roleStatus1,
    roleStatus2,
    roleStatus3,
    status,
    degree,
    profId,
    faxnumber,
  ]);

  useEffect(() => {
    set_loading(!profId || !transplantName);
  }, [profId, transplantName]);

  useEffect(() => {
    if (tableObject) {
      tableObject.table.addOnDataListener(() => {
        if (tableObject.table.rowCount === 1) {
          const [
            { qText: profNameId },
            { qText: profId },
            { qText: profDegree },
            { qText: profStaffRoleCombined },
            { qText: profPhone },
            { qText: profEmail },
          ] = tableObject.table.rows[0].cells;
          const name = profNameId.split('(')[0];
          const [lastName, firstName] = name.split(',');
          set_firstName(firstName.trim());
          set_lastName(lastName.trim());
          set_profId(profId);
          set_degree(profDegree);
          const roles = profStaffRoleCombined.split(';');
          roles.forEach((item, index) => {
            switch (index) {
              case 0:
                set_role1(item);
                set_roleStatus1('ACTIVE');
                break;
              case 1:
                set_role2(item);
                set_roleStatus2('ACTIVE');
                break;
              case 2:
                set_role3(item);
                set_roleStatus3('ACTIVE');
                break;
            }
          });
          set_phonenumber(profPhone);
          set_email(profEmail);
        }
      });
      tableObject.table.getMoreData();
    }
    return () => {
      if (tableObject && tableObject.close) {
        tableObject.close();
      }
    };
  }, [tableObject]);

  useEffect(() => {
    app.visualization
      .get(masterItems['Edit Staff Table Info'])
      .then(set_tableObject);
  }, []);

  useEffect(() => {
    app.visualization
      .get(masterItems['Transplant Center Text Box'])
      .then((qViz) => {
        qViz.table.addOnDataListener(() => {
          const [
            { qText: transplantCenter },
            { qText: sourceCity },
            { qText: sourceState },
            { qText: orgZip },
            { qText: unitType },
            { qText: activeAllogenicFlag },
            { qText: factApprovedAllogenic },
            { qText: yearOpenedAllogenic },
            { qText: nciMember },
            { qText: nccnMember },
            { qText: orgId },
            { qText: orgIdDw },
          ] = qViz.table.rows[0].cells;
          set_transplantName(transplantCenter);
          set_orgId(orgId);
        });
        qViz.table.getMoreData();
      });
  }, []);

  console.log(tableObject)

  if (loading || !tableObject) {
    return (
      <div style={{ height: '50%', width: '100%' }} >
        <Loader text="Loading..."/>
      </div>
    )
  } else {
    return (
      <>
        <div>
          <h1>
            Edit Staff Member Information - {transplantName}
          </h1>
          <Form>
            <HeaderDivider>Bio Section</HeaderDivider>                
              <Row gutter={[16, 16]} align="top">
                <Col span={12}>
                  <Form.Item
                    name="First Name"
                    label="First Name"
                    rules={[{ required: true }]}
                  >
                    <Input 
                      placeholder="First Name" 
                      defaultValue={firstName}
                      onChange={(data: any) =>
                        set_firstName(data.value)
                      } 
                    />
                  </Form.Item>
                  <Form.Item
                    label="Last Name"
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(data: any) =>
                        set_lastName(data.value)
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="BMT PROF ID"
                  >
                    <Input 
                      placeholder="BMT PROF ID"
                      value={profId}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    label="Degree"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={degree_options}
                      value={degree}
                      placeholder="Degree"
                      onSelect={(data: any) =>
                        set_degree(data.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Status"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={status_options}
                      placeholder="Status"
                      value={status}
                      onChange={(data: any) =>
                        set_status(data.value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <HeaderDivider>Role Section</HeaderDivider>
              <Row gutter={[16, 16]} align="top">
                <Col span={12}>
                  <Form.Item
                    label="Role 1"
                    rules={[{ required: true }]}
                  >
                    <Select 
                      options={role_options}
                      value={role1}
                      onChange={(data: any) =>
                        set_role1(data.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Role 1 Status"
                    rules={[{ required: true }]}
                  >
                    <Select 
                      options={status_options}
                      value={roleStatus1}
                      onChange={(data: any) =>
                        set_roleStatus1(data.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Role 2"
                  >
                    <Select
                      value={role2}
                      options={role_options}
                      onChange={(data: any) =>
                        set_role2(data.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Role 2 Status"
                    rules={[{ required: !!role2 }]}
                  >
                    <Select 
                      options={status_options}
                      value={roleStatus2}
                      onChange={(data: any) =>
                        set_roleStatus2(data.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Role 3"
                  >
                    <Select 
                      value={role3}
                      options={role_options}
                      onChange={(data: any) =>
                        set_role3(data.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Role 3 Status"
                    rules={[{ required: !!role3 }]}
                  >
                    <Select 
                      value={roleStatus3}
                      options={status_options}
                      onChange={(data: any) =>
                        set_roleStatus3(data.value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <HeaderDivider>Contact Information Section</HeaderDivider>
              <Row gutter={[16, 16]} align="top">
                <Col span={12}>
                  <Form.Item
                    label="Direct #"
                  >
                    <Input
                      placeholder="Direct #"
                      value={phonenumber}
                      onChange={(data: any) =>
                        set_phonenumber(data.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}></Col>
                <Col span={12}>
                  <Form.Item
                    label="Fax #"
                  >
                    <Input
                      placeholder="Fax #"
                      value={faxnumber}
                      onChange={(data: any) =>
                        set_faxnumber(data.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}></Col>
                <Col span={12}>
                  <Form.Item
                    label="Email"
                  >
                    <Input
                      placeholder="Email"
                      value={email}
                      onChange={(data: any) =>
                        set_email(data.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}></Col>
              </Row>
              <Row style={{ paddingTop: 8 }}>
                <Form.Item label="" style={{paddingRight: '10px'}}>
                  <Button htmlType="submit" type="primary" onClick={submitForm}>
                    Submit
                  </Button>
                </Form.Item>
                <Form.Item label="">
                  <Button onClick={() => globalActions.UI.closeModal()} >
                    Cancel 
                  </Button>
                </Form.Item>
              </Row>  
          </Form>
        </div>
      </>
    );
  }
}

export default EditStaffModalForm;
