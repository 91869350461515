import preval from 'preval.macro';
export const environment = {
  environmentName: 'Test',
  production: false,
  userEnvironmentSalt:'Test',
  authFailRedirectUrl: 'https://incyte-qs1test.bellcyn.com/Content/Redirector/index.html?app=bmtmosaictest',
  profImagesServer: 'https://profimageswebapp.azurewebsites.net/ProfImages/',
  mosaicHcpUrl: 'https://incyte-qs1test.bellcyn.com/Content/Default/redirector.html?app=MosaicTest&redirectUrl=MosaicTest/HCP360/Overview/',
  mdmUrlRedirect: 'https://incyte-mdm-portal-dev.bellcyn.com/authentication/login?returnUrl=https://incyte-mdm-portal-dev.bellcyn.com/hcp/details/',
  azureTableUrl: 'https://prod-30.eastus2.logic.azure.com:443/workflows/7fbd54c056c94753a0c1ba858c1655d1/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ymaJc3_hpj51cDnjBfG2KIJOxMKUNvJTeytJs6Ee0Sg',
  appId: '22ce20d6-4843-44a9-9e84-3fa1119ff411',
  host: 'incyte-qs1test.bellcyn.com',
  buildDate: preval`module.exports = Date.now()`,
};
