import React, { useState, useEffect, useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { Tooltip, Table } from 'antd';
import styled from 'styled-components';
import Utils from '@trinity-incyte/utils';
import { ConfigContext } from '@trinity-incyte/context';
import { QSAppMetadataFamily } from '@trinity-incyte/recoil';
import Loader from '../../Other/loader/loader';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

const RecentEventsTableHeader = styled.span`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 0.9rem;
	font-family: Lato;
	color: #595959;
	font-weight: bold;
`;

const RecentEventsTableCell = styled(Tooltip)`
	font-size: 0.9rem;
	font-family: Lato; 
	color: #595959;
`;

const RecentEventsTableCellOverflow = styled.span`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 0.9rem;
	font-family: Lato;
	color: #595959;
`;

const hasText = (value) => (value && value !== '' && value !== '-');

export const RecentEventsTable = (props) => {
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.Prof360,
		{ appId } = config.ids;
	const [events, set_events] = useState<any>(false);
	const [elementId] = useState(() => Utils.idGen('mashup'));
	const [tableObject, set_tableObject] = useState<any>(false);
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { FieldsTable: fields } = metadata;

	const createTableDef = (items?) => {
		const tempArray = [
			{
				"qDef": {
					"qFieldDefs": [
						`DD.DATE_ID`,
					],
					"qFieldLabels": [
						"Date"
					],
					"qSortCriterias": [
						{
							"qSortByNumeric": -1
						}
					]
				},
				"qNullSuppression": true,
				"qSuppressMissing": true,
				"qSuppressZero": true,
			}, {
				"qDef": {
					"qFieldDefs": [
						`INTERACTION_DESC`,
					],
					"qFieldLabels": [
						"Type"
					],
				},
				"qNullSuppression": true,
				"qSuppressMissing": true,
				"qSuppressZero": true,
			}, {
				"qDef": {
					"qFieldDefs": [
						`INTERACTION_TERR_WITH_REP`,
					],
					"qFieldLabels": [
						"Rep"
					],
				},
				"qNullSuppression": true,
				"qSuppressMissing": true,
				"qSuppressZero": true,
			}, {
				"qDef": {
					"qFieldDefs": [
						`INT.INTERACTION_LINE_1`,
					],
					"qFieldLabels": [
						"Details Line 1"
					],
				},
				"qNullSuppression": true,
				"qSuppressMissing": true,
				"qSuppressZero": true,
			}, {
				"qDef": {
					"qFieldDefs": [
						`INT.INTERACTION_LINE_2`,
					],
					"qFieldLabels": [
						"Details Line 2"
					],
				},
				"qNullSuppression": true,
				"qSuppressMissing": true,
				"qSuppressZero": true,
			}, {
				"qDef": {
					"qFieldDefs": [
						`INT.INTERACTION_LINE_3`,
					],
					"qFieldLabels": [
						"Details Line 3"
					],
				},
				"qNullSuppression": true,
				"qSuppressMissing": true,
				"qSuppressZero": true,
			}, {
				"qDef": {
					"qLabel": `Count`,
					"qDef": `=SUM({<EVENT_SHOW_FLAG={'Y'},[${fields.get('Mosaic_HCP 360_PROF ID')?.id}]={${props.profId}}, DD.DATE_ID = {">=$(=Date(Today(), 'MM/DD/YYYY'))"}>
					+
					<EVENT_SHOW_FLAG={'Y'},[${fields.get('Mosaic_HCP 360_PROF ID')?.id}]={${props.profId}},[DD.ACTUAL_WEEK_C13WTY]={'-1'}>} INTERACTION_CNT)`,
				},
				"qNullSuppression": true,
				"qSuppressMissing": true,
				"qSuppressZero": true,
			},
		];

		return tempArray;
	};

	useEffect(() => {
		const app = Mosaic.Qlik.app[appId];
		if (!app.visualization || !app.visualization.create) {
			return;
		}
		app.visualization.create(
			'table',
			createTableDef(),
			{ title: 'Recent Events' }
		).then((table) => {
			set_tableObject(table);
			const parseData = () => {
				set_events(table.table.rows);
				table.table.removeOnDataListener(parseData);
			};
			table.table.addOnDataListener(parseData);
			table.table.getMoreData();
		});

		return () => {
			if (tableObject && tableObject.close) tableObject.close();
		}
	}, []);

	let content;
	if (!events) {
		content = (
			<div style={{ height: '50%', width: '100%' }} id={elementId}>
					<Loader text="Loading..." />
			</div>
		);
	} else {
		const columns = [
			{
			  title: 
			  	<Tooltip color="white" placement="topLeft" title={<RecentEventsTableHeader>Date (Latest 8 Weeks)</RecentEventsTableHeader>}>
						<RecentEventsTableHeader>Date (Latest 8 Weeks)</RecentEventsTableHeader>
					</Tooltip>,
			  dataIndex: 'date',
			  key: 'date',
				width: '10%',
			  ellipsis: true,
			  render: date => (
				<Tooltip color="white" placement="topLeft" title={<RecentEventsTableCell>{date}</RecentEventsTableCell>}> 
					<RecentEventsTableCell>
						{date}
					</RecentEventsTableCell>
				</Tooltip>
			  )
			},
			{
			  title: <RecentEventsTableHeader>Type</RecentEventsTableHeader>,
			  dataIndex: 'type',
			  key: 'type',
				width: '10%',
				ellipsis: true,
			  render: type => (
				<Tooltip color="white" placement="top" title={<RecentEventsTableCell>{type}</RecentEventsTableCell>}> 
					<RecentEventsTableCellOverflow>
						{type}
					</RecentEventsTableCellOverflow>
				</Tooltip>
			  )
			},
			{
			  title: <RecentEventsTableHeader>Employee</RecentEventsTableHeader>,
			  dataIndex: 'employee',
			  key: 'employee',
				width: '40%',
			  render: employee => (
				<Tooltip color="white" placement="top" title={<RecentEventsTableCell>{employee}</RecentEventsTableCell>}> 
					<RecentEventsTableCell>
						{employee}
					</RecentEventsTableCell>
				</Tooltip>
			  )
			},
			{
			  title: <RecentEventsTableHeader>Detail/Subject</RecentEventsTableHeader>,
			  key: 'detail',
			  dataIndex: 'detail',
			  width: '40%',
				ellipsis: true,
			  render: detail => (
				<>
					<Tooltip color="white" placement="top" title={<RecentEventsTableCell>{detail}</RecentEventsTableCell>}> 
						<RecentEventsTableCellOverflow>
							{detail}
						</RecentEventsTableCellOverflow>
					</Tooltip>
				</>
			  ),
			}
		];

		const data = events.map((val) => {
			let retVal = {};

			const monthAgo = Utils.moment().subtract(1, 'month');
			const now = Utils.moment();
			const eventDate = Utils.moment(val.cells[0].qText);

			const [
				{ qText: date },
				{ qText: type },
				{ qText: employee },
				{ qText: line1 },
				{ qText: line2 },
				{ qText: line3 },
				{ qText: desc },
			] = val.cells;

			const details = [];
			if (hasText(line1)) details.push(line1);
			if (hasText(line2)) details.push(line2);
			if (hasText(line3)) details.push(line3);

			const detailString = details.join(", ");

			if (eventDate.isAfter(now) || eventDate.isBefore(monthAgo)) {
				return null;
			}
			
			retVal = {
				key: Utils.idGen('event'),
				date: date,
				type: type,
				employee: employee,
				detail: detailString,
			};
				
			return retVal
		});

		const eventData = data.filter(data => {
			if (data !== null) {
				return data;
		}})
			
		content = (
			<div style={{ borderTop: '2px solid #005cab' }}>
				<Table columns={columns} dataSource={eventData} bordered tableLayout='fixed' pagination={false} size='small' />
			</div>
		);
	}

	return (
		<div style={{ width: '100%', backgroundColor: 'white', backgroundClip: 'content-box', fontSize: '0.9em', padding: '11px' }}>
			{content}
		</div>
	);
};

export default RecentEventsTable;
