import { ConfigContext } from "@trinity-incyte/context";
import { QSAppMetadataFamily } from "@trinity-incyte/recoil";
import Utils from "@trinity-incyte/utils";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare var Mosaic: MosaicGlobal;

export const HCP360Context = createContext({} as any);

export const HCP360State = (props) => {	
    const { profId } = props;
    if (profId===undefined) return (<>{props.children}</>);
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.Prof360;
	const { appId } = config.ids;
	const app = Mosaic.Qlik.app[appId];
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids, FieldsTable: fields } = metadata;
	const [profData, set_profData] = useState<any>(false);
	const isActive = useRef(true);
	const profDataRetryCount = useRef(0);
	const profDataPromise = useRef<any>();
    
    const parseProfData_Derm = ( reply ) => {
        const profData = { ...reply };
        return profData;
    }

    const parseProfData_Mosaic = ( reply ) => {
        const profData = { ...reply };
        profData.imgUrl = ( profData.imgUrl === '-' || profData.imgUrl === '' ) ? Config.App.profImagesDefault : Config.App.profImagesServer + profData.imgUrl.split( 'ProfImages\\' )[1];
        profData.affinityLevels = ( profData.affinityLevels === '-' ) ? false : profData.affinityLevels.split(',');
        profData.lastInteraction = ( profData.lastInteraction === '-' ) ? false : profData.lastInteraction.split(',');
        profData.quintiles = ( profData.quintiles === '-' ) ? false : profData.quintiles.split(',');

        if ( profData.affinityLevels ) {
            let affinityText;
            let highestAffinity = 0;
            const affinityTexts = [
                'Face to Face',
                'Digital Push',
                'Phone Interview',
                'Digital Pull',
            ];
            const temp = profData.affinityLevels.map(( val ) => {
                if ( Number.isNaN( val )) {
                if ( val === 'LOW' ) {
                        return 2;
                    } else if ( val === 'HIGH' ) {
                        return 8;
                    } else {
                        return 5;
                    }
                } else {
                    return parseInt( val, 10 );
                }
            });

            for ( let ii = 0; ii < temp.length; ii += 1 ) {
                if ( temp[ii] > highestAffinity ) {
                    highestAffinity = temp[ii];
                    affinityText = affinityTexts[ii];
                }
            }

            profData.affinityKPI = affinityText;
        }

        if ( profData.lastInteraction ) {
            const [tempDate, rep] = profData.lastInteraction;
            profData.lastInteractionDateKPI = new Date( tempDate ).toLocaleDateString();
            profData.lastInteractionRepKPI = rep;
        }

        if ( profData.quintiles ) {
            [profData.quintileMFKPI, profData.quintilePVKPI, profData.quintileGVHDKPI] = profData.quintiles;
        }

        let date;
        if ( profData['INCYTE_LAST_INTERACTION_DATE'] ) {
            date = Utils.moment(profData['INCYTE_LAST_INTERACTION_DATE']);
            profData['INCYTE_LAST_INTERACTION_DATE'] = date.isValid() ? date.format('MM-DD-YYYY'): null;
        }
        if ( profData['INCYTE_NEXT_INTERACTION_DATE'] ) {
            date = Utils.moment(profData['INCYTE_NEXT_INTERACTION_DATE']);
            profData['INCYTE_NEXT_INTERACTION_DATE'] = date.isValid() ? date.format('MM-DD-YYYY'): null;
        }
        if ( profData['MORPHOSYS_LAST_INTERACTION_DATE'] ) {
            date = Utils.moment(profData['MORPHOSYS_LAST_INTERACTION_DATE']);
            profData['MORPHOSYS_LAST_INTERACTION_DATE'] = date.isValid() ? date.format('MM-DD-YYYY'): null;
        }
        if ( profData['MORPHOSYS_NEXT_INTERACTION_DATE'] ) {
            date = Utils.moment(profData['MORPHOSYS_NEXT_INTERACTION_DATE']);
            profData['MORPHOSYS_NEXT_INTERACTION_DATE'] = date.isValid() ? date.format('MM-DD-YYYY'): null;
        }

        if (profData.bio['State Restrictions'] === '-') {
            profData.bio['State Restrictions'] = null;
        }

        // Barriers
        if (profData.barriers['MF Text'] === '-') {
            profData.barriers['MF Text'] = null;
            profData.barriers['MF Date'] = null;
        }

        if (profData.barriers['PV Text'] === '-') {
            profData.barriers['PV Text'] = null;
            profData.barriers['PV Date'] = null;
        }

        if (profData.barriers['AGVHD Text'] === '-') {
            profData.barriers['AGVHD Text'] = null;
            profData.barriers['AGVHD Date'] = null;
        }

        if (profData.barriers['DLBCL Text'] === '-') {
            profData.barriers['DLBCL Text'] = null;
            profData.barriers['DLBCL Date'] = null;
        }

        if (!profData.barriers['MF Text']
            && !profData.barriers['PV Text']
            && !profData.barriers['AGVHD Text']
            && !profData.barriers['DLBCL Text']) {
            profData.barriers = false;
        }

        if (profData.products === '') {
            profData.products = false;
        }

        if (profData.pdrpOptout === 'Y') {
            profData.products = false;
        }

        return profData;
    };
    
    const getProfDataObject = ( profId ) => {
        if ( !app || !profId ) {
            return;
        }

        let setAnalysis = ``;
        let definition = {};

        if (Config.App.name=="MosaicDerm") {
            // TODO - prof id is not in the lineage fields for Derm, but we should have BI add one 
            // setAnalysis = `{1<[${fields.get('Derm Mosaic_HCP 360_PROF ID')?.id}]={${profId}}>}`;
            setAnalysis = `{1<[%PROF_ID]={${profId}}>}`;
            definition = {
                profId: profId,
                bio: {
                    Name: { qStringExpression: `=Only( ${setAnalysis} [${ fields.get('Derm Mosaic_HCP_Prof Full Name').id }] )`, },
                }    
            }
        }

        if (Config.App.name=="Mosaic") {
            setAnalysis = `{1<[${fields.get('Mosaic_HCP 360_PROF ID')?.id}]={${profId}}>}`;
            // I wonder if this could live in Config?
            definition = {
                profId: profId,
                isJak: true,
                isPem: { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_BRAND PEM')?.id}] )` },
                isMon: { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_BRAND MON')?.id}] )` },
                imgUrl: { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_PROF IMAGE URL')?.id}] )` },
                targetMon: { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_SALES TARGET TYPE - Monjuvi')?.id}] )` },
                targetPem: { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_SALES TARGET TYPE - Pemazyre')?.id}] )` },
                targetJak: { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_SALES TARGET TYPE - Jakafi')?.id}] )` },
                opportunityGrowth: { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_Growth Opportunity Group')?.id}] )` },
                opportunityProspecting: { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_Prospecting Opportunity Group')?.id}] )` },
                education: { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_Residency Organization')?.id}] )` },
                fellowship: { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_Fellowship Organization')?.id}] )` },
                targetAdvisor: { qStringExpression: `=Only( ${setAnalysis} [DPA.ADVISOR] )` },
                targetInvestigatorFlag: { qStringExpression: `=Only( ${setAnalysis} [DPA.INVESTIGATOR_FLAG] )` },
                targetEEFlag: { qStringExpression: `=Only( ${setAnalysis} [DPA.EE_FLAG] )` },
                targetExternalInvestigator: { qStringExpression: `=Only( ${setAnalysis} IF(
                    AGGR(SUM(IF(FC.RECORD_TYPE='DISCLOSURE' and FC.RECORD_SOURCE='MEDMEME',FC.FCT_CNT,0)),%PROF_ID)
                    +
                    AGGR(SUM(IF(FC.RECORD_SOURCE='CMS OPEN PAYMENTS' and CMS.SUBMITTING_COMPANY_NAME<>'INCYTE CORPORATION',FC.FCT_CNT,0)),%PROF_ID)
                    > 0,'Y','N') )`
                },
                targetRegionalPlan: { qStringExpression: `=Only( ${setAnalysis} [DPA.REGIONAL_PLAN] )` },
                targetJakafiExperience: { qStringExpression: `=Only( ${setAnalysis} [DPA.JAKAFI_EXPERIENCE] )` },
                targetSpeaker: { qStringExpression: `=Only( ${setAnalysis} [DPA.SPEAKER_FLAG] )` },
                kpi0: {
                    'HO1': { qStringExpression: `=Only( ${setAnalysis} [DP.PROF_FULL_NAME] & ', ' & [DP.PROF_DEGREE] & chr(10) & [${fields.get('Mosaic_HCP 360_HO1 Primary City')?.id}] & ', ' & [${fields.get('Mosaic_HCP 360_HO1 Primary State')?.id}] & chr(10) & [${fields.get('Mosaic_HCP 360_HO1 Primary Zip')?.id}] )` },
                    'HO2': { qStringExpression: `=Only( ${setAnalysis} [DP.PROF_FULL_NAME] & ', ' & [DP.PROF_DEGREE] & chr(10) & [${fields.get('Mosaic_HCP 360_HO1 Primary City')?.id}] & ', ' & [${fields.get('Mosaic_HCP 360_HO1 Primary State')?.id}] & chr(10) & [${fields.get('Mosaic_HCP 360_HO1 Primary Zip')?.id}] )` },
                    'HO3': { qStringExpression: `=Only( ${setAnalysis} [DP.PROF_FULL_NAME] & ', ' & [DP.PROF_DEGREE] & chr(10) & [${fields.get('Mosaic_HCP 360_HO3 Primary City')?.id}] & ', ' & [${fields.get('Mosaic_HCP 360_HO3 Primary State')?.id}] & chr(10) & [${fields.get('Mosaic_HCP 360_HO3 Primary Zip')?.id}] )` },
                    'OCNE': { qStringExpression: `=Only( ${setAnalysis} [DP.PROF_FULL_NAME] & ', ' & [DP.PROF_DEGREE] & chr(10) & [${fields.get('Mosaic_HCP 360_HO1 Primary City')?.id}] & ', ' & [${fields.get('Mosaic_HCP 360_HO1 Primary State')?.id}] & chr(10) & [${fields.get('Mosaic_HCP 360_HO1 Primary Zip')?.id}] )` },
                },
                kpi1: { qStringExpression: `=Only( ${setAnalysis} if(DPA.REGIONAL_PLAN='Y', 'RP','N') )` },
                kpi2: { qStringExpression: `=Only( ${setAnalysis} if([DPA.FOCUS_AREA_PRIMARY], [DPA.FOCUS_AREA_PRIMARY] & chr(10) &  [DPA.FOCUS_AREA_SECONDARY]  & chr(10) &  [DPA.FOCUS_AREA_TERTIARY], '' ))` },
                affinityLevels: { qStringExpression: `=Only( ${setAnalysis} [DPA.AM_NO_SEE_RATING] & ',' & [DPA.PI_AFFINITY_LVL]  & ',' & [DPA.DPH_AFFINITY_LVL] & ',' & [DPA.DPL_AFFINITY] )` },
                lastInteraction: { qStringExpression: `=Only( ${setAnalysis} [DPA.LAST_INTERACTION_DATE] & ',' & [DPA.LAST_INTERACTION_REP] )` },
                'OCE_Prof_ID': { qStringExpression: `=Only( ${setAnalysis} ${fields.get('Mosaic_HCP 360_HemOnc OCE Prof ID')?.id} )` },
                'HCO Name': { qStringExpression: `=Only( ${setAnalysis} ${fields.get('Mosaic_HCP 360_HCO Name')?.id} )` },
                'INCYTE_LAST_INTERACTION_DATE': { qStringExpression: `=Only( ${setAnalysis} ${fields.get('Mosaic_HCP 360_INCYTE_LAST_INTERACTION_DATE')?.id} )` },
                'INCYTE_LAST_INTERACTION_DESC': { qStringExpression: `=Only( ${setAnalysis} ${fields.get('Mosaic_HCP 360_INCYTE_LAST_INTERACTION_DESC')?.id} )` },
                'INCYTE_LAST_INTERACTION_TERR_EMP_NAME': { qStringExpression: `=Only( ${setAnalysis} ${fields.get('Mosaic_HCP 360_INCYTE_LAST_INTERACTION_TERR_EMP_NAME')?.id} )` },
                'INCYTE_NEXT_INTERACTION_DATE': { qStringExpression: `=Only( ${setAnalysis} ${fields.get('Mosaic_HCP 360_INCYTE_NEXT_INTERACTION_DATE')?.id} )` },
                'INCYTE_NEXT_INTERACTION_DESC': { qStringExpression: `=Only( ${setAnalysis} ${fields.get('Mosaic_HCP 360_INCYTE_NEXT_INTERACTION_DESC')?.id} )` },
                'INCYTE_NEXT_INTERACTION_TERR_EMP_NAME': { qStringExpression: `=Only( ${setAnalysis} ${fields.get('Mosaic_HCP 360_INCYTE_NEXT_INTERACTION_TERR_EMP_NAME')?.id} )` },
                'MORPHOSYS_LAST_INTERACTION_DATE': { qStringExpression: `=Only( ${setAnalysis} ${fields.get('Mosaic_HCP 360_MORPHOSYS_LAST_INTERACTION_DATE')?.id} )` },
                'MORPHOSYS_LAST_INTERACTION_DESC': { qStringExpression: `=Only( ${setAnalysis} ${fields.get('Mosaic_HCP 360_MORPHOSYS_LAST_INTERACTION_DESC')?.id} )` },
                'MORPHOSYS_LAST_INTERACTION_TERR_EMP_NAME': { qStringExpression: `=Only( ${setAnalysis} ${fields.get('Mosaic_HCP 360_MORPHOSYS_LAST_INTERACTION_TERR_EMP_NAME')?.id} )` },
                'MORPHOSYS_NEXT_INTERACTION_DATE': { qStringExpression: `=Only( ${setAnalysis} ${fields.get('Mosaic_HCP 360_MORPHOSYS_NEXT_INTERACTION_DATE')?.id} )` },
                'MORPHOSYS_NEXT_INTERACTION_DESC': { qStringExpression: `=Only( ${setAnalysis} ${fields.get('Mosaic_HCP 360_MORPHOSYS_NEXT_INTERACTION_DESC')?.id} )` },
                'MORPHOSYS_NEXT_INTERACTION_TERR_EMP_NAME': { qStringExpression: `=Only( ${setAnalysis} ${fields.get('Mosaic_HCP 360_MORPHOSYS_NEXT_INTERACTION_TERR_EMP_NAME')?.id} )` },
                'products': { qStringExpression: `=CONCAT( ${setAnalysis} ${fields.get('Mosaic_HCP 360_DLBCL Products')?.id} )` },
                pdrpOptout: { qStringExpression: `=Only( ${setAnalysis} [DPA.PDRP_OPTOUT] )` },
                quintiles: { qStringExpression: `=Only( ${setAnalysis} [DPA.MF_QUINTILE] & ',' & [DPA.PV_QUINTILE] & ',' & IF(DPA.BMT_FLAG='Y', num(DPA.ACUTE_UNSPECIFIED_GVHD_QUINTILE, '0')) )` },
                location: {
                    'HO1': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary Address 1')?.id}] & ', ' & [${fields.get('Mosaic_HCP 360_HO1 Primary City')?.id}] & ', ' & [${fields.get('Mosaic_HCP 360_HO1 Primary State')?.id}] & ' ' & [${fields.get('Mosaic_HCP 360_HO1 Primary Zip')?.id}])` },
                    'HO2': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary Address 1')?.id}] & ', ' & [${fields.get('Mosaic_HCP 360_HO1 Primary City')?.id}] & ', ' & [${fields.get('Mosaic_HCP 360_HO1 Primary State')?.id}] & ' ' & [${fields.get('Mosaic_HCP 360_HO1 Primary Zip')?.id}])` },
                    'HO3': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO3 Primary Address 1')?.id}] & ', ' & [${fields.get('Mosaic_HCP 360_HO3 Primary City')?.id}] & ', ' & [${fields.get('Mosaic_HCP 360_HO3 Primary State')?.id}] & ' ' & [${fields.get('Mosaic_HCP 360_HO3 Primary Zip')?.id}])` },
                    'OCNE': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary City')?.id}] & ', ' & [${fields.get('Mosaic_HCP 360_HO1 Primary State')?.id}] )` },
                },
                focusAreas: {
                    primary: { qStringExpression: `=Only( ${setAnalysis} [DPA.COMMERCIAL_FOCUS_AREA_PRIMARY])` },
                    secondary: { qStringExpression: `=Only( ${setAnalysis} [DPA.COMMERCIAL_FOCUS_AREA_SECONDARY])` },
                    tertiary: { qStringExpression: `=Only( ${setAnalysis} [DPA.COMMERCIAL_FOCUS_AREA_TERTIARY])` },
                },
                barriers: {
                    'MF Text': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_MF Barrier Display Text')?.id}])` },
                    'MF Date': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_MF Barrier Display Date')?.id}])` },
                    'PV Text': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_PV Barrier Display Text')?.id}])` },
                    'PV Date': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_PV Barrier Display Date')?.id}])` },
                    'AGVHD Text': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_AGVHD Barrier Display Text')?.id}])` },
                    'AGVHD Date': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_AGVHD Barrier Display Date')?.id}])` },
                    'DLBCL Text': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_DLBCL Barrier Display Text')?.id}])` },
                    'DLBCL Date': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_DLBCL Barrier Display Date')?.id}])` },
                    'Recent Barriers': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HemOnc Barrier Recent Flag')?.id}])` },
                    'Barrier Count': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HemOnc Barrier Count')?.id}])` },
                },
                bio: {
                    'Name': { qStringExpression: `=Only( ${setAnalysis} [DP.PROF_FULL_NAME] )` },
                    'Degree': { qStringExpression: `=Only( ${setAnalysis} [DP.PROF_DEGREE] )` },
                    'Specialty': { qStringExpression: `=Only( ${setAnalysis} [DP.PROF_SPEC_DESC] )` },
                    'Address': {
                        'HO1': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary Address 1')?.id}] )` },
                        'HO2': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary Address 1')?.id}] )` },
                        'HO3': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO3 Primary Address 1')?.id}] )` },
                        'OCNE': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary Address 1')?.id}] )` },
                    },
                    'Address2': {
                        'HO1': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary Address 2')?.id}] )` },
                        'HO2': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary Address 2')?.id}] )` },
                        'HO3': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO3 Primary Address 2')?.id}] )` },
                        'OCNE': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary Address 2')?.id}] )` },
                    },
                    'City': {
                        'HO1': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary City')?.id}] )` },
                        'HO2': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary City')?.id}] )` },
                        'HO3': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO3 Primary City')?.id}] )` },
                        'OCNE': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary City')?.id}] )` },
                    },
                    'State':  {
                        'HO1': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary State')?.id}] )` },
                        'HO2': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary State')?.id}] )` },
                        'HO3': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO3 Primary State')?.id}] )` },
                        'OCNE': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary State')?.id}] )` },
                    },
                    'Zip': {
                        'HO1': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary Zip')?.id}] )` },
                        'HO2': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary Zip')?.id}] )` },
                        'HO3': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO3 Primary Zip')?.id}] )` },
                        'OCNE': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_HO1 Primary Zip')?.id}] )` },
                    },
                    'Birth Year': { qStringExpression: `=Only( ${setAnalysis} [DP.PROF_BIRTHDATE] )` },
                    'Age': { qStringExpression: `=Only( ${setAnalysis} year(now()) - [DP.PROF_BIRTHDATE] )` },
                    'Phone': { qStringExpression: `=Only( ${setAnalysis} AGGR(FirstSortedValue({<FC.RECORD_TYPE={'CONTACT_INFO'}>} distinct PP.SOURCE_PHONE,1),%PROF_ID) )` },
                    'Affiliations': { qStringExpression: `=Only( ${setAnalysis} AGGR(FirstSortedValue({<FC.RECORD_TYPE={'CONTACT_INFO'}>} distinct PP.INSTITUTION_NAME,1),%PROF_ID) )` },
                    'Fax': { qStringExpression: `=Only( ${setAnalysis} AGGR(FirstSortedValue({<FC.RECORD_TYPE={'CONTACT_INFO'}>} distinct PP.SOURCE_FAX,1),%PROF_ID) )` },
                    'Email': { qStringExpression: `=Only( ${setAnalysis} [DP.PROF_EMAIL] )` },
                    'Rental Email': { qStringExpression: `=Only( ${setAnalysis} [DPA.RENTAL_EMAIL] )` },
                    'Twitter Handle': { qStringExpression: `=Only( ${setAnalysis} [DPA.TWITTER_HANDLE] )` },
                    'NPI': { qStringExpression: `=Only( ${setAnalysis} [DP.PROF_NPINUM] )` },
                    'State Restrictions': { qStringExpression: `=Only( ${setAnalysis} [${fields.get('Mosaic_HCP 360_State Restriction')?.id}] )` },
                },
            };
        }

        return app.createGenericObject(definition);
    };
    
	const queryForHCPData = ()=> {
        if (!profId) return;
		profDataPromise.current = getProfDataObject( profId );
		profDataPromise?.current?.then(( data ) => {
			if (!isActive.current) return; // Exit early if component is unmounted
			const layout = data?.layout;
			if (layout == null && profDataRetryCount.current < 5) {
				profDataRetryCount.current++;
				queryForHCPData();
				return;
			}
            let formattedData = {}; 
			if (Config.App.name=="MosaicDerm") {
                formattedData = parseProfData_Derm(layout);
            } else {
                formattedData = parseProfData_Mosaic(layout);
            }
            set_profData(formattedData);
		});
	}

	useEffect(() => {
        set_profData(false);
		queryForHCPData();
		return () => {
            profDataPromise?.current?.then((qViz) => {
                qViz.close();
            });
            if (profDataPromise.current && profDataPromise.current.close) profDataPromise.current.close();
		};
    }, [profId]);

    useEffect(() => {
        return () => {
            isActive.current = false;
        }
    }, []);

    return (
        <HCP360Context.Provider value={{profData}}>
            {props.children}
        </HCP360Context.Provider>
    )
};

export default HCP360Context;