import React from 'react';
import { Card, Col, Row } from 'antd';
import { useRecoilValue } from 'recoil';
import {
  activeTeam as activeTeamAtom,
  UserTerritory as UserTerritoryAtom
} from '@trinity-incyte/recoil';

const cardBodyStyle = {
	padding: '0 12px',
  minWidth:'180px',
};

export interface NavTerritoryProps {
  insideExistingNav?: boolean;
}

export function NavTerritory(props: NavTerritoryProps) {
  const activeTeam = useRecoilValue(activeTeamAtom);
	const userTerritory = useRecoilValue(UserTerritoryAtom);
    return (
      <>
        {props.insideExistingNav ? (
          <>
            <Col span={4}>
              <Card
                bordered={false}
                size="small"
                style={{ background: 'transparent' }}
                bodyStyle={{ ...cardBodyStyle, color: 'aliceblue' }}
              >
                <ul style={{ listStyle: 'none', marginBottom: '2px', fontSize: '1rem' }}>
                  {(activeTeam === 'HO1') && (
                    <li>{userTerritory.ho1}</li>
                  )}
                  {(activeTeam === 'HO2') && (
                    <li>{userTerritory.ho2}</li>
                  )}
                  {(activeTeam === 'HO3') && (
                    <li>{userTerritory.ho3}</li>
                  )}
                  {(activeTeam === 'OCNE') && (
                    <li>{userTerritory.ocne}</li>
                  )}
                </ul>
              </Card>
            </Col>
            <Col span={4}/>
          </>
        ) : (
          <Row gutter={[8, 4]} justify="start" style={{ width: '100%', background: 'black', padding: '4px 8px' }}>
            <Col span={4}>
              <Card
                bordered={false}
                size="small"
                style={{ background: 'transparent' }}
                bodyStyle={{ ...cardBodyStyle, color: 'aliceblue' }}
              >
                <ul style={{ listStyle: 'none', marginBottom: '2px', fontSize: '1rem' }}>
                  {(activeTeam === 'HO1') && (
                    <li>{userTerritory.ho1}</li>
                  )}
                  {(activeTeam === 'HO2') && (
                    <li>{userTerritory.ho2}</li>
                  )}
                  {(activeTeam === 'HO3') && (
                    <li>{userTerritory.ho3}</li>
                  )}
                </ul>
              </Card>
            </Col>
            <Col span={4}/>
          </Row>
        )}
      </>
    )
};

export default NavTerritory;
