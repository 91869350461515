import React from 'react';
import useGlobal from '../Store';
import Config from '../Config';
import { Button, Typography } from 'antd';
import { Center } from '@trinity-incyte/ui';
import { CheckOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const DisclaimerDiv = styled.div`
  padding: 1rem;
  margin-top: 0px;
  overflow: hidden;
  height: 90vh;
  font-family: "QlikView Sans", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  text-align: center;
  background-color: black;
`;

const AcceptButton = styled(Button)`
  font-weight: bold; 
  background-color: #21BA45; 
  border-color: #21BA45; 
  border-radius: .28571429rem; 
  color: white; 
`;

const Disclaimer = () => {
  const [, globalActions] = useGlobal();

  return (
    <>
      <DisclaimerDiv>
        <Center centering='both'>
          <Typography.Title  style={{ color: '#595959' }} level={2}>Legal Disclaimer</Typography.Title>

          <p style={{ color: 'white' }}>{`${Config.App.name} and the content you are about to access is for your use only and is not to be discussed, printed, exported, downloaded or shown to anyone outside of the company. The information contained within ${Config.App.name} is to be used consistent with the ${Config.App.name} Training, applicable Incyte Policies, and the Code of Conduct.`}</p>

          <br />
          <AcceptButton
            onClick={globalActions.UI.toggleDisclaimerAccept}
          >
            <CheckOutlined style={{color:'white', fontWeight:'bold'}}/> Accept
          </AcceptButton>
        </Center>
      </DisclaimerDiv>
    </>
  );
};

export default Disclaimer;
