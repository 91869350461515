import { Col, Row, Button } from 'antd';
import React, { useEffect, useState, useContext, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import styled from 'styled-components';
import {
    activeTeam as activeTeamAtom,
    filtersIsOpenState,
    filtersTransitionEasingState,
    filtersTransitionTimingState,
    QlikUser as QlikUserAtom,
    screenOrientationState,
    showFiltersState,
    customFiltersState,
		QSAppMetadataFamily
} from '@trinity-incyte/recoil';
import useGlobal from '@trinity-incyte/store';
import Utils, { convertTeam } from '@trinity-incyte/utils';
import { ConfigContext } from '@trinity-incyte/context';
import QSMashupObject from '../../Qlik/qsmashup-object';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

const MainContainer = styled.div`
	background: whitesmoke;
	position: absolute;
	margin-right: auto !important;
	margin-left: auto !important;
	max-width: 100% !important;
	top: 0;
	right: 0;
	z-index: 200;
	display: flex;
	flex-flow: column;
`;

const FilterContainer = styled.div`
	display: flex;
	flex-flow: column;
	height: 100%;
`;

const ClearButton = styled(Button)`
	z-index: 200;
	color: white;
	background-color: #1abc9c;
	border-color: #1abc9c;
	font-size: 1rem;
	font-family: Roboto;
	font-weight: 700;
`;

const CloseButton = styled(Button)`
	z-index: 200;
	background-color: black;
	border-color: black;
	color: white;
	font-size: 1rem;
	font-family: Roboto;
	font-weight: 700;
`;

/* eslint-disable-next-line */
export interface FilterSidebarProps {
    useTeamBasedFilter?: boolean;
    hideSelections?: boolean;
}

export function FilterSidebar({useTeamBasedFilter, hideSelections}: FilterSidebarProps) {
    const [, globalActions] = useGlobal();
	const Config = useContext(ConfigContext);
    const activeTeam = useRecoilValue(activeTeamAtom);
    const [buttonHeight, setButtonHeight] = useState<number>();
    const filtersIsOpen = useRecoilValue(filtersIsOpenState);
    const showFilters = useRecoilValue(showFiltersState);
    const customFilters = useRecoilValue(customFiltersState);
    const resetFilterState = useResetRecoilState(showFiltersState);
    const filtersTransitionEasing = useRecoilValue(
        filtersTransitionEasingState
    );
    const filtersTransitionTiming = useRecoilValue(
        filtersTransitionTimingState
    );
    const screenOrientation = useRecoilValue(screenOrientationState);
    const QlikUser = useRecoilValue(QlikUserAtom);
    const location = useLocation();

	useEffect(() => {
		return () => resetFilterState(); //When the component is unmounted
	}, []);


	const screen = Utils.screen(Config);
	const config = useTeamBasedFilter ? Config.Qlik.Prof360 : Config.Qlik.FieldSales;
	const { appId } = config.ids;
	const metadata = useRecoilValue(QSAppMetadataFamily(appId));
  	const { IDsTable: ids, FieldsTable: fields } = metadata;
	const sidebarWidth = 28;
	let filterFields: any;

	if (activeTeam) {
		filterFields = (
			<FilterContainer>
				{QlikUser.showSidebarFilters &&
					((!useTeamBasedFilter ? (
						<>
							{activeTeam === 'HO1' && (
								<>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic_Filters_filterpane_HO1 Territory Filter')?.id} // Territory
									/>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic_Filters_filterpane_HO1 Region Filter')?.id} // Region
									/>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic_Filters_filterpane_HO1 Area Filter')?.id} // Area
									/>
								</>
							)}
							{activeTeam === 'HO2' && (
								<>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic_Filters_filterpane_HO2 Territory Filter')?.id} // Territory
									/>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic_Filters_filterpane_HO2 Region Filter')?.id} // Region
									/>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic_Filters_filterpane_HO2 Area Filter')?.id} // Area
									/>
								</>
							)}
							{activeTeam === 'HO3' && (
								<>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic_Filters_filterpane_HO3 Territory Filter')?.id} // Territory
									/>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic_Filters_filterpane_HO3 Region Filter')?.id} // Region
									/>
								</>
							)}
							{activeTeam === 'OCNE' && (
								<>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic_Filters_filterpane_OCNE Territory Filter')?.id} // Territory
									/>
								</>
							)}
						</>
					) : (
						// listbuilder/HCP 360 conditions
						<>
							{activeTeam === 'HO1' && (
								<>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic HCP 360_Filters_filterpane_HO1 Territory Filter')?.id} // Territory
									/>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic HCP 360_Filters_filterpane_HO1 Region Filter')?.id} // Region
									/>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic HCP 360_Filters_filterpane_HO1 Area Filter')?.id} // Area
									/>
								</>
							)}
							{activeTeam === 'HO2' && (
								<>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic HCP 360_Filters_filterpane_HO2 Territory Filter')?.id} // Territory
									/>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic HCP 360_Filters_filterpane_HO2 Region Filter')?.id} // Region
									/>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic HCP 360_Filters_filterpane_HO2 Area Filter')?.id} // Area
									/>
								</>
							)}
							{activeTeam === 'HO3' && (
								<>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic HCP 360_Filters_filterpane_HO3 Territory Filter')?.id} // Territory
									/>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic HCP 360_Filters_filterpane_HO3 Region Filter')?.id} // Region
									/>
								</>
							)}
							{activeTeam === 'OCNE' && (
								<>
									<QSMashupObject
										contentStyle={{ height: '100%', margin: 0 }}
										appId={appId}
										mashupId={ids.get('Mosaic HCP 360_Filters_filterpane_OCNE Territory Filter')?.id} // Territory
									/>
								</>
							)}
						</>
					))
				)}
			</FilterContainer>
		);
	}

	// This ensures fields get closed *after* the sidebar is hidden
	if (showFilters === false && filtersIsOpen === false) {
			filterFields = [];
	} else if (customFilters) {
			filterFields = <FilterContainer>
					{customFilters}
			</FilterContainer>;
	}

	const content = (
		<MainContainer
			className="filter-sidebar"
			style={{
				transition: `all ${
					filtersTransitionTiming / 1000
				}s ${filtersTransitionEasing}`,
				transform: `translateX(${showFilters ? 0 : '100%'})`,
				width: `${sidebarWidth}vw`,
				zIndex: 200,
			}}
		>
			{/* <DateSelectionButtons config={config} /> */}
			<Row style={{display: 'flex', flexFlow:'nowrap'}}>
				<ClearButton
					className="square-corners clear-button"
					style={{
						width: `${sidebarWidth/2}vw`
					}}
					onClick={() => {
						const isDerm = Config.App.name === "MosaicDerm";
						const app = Mosaic.Qlik.app[appId];
						if (isDerm) {
							// there are no friendly names for Derm in Mosaic
							app.field('DSFC.DIST_NAME_WITH_MGR').clear()
							app.field('DSFC.TERR_NAME_WITH_REP').clear()
						} else {
							// H01
							app.field(fields.get('Mosaic_Field Sales_Region HO1')?.id).clear()
							app.field(fields.get('Mosaic_Field Sales_Territory HO1')?.id).clear()
							app.field(fields.get('Mosaic_Field Sales_Area HO1')?.id).clear()
							// H02
							app.field(fields.get('Mosaic_Field Sales_Region HO2')?.id).clear()
							app.field(fields.get('Mosaic_Field Sales_Territory HO2')?.id).clear()
							// H03
							app.field(fields.get('Mosaic_Field Sales_Region HO3')?.id).clear()
							app.field(fields.get('Mosaic_Field Sales_Territory HO3')?.id).clear()
						}
					}}
				>
					Clear
				</ClearButton>
				<CloseButton
					color="black"
					className="square-corners sidebar-close-button"
					style={{
						width: `${sidebarWidth/2}vw`,
					}}
					onClick={() => {
						resetFilterState();
					}}
				>
					Close
				</CloseButton>
			</Row>
			<Row style={{ height:  '100%' }}>
				<Col flex="auto">
					{filterFields}
				</Col>
			</Row>
		</MainContainer>
	);

	return showFilters && content;
};

export default FilterSidebar;
