import React from 'react';
import styled from 'styled-components';

interface LeftCornerProps {
  readonly sashWidth: string;
  readonly sashColor: string;
  readonly sashMargin: string;
}

const LeftCorner = styled.div<LeftCornerProps>`
  position: absolute;
  margin: ${props => props.sashMargin || '0'};
  padding: 0;
  width: ${props => props.sashWidth || '4rem'};
  height: ${props => props.sashWidth || '4rem'};
  overflow: hidden;
  border-color: ${props => props.sashColor || 'violet'} !important;
  color: #fff!important;
  background-color: transparent!important;
  z-index: 1;
  border: 0 solid transparent;

  &:before {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    z-index: -1;
    width: 0;
    height: 0;
    background-color: transparent!important;
    border-top: ${props => props.sashWidth || '4rem'} solid transparent;
    border-right: ${props => props.sashWidth || '4rem'} solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 0 solid transparent;
    border-top-color: inherit;
    -webkit-transition: border-color .1s ease;
    transition: border-color .1s ease;
  }
`;


interface TextWrapperProps {
  readonly textMargin: string;
  readonly textFontSize: string;
}

const TextWrapper = styled.div<TextWrapperProps>`
  cursor: default;
  position: relative;
  top: ${props => props.textMargin || '0.65rem'};
  left: ${props => props.textMargin || '0.65rem'};
  font-size: ${props => props.textFontSize || '1.25rem'};
  margin: 0;
`;

/* eslint-disable-next-line */
export interface SashProps {
  text: any;
  size?: 'sm' | 'md' | 'lg';
  color: string;
  corner?: 'left' | 'right' | 'bottom-left' | 'bottom-right'; // TODO: implement other corners
  margin?: string;
}

export function Sash({
  text,
  size = 'lg',
  color = '#8CAAC6',
  corner,
  margin,
}: SashProps) {

  let width;
  let textMargin;
  let textFontSize;

  if (size === 'sm') {
    width = '3rem';
    textMargin = '0.33rem';
    textFontSize = '0.9rem';
  } else if (size === 'md') {
    width = '4rem';
    textMargin = '0.5rem';
    textFontSize = '1.1rem';
  } else if (size === 'lg') {
    width = '5rem';
    textMargin = '0.75rem';
    textFontSize = '1.4rem';
  }

  return (
    // <div style={{
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   height: '6rem',
    //   width: '6rem',
    //   clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
    //   backgroundColor: color,
    // }}>
    <LeftCorner
      sashWidth={width}
      sashColor={color}
      sashMargin={margin}
    >
      <TextWrapper
        textMargin={textMargin}
        textFontSize={textFontSize}
      >
      {text}
      </TextWrapper>
    </LeftCorner>
  );
}

export default Sash;
