import React from 'react';
import useGlobalHook from 'use-global-hook';
import * as Actions from './lib';

const initialState = {
    Qlik: {
        isActiveSession: false,
        cannotAcquireRequire: false,
        qlik: false,
        isSelectedCenter: false,
        isSelectedStaff: false,
        app: {},
        lastModified: Date.now(),
        activeBrand: 'MONJUVI',
        userEmpType: false,
        userAccessType: false,
        dataDateRetail: '-',
    },
    UI: {},
};

const useGlobal = useGlobalHook(React, initialState, Actions);

export default useGlobal;
