/**
 * QS Selections Listener
 * This silently binds to the open Qlik App and syncs selection state to the react app so components can be QS-selection aware
 */

import { MosaicGlobal } from '@trinity-incyte/api-interfaces';
import React, { useEffect } from 'react';
import useGlobal from '../../Store';

declare var Mosaic: MosaicGlobal;

const QSSelectionsListener = ( props ) => {
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        if ( globalState.Qlik.qlik ) { // If Qlik has been loaded and connection established
            if ( globalState.Qlik.app[props.appId] && globalState.Qlik.app[props.appId].isLoaded ) {
                // If the specific app has been loaded
                const app = Mosaic.Qlik.app[props.appId];
                const selState = app.selectionState( );
                const listener = () => {
                    globalActions.Qlik.updateAppSelections( props.appId, selState.selections );
                };
                selState.addOnDataListener( listener );

                return () => {
                    selState.removeOnDataListener( listener );
                };
            }
        }
    }, []); // eslint-disable-line

    return (
	    <span data-qsdsa="SelectionsListener" style={{ display: 'none' }} />
    );
};

export default QSSelectionsListener;
