import { useFilter } from '@trinity-incyte/hooks';
import { rowFiltersState } from '@trinity-incyte/recoil';
import { WithColor } from '@trinity-incyte/ui';
import Utils from '@trinity-incyte/utils';
import {
    Badge,
    Calendar,
    Col,
    Divider,
    Drawer,
    Radio,
    Row,
    Select,
    Button
} from 'antd';
import React, {
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

const FlexContainer = styled.div`
    display: flex;
    flex-flow: column;
    height: 100%;
    align-items: stretch;
`;

// Remove filter for items with too few options
const filterVisibleFilters = (options) => {
    Object.keys(options).forEach((key) => {
        const filterOptionsCount = Object.keys(options[key]).filter(
            (value) => value !== '-'
        ).length;
        if (filterOptionsCount > 1 && filterOptionsCount < 3) {
            options[key] = {};
        }
    });
    return options;
};

/* eslint-disable-next-line */
export interface MosaicCalendarProps {
    events: { array: any[] };
    EventsList: any;
    header?: ReactNode;
}

export function MosaicCalendar({
    header,
    events,
    EventsList,
}: MosaicCalendarProps) {
    const [selectedCompany, set_selectedCompany] = useState<boolean | string>(
        false
    );
    const [selectedDate, set_selectedDate] = useState(Utils.moment());
    const [defaultYear] = useState(selectedDate.year());
    const [defaultMonth] = useState(selectedDate.month());
    const [selectedTeam, set_selectedTeam] = useState<boolean | string>(false);
    const [selectedType, set_selectedType] = useState<boolean | string>(false);
    const [metaDrawer, set_metaDrawer] = useState({
        isVisible: false,
        content: null,
        title: '',
    });
    const [mode, set_mode] = useState('day');
    const [datedEvents, set_datedEvents] = useState({});
    const [monthEvents, set_monthEvents] = useState({});
    const [fromTable, set_fromTable] = useRecoilState(rowFiltersState);
    const containerRef: any = useRef();

    const filterEvents = useFilter({
        fromTable,
        set_selectedCompany,
        set_selectedTeam,
        set_selectedType,
        hideCount: true,
    });

    const onPanelChange = useCallback(
        (date, mode) => {
            set_mode(mode === 'year' ? 'month' : 'day');
        },
        [mode]
    );

    const onSelect = useCallback(
        (date) => {
            const dateEvents = events.array.reduce((acc, curr) => {
                if (date.isSame(curr.date, mode)) {
                    if (selectedCompany && curr.companyName !== selectedCompany)
                        return acc;
                    if (selectedTeam && curr.teamName !== selectedTeam)
                        return acc;
                    if (selectedType && curr.filterType !== selectedType)
                        return acc;

                    acc.push(curr);
                }
                return acc;
            }, []);
            set_selectedDate(date);
            set_metaDrawer({
                isVisible:
                    mode === 'day'
                        ? selectedDate.month() === date.month()
                        : true,
                title: `Events ${mode === 'day' ? 'on' : 'in'} ${date.format(
                    mode === 'day' ? 'MMMM Do, YYYY' : 'MMMM YYYY'
                )}`,
                content: (
                    <EventsList
                        events={dateEvents.sort(
                            ({ date: date1 }, { date: date2 }) =>
                                date1.isBefore(date2)
                                    ? 1
                                    : date1.isAfter(date2)
                                    ? -1
                                    : 0
                        )}
                    />
                ),
            });
        },
        [
            selectedDate,
            metaDrawer,
            mode,
            events,
            selectedCompany,
            selectedTeam,
            selectedType,
        ]
    );

    const dateFullCellRender = useCallback(
        (value) => {
            const dateEvents = datedEvents[value.format('YYYY_MM_DD')] || {};
            const isWeekend = value.day() === 0 || value.day() === 6;
            const currentMonth = selectedDate ? selectedDate.month() : -1;
            const isCurrentMonth = value.month() === currentMonth;
            return (
                <div
                    className={`calendar-cell-container ${
                        isWeekend ? 'weekend' : 'weekday'
                    }`}
                >
                    <div className="calendar-cell-title">
                        <Divider
                            orientation="right"
                            style={{
                                margin: '4px',
                                fontWeight: isCurrentMonth ? 'bold' : 'lighter',
                            }}
                        >
                            {value.format('MMM Do')}
                        </Divider>
                    </div>
                    <div className="calendar-cell-content">
                        <ul className="events">
                            {Object.keys(dateEvents).map((activity) => {
                                const item = dateEvents[activity];
                                let activityUpdated = (item.count >= 2 && !activity.includes('('))
                                    ? activity + 's'
                                        : (item.count >= 2 && activity.includes('Email'))
                                            ? activity.replace('Email', 'Emails')
                                                : activity;
                                return (
                                    <li key={activity}>
                                        <Badge
                                            status={item.status}
                                            text={`${item.count} ${activityUpdated}`}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            );
        },
        [datedEvents, selectedDate]
    );

    const monthCellRender = useCallback(
        (value) => {
            const monthlyEvents = monthEvents[value.format('MMMM_YY')] || {};
            return (
                <div className="notes-month">
                    <ul className="events">
                        {Object.keys(monthlyEvents).map((activity) => {
                            const item = monthlyEvents[activity];
                            let activityUpdated = (item.count >= 2 && !activity.includes('('))
                                ? activity + 's'
                                    : (item.count >= 2 && activity.includes('Email'))
                                        ? activity.replace('Email', 'Emails')
                                            : activity;
                            return (
                                <li key={activity}>
                                    <Badge
                                        status={item.status}
                                        text={`${item.count} ${activityUpdated}`}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        },
        [monthEvents]
    );

    const fromDate = Utils.moment().subtract(8, 'weeks');
    const toDate = Utils.moment().add(8, 'weeks');

    const onClose = () => {
        set_metaDrawer({
            ...metaDrawer,
            isVisible: false,
        });
    };
    useEffect(() => {
        const { tempDatedEvents, tempMonthEvents } = events.array.reduce(
            (acc, event) => {
                if (!acc.tempDatedEvents[event.date.format('YYYY_MM_DD')]) {
                    acc.tempDatedEvents[event.date.format('YYYY_MM_DD')] = {};
                }
                if (!acc.tempMonthEvents[event.date.format('MMMM_YY')]) {
                    acc.tempMonthEvents[event.date.format('MMMM_YY')] = {};
                }

                if (selectedCompany && event.companyName !== selectedCompany)
                    return acc;
                if (selectedTeam && event.teamName !== selectedTeam) return acc;
                if (selectedType && event.filterType !== selectedType)
                    return acc;

                if (
                    acc.tempDatedEvents[event.date.format('YYYY_MM_DD')][
                        event.activity
                    ]
                ) {
                    acc.tempDatedEvents[event.date.format('YYYY_MM_DD')][
                        event.activity
                    ].count += 1;
                } else {
                    acc.tempDatedEvents[event.date.format('YYYY_MM_DD')][
                        event.activity
                    ] = {
                        count: 1,
                        status: 'success',
                    };
                }
                if (
                    acc.tempMonthEvents[event.date.format('MMMM_YY')][
                        event.activity
                    ]
                ) {
                    acc.tempMonthEvents[event.date.format('MMMM_YY')][
                        event.activity
                    ].count += 1;
                } else {
                    acc.tempMonthEvents[event.date.format('MMMM_YY')][
                        event.activity
                    ] = {
                        count: 1,
                        status: 'success',
                    };
                }
                return acc;
            },
            {
                tempDatedEvents: {},
                tempMonthEvents: {},
            }
        );

        set_datedEvents(tempDatedEvents);
        set_monthEvents(tempMonthEvents);
    }, [events, selectedCompany, selectedTeam, selectedType]);

    useEffect(() => {
        set_fromTable(
            filterVisibleFilters(
                events.array.reduce(
                    (acc, row) => {
                        const { filterType, companyName, teamName } = row;
                        acc.types[filterType] = acc.types[filterType] || 0;
                        acc.companies[companyName] =
                            acc.companies[companyName] || 0;
                        acc.teams[teamName] = acc.teams[teamName] || 0;
                        acc.totalCount = acc.totalCount || {
                            All: 0,
                            teams: {},
                            companies: {},
                            types: {},
                        };
                        if (selectedType === filterType) {
                            acc.types.All += 1;
                            acc.types[filterType] =
                                acc.types[filterType] + 1 || 1;
                        } else if (!selectedType) {
                            acc.types.All += 1;
                            acc.types[filterType] =
                                acc.types[filterType] + 1 || 1;
                        }
                        if (selectedCompany === companyName) {
                            acc.teams.All += 1;
                            acc.teams[teamName] = acc.teams[teamName] + 1 || 1;
                        } else if (!selectedCompany) {
                            acc.teams.All += 1;
                            acc.teams[teamName] = acc.teams[teamName] + 1 || 1;
                        }
                        if (selectedTeam === teamName) {
                            acc.companies.All += 1;
                            acc.companies[companyName] =
                                acc.companies[companyName] + 1 || 1;
                        } else if (!selectedTeam) {
                            acc.companies.All += 1;
                            acc.companies[companyName] =
                                acc.companies[companyName] + 1 || 1;
                        }
                        if (
                            (!selectedType || filterType === selectedType) &&
                            (!selectedCompany ||
                                companyName === selectedCompany) &&
                            (!selectedTeam || teamName === selectedTeam)
                        ) {
                            acc.totalCount.All += 1;
                            if (
                                !selectedCompany ||
                                companyName === selectedCompany
                            ) {
                                acc.totalCount.companies[companyName] =
                                    (acc.totalCount.companies[companyName] ||
                                        0) + 1;
                            }
                            if (!selectedTeam || teamName === selectedTeam) {
                                acc.totalCount.teams[teamName] =
                                    (acc.totalCount.teams[teamName] || 0) + 1;
                            }
                            if (!selectedType || filterType === selectedType) {
                                acc.totalCount.types[filterType] =
                                    (acc.totalCount.types[filterType] || 0) + 1;
                            }
                        }
                        return acc;
                    },
                    {
                        companies: { All: 0 },
                        teams: { All: 0 },
                        types: { All: 0 },
                    }
                )
            )
        );
    }, [selectedCompany, selectedTeam, selectedType, events]);

    useEffect(() => {
        if (metaDrawer.isVisible) {
            // Update the event list to add the newly added events for the specific day/month
            onSelect(selectedDate);
        }
    }, [events]);

    const headerRender = useCallback(
        ({ value, type, onChange, onTypeChange }) => {
            const start = 0;
            const end = 12;
            const monthOptions = [];

            const current = value.clone();
            const localeData = value.localeData();
            const months = [];

            for (let index = start; index < end; index++) {
                current.month(index);
                months.push(localeData.monthsShort(current));
                monthOptions.push(
                    <Select.Option
                        className="month-item"
                        key={`${index}`}
                        value={index}
                    >
                        {months[index]}
                    </Select.Option>
                );
            }
            const options = [];
            const year = defaultYear;
            if (defaultMonth + 2 > 11 || defaultMonth - 2 < 0) {
                let i;
                if (defaultMonth + 2 > 11) {
                    i = year + 1;
                    options.push(
                        <Select.Option key={i} value={i}>
                            {i}
                        </Select.Option>
                    );
                }
                i = year;
                options.push(
                    <Select.Option key={i} value={i}>
                        {i}
                    </Select.Option>
                );
                if (defaultMonth - 2 < 0) {
                    i = year - 1;
                    options.push(
                        <Select.Option key={i} value={i}>
                            {i}
                        </Select.Option>
                    );
                }
            }
            return (
                <div style={{ padding: 8 }}>
                    <Row wrap={false} justify="space-between" align="middle">
                        <Col flex="auto">{filterEvents}</Col>
                        <Col flex="none">
                            <Row gutter={8} justify="end">
                                {!!options.length && (
                                    <Col>
                                        <Select
                                            size="middle"
                                            dropdownMatchSelectWidth={false}
                                            onChange={(newYear) => {
                                                const newValue = value
                                                    .clone()
                                                    .year(newYear);
                                                onChange(newValue);
                                            }}
                                            value={value.year()}
                                        >
                                            {options}
                                        </Select>
                                    </Col>
                                )}
                                <Col>
                                    <Select
                                        size="middle"
                                        dropdownMatchSelectWidth={false}
                                        value={value.month()}
                                        onChange={(selectedMonth) => {
                                            const newValue = value.clone();
                                            newValue.month(
                                                parseInt(selectedMonth, 10)
                                            );
                                            onChange(newValue);
                                        }}
                                    >
                                        {monthOptions}
                                    </Select>
                                </Col>
                                <Col>
                                    <Radio.Group
                                        size="middle"
                                        onChange={({ target: { value } }) =>
                                            onTypeChange(value)
                                        }
                                        value={type}
                                    >
                                        <Radio.Button value="month">
                                            Month
                                        </Radio.Button>
                                        <Radio.Button value="year">
                                            Year
                                        </Radio.Button>
                                    </Radio.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            );
        },
        [filterEvents]
    );
    return (
        <div style={{ height: '100%' }}>
            {header}
            <div
                className="events"
                ref={containerRef}
                style={{ height: '100%' }}
            >
                <Calendar
                    dateFullCellRender={dateFullCellRender}
                    monthCellRender={monthCellRender}
                    onPanelChange={onPanelChange}
                    onSelect={onSelect}
                    validRange={[fromDate, toDate]}
                    style={{
                        height: '100%',
                    }}
                    headerRender={headerRender}
                />
                <Drawer
                    title={
                        <>
                            {metaDrawer.title}
                            <WithColor color="black"  textColor="white" component={
                                <Button
                                    style={{ float: 'right' }}
                                    className="square-corners"
                                    onClick={onClose}
                                >
                                    Close
                                </Button>}/>
                        </>
                    }
                    afterVisibleChange={(visible) => {
                        if (!visible) {
                            set_metaDrawer({
                                isVisible: false,
                                content: null,
                                title: '',
                            });
                        }
                    }}
                    placement="bottom"
                    closable={false}
                    onClose={onClose}
                    visible={metaDrawer.isVisible}
                    getContainer={false}
                    height={Math.floor(window.innerHeight * 0.8)}
                    headerStyle={{ fontWeight: 'bold' }}
                    style={{ position: 'absolute' }}
                >
                    <FlexContainer>{metaDrawer.content}</FlexContainer>
                </Drawer>
            </div>
        </div>
    );
}

export default MosaicCalendar;
