import React from 'react';
import './kpihome-header.module.scss';

/* eslint-disable-next-line */

export interface KPIHomeHeaderProps {
  color?: '#595959';
  children?: any;
  text?: string;
  style?: object;
}

export function KPIHomeHeader({ children }: KPIHomeHeaderProps) {
  const headerStyle = {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: 1.4,
    color: '#595959'
  };
  return (
    <h2 style={headerStyle}>
      {children}
    </h2>
  );
}

export default KPIHomeHeader;
