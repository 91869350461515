import { useEffect, useState } from 'react';

/* eslint-disable-next-line */
export interface useQSGenericProps {
  definition: any;
  app: any;
}

export function useQSGeneric({ definition, app }: useQSGenericProps): any {
  const [genericObject, set_genericObject] = useState(null);
  const [content, set_content] = useState(null);

  useEffect(() => {
    set_genericObject(
      app.createGenericObject({
        ...definition,
        timestamp: Date.now(), 
      })
    );

    return () => {
      if (genericObject && genericObject.close) genericObject.close();
    }
  }, []);

  useEffect(() => {
    if (!genericObject) return;
    genericObject
      .then(({ layout }) => {
        set_content(layout);
      });
  }, [genericObject]);

  return content;
}

export default useQSGeneric;
