import React, { useContext } from 'react';
import { ConfigContext } from '@trinity-incyte/context';
import QSMashupObject from '../../Qlik/qsmashup-object';
import { Row } from 'antd';

import './speaker-programs.module.scss';
import { useSelections } from '@trinity-incyte/hooks';
import { useRecoilValue } from 'recoil';
import { QSAppMetadataFamily } from '@trinity-incyte/recoil';

/* eslint-disable-next-line */
export interface SpeakerProgramsProps {
  profId: any;
}

export function SpeakerPrograms(props: SpeakerProgramsProps) {
	const Config = useContext(ConfigContext);
	const config = Config.Qlik.Prof360;
	const { appId, items } = config.ids;
  const {simpleSelections} = useSelections({config});
  const metadata = useRecoilValue(QSAppMetadataFamily(appId));
	const { IDsTable: ids } = metadata;

  let eventId; // Needed to update the Attendees table on external selection
  if (simpleSelections['SPKR.EVENT_ID']) {
    eventId = simpleSelections['SPKR.EVENT_ID'][0].qName;
  } else {
    eventId = 'null';
  }

  return (
    <div style={{ height: '100%' }}>
      <Row style={{ height: '50%' }}>
        <QSMashupObject
          appId={appId}
          elementId="speakerProgramsTable"
          mashupId={ids.get('Mosaic_HCP 360 - Incyte Activity_Speaker Programs - Click on an Event ID to see the attendees')?.id}
          isTable
          showExports
          tableProps={{
            colOverrides: [
              {
                columns: [0],
                hStyles: { width: '6rem' },
              }, {
                columns: [1],
                hStyles: { width: '20rem' },
              }, {
                columns: [2],
                hStyles: { width: '13rem' },
              }, {
                columns: [3],
                hStyles: { width: '14rem' },
              }, {
                columns: [4, 5],
                hStyles: { width: '8rem' },
              }, {
                columns: [6, 7],
                hStyles: { width: '11rem' },
              }, {
                columns: [8],
                hStyles: { width: '6.5rem' },
              },
            ]
          }}
          {...props}
        />
      </Row>
      <Row style={{ height: '50%' }}>
        <QSMashupObject
          hideSearch
          key={`attendees_${eventId}`}
          appId={appId}
          elementId="speakerAttendeesTable"
          mashupId={ids.get('Mosaic_HCP 360 - Incyte Activity_Attendees for Event ID')?.id}
          showExports
          tableProps={{
            colOverrides: [
              {
                columns: [0],
                hStyles: { width: '20rem' },
              }, {
                columns: [1],
                hStyles: { width: '7rem' },
              }, {
                columns: [2, 3, 4],
                hStyles: { width: '6rem' },
              },
            ],
          }}
          isTable
          {...props}
        />
      </Row>
    </div>
  );
}

export default SpeakerPrograms;