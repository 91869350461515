import React from "react";
import styled from "styled-components";

const StatisticContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const StatisticLabel = styled.div<StatisticProps>`
	font-size: 1em; 
	text-align: center;
	font-weight: 700;
	text-transform: uppercase;
  padding-top: 4px;
	color: ${props => props.inverted ? '#FFFFFFE6' :'#595959'};
`;

const HorizontalLabel = styled.div<StatisticProps>`
  font-size: 1em; 
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: ${props => props.inverted ? '#FFFFFFE6' :'#595959'};
  margin: 0 0 0 .75em;
`;

const StatisticValue = styled.div<StatisticProps>`
	font-weight: 400;
	font-size: ${props => props.size};
	text-transform: uppercase;
	line-height: 1.5rem;
	text-align: center;
  color: ${props => props.color || (props.inverted ? '#FFF' : 'black')};
`;

/* eslint-disable-next-line */
export interface StatisticProps {
  style?: any;
  valueStyle?: any;
  labelStyle?: any;
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'huge';
  color?: any;
  inverted?: boolean;
  horizontal?: boolean;
  label?: any;
  value?: any;
}

export function Statistic({
  style,
  valueStyle,
  labelStyle,
  size,
  color,
  inverted,
  horizontal,
  label,
  value
}: StatisticProps) {

  let textFontSize;
  if (size === 'mini') {
    textFontSize = "1.5rem";
  } else if (size === 'tiny') {
    textFontSize = "2rem";
  } else if (size === 'small') {
    textFontSize = "3rem";
  } else if (size === 'large') {
    textFontSize = "5rem";
  } else if (size === 'huge') {
    textFontSize = "6rem"
  } else {
    textFontSize = "4rem";
  };

  if (horizontal) {
    return (
      <StatisticContainer style={style}>
        <HorizontalLabel 
          style={labelStyle} 
          inverted={inverted}
        >
          {label}
        </HorizontalLabel>
        <StatisticValue 
          style={valueStyle} 
          color={color} 
          inverted={inverted} 
          size={textFontSize}
        >
          {value}
        </StatisticValue>
      </StatisticContainer>
    )
  } else {
    return (
      <div style={{ display: 'flex', flexFlow: 'column' }}>
        <StatisticLabel 
          style={labelStyle} 
          inverted={inverted}
        >
          {label}
        </StatisticLabel>
        <StatisticValue 
          style={valueStyle} 
          color={color} 
          inverted={inverted} 
          size={textFontSize}
        >
          {value}
        </StatisticValue>
      </div>
    )
  }
}

export default Statistic;
