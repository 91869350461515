import { 
  Col, 
  Modal as AntdModal, 
  Row, Typography, 
  Divider, 
  Form, 
  Button,
  Input,
  Select 
} from 'antd';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import Config from '../../../Config';
import useGlobal from '../../../Store';
import { degree_options } from '../degree_options';
import { role_options } from '../role_options';
import styled from 'styled-components';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

const HeaderDivider = styled(Divider)`
  font-family: "QlikView Sans", Lato;
  font-size: 1rem !important;
  font-weight: 700 !important;
  text-transform: uppercase;
`

const TableUrl = Config.App.azureTableUrl;
// const StackUrl = 'https://prod-58.eastus2.logic.azure.com:443/workflows/8279178c72c146ee98321fded1e5b548/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=EeBcJuopHj85Ii_5h3xMyFVkrdMCjM19U3PPRC1vcQ0';

declare var Mosaic: MosaicGlobal;
declare var WalkMeData: any;

/* eslint-disable-next-line */
export interface AddStaffModalFormProps {}

export function AddStaffModalForm(props: AddStaffModalFormProps) {
  const [globalState, globalActions] = useGlobal();
  const app = Mosaic.Qlik.app[Config.Qlik.app1.ids.appId];

  const [loading, set_loading] = useState(true);
  const [firstName, set_firstName] = useState<string>();
  const [lastName, set_lastName] = useState<string>();
  const [degree, set_degree] = useState<string>();
  const [role1, set_role1] = useState<string>();
  const [role2, set_role2] = useState<string>();
  const [role3, set_role3] = useState<string>();
  const [dea, set_dea] = useState<number | string>();
  const [npi, set_npi] = useState<number | string>();
  const [phonenumber, set_phonenumber] = useState<string>();
  const [faxnumber, set_faxnumber] = useState<string>();
  const [email, set_email] = useState<string>();
  const [orgId, set_orgId] = useState<string>();
  const [transplantName, set_transplatName] = useState();

  const submitForm = useCallback(async () => {
    set_loading(true);
    try {
      await axios({
        url: TableUrl,
        method: 'POST',
        timeout: 0,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          FirstName: firstName,
          LastName: lastName,
          UserID: WalkMeData?.UserID,
          Status: 'ACTIVE',
          Degree: degree,
          DEA: dea,
          NPI: npi,
          Role1: role1,
          Role2: role2,
          Role3: role3,
          RoleStatus1: role1 && 'ACTIVE',
          RoleStatus2: role2 && 'ACTIVE',
          RoleStatus3: role3 && 'ACTIVE',
          Direct_Num: phonenumber,
          Fax_Num: faxnumber,
          Email: email,
          OrgID: orgId,
          ProfID: '',
          ActionType: 'ADD',
        }),
      });

      AntdModal.success({
        title: 'Successful submission',
        content: (
          <>
            <Typography.Text strong>
              {lastName},{firstName} {degree}
            </Typography.Text>{' '}
            has been added as a staff member to{' '}
            <Typography.Text strong>
              {transplantName}
            </Typography.Text>
            . Please allow 1 business day for this to be available in BMT Mosaic.
          </>
        ),
      });
      globalActions.UI.closeModal();
      set_loading(false);
    } catch (error) {
      AntdModal.error({
        title: 'Failed Submission',
        content: (
          <>
            <Typography.Text strong>
              {lastName},{firstName} {degree}
            </Typography.Text>{' '}
            has not been added as a staff member. Please try again
          </>
        ),
      });
      globalActions.UI.closeModal();
      set_loading(false);
    } 
  }, [
    orgId,
    email,
    phonenumber,
    role1,
    role2,
    role3,
    degree,
    dea,
    npi,
    firstName,
    lastName,
  ]);

  useEffect(() => {
    set_loading(!transplantName);
  }, [transplantName]);

  useEffect(() => {
    app.visualization
      .get(Config.Qlik.app1.ids.masterItems['Transplant Center Text Box'])
      .then((qViz) => {
        qViz.table.addOnDataListener(() => {
          const [
            { qText: transplantCenter },
            { qText: sourceCity },
            { qText: sourceState },
            { qText: orgZip },
            { qText: unitType },
            { qText: activeAllogenicFlag },
            { qText: factApprovedAllogenic },
            { qText: yearOpenedAllogenic },
            { qText: nciMember },
            { qText: nccnMember },
            { qText: orgId },
            { qText: orgIdDw },
          ] = qViz.table.rows[0].cells;
          set_transplatName(transplantCenter);
          set_orgId(orgId);
        });
        qViz.table.getMoreData();
      });
  }, []);

  return (
    <>
      <div style={{ zIndex: 2000 }}>
        <h1>Add New Staff Member - {transplantName}</h1>
        <Form>
          <HeaderDivider>Bio Section</HeaderDivider>
          <Row gutter={[16, 16]} align="top">
            <Col span={12}>
              <Form.Item
                name="First Name"
                label="First Name"
                rules={[{ required: true }]}
              >
                <Input 
                  placeholder="First Name" 
                  value={firstName}
                  onChange={(data: any) =>
                    set_firstName(data.value)
                  } 
                />
              </Form.Item>
              <Form.Item
                name="Last Name"
                label="Last Name"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(data: any) =>
                    set_lastName(data.value)
                  }
                />
              </Form.Item>
              <Form.Item
                name="Degree"
                label="Degree"
                rules={[{ required: true }]}
              >
                <Select
                  options={degree_options}
                  defaultValue={degree}
                  placeholder="Degree"
                  onSelect={(data: any) =>
                    set_degree(data.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="DEA #"
              >
                <Input
                  placeholder="DEA"
                  onChange={(data: any) =>
                    set_dea(data.value)
                  }
                />
              </Form.Item>
              <Form.Item
                label="NPI #"
              >
                <Input
                  placeholder="NPI"
                  onChange={(data: any) =>
                    set_npi(data.value)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <HeaderDivider type="horizontal">Role Section</HeaderDivider>
          <Row gutter={[16, 16]} align="top">
            <Col span={12}>
              <Form.Item
                label="Role 1"
                rules={[{ required: true }]}
              >
                <Select
                  options={role_options}
                  defaultValue={role1}
                  onSelect={(data: any) =>
                    set_role1(data.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                label="Role 2"
              >
                <Select
                  options={role_options}
                  defaultValue={role2}
                  onChange={(data: any) =>
                    set_role2(data.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                label="Role 3"
              >
                <Select
                  options={role_options}
                  defaultValue={role3}
                  onChange={(data: any) =>
                    set_role3(data.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
          <HeaderDivider type="horizontal">Contact Information Section</HeaderDivider>
          <Row gutter={[16, 16]} justify="center" align="top">
            <Col span={12}>
              <Form.Item
                name="Direct #"
                label="Direct #"
              >
                <Input 
                  placeholder="Direct #" 
                  value={phonenumber}
                  onChange={(data: any) =>
                    set_phonenumber(data.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                name="Fax #"
                label="Fax #"
              >
                <Input
                  placeholder="Fax #"
                  value={faxnumber}
                  onChange={(data: any) =>
                    set_faxnumber(data.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                name="Email"
                label="Email"
              >
                <Input 
                  placeholder="Email" 
                  value={email} 
                  onChange={(data: any) =>
                    set_email(data.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row>
            <Form.Item label="" style={{paddingRight: '10px'}}>
              <Button htmlType="submit" type="primary" onClick={submitForm}>
                Submit
              </Button>
            </Form.Item>
            <Form.Item label="">
              <Button onClick={() => globalActions.UI.closeModal()} >
                Cancel 
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default AddStaffModalForm;
