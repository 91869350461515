import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
	AppState as AppStateAtom,
  QSAppMetadataFamily,
} from '@trinity-incyte/recoil';
import Utils from '@trinity-incyte/utils';
import { MosaicGlobal } from '@trinity-incyte/api-interfaces';

declare const window: any;
declare const Mosaic: MosaicGlobal;

export interface useAppMetadataProps {
  ids: any;
  app: any;
}

export function useAppMetadata ({ ids, app }: useAppMetadataProps) {
	const [AppState, set_AppState] = useRecoilState(AppStateAtom);
  const [appMetadata, set_appMetadata] = useState<boolean>(false);
  const set_QSAppMetadata = useSetRecoilState(QSAppMetadataFamily(ids.appId));

  useEffect(() => {
    if (app === null || appMetadata === true) return;

    const metadata = {
      FieldsTable: null,
      IDsTable: null,
      DimensionsList: null,
      MeasuresList: null,
      FieldsList: null,
      AlertsDismissals: null
    };

    const criticalDataFetchItems = [
      new Promise((resolve, reject) => {
        app.visualization.get(ids.items['Mashup Verify Object IDs'])
        .then((qViz) => {
          const onQVizData = () => {
            if (!qViz) return;
            const { rows, rowCount } = qViz.table;
            const { length } = rows;
            if (length !== rowCount) {
              qViz.table.getMoreData();
            } else {
              const newMap = new Map();
              rows.forEach((item) => {
                // Creates a variable named key whose value is either the value of qText in the result of the find call, or a blank object
                const { qText: key } = 
                  item.cells.find(
                    ({ qDimensionInfo: { qFallbackTitle } }) =>
                      qFallbackTitle === '%QlikSenseFriendlyName'
                  ) || {};
                const { qText: id } =
                  item.cells.find(
                    ({ qDimensionInfo: { qFallbackTitle } }) =>
                      qFallbackTitle === '%QlikSenseObjectId'
                  ) || {};
                const { qText: description } =
                  item.cells.find(
                    ({ qDimensionInfo: { qFallbackTitle } }) =>
                      qFallbackTitle === '%QlikSenseObjectDescription'
                  ) || {};
                newMap.set(key, {
                  id,
                  description,
                });
              });
              metadata.IDsTable = newMap;
              qViz.close();
              resolve(true);
            }
          };

          qViz.table.addOnDataListener(onQVizData);
          qViz.table._instance.getMoreData = Utils.generateGetMoreData();
          qViz.table.getMoreData();
        })
        .catch((err) => reject(err));
      }),
      new Promise((resolve, reject) => {
       app.visualization.get(ids.items['Mashup Verify Fields'])
       .then((qViz) => {
         const onQVizData = () => {
           if (!qViz) return;
           const { rows, rowCount } = qViz.table;
           const { length } = rows;

           if (length !== rowCount) {
             qViz.table.getMoreData();
           } else {
             const newMap = new Map();
             rows.forEach((item) => {
               const { qText: key } =
                 item.cells.find(
                   ({ qDimensionInfo: { qFallbackTitle } }) =>
                     qFallbackTitle === '%QlikSenseFriendlyFieldName'
                 ) || {};
               const { qText: id } =
                 item.cells.find(
                   ({ qDimensionInfo: { qFallbackTitle } }) =>
                     qFallbackTitle === '%QlikSenseFieldName'
                 ) || {};
               const { qText: description } =
                 item.cells.find(
                   ({ qDimensionInfo: { qFallbackTitle } }) =>
                     qFallbackTitle === '%QlikSenseFieldDescription'
                 ) || {};
               newMap.set(key, {
                 id,
                 description,
               });
             });
             metadata.FieldsTable = newMap;
             qViz.close();
             resolve(true);
           }
         };
   
         qViz.table.addOnDataListener(onQVizData);
         qViz.table._instance.getMoreData = Utils.generateGetMoreData();
         qViz.table.getMoreData();
       })
       .catch((err) => reject(err));
      }),

    ];

    // This may work but is not going to be true for the 360-only app
    const shouldDoAllSettled = (Mosaic.Qlik.criticalDataFetchQueue.length === 0) ? false : true;

    criticalDataFetchItems.forEach((val) => {
      Mosaic.Qlik.criticalDataFetchQueue.push(val);
    });

    // Set the QS metadata for this particular app
    Promise.allSettled(criticalDataFetchItems)
      .then(() => {
        set_QSAppMetadata(metadata);
      });

    // If this is the last of multiple apps, then as soon as all data fetch is complete, set app state
    if (shouldDoAllSettled) Promise.allSettled<PromiseSettledResult<any>>(Mosaic.Qlik.criticalDataFetchQueue)
    .then((results) => {
      if (results.some(result => result.status === 'rejected')) {
        set_AppState({
          state: 'ERROR',
          error: 'NO PERMISSION',
        });

      } else {
        set_AppState({
          state: 'ALL GOOD',
          error: false,
        });
      }
    });

  }, [app]);

  return null;
}

export default useAppMetadata;