/*
 * Note: limited to 1000 rows, don't go wild with these
 */
import { useState, useEffect, useContext, useCallback } from 'react';
import {useRecoilValue} from 'recoil';
import {
	QlikLastModified as QlikLastModifiedAtom
} from '@trinity-incyte/recoil';
import { ConfigContext } from '@trinity-incyte/context';
import { MosaicGlobal, QlikModel, QlikModelFacade, QlikVisualizationInstance } from '@trinity-incyte/api-interfaces';

declare const Mosaic: MosaicGlobal;

/* eslint-disable-next-line */
export interface useQSListboxProps {
	config: any,
	definition: string[],
	title: string,
	options?: any,
	onObject?: Function,
	single?: boolean;
	searchText?: string;
}

export function useQSListbox({
	config,
	definition,
	title,
	options = {},
	onObject = (object) => {},
	single = false,
	searchText = '',
}: useQSListboxProps) {
	const Config = useContext(ConfigContext);
	const QlikLastModified = useRecoilValue(QlikLastModifiedAtom);
	const [qsObject, set_qsObject] = useState<QlikVisualizationInstance>(null);
	const [rows, set_rows] = useState({array: []});

	const { appId } = config.ids;
	const app = Mosaic.Qlik.app[appId];

	const cellSelect = (cell, model: QlikModel) => {
		return model.getListObjectData(
			'/qListObjectDef',
			[{
				qTop: 0,
				qLeft: 0,
				qHeight: 1000,
				qWidth: 1
			}]
		)
		.then(dataPage => {
			const selectedCells = dataPage[0].qMatrix.filter((curr) => curr[0].qState === Config.Qlik.qStateValues.SELECTED).map((elem) => elem[0].qElemNumber);
			model.selectListObjectValues(
				'/qListObjectDef',
				single ? [cell[0].qElemNumber] : [...selectedCells, cell[0].qElemNumber],
				false
			);
		});
	};

	const cellUnselect = (cell, model) => {
		return model.getListObjectData(
			'/qListObjectDef',
			[{
				qTop: 0,
				qLeft: 0,
				qHeight: 1000,
				qWidth: 1
			}]
		)
		.then(dataPage => {
			const cellsToSelect = dataPage[0].qMatrix.reduce((acc, curr) => {
				if (curr[0].qState === Config.Qlik.qStateValues.SELECTED
					&& curr[0].qElemNumber !== cell[0].qElemNumber) {
						acc.push(curr[0].qElemNumber);
				}
				return acc;
			}, []);
			model.selectListObjectValues(
				'/qListObjectDef',
				cellsToSelect,
				false
			);
		});
	};

	
	const getData = useCallback((top) => {
		if (!qsObject?.model) return;
		const getIt = () =>
			qsObject.model
				.getListObjectData('/qListObjectDef', [{
					qTop: top,
					qLeft: 0,
					qHeight: 1000,
					qWidth: 1,
				}])
				.then((dataPage) => {
					// NOTE! This modifies the return from QS and is *NOT* intuitive
					// This was requested to handle edge cases of dirty data from the QS side
					const newDataArray = dataPage[0]?.qMatrix.reduce(
						(acc, curr) => {
							if (curr[0].qText || !isNaN(curr[0].qNum)) {
								acc.push({
									model: qsObject.model,
									clear: () => (qsObject.model.clearSelections(
										'/qListObjectDef'
									)),
									select: () => (cellSelect(curr, qsObject.model)),
									unselect: () => (cellUnselect(
										curr,
										qsObject.model
									)),
									...curr[0],
								});
							}
							return acc;
						},
						[] as any
					);

					let val: any =
						top === 0
							? newDataArray
							: [...rows.array, ...newDataArray];

					set_rows({
						array: val,
					});
				})
				.catch((err) => console.warn(err));
		qsObject?.model
			.searchListObjectFor('/qListObjectDef', searchText)
			.then((dataPage) => {
				if (dataPage === true) {
					getIt();
				}
			});
	}, [qsObject, rows.array.length, searchText]);

	const getMoreData = useCallback((position) => {
		if (!qsObject?.model) return;
		if (qsObject.model.layout.qListObject.qSize.qcy > position
			&& (!options.rowLimit || (position < options.rowLimit))) {
			getData(position);
		}
	}, [qsObject, rows.array.length, searchText]);

	useEffect(() => {
		if (!app || !definition) return;

		app.visualization.create(
			'listbox',
			definition,
			{ title },
		)
		.then(obj => {
			onObject(obj);
			set_qsObject(obj);

			obj.model.getListObjectData(
				'/qListObjectDef',
				[{
					qTop: 0,
					qLeft: 0,
					qHeight: 1000,
					qWidth: 1
				}]
			)
			.then(dataPage => {
				// NOTE! This modifies the return from QS and is *NOT* intuitive
				// This was requested to handle edge cases of dirty data from the QS side
				const newDataArray = [];
				dataPage[0].qMatrix.forEach((curr) => {
					if (curr[0].qText || !isNaN(curr[0].qNum)) {
						newDataArray.push({
							model: obj.model,
							clear: () => {
								return obj.model.clearSelections('/qListObjectDef');
							},
							select: () => {
								return cellSelect(curr, obj.model);
							},
							unselect: () => {
								return cellUnselect(curr, obj.model);
							},
							...curr[0]
						});
					}
				});

				set_rows({
					array: newDataArray
				});
			})
			.catch(err => console.warn(err));
		})
		.catch(err => console.warn(err));

	}, [JSON.stringify(definition)]); // This ensures this runs only when the definition changes

	// Makes search aware
	useEffect(() => {
		getData(0);
	}, [searchText]);

	useEffect(() => {
		return () => { qsObject?.close(); }
	}, [qsObject?.id]);

	useEffect(() => {
		if (!qsObject?.model) return;

		qsObject.model.getListObjectData(
			'/qListObjectDef',
			[{
				qTop: 0,
				qLeft: 0,
				qHeight: 1000,
				qWidth: 1
			}]
		)
		.then(dataPage => {
			const newDataArray = dataPage[0].qMatrix.map(val => ({
				model: qsObject.model,
				clear: () => (qsObject.model.clearSelections('/qListObjectDef')),
				select: () => (cellSelect(val, qsObject.model)),
				unselect: () => (cellUnselect(val, qsObject.model)),
				...val[0]
			}));

			set_rows({
				array: newDataArray
			});
		})
		.catch(err => console.warn(err));
	}, [QlikLastModified]);

	return {
		rows: rows.array,
		totalRow: qsObject?.model?.layout.qListObject.qSize.qcy || 0,
		getMore: (_) => getMoreData(rows.array.length),
	};
};

export default useQSListbox;
